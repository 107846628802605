import cx from "classnames";
import React from "react";
import IIcon from "./IIcon";

const Icon = ({
  name,
  small,
  color,
  onClick,
  base,
  active,
  disabled,
  className,
  inverted
}: IIcon) => {
  return (
    <i
      role="presentation"
      onKeyPress={onClick}
      onClick={onClick}
      className={cx(
        "icon",
        { color },
        { inverted },
        className,
        `icon-${name}`,
        { small: small === true },
        { "align-baseline": base },
        { disabled },
        { active }
      )}
    />
  );
};

export default Icon;
