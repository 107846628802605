import React, {
  useEffect,
  useContext,
  useCallback,
  useState,
  useLayoutEffect,
  useRef,
  useMemo
} from "react";

import {
  Loader,
  Card,
  Tile,
  Row,
  Button,
  Icon,
  Pill
} from "@react-gcc-eds/core";
import { VariableSizeList } from "react-window";

import { AuthenticationContext } from "../../../contexts/authentication-context";
import { CustomerContext } from "../../../contexts/customer-context";
import { LayoutContext } from "../../../contexts/layout-context";
import {
  SiteProjectsClient,
  BillOfMaterialItemDto,
  DeliveryProductListItemDto
} from "../../../domain/client-customer";
// TODO: To be aligned in story 21416
// import BomChangeReasonDialog from "./bom-change-reason-dialog/bom-change-reason-dialog";
import { ProductFilters } from "../../../domain/enums";
import {
  ComponentSpecificKeys,
  NamespaceKeys,
  GeneralKeys,
  BomSpecificKeys,
  MenuTitleKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";
import CenteredContainer from "../../common/layout/centered-container";
import ProductDetailsDialog, {
  DeliveryProductListItemInformation
} from "../../common/product-details-modal/product-details-dialog";
import { fromDplItemDtoToInformationItem } from "../../common/product-details-modal/product-details-utils";
import BomFilter from "./bom-filter";
import { BomFilters } from "./bom-filter/bom-filter-component";
import BomViewerList from "./bom-viewer-list";
import {
  itemIsSplitIntoMultipleMaterialCategories,
  // toBillOfMaterialItemRequestModel, // TODO: To be aligned in story 21416
  applyFiltersOnBom
} from "./bom-viewer-utils";

import "./bom-viewer.scss";

export type BillOfMaterialItemDtoEditState = {
  bomItem: BillOfMaterialItemDto;
  doesItemAppearMoreThanOnceOnBom: boolean;
  collapsed: boolean;
};

type Props = {
  siteProjectId: string;
};

const BomViewerComponent = ({ siteProjectId }: Props): JSX.Element => {
  //const { createInstantNotification } = useNotifications(); // TODO: To be aligned in story 21416
  const { setPageTitle } = useContext(LayoutContext);
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const { currentCustomer } = useContext(CustomerContext);
  const { translate } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);
  const [onError, setOnError] = useState<boolean>(false);
  const [originalBom, setOriginalBom] = useState<BillOfMaterialItemDto[]>();
  const [editBom, setEditBom] = useState<BillOfMaterialItemDtoEditState[]>();
  const [title, setTitle] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<ComponentSpecificKeys>();
  const [modalProduct, setModalProduct] =
    useState<DeliveryProductListItemDto>();
  // TODO: To be aligned in story 21416
  // const [validations, setValidations] = useState<
  //   DeliveryRequisitionChangesValidationViewModel[]
  // >();
  // const [supplementaryReasonCodes, setSupplementaryReasonCodes] = useState<
  //   NameIdViewModel[]
  // >();
  const [hasBomChanged, setHasBomChanged] = useState<boolean>(false);
  const listRef: React.RefObject<VariableSizeList> | null =
    useRef<VariableSizeList>(null);
  const [editable, setEditable] = useState<boolean>();
  const dplInformationItem = useMemo<
    DeliveryProductListItemInformation | undefined
  >(() => fromDplItemDtoToInformationItem(modalProduct), [modalProduct]);
  const [hideFilters, setHideFilters] = useState<boolean>(true);
  const [filters, setFilters] = useState<BomFilters>({
    Commodity: "",
    EricssonProductNumber: "",
    MaterialCategory: "",
    SourcingProvider: ""
  });
  const filteredBom = useMemo<BillOfMaterialItemDtoEditState[]>(
    () => applyFiltersOnBom(editBom, filters),
    [editBom, filters]
  );

  useEffect(() => {
    const originalBomString = JSON.stringify(originalBom);
    const editBomString = JSON.stringify(editBom?.map((i) => i.bomItem));
    setHasBomChanged(originalBomString !== editBomString);
  }, [originalBom, editBom]);

  const getBom = useCallback(async (): Promise<void> => {
    const customerIdentifier =
      currentCustomer && currentCustomer.HeaderIdentifier;
    if (!customerIdentifier) {
      setErrorMessage(ComponentSpecificKeys.CustomersErrorMessageTitle);
      return;
    }
    try {
      setLoading(true);
      setHasBomChanged(false);
      // TODO: To be aligned in story 21416
      // setValidations(undefined);
      setEditBom(undefined);
      setOriginalBom(undefined);
      const fetchedBom = await new SiteProjectsClient(
        await getCustomerConfigurationProvider()
      ).getBoM(siteProjectId, false, customerIdentifier);

      setTitle(fetchedBom.SiteProjectDisplayName);
      setOriginalBom(fetchedBom.BillOfMaterialItems);
      setEditBom(
        fetchedBom.BillOfMaterialItems?.map((i) => ({
          bomItem: i,
          doesItemAppearMoreThanOnceOnBom:
            itemIsSplitIntoMultipleMaterialCategories(
              i,
              fetchedBom.BillOfMaterialItems ?? []
            ),
          collapsed: true
        }))
      );
      setEditable(fetchedBom.IsEditable);
    } catch {
      setOnError(true);
    } finally {
      setLoading(false);
    }
  }, [currentCustomer, getCustomerConfigurationProvider, siteProjectId]);

  useEffect(() => {
    getBom();
  }, [getBom]);

  // TODO: To be aligned in story 21416
  // const getChangeRequestReasonCodes = useCallback(async (): Promise<void> => {
  //   const customerIdentifier =
  //     currentCustomer && currentCustomer.HeaderIdentifier;
  //   if (!customerIdentifier) {
  //     setErrorMessage(ComponentSpecificKeys.CustomersErrorMessageTitle);
  //     return;
  //   }
  //   try {
  //     setLoading(true);
  //     const supplementaryReasonCodes = await new SupplementaryReasonCodeClient(
  //       await getCustomerConfigurationProvider()
  //     ).getSupplementaryReasonCodes(customerIdentifier);
  //     setSupplementaryReasonCodes(supplementaryReasonCodes);
  //   } catch {
  //     setOnError(true);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [currentCustomer, getCustomerConfigurationProvider]);

  // useEffect(() => {
  //   getChangeRequestReasonCodes();
  // }, [getChangeRequestReasonCodes]);

  const validateBomChanges = useCallback(
    async (): Promise<void> => {
      // TODO: To be aligned in story 21416
      // const customerIdentifier =
      //   currentCustomer && currentCustomer.HeaderIdentifier;
      // if (!customerIdentifier) {
      //   setErrorMessage(ComponentSpecificKeys.CustomersErrorMessageTitle);
      //   return;
      // }
      // const bomValidationItems = editBom?.map(i =>
      //   toBillOfMaterialItemRequestModel(i.bomItem)
      // );
      // const model: ValidateBillOfMaterialRequestModel = {
      //   BillOfMaterialItems: bomValidationItems ?? []
      // };
      // try {
      //   setLoading(true);
      //   const validations = await new SiteProjectsClient(
      //     await getCustomerConfigurationProvider()
      //   ).validateBillOfMaterialChanges(siteProjectId, model, customerIdentifier);
      //   setValidations(validations);
      // } catch {
      //   createInstantNotification(
      //     translate(NamespaceKeys.BomSpecific, BomSpecificKeys.CouldNotSaveBom),
      //     translate(
      //       NamespaceKeys.ComponentSpecific,
      //       ComponentSpecificKeys.BomChangeValidationErrorDescription
      //     ),
      //     "warning",
      //     "triangle-warning",
      //     5000
      //   );
      // } finally {
      //   setLoading(false);
      // }
    },
    [
      // createInstantNotification,
      // currentCustomer,
      // siteProjectId,
      // editBom,
      // getCustomerConfigurationProvider,
      // translate
    ]
  );

  // const saveBom = useCallback(
  //   async (
  //     upsertBomRequestModel: UpsertBillOfMaterialRequestModel
  //   ): Promise<void> => {
  //     const customerIdentifier =
  //       currentCustomer && currentCustomer.HeaderIdentifier;
  //     if (!customerIdentifier) {
  //       setErrorMessage(ComponentSpecificKeys.CustomersErrorMessageTitle);
  //       return;
  //     }

  //     setLoading(true);
  //     try {
  //       await new SiteProjectsClient(
  //         await getCustomerConfigurationProvider()
  //       ).upsertBillOfMaterial(
  //         siteProjectId,
  //         upsertBomRequestModel,
  //         customerIdentifier
  //       );
  //       createInstantNotification(
  //         translate(NamespaceKeys.BomSpecific, BomSpecificKeys.BomSaved),
  //         translate(
  //           NamespaceKeys.BomSpecific,
  //           BomSpecificKeys.TheBomWasSavedSuccessfully
  //         ),
  //         "default",
  //         "check",
  //         5000
  //       );
  //       getBom();
  //     } catch {
  //       createInstantNotification(
  //         translate(NamespaceKeys.BomSpecific, BomSpecificKeys.CouldNotSaveBom),
  //         translate(
  //           NamespaceKeys.ComponentSpecific,
  //           ComponentSpecificKeys.BomSavingErrorDescription
  //         ),
  //         "warning",
  //         "triangle-warning",
  //         5000
  //       );
  //     } finally {
  //       setLoading(false);
  //     }
  //   },

  //   [
  //     currentCustomer,
  //     getCustomerConfigurationProvider,
  //     siteProjectId,
  //     createInstantNotification,
  //     translate,
  //     getBom
  //   ]
  // );

  // const saveBoMIfChanged = useCallback(
  //   async (
  //     changeReasons: BillOfMaterialDrChangeReasonRequestModel[] | undefined
  //   ): Promise<void> => {
  //     const customerIdentifier =
  //       currentCustomer && currentCustomer.HeaderIdentifier;
  //     if (!customerIdentifier) {
  //       setErrorMessage(ComponentSpecificKeys.CustomersErrorMessageTitle);
  //       return;
  //     }

  //     if (!editBom) {
  //       return;
  //     }

  //     const bomItemsRequestModel = editBom.map(i =>
  //       toBillOfMaterialItemRequestModel(i.bomItem)
  //     );

  //     const upsertBomRequestModel: UpsertBillOfMaterialRequestModel = {
  //       BillOfMaterialItems: bomItemsRequestModel,
  //       ChangeReasons: changeReasons
  //     };

  //     saveBom(upsertBomRequestModel);
  //   },
  //   [currentCustomer, editBom, saveBom]
  // );

  useEffect(() => {
    const titleToSet =
      title ||
      translate(NamespaceKeys.MenuTitles, MenuTitleKeys.SiteProjectMenuTitle);
    setPageTitle && setPageTitle({ title: titleToSet });
  }, [translate, setPageTitle, title]);
  const [listContainerDomObjectRef, setListContainerDomObjectRef] =
    useState<HTMLDivElement>();
  const [listContainerHeight, setListContainerHeight] = useState<number>(0);

  const refFn: (instance: HTMLDivElement | null) => void = useCallback(
    (node) => {
      if (node) {
        setListContainerDomObjectRef(node);
        setListContainerHeight(node.getBoundingClientRect().height);
      }
    },
    []
  );

  useLayoutEffect(() => {
    listContainerDomObjectRef &&
      setListContainerHeight(
        listContainerDomObjectRef.getBoundingClientRect().height
      );
  }, [listContainerDomObjectRef, hideFilters, filters, filteredBom]);

  const handleOnCloseDialog: () => void = () => {
    setModalProduct(undefined);
  };

  const handleOnOpenProductDetails = useCallback(
    (item: BillOfMaterialItemDtoEditState) => {
      if (!editBom) return;
      const index = editBom.indexOf(item);
      setModalProduct(editBom[index].bomItem.DeliveryProductListItem);
    },
    [editBom]
  );

  const onQuantityChanged = useCallback(
    (item: BillOfMaterialItemDtoEditState, newQuantity: number): void => {
      if (newQuantity <= 0) {
        newQuantity = 1;
      }

      setEditBom((prev) => {
        if (!prev) return;
        const index = prev.indexOf(item);
        const changedItem = prev[index];
        const newItems = prev?.map((i) => {
          if (
            i.bomItem.DeliveryProductListItem?.Id ===
              changedItem.bomItem.DeliveryProductListItem?.Id &&
            i.bomItem.MaterialCategory === changedItem.bomItem.MaterialCategory
          ) {
            return {
              ...i,
              bomItem: {
                ...i.bomItem,
                quantity: newQuantity
              }
            };
          }
          return i;
        });
        setHasBomChanged(true);
        return newItems;
      });
    },
    []
  );

  const onItemRemoved = useCallback(
    (removedItem: BillOfMaterialItemDtoEditState) => {
      setEditBom((prev) => {
        if (!prev) return;
        const removedIndex = prev.indexOf(removedItem);
        const filteredItems = prev?.filter(
          (_item, index) => index !== removedIndex
        );
        setHasBomChanged(true);
        listRef?.current?.resetAfterIndex(removedIndex, true);
        return filteredItems;
      });
    },
    []
  );

  const onSourcingProviderChanged = useCallback(
    (
      item: BillOfMaterialItemDtoEditState,
      newSourcingProvider: string
    ): void => {
      setEditBom((prev) => {
        if (!prev) return;
        const index = prev.indexOf(item);
        const changedItem = prev[index];
        const newItems = prev?.map((i) => {
          if (
            i.bomItem.DeliveryProductListItem?.Id ===
              changedItem.bomItem.DeliveryProductListItem?.Id &&
            i.bomItem.MaterialCategory === changedItem.bomItem.MaterialCategory
          ) {
            return {
              ...i,
              bomItem: {
                ...i.bomItem,
                sourcingProvider: newSourcingProvider
              }
            };
          }
          return i;
        });
        setHasBomChanged(true);
        return newItems;
      });
    },
    []
  );
  const onMaterialCategoryChanged = useCallback(
    (
      item: BillOfMaterialItemDtoEditState,
      newMaterialCategory: string
    ): void => {
      setEditBom((prev) => {
        if (!prev) return;
        const index = prev.indexOf(item);
        const changedItem = prev[index];
        const newItems = prev?.map((i) => {
          if (
            i.bomItem.DeliveryProductListItem?.Id ===
              changedItem.bomItem.DeliveryProductListItem?.Id &&
            i.bomItem.MaterialCategory === changedItem.bomItem.MaterialCategory
          ) {
            return {
              ...i,
              bomItem: {
                ...i.bomItem,
                materialCategory: newMaterialCategory
              }
            };
          }
          return i;
        });
        setHasBomChanged(true);
        return newItems;
      });
    },
    []
  );

  const onUndo = useCallback(() => {
    setEditBom(
      originalBom?.map(
        (i: BillOfMaterialItemDto): BillOfMaterialItemDtoEditState => ({
          bomItem: i,
          doesItemAppearMoreThanOnceOnBom:
            itemIsSplitIntoMultipleMaterialCategories(i, originalBom ?? []),
          collapsed: true
        })
      )
    );
    setHasBomChanged(false);
    listRef?.current?.resetAfterIndex(0, true);
  }, [originalBom]);

  // const onChangeReasonDialogCancel = (): void => setValidations(undefined);

  const handleOnItemCollapseToggle = useCallback(
    (item: BillOfMaterialItemDtoEditState) => {
      setEditBom((prev) => {
        if (!prev) return;
        const itemIndexToToggle = prev.indexOf(item);
        const matchingItem = prev[itemIndexToToggle];
        if (matchingItem) {
          matchingItem.collapsed = !matchingItem.collapsed;
          listRef?.current?.resetAfterIndex(itemIndexToToggle, true);
        }
        return prev;
      });
    },
    []
  );

  const handleOnNoteChanged: (
    item: BillOfMaterialItemDtoEditState,
    newValue: string
  ) => void = useCallback((itemToUpdate, newValue) => {
    setEditBom((prev) => {
      if (!prev) return;
      const index = prev.indexOf(itemToUpdate);
      const item = prev[index];
      const items = prev.concat();
      items[index] = {
        ...item,
        bomItem: {
          ...item.bomItem,
          Note: newValue
        }
      };
      return items;
    });
  }, []);
  const handleToggleFilterClick = useCallback(() => {
    setListContainerHeight(0);
    setHideFilters((prev) => !prev);
  }, []);

  const handleOnFiltersChanged = useCallback(
    (filters: BomFilters) => {
      setListContainerHeight(0);
      setFilters(filters);

      title &&
        setPageTitle &&
        (loading
          ? setPageTitle({ title: title })
          : setPageTitle({
              title: title,
              actions: <Icon name="filter" onClick={handleToggleFilterClick} />
            }));
    },
    [handleToggleFilterClick, loading, setPageTitle, title]
  );

  const handleOnFilterRemoved = useCallback((title: ProductFilters): void => {
    setFilters((prev) => ({ ...prev, [title]: "" }));
  }, []);

  const handleOnRemoveCommodityFilter = useCallback(
    (): void => handleOnFilterRemoved(ProductFilters.Commodity),
    [handleOnFilterRemoved]
  );

  const handleOnRemoveMaterialCategoryFilter = useCallback(
    (): void => handleOnFilterRemoved(ProductFilters.MaterialCategory),
    [handleOnFilterRemoved]
  );

  const handleOnRemoveSourcingProviderFilter = useCallback(
    (): void => handleOnFilterRemoved(ProductFilters.SourcingProvider),
    [handleOnFilterRemoved]
  );

  const handleOnRemoveEricssonProductNumberFilter = useCallback(
    (): void => handleOnFilterRemoved(ProductFilters.EricssonProductNumber),
    [handleOnFilterRemoved]
  );
  const handleOnFilterCommodity: (commodity: string) => void = useCallback(
    (commodity) => setFilters((prev) => ({ ...prev, Commodity: commodity })),
    []
  );

  const handleOnFilterMaterialCategory: (materialCategory: string) => void =
    useCallback(
      (materialCategory) =>
        setFilters((prev) => ({ ...prev, MaterialCategory: materialCategory })),
      []
    );

  const handleOnFilterSourcingProvider: (sourcingProvider: string) => void =
    useCallback(
      (sourcingProvider) =>
        setFilters((prev) => ({ ...prev, SourcingProvider: sourcingProvider })),
      []
    );

  const handleOnSearchChange: (newValue: string) => void = useCallback(
    (newValue) =>
      setFilters((prev) => ({ ...prev, EricssonProductNumber: newValue })),
    []
  );

  return loading ? (
    <CenteredContainer>
      <Loader size="large" />
    </CenteredContainer>
  ) : onError ? (
    <Card
      title={translate(
        NamespaceKeys.ComponentSpecific,
        ComponentSpecificKeys.BomErrorMessageTitle
      )}
      content={translate(
        NamespaceKeys.General,
        GeneralKeys.PleaseTryAgainLater
      )}
      actions={[
        <Button
          key="reload"
          primary
          onClick={(): void => window.location.reload()}
        >
          {translate(NamespaceKeys.General, GeneralKeys.Reload)}
        </Button>
      ]}
    />
  ) : errorMessage ? (
    <Tile title={translate(NamespaceKeys.ComponentSpecific, errorMessage)}>
      {translate(NamespaceKeys.General, GeneralKeys.PleaseTryAgainLater)}
    </Tile>
  ) : originalBom && !originalBom.length ? (
    <CenteredContainer>
      <div className="centered-text">
        {translate(NamespaceKeys.BomSpecific, BomSpecificKeys.BomIsEmpty)}
      </div>
    </CenteredContainer>
  ) : (
    <>
      <div className="bom-main">
        {editBom && (
          <BomFilter
            filters={filters}
            hidden={hideFilters}
            bom={editBom}
            onFiltersChanged={handleOnFiltersChanged}
            onToggleFilter={handleToggleFilterClick}
            onFilterCommodityChanged={handleOnFilterCommodity}
            onFilterMaterialCategoryChanged={handleOnFilterMaterialCategory}
            onFilterSourcingProviderChanged={handleOnFilterSourcingProvider}
            onSearchChanged={handleOnSearchChange}
          />
        )}
        {editBom && (
          <Row className="bom-viewer">
            <Tile
              title={translate(
                NamespaceKeys.BomSpecific,
                BomSpecificKeys.BillOfMaterial
              )}
              actions={
                (hasBomChanged && (
                  <>
                    <Button onClick={onUndo} iconName="undo">
                      {translate(NamespaceKeys.General, GeneralKeys.Undo)}
                    </Button>
                    <Button
                      primary
                      onClick={validateBomChanges}
                      iconName="check"
                    >
                      {translate(NamespaceKeys.General, GeneralKeys.Save)}
                    </Button>
                  </>
                )) ||
                []
              }
            >
              <>
                {filters && (
                  <div className="pill-container">
                    <Pill key="1" onRemove={handleOnRemoveCommodityFilter}>
                      {filters.Commodity}
                    </Pill>
                    <Pill
                      key="2"
                      onRemove={handleOnRemoveMaterialCategoryFilter}
                    >
                      {filters.MaterialCategory && filters.MaterialCategory}
                    </Pill>
                    <Pill
                      key="3"
                      onRemove={handleOnRemoveSourcingProviderFilter}
                    >
                      {filters.SourcingProvider && filters.SourcingProvider}
                    </Pill>
                    <Pill
                      key="4"
                      onRemove={handleOnRemoveEricssonProductNumberFilter}
                    >
                      {filters.EricssonProductNumber &&
                        filters.EricssonProductNumber}
                    </Pill>
                  </div>
                )}
                <div ref={refFn} className="list-container">
                  <BomViewerList
                    height={listContainerHeight}
                    ref={listRef}
                    width="100%"
                    items={filteredBom}
                    editable={editable}
                    onNoteChanged={handleOnNoteChanged}
                    onOpenProductDetails={handleOnOpenProductDetails}
                    onQuantityChanged={onQuantityChanged}
                    onItemRemoved={onItemRemoved}
                    onSourcingProviderChanged={onSourcingProviderChanged}
                    onMaterialCategoryChanged={onMaterialCategoryChanged}
                    onItemCollapseToggle={handleOnItemCollapseToggle}
                  />
                </div>
              </>
            </Tile>
          </Row>
        )}
        {dplInformationItem && (
          <ProductDetailsDialog
            item={dplInformationItem}
            showModal
            onClose={handleOnCloseDialog}
          />
        )}
        {/* // TODO: To be aligned in story 21416 */}
        {/* {validations && (
          <BomChangeReasonDialog
            siteProjectId={siteProjectId}
            validations={validations}
            onSave={saveBoMIfChanged}
            onCancel={onChangeReasonDialogCancel}
            supplementaryReasonCodes={supplementaryReasonCodes}
          />
        )} */}
      </div>
    </>
  );
};

export default BomViewerComponent;
