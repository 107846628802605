import React, { useContext, useMemo } from "react";

import {
  Accordion,
  AccordionItem,
  Button,
  Column,
  Loader,
  Row
} from "@react-gcc-eds/core";
import { useParams } from "react-router";

import UrlParameters from "../../../../domain/enums/url-parameters";
import {
  GeneralKeys,
  NamespaceKeys,
  ReceivingMaterialSpecificKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import CenteredContainer from "../../../common/layout/centered-container";
import { ReceiveMaterialContext } from "../contexts/receive-material-context";
import { ComponentViews } from "../shared/receive-material-models";
import ReceiveMaterialScanSerialNumbersTransferList from "./scan-serial-numbers-transfer-list";

import "./scan-serial-numbers.scss";

type MatchProps = {
  [UrlParameters.shipmentReferenceNumber]?: string;
};

const ReceiveMaterialScanSerialNumbersTransferListComponent =
  (): React.ReactElement => {
    const { shipmentReferenceNumber } = useParams<MatchProps>();
    const { translate } = useTranslation();
    const { setCurrentView, transferDetailsStateItems } = useContext(
      ReceiveMaterialContext
    );

    const loading = false;
    const errorState = false;

    const serializedTransferStateItems = useMemo(() => {
      return transferDetailsStateItems.filter(
        (x) => x.HandlingUnits.length > 0
      );
    }, [transferDetailsStateItems]);

    const renderError = (): React.ReactElement => {
      return (
        <CenteredContainer>
          <div className="empty-state">
            <div className="message">
              <p>
                {translate(
                  NamespaceKeys.ReceivingMaterialSpecific,
                  ReceivingMaterialSpecificKeys.AddSerialNumber
                )}
              </p>
            </div>
          </div>
        </CenteredContainer>
      );
    };

    const renderLoading = () => {
      return (
        <CenteredContainer>
          <Loader size="large" />
        </CenteredContainer>
      );
    };

    const renderTransfersList = () => {
      return (
        <div className="rm-container">
          <div className="rm-header">{shipmentReferenceNumber}</div>
          <div className="rm-body">
            <div className="rm-scan-serial-numbers">
              <Accordion className="rm-scan-serial-numbers-accordion" lines>
                {serializedTransferStateItems?.map(
                  (serializedTransferStateItem, i) => {
                    return (
                      <AccordionItem
                        className="rm-scan-serial-numbers-accordion-title"
                        defaultOpen={i === 0}
                        key={serializedTransferStateItem.MaterialTransferId}
                        title={serializedTransferStateItem.SiteProjectName}
                      >
                        <ReceiveMaterialScanSerialNumbersTransferList
                          materialTransferId={
                            serializedTransferStateItem.MaterialTransferId
                          }
                          handlingUnits={
                            serializedTransferStateItem.HandlingUnits
                          }
                        />
                      </AccordionItem>
                    );
                  }
                )}
              </Accordion>
            </div>
          </div>
          <div className="rm-footer">
            <Row className="rm-row">
              <Column sm={12}>
                <Button className="primary " onClick={handleOnClickComplete}>
                  {translate(NamespaceKeys.General, GeneralKeys.Close)}
                </Button>
              </Column>
            </Row>
          </div>
        </div>
      );
    };

    const handleOnClickComplete = (): void => {
      setCurrentView?.(ComponentViews.TransferList);
    };

    return errorState
      ? renderError()
      : loading
      ? renderLoading()
      : renderTransfersList();
  };

export default ReceiveMaterialScanSerialNumbersTransferListComponent;
