import React from "react";

import { TextField } from "@react-gcc-eds/core";

import ChecklistStandardItem from "./generic/checklist-standard-item";
import { SingleItemComponent, SingleItemProps } from "./types";

const PrefilledElement: SingleItemComponent = ({
  item,
  onValueChanged
}: SingleItemProps) => (
  <ChecklistStandardItem item={item} onValueChanged={onValueChanged}>
    <TextField value={item.Values?.[0] ?? ""} fullWidth readonly />
  </ChecklistStandardItem>
);

export default PrefilledElement;
