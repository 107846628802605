import React, { useCallback, ReactNode, useMemo } from "react";

import { Radiobutton } from "@react-gcc-eds/core";

import { ChecklistItemOptionViewModel } from "../../../../domain/client-obsolete";
import ChecklistStandardItem from "./generic/checklist-standard-item";
import { SingleItemComponent, SingleItemProps } from "./types";

const SingleSelectElement: SingleItemComponent = ({
  item,
  onValueChanged
}: SingleItemProps) => {
  const isChecked = useCallback(
    (optionModel: ChecklistItemOptionViewModel): boolean =>
      item.Values?.includes(optionModel.Option ?? "") ?? false,
    [item.Values]
  );

  const handleOnChange = useCallback(
    (optionModel: ChecklistItemOptionViewModel): void =>
      onValueChanged({
        ...item,
        Values: [optionModel.Option ?? ""]
      }),
    [item, onValueChanged]
  );

  const children = useMemo<ReactNode>(
    () =>
      item.Options?.map((o: ChecklistItemOptionViewModel) => {
        const callback = (): void => handleOnChange(o);

        return (
          <div onClick={callback} className="switch-container" key={o.Id}>
            <div className="title">{o.Option}</div>
            <Radiobutton
              key={o.Option}
              value={isChecked(o)}
              onChange={callback}
              text=""
            />
          </div>
        );
      }),
    [item.Options, isChecked, handleOnChange]
  );

  return (
    <ChecklistStandardItem item={item} onValueChanged={onValueChanged}>
      {children}
    </ChecklistStandardItem>
  );
};

export default SingleSelectElement;
