import React, { ReactElement, useEffect, useState, useCallback } from "react";

import {
  DeliveryProductListItemModel,
  MaterialTransferItemStatus,
  SerialNumbersNeeded
} from "../../../../../domain/client-customer";
import { ComponentSpecificKeys } from "../../../../../translation/dictionary-keys";
import { SerializedItemState } from "../../../receive-material-overview/shared/receive-material-models";
import { WizardPages } from "../material-transfer-validation-component";
import SerialNumberValidatorView from "./serial-number-validator-view";

export type SerialNumberValidationItem = {
  serialNumbersValidated: boolean;
  dplItem: DeliveryProductListItemModel;
  materialCategory: string;
  ericssonSerialNumber: string | undefined;
  customerSerialNumber: string | undefined;
  materialTransferItemId: string | undefined;
  serialNumbersNeeded: SerialNumbersNeeded | undefined;
  status: MaterialTransferItemStatus;
  serialKey?: number;
};

export enum SerialNumberType {
  None,
  EricssonSerialNumber,
  CustomerSerialNumber
}

type Props = {
  serializedItems: SerializedItemState[];
  onSmartScanClicked: () => void;
  onSerializedItemsUpdated: (
    serializedItemsToUpdate: SerializedItemState[]
  ) => void;
  onStepCompletionStatusChanged: (
    page: WizardPages,
    isCompletable: boolean
  ) => void;
};
const SerialNumberValidatorComponent = ({
  serializedItems,
  onSmartScanClicked,
  onStepCompletionStatusChanged,
  onSerializedItemsUpdated
}: Props): ReactElement => {
  const [errorMessage, setErrorMessage] = useState<ComponentSpecificKeys>();

  useEffect(() => {
    onStepCompletionStatusChanged(WizardPages.SerialNumberValidator, true);
  }, [onStepCompletionStatusChanged]);

  const handleOnItemChange = useCallback(
    (item: SerialNumberValidationItem) => {
      const itemToUpdate = serializedItems.find(
        (si) =>
          si.billOfMaterialItemIdentity.Value === item.materialTransferItemId &&
          si.serialKey === item.serialKey
      );
      if (!itemToUpdate) {
        setErrorMessage(
          ComponentSpecificKeys.AnErrorOccurredPleaseTryAgainLater
        );
        return;
      }
      const updatedItem: SerializedItemState = {
        ...itemToUpdate,
        customerSerialNumber: item.customerSerialNumber,
        ericssonSerialNumber: item.ericssonSerialNumber,
        serialNumbersValidated: item.serialNumbersValidated
      };
      onSerializedItemsUpdated([updatedItem]);
    },
    [onSerializedItemsUpdated, serializedItems]
  );

  return (
    <SerialNumberValidatorView
      serializedItems={serializedItems}
      errorMessage={errorMessage}
      onItemChange={handleOnItemChange}
      onSmartScanClicked={onSmartScanClicked}
    />
  );
};

export default SerialNumberValidatorComponent;
