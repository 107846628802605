import React from "react";

import { useParams } from "react-router";

import MaterialTransferDetails from "../components/material-handling/material-transfer/details";
import UrlParameters from "../domain/enums/url-parameters";

type MatchProps = {
  [UrlParameters.materialTransferId]?: string;
};

const MaterialTransferDetailsRoute = (): React.ReactElement | null => {
  const { materialTransferId } = useParams<MatchProps>();

  return (
    (materialTransferId && (
      <MaterialTransferDetails materialTransferId={materialTransferId} />
    )) ||
    null
  );
};

export default MaterialTransferDetailsRoute;
