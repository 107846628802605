import React, { useMemo, useState, useCallback } from "react";

import {
  ChecklistItemImageViewModel,
  RotationEnum
} from "../../../../../../domain/client-obsolete";
import {
  ChecklistSpecificKeys,
  NamespaceKeys
} from "../../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../../translation/translation-utils";
import { rotateBase64 } from "../../../../../../utils/utils";
import {
  ChecklistItemEditModel,
  ChecklistItemImageEditModel,
  ChecklistItemImageRotationEditModel
} from "../../../checklist-models";
import SavedImageDetailsDialog from "../image-details-dialog/saved-image-details-dialog";
import UnsavedImageDetailsDialog from "../image-details-dialog/unsaved-image-details-dialog";
import SavedImage from "./saved-image";
import UnsavedImage from "./unsaved-image";

import "./uploaded-images-container.scss";

const getNewRotation = (
  currentRotation: RotationEnum | undefined
): RotationEnum | undefined => {
  switch (currentRotation) {
    case undefined:
      return RotationEnum.Ninety;
    case RotationEnum.Ninety:
      return RotationEnum.OneHundredEighty;
    case RotationEnum.OneHundredEighty:
      return RotationEnum.TwoHundredSeventy;
    case RotationEnum.TwoHundredSeventy:
      return undefined;
  }
};

type Props = {
  item: ChecklistItemEditModel;
  onUnsavedImageUpdated: (updatedImage: ChecklistItemImageEditModel) => void;
  onSavedImageUpdated: (
    updatedImage: ChecklistItemImageRotationEditModel
  ) => void;
  onDeleteUnsavedImage: (image: ChecklistItemImageEditModel) => void;
  onDeleteSavedImage: (image: ChecklistItemImageViewModel) => void;
};

const UploadedImagesContainer = ({
  item,
  onUnsavedImageUpdated,
  onSavedImageUpdated,
  onDeleteUnsavedImage,
  onDeleteSavedImage
}: Props): React.ReactElement => {
  const [shownSavedImage, setShownSavedImage] =
    useState<ChecklistItemImageViewModel>();
  const [shownUnsavedImage, setShownUnsavedImage] =
    useState<ChecklistItemImageEditModel>();

  const { translate } = useTranslation();

  const noImagesUploaded: boolean = useMemo(
    () => (item.SavedImages?.length ?? 0) + item.unsavedImages.length < 1,
    [item.SavedImages, item.unsavedImages.length]
  );

  const savedImages = useMemo<React.ReactElement[]>(
    () =>
      item.SavedImages?.map((savedImage) => (
        <SavedImage
          key={savedImage.Id}
          image={savedImage}
          showImageDetails={setShownSavedImage}
        />
      )) ?? [],
    [item.SavedImages]
  );

  const unsavedImages = useMemo<React.ReactElement[]>(
    () =>
      item.unsavedImages.map((image) => {
        return (
          <UnsavedImage
            key={`${image.file.name}${image.file.size}${image.timestampInTicks}`}
            image={image}
            onImageUpdated={onUnsavedImageUpdated}
            showImageDetails={setShownUnsavedImage}
          />
        );
      }),
    [item.unsavedImages, onUnsavedImageUpdated]
  );

  const handleOnCloseSavedImageDetails = useCallback(
    () => setShownSavedImage(undefined),
    []
  );

  const handleOnDeleteSavedImage = useCallback(
    (image: ChecklistItemImageViewModel) => {
      setShownSavedImage(undefined);
      onDeleteSavedImage(image);
    },
    [onDeleteSavedImage]
  );

  const handleOnRotateCWUnsavedImage = useCallback(
    async (image: ChecklistItemImageEditModel): Promise<void> => {
      if (!image.hostedUrl) return;
      const rotatedBase64 = await rotateBase64(image.hostedUrl);
      onUnsavedImageUpdated({ ...image, hostedUrl: rotatedBase64 });
    },
    [onUnsavedImageUpdated]
  );

  const handleOnRotateCWSavedImage = useCallback(
    async (image: ChecklistItemImageRotationEditModel): Promise<void> => {
      const newRotation = getNewRotation(image.rotation);
      onSavedImageUpdated({ ...image, rotation: newRotation });
    },
    [onSavedImageUpdated]
  );

  const handleOnCloseUnsavedImageDetails = useCallback(
    () => setShownUnsavedImage(undefined),
    []
  );

  const handleOnDeleteUnsavedImage = useCallback(
    (image: ChecklistItemImageEditModel) => {
      setShownUnsavedImage(undefined);
      onDeleteUnsavedImage(image);
    },
    [onDeleteUnsavedImage]
  );

  return (
    <div className="image-container">
      {savedImages}
      {unsavedImages}
      {noImagesUploaded && (
        <span className="image-container-no-images-textfield">
          {translate(
            NamespaceKeys.ChecklistSpecific,
            ChecklistSpecificKeys.PressTheButtonBelowToUploadImages
          )}
        </span>
      )}
      {shownSavedImage && (
        <SavedImageDetailsDialog
          image={shownSavedImage}
          onDelete={handleOnDeleteSavedImage}
          onClose={handleOnCloseSavedImageDetails}
          onRotateCW={handleOnRotateCWSavedImage}
        />
      )}
      {shownUnsavedImage && (
        <UnsavedImageDetailsDialog
          image={shownUnsavedImage}
          onDelete={handleOnDeleteUnsavedImage}
          onClose={handleOnCloseUnsavedImageDetails}
          onRotateCW={handleOnRotateCWUnsavedImage}
        />
      )}
    </div>
  );
};

export default UploadedImagesContainer;
