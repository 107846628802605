import React from "react";

import ReceiveMaterialValidationComponent from "./receive-material-validation-component";
type Props = {
  shipmentReferenceNumber: string;
};

const ReceiveMaterialValidationWrapper = ({
  shipmentReferenceNumber
}: Props): JSX.Element => {
  return (
    <ReceiveMaterialValidationComponent
      shipmentReferenceNumber={shipmentReferenceNumber}
    />
  );
};

export default ReceiveMaterialValidationWrapper;
