import { useState, useEffect } from "react";

export enum ScreenOrientation {
  Portrait,
  Landscape
}

export type windowDimensions = {
  width: number;
  height: number;
  screenOrientation: ScreenOrientation;
};

const getWindowDimensions = (): windowDimensions => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
    screenOrientation:
      height >= width ? ScreenOrientation.Portrait : ScreenOrientation.Landscape
  };
};

const useWindowDimensions = (): windowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = (): void => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return (): void => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export default useWindowDimensions;
