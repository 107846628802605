import React, { useCallback, useContext, useState, useEffect } from "react";

import { AuthenticationContext } from "../../../../contexts/authentication-context";
import { CustomerContext } from "../../../../contexts/customer-context";
import {
  BillOfMaterialDetailedModel,
  SiteProjectsClient
} from "../../../../domain/client-customer";
import { ComponentSpecificKeys } from "../../../../translation/dictionary-keys";
import BomTileView from "./bom-tile-view";

type Props = {
  siteProjectId: string | undefined;
};

const BomTileComponent = ({ siteProjectId }: Props): JSX.Element => {
  const { currentCustomer } = useContext(CustomerContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [bom, setBom] = useState<BillOfMaterialDetailedModel>();
  const [errorMessage, setErrorMessage] = useState<ComponentSpecificKeys>();
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );

  const getBom = useCallback(async () => {
    const customerIdentifier =
      currentCustomer && currentCustomer.HeaderIdentifier;
    if (!customerIdentifier || !siteProjectId) {
      setErrorMessage(ComponentSpecificKeys.CustomersErrorMessageTitle);
      return;
    }
    setLoading(true);
    new SiteProjectsClient(await getCustomerConfigurationProvider())
      .getBoM(siteProjectId, false, customerIdentifier)
      .then((fetchedBom) => {
        setBom(fetchedBom);
      })
      .catch(() => {
        setErrorMessage(ComponentSpecificKeys.BomErrorMessageTitle);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getCustomerConfigurationProvider, siteProjectId, currentCustomer]);

  useEffect(() => {
    getBom();
  }, [getBom]);

  return (
    <BomTileView
      bom={bom}
      loading={loading}
      errorMessage={errorMessage}
      siteProjectId={siteProjectId}
    />
  );
};

export default BomTileComponent;
