import React, { useCallback, useMemo, useState } from "react";

import { Button, Dialog, TextField } from "@react-gcc-eds/core";

import {
  NamespaceKeys,
  GeneralKeys,
  DocumentSpecificKeys
} from "../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../translation/translation-utils";
import { DocumentModelState } from "../documents-tile-component";

import "../documents-tile.scss";

const keepProtocolOrAddHttp = (value?: string): string | undefined =>
  value?.match(`[a-z]*?://?[-a-zA-Z0-9@:%._+~#=]{2,256}`)
    ? value
    : `http://${value}`;

type Props = {
  onSave: (title: string, url: string) => void;
  onClose: () => void;
  existingDocuments: DocumentModelState[] | undefined;
};

const DocumentsTileAddLinkDialog = ({
  onSave,
  onClose,
  existingDocuments
}: Props): React.ReactElement => {
  const { translate } = useTranslation();
  const [name, setName] = useState<string>();
  const [url, setUrl] = useState<string>();

  const nameLengthValidationMessage = useMemo<string | undefined>(
    () =>
      (name?.length ?? 0) <= 2
        ? translate(
            NamespaceKeys.General,
            GeneralKeys.EnterAtLeastXCharacters,
            { numberOfCharacters: 3 }
          )
        : undefined,
    [name, translate]
  );
  const urlLengthValidationMessage = useMemo<string | undefined>(
    () =>
      (url?.length ?? 0) <= 2
        ? translate(
            NamespaceKeys.General,
            GeneralKeys.EnterAtLeastXCharacters,
            { numberOfCharacters: 3 }
          )
        : undefined,
    [translate, url]
  );
  const urlUniquenessValidationMessage = useMemo<string | undefined>(
    () =>
      existingDocuments &&
      existingDocuments.some(
        (d: DocumentModelState): boolean =>
          d.DocumentPath === keepProtocolOrAddHttp(url)
      )
        ? translate(
            NamespaceKeys.DocumentSpecific,
            DocumentSpecificKeys.DocumentLinkWithSpecifiedUrlAlreadyExists
          )
        : undefined,
    [existingDocuments, translate, url]
  );

  const handleOnClickSaveButton = useCallback((): void => {
    const urlWithProtocol = keepProtocolOrAddHttp(url);
    if (
      !name ||
      !urlWithProtocol ||
      nameLengthValidationMessage ||
      urlLengthValidationMessage ||
      urlUniquenessValidationMessage
    ) {
      return;
    }
    onSave(name, urlWithProtocol);
  }, [
    onSave,
    name,
    nameLengthValidationMessage,
    url,
    urlLengthValidationMessage,
    urlUniquenessValidationMessage
  ]);

  const handleOnChangeName = useCallback((value: string): void => {
    setName(value);
  }, []);

  const handleOnChangeUrl = useCallback((value: string): void => {
    setUrl(value);
  }, []);

  return (
    <Dialog
      title={translate(
        NamespaceKeys.DocumentSpecific,
        DocumentSpecificKeys.AddNewSiteDocumentLink
      )}
      buttons={[
        <Button key="cancel" onClick={onClose}>
          {translate(NamespaceKeys.General, GeneralKeys.Cancel)}
        </Button>,
        <Button
          primary
          key="save"
          onClick={handleOnClickSaveButton}
          iconName="check"
          disabled={
            !!urlUniquenessValidationMessage ||
            !!nameLengthValidationMessage ||
            !!urlLengthValidationMessage
          }
        >
          {translate(NamespaceKeys.General, GeneralKeys.Save)}
        </Button>
      ]}
    >
      <TextField
        label={translate(
          NamespaceKeys.DocumentSpecific,
          DocumentSpecificKeys.Name
        )}
        value={name ?? ""}
        onChange={handleOnChangeName}
        placeholder={translate(
          NamespaceKeys.DocumentSpecific,
          DocumentSpecificKeys.LinkName
        )}
        validation={nameLengthValidationMessage}
        fullWidth
      />

      <TextField
        label={translate(
          NamespaceKeys.DocumentSpecific,
          DocumentSpecificKeys.Url
        )}
        value={url ?? ""}
        onChange={handleOnChangeUrl}
        placeholder={translate(
          NamespaceKeys.DocumentSpecific,
          DocumentSpecificKeys.LinkUrl
        )}
        validation={
          urlLengthValidationMessage || urlUniquenessValidationMessage
        }
        fullWidth
      />
    </Dialog>
  );
};

export default DocumentsTileAddLinkDialog;
