import React, { ReactElement, useCallback } from "react";

import {
  Row,
  Icon,
  Column,
  TextField,
  Button,
  NumberField,
  Tooltip,
  Dialog
} from "@react-gcc-eds/core";

import { MaterialTransferItemStatus } from "../../../../../../domain/client-customer";
import {
  GeneralKeys,
  NamespaceKeys,
  ComponentSpecificKeys
} from "../../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../../translation/translation-utils";
import ProductDetailsDialog, {
  DeliveryProductListItemInformation
} from "../../../../../common/product-details-modal/product-details-dialog";
import { QuantityValidationStateItem } from "../quantity-validator-component";

import "./quantity-validator-item.scss";

type Props = {
  item: QuantityValidationStateItem;
  expanded: boolean;
  hasDeviation: boolean;
  isValidated: boolean;
  onItemValidate: () => void;
  onItemCorrect: () => void;
  onItemRestore: () => void;
  onDeviationCancel: () => void;
  onToggleDeviation: () => void;
  onDeviationChange: (
    value: number,
    status: MaterialTransferItemStatus
  ) => void;
  showProductDetailsDialog: boolean;
  onClickProductDetailsButton: () => void;
  onCloseProductDetailsDialog: () => void;
  showProductNotesDialog: boolean;
  onClickProductNotesButton: () => void;
  onCloseProductNotesDialog: () => void;
  dplInformationItem: DeliveryProductListItemInformation | undefined;
};

const QuantityValidatorItem = ({
  item,
  expanded,
  isValidated,
  onDeviationChange,
  onItemValidate,
  onItemCorrect,
  onDeviationCancel,
  onToggleDeviation,
  hasDeviation,
  onItemRestore,
  showProductDetailsDialog,
  onClickProductDetailsButton,
  onCloseProductDetailsDialog,
  dplInformationItem,
  showProductNotesDialog,
  onClickProductNotesButton,
  onCloseProductNotesDialog
}: Props): ReactElement => {
  const { translate } = useTranslation();
  const onOkChange = useCallback(
    (value: number) => onDeviationChange(value, MaterialTransferItemStatus.Ok),
    [onDeviationChange]
  );
  const onDamagedChange = useCallback(
    (value: number) =>
      onDeviationChange(value, MaterialTransferItemStatus.Damaged),
    [onDeviationChange]
  );
  const onMissingChange = useCallback(
    (value: number) =>
      onDeviationChange(value, MaterialTransferItemStatus.Missing),
    [onDeviationChange]
  );
  const onExcessChange = useCallback(
    (value: number) =>
      onDeviationChange(value, MaterialTransferItemStatus.Excess),
    [onDeviationChange]
  );
  const handleOnValidClick = useCallback(
    () => (expanded ? onItemCorrect() : onItemValidate()),
    [expanded, onItemValidate, onItemCorrect]
  );
  const handleOnDeviationEditCancelClick = useCallback(() => {
    if (expanded) {
      onDeviationCancel();
    }

    onToggleDeviation();
  }, [expanded, onDeviationCancel, onToggleDeviation]);

  const renderDeviation = (): ReactElement => {
    return (
      <>
        <Row className="quantity-deviation-row">
          <Column sm={6} className="quantity-container">
            <span>{translate(NamespaceKeys.General, GeneralKeys.None)}</span>
          </Column>
          <Column sm={6} className="right-align-content">
            <NumberField
              value={item?.validationQuantities?.None ?? 0}
              disabled
            />
          </Column>
        </Row>
        <Row className="quantity-deviation-row">
          <Column sm={6} className="quantity-container">
            <span>{translate(NamespaceKeys.General, GeneralKeys.Ok)}</span>
          </Column>
          <Column sm={6} className="right-align-content">
            <NumberField
              type="spinner"
              value={item?.validationQuantities?.Ok ?? 0}
              onChange={onOkChange}
            />
          </Column>
        </Row>
        <Row className="quantity-deviation-row">
          <Column sm={6} className="quantity-container">
            <span>{translate(NamespaceKeys.General, GeneralKeys.Damaged)}</span>
          </Column>
          <Column sm={6} className="right-align-content">
            <NumberField
              type="spinner"
              value={item?.validationQuantities?.Damaged ?? 0}
              onChange={onDamagedChange}
            />
          </Column>
        </Row>
        <Row className="quantity-deviation-row">
          <Column sm={6} className="quantity-container">
            <span>{translate(NamespaceKeys.General, GeneralKeys.Missing)}</span>
          </Column>
          <Column sm={6} className="right-align-content">
            <NumberField
              type="spinner"
              value={item?.validationQuantities?.Missing ?? 0}
              onChange={onMissingChange}
            />
          </Column>
        </Row>
        <Row className="quantity-deviation-row">
          <Column sm={6} className="quantity-container">
            <span>{translate(NamespaceKeys.General, GeneralKeys.Excess)}</span>
          </Column>
          <Column sm={6} className="right-align-content">
            <NumberField
              type="spinner"
              value={item?.validationQuantities?.Excess ?? 0}
              onChange={onExcessChange}
            />
          </Column>
        </Row>
      </>
    );
  };

  return (
    <div className="quantity-tile">
      <Row>
        <Column sm={9} className="quality-validator-item-header">
          <div className="quality-validator-item-title">
            {item.dplItem?.EricssonProductNumber}
          </div>
          <div className="quality-validator-item-subtitle">
            {item.materialCategory}
          </div>
          <div className="quality-validator-item-description">
            {item.dplItem?.EricssonProductDescription ?? ""}
          </div>
        </Column>
        <Column sm={3} className="space-align">
          <>
            {hasDeviation && (
              <Tooltip
                className="actions-icon"
                position="left"
                text={translate(
                  NamespaceKeys.ComponentSpecific,
                  ComponentSpecificKeys.ThisItemHasDeviations
                )}
                type="pointer"
              >
                <Icon name="flag" />
              </Tooltip>
            )}
            {item.note && (
              <Icon
                name="document"
                className="actions-icon"
                onClick={onClickProductNotesButton}
              />
            )}
            {!!dplInformationItem && (
              <Icon
                name="info"
                onClick={onClickProductDetailsButton}
                className="actions-icon"
              />
            )}
          </>
        </Column>
      </Row>
      <Row>
        <Column sm={4} className="quantity-container">
          <TextField
            value={item.quantity.toString()}
            suffix={item.dplItem?.UnitOfMeasurement}
            disabled
            className="text-field-with-suffix"
          />
        </Column>
        <Column sm={8} className="right-align-content">
          <div className="buttons-container">
            {!isValidated || expanded ? (
              <Button primary onClick={handleOnValidClick}>
                <Icon name="check" />
                {expanded
                  ? translate(NamespaceKeys.General, GeneralKeys.Correct)
                  : translate(NamespaceKeys.General, GeneralKeys.Valid)}
              </Button>
            ) : (
              <Button onClick={onItemRestore}>
                <Icon name="restore" />
                {translate(NamespaceKeys.General, GeneralKeys.Restore)}
              </Button>
            )}
            <Button onClick={handleOnDeviationEditCancelClick}>
              <Icon name={expanded ? "cross" : isValidated ? "edit" : "flag"} />
              {expanded
                ? translate(NamespaceKeys.General, GeneralKeys.Cancel)
                : isValidated
                ? translate(NamespaceKeys.General, GeneralKeys.Edit)
                : translate(NamespaceKeys.General, GeneralKeys.Deviation)}
            </Button>
          </div>
        </Column>
      </Row>
      {expanded && renderDeviation()}
      {showProductDetailsDialog && dplInformationItem && (
        <ProductDetailsDialog
          showModal
          item={dplInformationItem}
          onClose={onCloseProductDetailsDialog}
        />
      )}
      {showProductNotesDialog && item.note && (
        <Dialog
          title={translate(NamespaceKeys.General, GeneralKeys.Notes)}
          buttons={
            <Button onClick={onCloseProductNotesDialog}>
              {translate(NamespaceKeys.General, GeneralKeys.Close)}
            </Button>
          }
          className={"product-details-dialog"}
        >
          {item.note}
        </Dialog>
      )}
    </div>
  );
};

export default QuantityValidatorItem;
