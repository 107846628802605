import useAppContext from "./ContextHelpers";

const useNotifications = () => {
  const { notifications } = useAppContext();
  return {
    createNotification: notifications.create,
    createInstantNotification: notifications.createInstant
  };
};

export default useNotifications;
