import React, { useCallback, ReactElement, ReactNode, useMemo } from "react";

import { Column, Loader, Row, Tile } from "@react-gcc-eds/core";

import { RevisionItemTypeEnum } from "../../../domain/client-obsolete";
import {
  ChecklistSpecificKeys,
  NamespaceKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";
import CenteredContainer from "../../common/layout/centered-container";
import { ChecklistItemEditModel } from "./checklist-models";
import ConditionalBooleanGroupElement from "./elements/conditional-boolean-group-element";
import ConditionalNumericGroupElement from "./elements/conditional-numeric-group-element";
import DatetimeElement from "./elements/datetime-element";
import DropdownElement from "./elements/dropdown-element";
import ChecklistItemImage from "./elements/generic/checklist-item-image";
import MultiSelectElement from "./elements/multi-select-element";
import NumericElement from "./elements/numeric-element";
import PrefilledElement from "./elements/prefilled-element";
import SectionElement from "./elements/section-element";
import SingleSelectElement from "./elements/single-select-element";
import TextElement from "./elements/text-element";

import "./checklist-viewer.scss";

type Props = {
  surveyTitle: string | undefined;
  survey: ChecklistItemEditModel[] | undefined;
  errorMessage: ChecklistSpecificKeys | undefined;
  loading: boolean;
  onChecklistRootItemChanged: (item: ChecklistItemEditModel) => void;
};

const ChecklistViewerView = ({
  survey,
  errorMessage,
  loading,
  onChecklistRootItemChanged,
  surveyTitle
}: Props): React.ReactElement => {
  const { translate } = useTranslation();

  const renderElement = useCallback(
    (
      item: ChecklistItemEditModel,
      onValueChanged: (item: ChecklistItemEditModel) => void
    ): ReactElement => {
      const renderSections = (child: ChecklistItemEditModel): ReactElement =>
        renderElement(child, (updatedItem) => {
          const itemUpdatedWithChildChanges: ChecklistItemEditModel = {
            ...item,
            Children: item.Children.map((c) =>
              c.Id === updatedItem.Id ? updatedItem : c
            )
          };
          onValueChanged(itemUpdatedWithChildChanges);
        });
      switch (item.ItemType) {
        case RevisionItemTypeEnum.Text:
          return (
            <div className="checklist-item" key={item.Id}>
              <TextElement item={item} onValueChanged={onValueChanged} />
            </div>
          );
        case RevisionItemTypeEnum.Number:
          return (
            <div className="checklist-item" key={item.Id}>
              <NumericElement item={item} onValueChanged={onValueChanged} />
            </div>
          );
        case RevisionItemTypeEnum.SingleSelect:
          return (
            <div className="checklist-item" key={item.Id}>
              <SingleSelectElement
                item={item}
                onValueChanged={onValueChanged}
              />
            </div>
          );
        case RevisionItemTypeEnum.MultiSelect:
          return (
            <div className="checklist-item" key={item.Id}>
              <MultiSelectElement item={item} onValueChanged={onValueChanged} />
            </div>
          );
        case RevisionItemTypeEnum.DropDown:
          return (
            <div className="checklist-item" key={item.Id}>
              <DropdownElement item={item} onValueChanged={onValueChanged} />
            </div>
          );
        case RevisionItemTypeEnum.DateTime:
          return (
            <div className="checklist-item" key={item.Id}>
              <DatetimeElement item={item} onValueChanged={onValueChanged} />
            </div>
          );
        case RevisionItemTypeEnum.Image:
          return (
            <div className="checklist-item" key={item.Id}>
              <ChecklistItemImage item={item} onValueChanged={onValueChanged} />
            </div>
          );
        case RevisionItemTypeEnum.Prefilled:
          return (
            <div className="checklist-item" key={item.Id}>
              <PrefilledElement item={item} onValueChanged={onValueChanged} />
            </div>
          );
        case RevisionItemTypeEnum.Grouping:
          return (
            <div className="checklist-item" key={item.Id}>
              <ConditionalBooleanGroupElement
                item={item}
                onValueChanged={onChecklistRootItemChanged}
                onRenderSections={renderSections}
              />
            </div>
          );
        case RevisionItemTypeEnum.ConditionalGrouping:
          return (
            <div className="checklist-item" key={item.Id}>
              <ConditionalNumericGroupElement
                item={item}
                onValueChanged={onChecklistRootItemChanged}
                onRenderSections={renderSections}
              />
            </div>
          );
        case RevisionItemTypeEnum.Section:
          return (
            <div className="checklist-item" key={item.Id}>
              <SectionElement
                item={item}
                onValueChanged={onChecklistRootItemChanged}
                onRenderSections={renderSections}
              />
            </div>
          );
        default:
          return <></>;
      }
    },
    [onChecklistRootItemChanged]
  );

  const renderedChecklistItems = useMemo<ReactNode>(
    () => survey?.map((i) => renderElement(i, onChecklistRootItemChanged)),
    [onChecklistRootItemChanged, renderElement, survey]
  );

  return errorMessage ? (
    <CenteredContainer>
      <p className="checklist-view-error-message">
        {translate(NamespaceKeys.ChecklistSpecific, errorMessage)}
      </p>
    </CenteredContainer>
  ) : loading ? (
    <CenteredContainer>
      <Loader size="large" />
    </CenteredContainer>
  ) : (
    <Row className="checklist-wrapper">
      {!survey ? (
        <Tile
          title={
            surveyTitle ??
            translate(
              NamespaceKeys.ChecklistSpecific,
              ChecklistSpecificKeys.Checklist
            )
          }
        ></Tile>
      ) : (
        <Tile
          title={
            surveyTitle ??
            translate(
              NamespaceKeys.ChecklistSpecific,
              ChecklistSpecificKeys.Checklist
            )
          }
        >
          <Row>
            <Column>{renderedChecklistItems}</Column>
          </Row>
        </Tile>
      )}
    </Row>
  );
};

export default ChecklistViewerView;
