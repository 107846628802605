import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo
} from "react";

import { useNotifications } from "@react-gcc-eds/core";

import { CustomerContext } from "../../../../contexts/customer-context";
import { LayoutContext } from "../../../../contexts/layout-context";
import {
  DeliveryProductListItemModel,
  MaterialTransferItemStatus,
  MaterialTransferToValidateDetailsViewModel,
  ValidateMaterialTransferRequestModel,
  MaterialTransferReason,
  MaterialTransferDetailsSerializedItemViewModel,
  MaterialTransferDetailsNonSerializedItemViewModel
} from "../../../../domain/client-customer";
import {
  ComponentSpecificKeys,
  NamespaceKeys,
  MaterialSpecificKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import {
  NonSerializedItemState,
  SerializedItemState
} from "../../receive-material-overview/shared/receive-material-models";
import {
  toMaterialTransferValidationNonSerializedItemsRequestModel,
  toMaterialTransferValidationSerializedItemsRequestModel,
  toNonSerializedItemState,
  toSerializedItemState
} from "./material-transfer-validation-utils";
import MaterialTransferValidationView from "./material-transfer-validation-view";
import { ValidationQuantities } from "./quantity-validator/quantity-validator-component";

import "./material-transfer-validation.scss";

export type SmartScanItemUpdate = {
  constructionItemId: string;
  validationQuantities: ValidationQuantities;
  ericssonSerialNumber?: string;
  customerSerialNumber?: string;
};

export const validationQuantitiesKeys = Object.values(
  MaterialTransferItemStatus
);
export enum WizardPages {
  QuantityValidator,
  SerialNumberValidator,
  Review
}

export type WizardPagesCompletableStatus = {
  [WizardPages.QuantityValidator]: boolean;
  [WizardPages.SerialNumberValidator]: boolean;
  [WizardPages.Review]: boolean;
};

const toTranslationTitleEnum = (
  reason: MaterialTransferReason | undefined
): MaterialSpecificKeys | undefined => {
  if (reason === MaterialTransferReason.DispatchCreated)
    return MaterialSpecificKeys.DispatchCreated;
  if (reason === MaterialTransferReason.MaterialReplacementNeeded)
    return MaterialSpecificKeys.MaterialReplacementNeeded;
  return undefined;
};

type Props = {
  getValidationData: (
    customerIdentifier: string
  ) => Promise<MaterialTransferToValidateDetailsViewModel>;
  postValidationData: (
    siteProjectId: string,
    requestModel: ValidateMaterialTransferRequestModel,
    customerIdentifier: string
  ) => Promise<void>;
  navigateBack: (siteProjectId: string | undefined) => void;
};

const MaterialTransferValidationComponent = ({
  getValidationData,
  navigateBack,
  postValidationData
}: Props): React.ReactElement => {
  const { createNotification } = useNotifications();
  const { currentCustomer } = useContext(CustomerContext);
  const { translate } = useTranslation();

  const [nonSerializedStateItems, setNonSerializedStateItems] = useState<
    NonSerializedItemState[]
  >([]);
  const [serializedStateItems, setSerializedStateItems] = useState<
    SerializedItemState[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { setPageTitle } = useContext(LayoutContext);
  const [headerTitle, setHeaderTitle] = useState<string>();
  const [headerSubtitle, setHeaderSubtitle] = useState<string>();
  const [errorState, setErrorState] = useState<string>();
  const [currentPage, setCurrentPage] = useState<WizardPages>(
    WizardPages.QuantityValidator
  );
  const [completedSteps, setCompletedSteps] = useState<WizardPages[]>([]);
  const [wizardPagesCompletableStatus, setWizardPagesCompletableStatus] =
    useState<WizardPagesCompletableStatus>({
      [WizardPages.QuantityValidator]: true,
      [WizardPages.SerialNumberValidator]: false,
      [WizardPages.Review]: false
    });
  const [posting, setPosting] = useState<boolean>(false);
  const [siteProjectId, setSiteProjectId] = useState<string>();
  const nextButtonDisabled = useMemo<boolean>(
    () => !wizardPagesCompletableStatus[currentPage],
    [currentPage, wizardPagesCompletableStatus]
  );

  const markAllItemsAsValidated = useCallback(() => {
    setSerializedStateItems((items) =>
      items.map((item, itemIndex) => ({
        ...item,
        quantityValidated: true,
        serialKey: itemIndex
      }))
    );
    setNonSerializedStateItems((items) =>
      items.map((item) => ({
        ...item,
        quantityValidated: true
      }))
    );
  }, []);

  const handleOnNextChange = useCallback(
    (nextPage: WizardPages, prevPage: WizardPages): void => {
      if (!wizardPagesCompletableStatus[currentPage]) {
        return;
      }

      if (currentPage === WizardPages.QuantityValidator) {
        markAllItemsAsValidated();
      }

      setCurrentPage(nextPage);
      setCompletedSteps((prev) => [...prev, prevPage]);
    },
    [currentPage, markAllItemsAsValidated, wizardPagesCompletableStatus]
  );

  const handleOnPreviousPage = useCallback(
    (previousPage: WizardPages | undefined): void => {
      if (previousPage === undefined) {
        return;
      }
      setCompletedSteps((prev) => prev.filter((step) => step !== currentPage));
      setCurrentPage(previousPage);
    },
    [currentPage]
  );

  const createErrorWhileSavingNotification = useCallback(() => {
    createNotification(
      translate(
        NamespaceKeys.ComponentSpecific,
        ComponentSpecificKeys.MaterialTransferPostValidationErrorTitle
      ),
      translate(
        NamespaceKeys.ComponentSpecific,
        ComponentSpecificKeys.MaterialTransferPostValidationErrorDescription
      ),
      "warning",
      undefined,
      5000
    );
    setPosting(false);
  }, [createNotification, translate]);

  const asyncHandleOnFinish = useCallback(async (): Promise<void> => {
    setCompletedSteps((prev) => [...prev, currentPage]);

    setPosting(true);
    const customerIdentifier =
      currentCustomer && currentCustomer.HeaderIdentifier;

    if (!customerIdentifier || !siteProjectId) {
      createErrorWhileSavingNotification();
      return;
    }
    try {
      const requestModel: ValidateMaterialTransferRequestModel = {
        NonSerializedItems: nonSerializedStateItems.map((n) =>
          toMaterialTransferValidationNonSerializedItemsRequestModel(n)
        ),
        SerializedItems: serializedStateItems.map((s) =>
          toMaterialTransferValidationSerializedItemsRequestModel(s)
        )
      };
      await postValidationData(siteProjectId, requestModel, customerIdentifier);
    } catch {
      createErrorWhileSavingNotification();
      return;
    }

    navigateBack(siteProjectId);
  }, [
    currentCustomer,
    siteProjectId,
    navigateBack,
    currentPage,
    createErrorWhileSavingNotification,
    nonSerializedStateItems,
    serializedStateItems,
    postValidationData
  ]);

  const synchronousHandleOnFinish = useCallback(() => {
    asyncHandleOnFinish();
  }, [asyncHandleOnFinish]);

  const aggregateSerializedItemByDplItemId = (
    sItemList: MaterialTransferDetailsSerializedItemViewModel[],
    dplItems: DeliveryProductListItemModel[],
    aggregatedItems: SerializedItemState[]
  ): SerializedItemState[] => {
    sItemList.forEach((item) => {
      if (!aggregatedItems.find((o) => o.dplItem?.Id === item.DplItemId)) {
        for (let i = 0; i < item.DispatchItemQuantity; i++) {
          aggregatedItems.push(
            toSerializedItemState({ ...item, SerialNumberIndex: i }, dplItems)
          );
        }
      }
    });
    return aggregatedItems;
  };

  const aggregateNonSerializedItemByDplItemId = (
    sItemList: MaterialTransferDetailsNonSerializedItemViewModel[],
    dplItems: DeliveryProductListItemModel[],
    aggregatedItems: NonSerializedItemState[]
  ): NonSerializedItemState[] => {
    sItemList.forEach((item) => {
      if (!aggregatedItems.find((o) => o.dplItem?.Id === item.DplItemId)) {
        aggregatedItems.push(toNonSerializedItemState(item, dplItems));
      }
    });
    return aggregatedItems;
  };

  const getMaterialTransferItemsToValidateDetails = useCallback(async () => {
    const customerIdentifier =
      currentCustomer && currentCustomer.HeaderIdentifier;
    if (!customerIdentifier) {
      setErrorState(
        translate(
          NamespaceKeys.ComponentSpecific,
          ComponentSpecificKeys.AnErrorOccurredPleaseTryAgain
        )
      );
      setLoading(false);
      return;
    }
    getValidationData(customerIdentifier)
      .then((fetchedMaterialTransferToValidate) => {
        setSiteProjectId(fetchedMaterialTransferToValidate.SiteProjectId);
        const aggregateNonSerializedItems: NonSerializedItemState[] = [];
        fetchedMaterialTransferToValidate.HandlingUnits.map((hu) => {
          aggregateNonSerializedItemByDplItemId(
            hu.NonSerializedItems,
            hu.DeliveryProductListItems,
            aggregateNonSerializedItems
          );
        });
        setNonSerializedStateItems(aggregateNonSerializedItems);
        const aggregateSerializedItems: SerializedItemState[] = [];
        fetchedMaterialTransferToValidate.HandlingUnits.map((hu) => {
          aggregateSerializedItemByDplItemId(
            hu.SerializedItems,
            hu.DeliveryProductListItems,
            aggregateSerializedItems
          );
        });
        setSerializedStateItems(aggregateSerializedItems);
        const reasonAsTranslationEnum = toTranslationTitleEnum(
          fetchedMaterialTransferToValidate.Reason
        );

        const translatedReason =
          (reasonAsTranslationEnum &&
            translate(
              NamespaceKeys.MaterialSpecific,
              reasonAsTranslationEnum
            )) ||
          "";

        const translatedTitle = translate(
          NamespaceKeys.MaterialSpecific,
          MaterialSpecificKeys.ValidationTitle,
          { reason: translatedReason }
        );

        setHeaderTitle(translatedTitle);
        setHeaderSubtitle(
          fetchedMaterialTransferToValidate.SiteProjectName ?? ""
        );
      })
      .catch(() => {
        setErrorState(
          translate(
            NamespaceKeys.ComponentSpecific,
            ComponentSpecificKeys.AnErrorOccurredPleaseTryAgain
          )
        );
      })
      .finally(() => setLoading(false));
  }, [currentCustomer, getValidationData, translate]);

  useEffect(() => {
    getMaterialTransferItemsToValidateDetails();
  }, [getMaterialTransferItemsToValidateDetails]);

  useEffect(() => {
    setPageTitle &&
      setPageTitle({
        title: headerTitle,
        subtitle: headerSubtitle
      });
  }, [setPageTitle, headerTitle, headerSubtitle]);

  const handleOnSerializedItemsUpdated = useCallback(
    (serializedItemsToUpdate: SerializedItemState[]): void => {
      setSerializedStateItems((prev: SerializedItemState[]) =>
        prev.map((previousItem: SerializedItemState) => {
          const matchingItem = serializedItemsToUpdate.find(
            (itemToUpdate) =>
              itemToUpdate.billOfMaterialItemIdentity ===
                previousItem.billOfMaterialItemIdentity &&
              itemToUpdate.serialNumberIndex === previousItem.serialNumberIndex
          );
          return matchingItem ?? previousItem;
        })
      );
    },
    []
  );

  const handleOnNonSerializedItemUpdated = useCallback(
    (nonSerializedItemToUpdate: NonSerializedItemState): void => {
      setNonSerializedStateItems((prev: NonSerializedItemState[]) =>
        prev.map((previousItem: NonSerializedItemState) => {
          if (
            previousItem.billOfMaterialItemIdentity ===
            nonSerializedItemToUpdate.billOfMaterialItemIdentity
          ) {
            return nonSerializedItemToUpdate;
          }
          return previousItem;
        })
      );
    },
    []
  );

  const handleOnStepCompletionStatusChange = useCallback(
    (page: WizardPages, isCompletable: boolean) => {
      setWizardPagesCompletableStatus(
        (prev: WizardPagesCompletableStatus): WizardPagesCompletableStatus => {
          return {
            ...prev,
            [page]: isCompletable
          };
        }
      );
    },
    []
  );
  const handleRetry = useCallback(() => {
    setErrorState(undefined);
    getMaterialTransferItemsToValidateDetails();
  }, [getMaterialTransferItemsToValidateDetails]);

  const handleOnCancel = useCallback(() => {
    navigateBack(siteProjectId);
  }, [navigateBack, siteProjectId]);

  return (
    <MaterialTransferValidationView
      error={errorState}
      loading={loading}
      nonSerializedStateItems={nonSerializedStateItems}
      serializedStateItems={serializedStateItems}
      currentPage={currentPage}
      completedSteps={completedSteps}
      onNextPage={handleOnNextChange}
      nextButtonDisabled={nextButtonDisabled}
      onFinish={synchronousHandleOnFinish}
      posting={posting}
      onCancel={handleOnCancel}
      onPreviousPage={handleOnPreviousPage}
      onSerializedItemsUpdated={handleOnSerializedItemsUpdated}
      onNonSerializedItemUpdated={handleOnNonSerializedItemUpdated}
      onStepCompletionStatusChanged={handleOnStepCompletionStatusChange}
      onRetry={handleRetry}
    />
  );
};

export default MaterialTransferValidationComponent;
