import React, { ChangeEvent } from "react";

import cx from "classnames";

import { uniqueId, useFocus } from "../../utils/utils";
import INumberField from "./INumberField";

const NumberField = ({
  label,
  value,
  onChange,
  onBlur,
  onKeyDown,
  onKeyPress,
  onKeyUp,
  focus,
  disabled,
  max = Number.MAX_SAFE_INTEGER,
  min = 0,
  step = 1,
  type,
  className
}: INumberField) => {
  const { inputRef } = useFocus();

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(e.target.value);
    if (onChange && ((newValue >= min && newValue <= max) || !e.target.value)) {
      onChange(newValue);
    }
  };

  const increase = () => {
    if (onChange && value + step <= max) {
      onChange((value + step) * 1);
    }
  };

  const decrease = () => {
    if (onChange && value - step >= min) {
      onChange((value - step) * 1);
    }
  };

  const id = uniqueId();

  return (
    <div className={cx({ spinner: type === "spinner", disabled }, className)} >
      {label && <label htmlFor={id}>{label}</label>}
      <input
        ref={focus ? inputRef : null}
        id={id}
        type="number"
        value={isNaN(value) ? "" : value}
        onChange={onChangeHandler}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => onBlur && onBlur(e)}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyDown && onKeyDown(e)}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyUp && onKeyUp(e)}
        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyPress && onKeyPress(e)}
        disabled={disabled}
        max={max}
        min={min}
        step={step}
      />
      {type === "spinner" && (
        <div className="controls">
          <i
            role="presentation"
            className={cx("icon icon-chevron-down", { disabled: value <= min })}
            onClick={decrease}
          />
          <i
            role="presentation"
            className={cx("icon icon-chevron-up", { disabled: value >= max })}
            onClick={increase}
          />
        </div>
      )}
    </div>
  );
};

export default NumberField;
