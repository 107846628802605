import React, { MouseEventHandler } from "react";

import { Button, Dialog, Icon } from "@react-gcc-eds/core";

import { UserTooltipModel } from "../../domain/client-customer";

type Props = {
  user: UserTooltipModel;
  onClose: MouseEventHandler;
};

const UserDetailsModal = ({ user, onClose }: Props): JSX.Element => {
  return (
    <Dialog
      className="round-border"
      buttons={<Button onClick={onClose}>Close</Button>}
    >
      <div className="user-details-container">
        <Icon name="avatar" base className="user-details-icon" />
        <div className="user-details">
          <div>
            <Icon name="avatar" className="icon" />
            {user.FirstName} {user.LastName}
          </div>
          <div>
            <Icon name="home" />
            {user.Company}
          </div>
          <div>
            <Icon name="email" />
            {user.LoginEmail}
          </div>
          <div>
            <Icon name="phone-cobra" />
            {user.MobilePhoneNumber}
          </div>
          <div>
            <Icon name="phone-classic" />
            {user.FixedPhoneNumber}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default UserDetailsModal;
