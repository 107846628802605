import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext
} from "react";

import { Row, Tile, TextField, Button, Icon } from "@react-gcc-eds/core";

import { CustomerContext } from "../../../contexts/customer-context";
import { InstallationSitesOverviewViewModel } from "../../../domain/client-customer";
import {
  MaterialInstallationOverviewSortProperty,
  SortOrder
} from "../../../hooks/sorting";
import {
  NamespaceKeys,
  MaterialSpecificKeys,
  GeneralKeys,
  SiteSpecificKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";

import "./material-installation-overview.scss";

const useSortIcon = (
  sortProperty: MaterialInstallationOverviewSortProperty,
  sortOrder: SortOrder,
  property: MaterialInstallationOverviewSortProperty
) =>
  useMemo(
    () =>
      sortProperty === property && sortOrder === SortOrder.Asc
        ? "sort-up"
        : sortProperty === property && sortOrder === SortOrder.Desc
        ? "sort-down"
        : "sorting",
    [property, sortOrder, sortProperty]
  );

type Props = {
  hidden?: boolean;
  sites: InstallationSitesOverviewViewModel[] | undefined;
  onFiltersChanged: (
    filteredItems: InstallationSitesOverviewViewModel[]
  ) => void;
  setSortPropertyOrToggleSortOrder: (
    sortProperty: "SiteName" | "StartDate"
  ) => void;
  sortProperty: "SiteName" | "StartDate";
  sortOrder: SortOrder;
};

const MaterialInstallationOverviewFilters: (
  props: Props
) => JSX.Element | null = ({
  hidden,
  sites: sites,
  onFiltersChanged,
  setSortPropertyOrToggleSortOrder,
  sortProperty,
  sortOrder
}: Props) => {
  const { translate } = useTranslation();
  const { currentUser } = useContext(CustomerContext);
  const userCompanyName: string | undefined = useMemo(
    () => currentUser?.CompanyName,
    [currentUser]
  );
  const [siteProjectAndSiteNameQuery, setSiteProjectAndSiteNameQuery] =
    useState<string>("");
  const handleOnSearchChange: (newValue: string) => void = useCallback(
    (newValue) => setSiteProjectAndSiteNameQuery(newValue),
    []
  );

  const startDateSortIcon = useSortIcon(sortProperty, sortOrder, "StartDate");
  const siteNameSortIcon = useSortIcon(sortProperty, sortOrder, "SiteName");

  const sortOnSiteButtonChange = useCallback(
    (): void => setSortPropertyOrToggleSortOrder("SiteName"),
    [setSortPropertyOrToggleSortOrder]
  );

  const sortOnDateButtonChange = useCallback(
    (): void => setSortPropertyOrToggleSortOrder("StartDate"),
    [setSortPropertyOrToggleSortOrder]
  );

  useEffect(() => {
    let filteredItems = sites ?? [];
    if (siteProjectAndSiteNameQuery && siteProjectAndSiteNameQuery.length) {
      const lowercaseSearchQuery = siteProjectAndSiteNameQuery.toLowerCase();
      filteredItems = filteredItems.filter(
        (f) =>
          (f.SiteName &&
            f.SiteName.toLowerCase().includes(lowercaseSearchQuery)) ||
          (f.SiteName &&
            f.SiteName.toLowerCase().includes(lowercaseSearchQuery))
      );
    }
    onFiltersChanged(filteredItems);
  }, [sites, onFiltersChanged, siteProjectAndSiteNameQuery, userCompanyName]);

  const siteProjectAndSiteNameField = useMemo<JSX.Element>(
    () => (
      <TextField
        key="name-filter"
        value={siteProjectAndSiteNameQuery}
        onChange={handleOnSearchChange}
        placeholder={translate(
          NamespaceKeys.MaterialSpecific,
          MaterialSpecificKeys.SearchSiteProjectOrSiteName
        )}
        fullWidth
        className="freetext-filter"
      />
    ),
    [siteProjectAndSiteNameQuery, handleOnSearchChange, translate]
  );

  return hidden ? null : (
    <Row className="filters-row">
      <Tile
        sm={12}
        title={translate(NamespaceKeys.General, GeneralKeys.Filters)}
      >
        {siteProjectAndSiteNameField}
        <div className="sort-panel">
          <Button onClick={sortOnSiteButtonChange}>
            {translate(NamespaceKeys.SiteSpecific, SiteSpecificKeys.Site)}
            <Icon name={siteNameSortIcon} />
          </Button>
          <Button onClick={sortOnDateButtonChange}>
            {translate(NamespaceKeys.General, GeneralKeys.Date)}
            <Icon name={startDateSortIcon} />
          </Button>
        </div>
      </Tile>
    </Row>
  );
};

export default MaterialInstallationOverviewFilters;
