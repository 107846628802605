import { useState, useCallback, useEffect } from "react";

import { UtilityClient, CustomerViewModel } from "../domain/client-customer";
import { AuthenticationProps } from "./useAuthentication";

type AcceptedFileFormatsContextProps = {
  fileFormats?: string[] | undefined;
  imageFormats?: string[] | undefined;
};

const useFileType = (
  currentCustomer: CustomerViewModel | undefined,
  { getCustomerConfigurationProvider }: AuthenticationProps
): AcceptedFileFormatsContextProps => {
  const [fileFormats, setFileFormats] = useState<string[]>();
  const [fileFormatsLoading, setFileFormatsLoading] = useState<boolean>(false);
  const [imageFormats, setImageFormats] = useState<string[]>();
  const [imageFormatsLoading, setImageFormatsLoading] =
    useState<boolean>(false);

  const getFileTypes = useCallback(async () => {
    try {
      if (!currentCustomer?.HeaderIdentifier) return;
      setFileFormatsLoading(true);
      const fileFormats = await new UtilityClient(
        await getCustomerConfigurationProvider()
      ).getFileFormats(currentCustomer?.HeaderIdentifier);
      setFileFormats(fileFormats);
    } catch {
      console.log("Error in accessing file format");
      ("// TODO: Make this into a useNotifications when app context provider has been created");
      ("// The progress of this is tracked in story: #22331");
    } finally {
      setFileFormatsLoading(false);
    }
  }, [currentCustomer, getCustomerConfigurationProvider]);

  const getImageFileTypes = useCallback(async () => {
    try {
      if (!currentCustomer?.HeaderIdentifier) return;
      setImageFormatsLoading(true);
      const imageFormats = await new UtilityClient(
        await getCustomerConfigurationProvider()
      ).getFileImageFormats(currentCustomer?.HeaderIdentifier);
      setImageFormats(imageFormats);
    } catch {
      console.log("Error in accessing image format");
      ("// TODO: Make this into a useNotifications when app context provider has been created");
      ("// The progress of this is tracked in story: #22331");
    } finally {
      setImageFormatsLoading(false);
    }
  }, [currentCustomer, getCustomerConfigurationProvider]);

  useEffect(() => {
    if (!currentCustomer || fileFormatsLoading || fileFormats) return;
    getFileTypes();
  }, [currentCustomer, fileFormatsLoading, fileFormats, getFileTypes]);

  useEffect(() => {
    if (!currentCustomer || imageFormatsLoading || imageFormats) return;
    getImageFileTypes();
  }, [currentCustomer, getImageFileTypes, imageFormatsLoading, imageFormats]);

  return {
    fileFormats,
    imageFormats
  };
};

export default useFileType;
