import React from "react";

import { Icon, Tile } from "@react-gcc-eds/core";
import { Link } from "react-router-dom";

import { BomStatus, SiteProjectViewModel } from "../../domain/client-customer";
import {
  NamespaceKeys,
  BomSpecificKeys
} from "../../translation/dictionary-keys";
import { useTranslation } from "../../translation/translation-utils";
import TitleValuePair from "../common/layout/title-value-pair";

type Props = {
  siteProject: SiteProjectViewModel;
};

const SiteProjectListItem = ({ siteProject }: Props): JSX.Element => {
  const { translate } = useTranslation();
  const getBomStatus = (): string =>
    siteProject.BomStatus === BomStatus.Empty ? "red" : "green";

  return (
    <Tile
      title={siteProject.Name || ""}
      subtitle={siteProject.SiteName}
      className="overview-list-item"
    >
      <div className="overview-content">
        <TitleValuePair
          title={translate(
            NamespaceKeys.BomSpecific,
            BomSpecificKeys.BomStatus
          )}
          value={
            <>
              <Icon name="view-list" className={getBomStatus()} />
              {siteProject.BomStatus}
            </>
          }
        />
      </div>
      <div className="actions">
        <Link to={`/siteProjects/${siteProject.Id}`} className="btn primary">
          Open
        </Link>
        {/*<OfflineSyncButton />*/}
        <div />
      </div>
    </Tile>
  );
};

export default SiteProjectListItem;
