import React, { useMemo } from "react";

import { Loader, Button } from "@react-gcc-eds/core";

import { MaterialTransferDetailsViewModel } from "../../../../domain/client-customer";
import {
  NamespaceKeys,
  GeneralKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import CenteredContainer from "../../../common/layout/centered-container";
import MaterialTransferDetailsItem, {
  MaterialTransferItem
} from "./material-transfer-details-item";

import "./material-transfer-details.scss";

type Props = {
  loading: boolean;
  error: string | undefined;
  onRetry: () => void;
  materialTransferDetails: MaterialTransferDetailsViewModel | undefined;
};

const MaterialTransferDetailsView = ({
  loading,
  error,
  onRetry,
  materialTransferDetails
}: Props): React.ReactElement => {
  const { translate } = useTranslation();

  const aggregatedItems: MaterialTransferItem[] = useMemo(
    () =>
      Object.values({
        ...materialTransferDetails?.NonSerializedItems.reduce(
          (dplGroups, item) => {
            const dplItemId = item.DplItemId ?? "";
            const existingItem = dplGroups[dplItemId];
            if (existingItem) {
              return {
                ...dplGroups,
                [dplItemId]: {
                  ...existingItem,
                  HandlingUnitQuantity:
                    existingItem.HandlingUnitQuantity +
                    item.HandlingUnitQuantity,
                  DispatchItemQuantity:
                    existingItem.DispatchItemQuantity +
                    item.DispatchItemQuantity
                }
              };
            } else {
              return {
                ...dplGroups,
                [dplItemId]: {
                  ...item,
                  HandlingUnitQuantity: item.HandlingUnitQuantity
                }
              };
            }
          },
          {} as Record<string, MaterialTransferItem>
        ),
        ...materialTransferDetails?.SerializedItems.reduce(
          (dplGroups, item) => {
            const dplItemId = item.DplItemId ?? "";
            const existingItem = dplGroups[dplItemId];
            if (existingItem) {
              return {
                ...dplGroups,
                [dplItemId]: {
                  ...existingItem,
                  DispatchItemQuantity: existingItem.DispatchItemQuantity + 1
                }
              };
            } else {
              return {
                ...dplGroups,
                [dplItemId]: {
                  ...item,
                  HandlingUnitQuantity: 1,
                  DispatchItemQuantity: 1
                }
              };
            }
          },
          {} as Record<string, MaterialTransferItem>
        )
      }),
    [materialTransferDetails]
  );

  const itemElements: React.ReactNode[] = useMemo(
    () =>
      aggregatedItems.map(
        (item) =>
          materialTransferDetails && (
            <MaterialTransferDetailsItem
              key={item.DplItemId}
              materialTransferDetails={materialTransferDetails}
              item={item}
            />
          )
      ),
    [materialTransferDetails, aggregatedItems]
  );

  return error ? (
    <CenteredContainer>
      <div className="material-transfer-api-error">
        <div className="material-transfer-retry-title">{error}</div>
        <Button className="material-transfer-retry-button" onClick={onRetry}>
          {translate(NamespaceKeys.General, GeneralKeys.Retry)}
        </Button>
      </div>
    </CenteredContainer>
  ) : loading ? (
    <CenteredContainer>
      <Loader size="large" />
    </CenteredContainer>
  ) : (
    <div className="overview-list-item">{itemElements}</div>
  );
};

export default MaterialTransferDetailsView;
