import React from "react";

type Props = {
  label: string;
  value: string;
};

const ReceiveMaterialValidationSummaryItem = ({
  label,
  value
}: Props): React.ReactElement => {
  return (
    <div className="rm-summary-item">
      <div className="rm-summary-item-label">{label}:</div>
      <div className="rm-summary-item-value">{value}</div>
    </div>
  );
};

export default ReceiveMaterialValidationSummaryItem;
