import React, { useContext, useMemo } from "react";

import { Button, Dialog } from "@react-gcc-eds/core";

import {
  MaterialTransferItemStatus,
  ValidateMaterialTransfersRequestModel
} from "../../../../domain/client-customer";
import {
  GeneralKeys,
  NamespaceKeys,
  ReceivingMaterialSpecificKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import CenteredContainer from "../../../common/layout/centered-container";
import { ReceiveMaterialContext } from "../contexts/receive-material-context";
import {
  NonSerializedItemState,
  ReceiveMaterialTransferStateItem,
  SelectableMaterialTransferOverviewViewModel
} from "../shared/receive-material-models";
import useDeviationSummaries from "../shared/use-deviation-summaries";

import "./receive-material-confirm-validation.scss";

type Props = {
  onCancel: () => void;
  onValidate: (data: ValidateMaterialTransfersRequestModel[]) => void;
};

const ReceiveMaterialConfirmValidationDialog = ({
  onCancel,
  onValidate
}: Props): React.ReactElement => {
  const { translate } = useTranslation();
  const { transfersOverviewStateItems, transferDetailsStateItems } = useContext(
    ReceiveMaterialContext
  );
  const { totalMissing: missing, totalDamaged: damaged } =
    useDeviationSummaries();

  const selectedTransfersOverviewStateItems =
    React.useMemo((): SelectableMaterialTransferOverviewViewModel[] => {
      return transfersOverviewStateItems.filter((x) => x.Selected);
    }, [transfersOverviewStateItems]);

  const totalSiteProjects = React.useMemo((): number => {
    const siteProjectsIds = selectedTransfersOverviewStateItems.map(
      (o) => o.SiteProjectId
    );
    return selectedTransfersOverviewStateItems.filter(
      ({ SiteProjectId }, index) =>
        !siteProjectsIds.includes(SiteProjectId, index + 1)
    ).length;
  }, [selectedTransfersOverviewStateItems]);

  const totalItems = React.useMemo((): number => {
    return selectedTransfersOverviewStateItems
      .map((i) => i.NumberOfItems)
      .reduce((a, b) => a + b, 0);
  }, [selectedTransfersOverviewStateItems]);

  const totalQty = React.useMemo((): number => {
    return selectedTransfersOverviewStateItems
      .map((i) => i.TotalQuantity)
      .reduce((a, b) => a + b, 0);
  }, [selectedTransfersOverviewStateItems]);

  const totalHandlingUnits = React.useMemo((): number => {
    return selectedTransfersOverviewStateItems
      .map((i) => i.HandlingUnitCount)
      .reduce((a, b) => a + b, 0);
  }, [selectedTransfersOverviewStateItems]);

  const isSiteProjectsNotWithFullHandlingUnit = React.useMemo((): boolean => {
    return (
      selectedTransfersOverviewStateItems.filter(
        (f) => !f.IsWithFullHandlingUnit
      ).length > 0
    );
  }, [selectedTransfersOverviewStateItems]);

  const validationMessage: string | undefined = useMemo(() => {
    let output = isSiteProjectsNotWithFullHandlingUnit
      ? translate(
          NamespaceKeys.ReceivingMaterialSpecific,
          ReceivingMaterialSpecificKeys.ValidationMessageWithoutHandlingUnit,
          {
            totalSiteProjects: totalSiteProjects,
            totalItems: totalItems,
            totalQty: totalQty
          }
        )
      : translate(
          NamespaceKeys.ReceivingMaterialSpecific,
          ReceivingMaterialSpecificKeys.ValidationMessageWithHandlingUnit,
          {
            totalSiteProjects: totalSiteProjects,
            totalHandlingUnits: totalHandlingUnits,
            totalItems: totalItems,
            totalQty: totalQty
          }
        );
    if (damaged > 0 && missing > 0) {
      output += ` ${damaged} ${translate(
        NamespaceKeys.ReceivingMaterialSpecific,
        ReceivingMaterialSpecificKeys.ReportedAsDamaged
      )} ${translate(
        NamespaceKeys.ReceivingMaterialSpecific,
        ReceivingMaterialSpecificKeys.And
      )} ${missing} ${translate(
        NamespaceKeys.ReceivingMaterialSpecific,
        ReceivingMaterialSpecificKeys.ReportedAsMissing
      )}.`;
    } else {
      if (damaged > 0)
        output += ` ${damaged} ${translate(
          NamespaceKeys.ReceivingMaterialSpecific,
          ReceivingMaterialSpecificKeys.ReportedAsDamaged
        )}.`;

      if (missing > 0)
        output += ` ${missing} ${translate(
          NamespaceKeys.ReceivingMaterialSpecific,
          ReceivingMaterialSpecificKeys.ReportedAsMissing
        )}.`;
    }

    return output;
  }, [
    isSiteProjectsNotWithFullHandlingUnit,
    translate,
    totalSiteProjects,
    totalItems,
    totalQty,
    totalHandlingUnits,
    damaged,
    missing
  ]);

  const groupAndSum = (items: NonSerializedItemState[]) => {
    const groupedItems: Record<string, NonSerializedItemState[]> = {};

    items.forEach((item) => {
      const key = item.dplItem?.Id ?? "";
      if (!groupedItems[key]) {
        groupedItems[key] = [];
      }
      groupedItems[key].push({ ...item, validationQuantities: {} });
    });

    Object.keys(groupedItems).forEach((key) => {
      const group = groupedItems[key];
      const statusCounts: Record<string, number> = {};

      items.forEach((item) => {
        if (item.dplItem?.Id === key && item.validationQuantities) {
          Object.keys(item.validationQuantities).forEach((status: string) => {
            if (!statusCounts[status]) {
              statusCounts[status] = 0;
            }
            if (item.validationQuantities) {
              statusCounts[status] +=
                item.validationQuantities[
                  status as MaterialTransferItemStatus
                ] ?? 0;
            }
          });
        }
      });

      for (const status of Object.keys(statusCounts)) {
        let remainingStatus = statusCounts[status];
        const statusKey = status as MaterialTransferItemStatus;

        for (let i = 0; i < group.length && remainingStatus > 0; i++) {
          if (group[i].quantity > 0) {
            if (group[i].validationQuantities) {
              (group[i].validationQuantities as { [key: string]: number })[
                statusKey
              ] = Math.min(group[i].quantity, remainingStatus);
              remainingStatus -=
                group[i].validationQuantities?.[statusKey] ?? 0;
              group[i].quantity -=
                group[i].validationQuantities?.[statusKey] ?? 0;
            }
          }
        }
      }

      group.forEach((item) => {
        item.quantity =
          item.quantity +
          (item.validationQuantities
            ? Object.values(item.validationQuantities).reduce(
                (a, b) => a + b,
                0
              )
            : 0);
      });
    });

    return Object.values(groupedItems).flat();
  };

  function handleOnValidate() {
    const validateReceiveMaterialRequestModels: ValidateMaterialTransfersRequestModel[] =
      transferDetailsStateItems.map(
        (item: ReceiveMaterialTransferStateItem) => ({
          SiteProjectId: item.SiteProjectId,
          MaterialTransferId: item.MaterialTransferId,
          HandlingUnits: item.HandlingUnits.map((hu) => {
            const groupedNonSerializedItems = groupAndSum(
              hu.NonSerializedItems
            );

            return {
              HandlingUnit: hu.ExternalHandlingUnit,
              NonSerializedItems: groupedNonSerializedItems.map((nsItem) => ({
                BillOfMaterialItemIdentity: nsItem.billOfMaterialItemIdentity,
                StatusAndQuantities: Object.keys(
                  nsItem.validationQuantities ?? {}
                ).map((status) => ({
                  Status:
                    (status as MaterialTransferItemStatus) ===
                    MaterialTransferItemStatus.None
                      ? MaterialTransferItemStatus.Ok
                      : (status as MaterialTransferItemStatus),
                  Quantity:
                    nsItem.validationQuantities?.[
                      status as MaterialTransferItemStatus
                    ] ?? 0
                }))
              })),
              SerializedItems: hu.SerializedItems.map((sItem) => ({
                BillOfMaterialItemIdentity: sItem.billOfMaterialItemIdentity,
                EricssonSerialNumber: sItem.ericssonSerialNumber ?? "",
                CustomerSerialNumber: sItem.customerSerialNumber ?? "",
                Status:
                  sItem.status === MaterialTransferItemStatus.None
                    ? MaterialTransferItemStatus.Ok
                    : sItem.status
              }))
            };
          })
        })
      );

    onValidate(validateReceiveMaterialRequestModels);
  }

  return (
    <div className="rm-confirm-validation-dialog">
      <Dialog
        title="Validation"
        buttons={[
          <Button key="ValidateAllItems" onClick={handleOnValidate} primary>
            {translate(NamespaceKeys.General, GeneralKeys.Validate)}
          </Button>,
          <Button key="Cancel" onClick={onCancel}>
            {translate(NamespaceKeys.General, GeneralKeys.Cancel)}
          </Button>
        ]}
      >
        <CenteredContainer>
          <p>{validationMessage}</p>
        </CenteredContainer>
      </Dialog>
    </div>
  );
};

export default ReceiveMaterialConfirmValidationDialog;
