export interface ActivitySurveyAssignmentRequestModel {
  SurveyTemplateId: string;
  DatasetId?: string | undefined;
}

export interface ChecklistItemImageRequestModel {
  Data?: string | undefined;
  FileName?: string | undefined;
}

export interface ChecklistItemImageViewModel {
  Id?: string;
  FileName?: string | undefined;
  HostedUrl?: string | undefined;
}

export interface ChecklistItemOptionViewModel {
  Id?: string;
  Option?: string | undefined;
}

export interface ChecklistItemRequestModel {
  Id?: string;
  Comment?: string | undefined;
  ImagesToUpload?: ChecklistItemImageRequestModel[] | undefined;
  ImagesToDelete?: string[] | undefined;
  ImagesToRotate?: ChecklistItemRotationRequestModel[] | undefined;
  Values?: string[] | undefined;
}

export enum RotationEnum {
  Ninety = "Ninety",
  OneHundredEighty = "OneHundredEighty",
  TwoHundredSeventy = "TwoHundredSeventy"
}

export interface ChecklistItemRotationRequestModel {
  ImageId?: string;
  Rotation?: RotationEnum;
}

export enum ConditionalGroupingOperator {
  LessThan = "LessThan",
  Equals = "Equals",
  GreaterThan = "GreaterThan"
}

export interface ChecklistItemViewModel {
  Id?: string;
  Title?: string | undefined;
  Description?: string | undefined;
  ItemType?: RevisionItemTypeEnum;
  Commentable?: boolean;
  Mandatory?: boolean;
  DisplayInline?: boolean;
  DisplayTime?: boolean;
  SupportsImage?: boolean;
  Options?: ChecklistItemOptionViewModel[] | undefined;
  Children?: ChecklistItemViewModel[] | undefined;
  Comment?: string | undefined;
  SavedImages?: ChecklistItemImageViewModel[] | undefined;
  Values?: string[] | undefined;
  ConditionalGroupingOperator?: ConditionalGroupingOperator;
  ConditionalGroupingReferenceValue?: number | undefined;
}

export interface ChecklistViewModel {
  Id?: string;
  Title?: string | undefined;
  SiteProjectName?: string | undefined;
  Items?: ChecklistItemViewModel[] | undefined;
}

export enum RevisionItemTypeEnum {
  ConditionalGrouping = "ConditionalGrouping",
  DateTime = "DateTime",
  DropDown = "DropDown",
  Grouping = "Grouping",
  Image = "Image",
  MultiSelect = "MultiSelect",
  Number = "Number",
  Prefilled = "Prefilled",
  RepeatingSection = "RepeatingSection",
  Section = "Section",
  SingleSelect = "SingleSelect",
  Text = "Text"
}

export interface StringStringValuesKeyValuePair {
  Key?: string | undefined;
  Value?: string[];
}

export interface SurveyActivityViewModel {
  ActivityId?: number;
  ActivityType?: string | undefined;
  ActivityLabel?: string | undefined;
  IsScheduled?: boolean;
  IsCompleted?: boolean;
  CanEditSurveysInActivity?: boolean;
  CanAddSurveysToActivity?: boolean;
  Surveys?: SurveyModel[] | undefined;
}

export interface SurveyAnswerSchemaItemModel {
  values?: string[] | undefined;
  comment?: string | undefined;
  pictures?: string[] | undefined;
}

export interface SurveyModel {
  Id?: string;
  SiteWalkActivityId?: number;
  DataSchema?: SurveyTemplateDataSchemaResponseModel;
  UiSchema?: { [key: string]: SurveyTemplateUiSchemaItem } | undefined;
  AnswerSchema?: { [key: string]: SurveyAnswerSchemaItemModel } | undefined;
  LastEditedBy?: string | undefined;
  LastEditedOn?: Date | undefined;
  IsCompleted?: boolean;
  IsCompletable?: boolean;
  IsEditable?: boolean;
}

export interface SurveyTemplateDataSchemaItemModel {
  id?: string;
  readonly type?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  datafield?: string | undefined;
  prefill?: string | undefined;
  commentable?: boolean;
  canHavePictures?: boolean;
}

export interface SurveyTemplateDataSchemaItemOptionsModel {
  type?: string | undefined;
  options?: string[] | undefined;
}

export interface SurveyTemplateDataSchemaItemWithOptionModel {
  items?: SurveyTemplateDataSchemaItemOptionsModel;
  uniqueItems?: boolean;
  id?: string;
  readonly type?: string | undefined;
  title?: string | undefined;
  description?: string | undefined;
  datafield?: string | undefined;
  prefill?: string | undefined;
  commentable?: boolean;
  canHavePictures?: boolean;
}

export interface SurveyTemplateDataSchemaRequestModel {
  title?: string | undefined;
  description?: string | undefined;
  readonly type?: string | undefined;
  required?: string[] | undefined;
  order?: string[] | undefined;
  properties?:
    | { [key: string]: SurveyTemplateDataSchemaItemWithOptionModel }
    | undefined;
}

export interface SurveyTemplateDataSchemaResponseModel {
  title?: string | undefined;
  description?: string | undefined;
  readonly type?: string | undefined;
  required?: string[] | undefined;
  order?: string[] | undefined;
  properties?: { [key: string]: SurveyTemplateDataSchemaItemModel } | undefined;
}

export interface SurveyTemplateDatasetModel {
  id?: string;
  title?: string | undefined;
  description?: string | undefined;
  lastEditedBy?: string | undefined;
  lastEditedOn?: Date | undefined;
  answerSchema?: { [key: string]: SurveyAnswerSchemaItemModel } | undefined;
}

export interface SurveyTemplateListModel {
  Id?: string;
  Title?: string | undefined;
  Description?: string | undefined;
  IsDefault?: boolean;
  LastUpdatedBy?: string | undefined;
  LastUpdatedOn?: Date;
  PublishedRevision?: number;
  PublishedOn?: Date | undefined;
  Datasets?: { [key: string]: string } | undefined;
  HasOngoing?: boolean;
}

export interface SurveyTemplateRequestModel {
  DataSchema?: SurveyTemplateDataSchemaRequestModel;
  UiSchema?: { [key: string]: SurveyTemplateUiSchemaItem } | undefined;
}

export interface SurveyTemplateRevisionModel {
  Id?: string;
  CreatedBy?: string | undefined;
  CreatedOn?: Date;
  IsPublished?: boolean;
  PublishedOn?: Date | undefined;
  DataSchema?: SurveyTemplateDataSchemaResponseModel;
  UiSchema?: { [key: string]: SurveyTemplateUiSchemaItem } | undefined;
}

export interface SurveyTemplateUiSchemaItem {
  "ui:field"?: string | undefined;
  "ui:options"?: SurveyTemplateUiSchemaOptionsModel;
  parent?: string | undefined;
  level?: number;
}

export interface SurveyTemplateUiSchemaOptionsFormatModel {
  showTime?: boolean;
  minDate?: Date | undefined;
}

export interface SurveyTemplateUiSchemaOptionsModel {
  inline?: boolean;
  format?: SurveyTemplateUiSchemaOptionsFormatModel;
}

export interface SurveyTemplateViewModel {
  Id?: string;
  Title?: string | undefined;
  Description?: string | undefined;
  Revisions?: SurveyTemplateRevisionModel[] | undefined;
}

export interface MultipleCompaniesActivityViewModel {
  UserCanEditCompanyList?: boolean;
  AssignedCompanies?: string[] | undefined;
  AvailableCompanies?: string[] | undefined;
}
