import React, { useCallback, useMemo } from "react";

import { ChecklistItemImageRotationEditModel } from "../../../checklist-models";
import { getStylingFromRotation } from "../rotation-styling-helper";

type Props = {
  image: ChecklistItemImageRotationEditModel;
  showImageDetails: (image: ChecklistItemImageRotationEditModel) => void;
};

const SavedImage = ({ image, showImageDetails }: Props): React.ReactElement => {
  const handleOnClickImage = useCallback(
    () => showImageDetails(image),
    [image, showImageDetails]
  );

  const imageStyling = useMemo<React.CSSProperties | undefined>(
    () => getStylingFromRotation(image.rotation),
    [image.rotation]
  );

  return (
    <div className="saved-image">
      <img
        src={image.HostedUrl}
        alt={image.FileName}
        onClick={handleOnClickImage}
        style={imageStyling}
      />
      <span>{image.FileName}</span>
    </div>
  );
};

export default SavedImage;
