import React, { useMemo } from "react";

import { ConditionalGroupingOperator } from "../../../../domain/client-obsolete";
import ChecklistConditionalGroup from "./generic/checklist-conditional-group";
import NumericElement from "./numeric-element";
import { stringToNumberOrUndefined } from "./string-to-type-deserializer";
import { HybridItemComponent, HybridItemProps } from "./types";

type ConditionalOperator = (left: number, right: number) => boolean;

const conditionalOperatorMap: Record<
  ConditionalGroupingOperator,
  ConditionalOperator
> = {
  [ConditionalGroupingOperator.Equals]: (left, right) => left === right,
  [ConditionalGroupingOperator.GreaterThan]: (left, right) => left > right,
  [ConditionalGroupingOperator.LessThan]: (left, right) => left < right
};

const ConditionalNumericGroupElement: HybridItemComponent = ({
  item,
  onValueChanged,
  onRenderSections
}: HybridItemProps) => {
  const operator = useMemo<ConditionalOperator>(
    () =>
      item.ConditionalGroupingOperator
        ? conditionalOperatorMap[item.ConditionalGroupingOperator]
        : (): boolean => false,
    [item.ConditionalGroupingOperator]
  );
  const value = useMemo<number>(
    () =>
      (item.Values?.length && stringToNumberOrUndefined(item.Values[0])) ?? 0,
    [item.Values]
  );
  const isMeetingCondition = useMemo<boolean>(
    () =>
      item.ConditionalGroupingReferenceValue
        ? operator(value, item.ConditionalGroupingReferenceValue)
        : false,
    [item.ConditionalGroupingReferenceValue, operator, value]
  );

  return (
    <ChecklistConditionalGroup
      item={item}
      onValueChanged={onValueChanged}
      onRenderSections={onRenderSections}
      isMeetingCondition={isMeetingCondition}
    >
      <NumericElement item={item} onValueChanged={onValueChanged} />
    </ChecklistConditionalGroup>
  );
};

export default ConditionalNumericGroupElement;
