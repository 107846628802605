import React, { useCallback, useMemo } from "react";

import { TextField } from "@react-gcc-eds/core";

import ChecklistStandardItem from "./generic/checklist-standard-item";
import { SingleItemComponent, SingleItemProps } from "./types";

const TextElement: SingleItemComponent = ({
  item,
  onValueChanged
}: SingleItemProps) => {
  const value = useMemo<string>(() => item.Values?.[0] ?? "", [item.Values]);
  const handleOnChange = useCallback(
    (value: string): void =>
      onValueChanged({
        ...item,
        Values: [value]
      }),
    [item, onValueChanged]
  );

  return (
    <ChecklistStandardItem item={item} onValueChanged={onValueChanged}>
      <TextField value={value} onChange={handleOnChange} fullWidth />
    </ChecklistStandardItem>
  );
};

export default TextElement;
