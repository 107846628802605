import React, { ReactElement, useCallback } from "react";

import {
  Row,
  Icon,
  Column,
  Button,
  NumberField,
  Tooltip,
  Dialog
} from "@react-gcc-eds/core";

import { MaterialTransferItemStatus } from "../../../../../../domain/client-customer";
import {
  GeneralKeys,
  NamespaceKeys,
  ComponentSpecificKeys,
  InstallationSpecificKeys
} from "../../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../../translation/translation-utils";
import ProductDetailsDialog, {
  DeliveryProductListItemInformation
} from "../../../../../common/product-details-modal/product-details-dialog";
import { QuantityValidationStateItem } from "../quantity-validator-component";

import "./quantity-validator-item.scss";

type Props = {
  item: QuantityValidationStateItem;
  expanded: boolean;
  hasDeviation: boolean;
  isValidated: boolean;
  onItemValidate: () => void;
  onItemCorrect: () => void;
  onItemRestore: () => void;
  onDeviationCancel: () => void;
  onToggleDeviation: () => void;
  onDeviationChange: (
    value: number,
    status: MaterialTransferItemStatus
  ) => void;
  showProductDetailsDialog: boolean;
  onClickProductDetailsButton: () => void;
  onCloseProductDetailsDialog: () => void;
  showProductNotesDialog: boolean;
  onClickProductNotesButton: () => void;
  onCloseProductNotesDialog: () => void;
  dplInformationItem: DeliveryProductListItemInformation | undefined;
};

const QuantityValidatorItem = ({
  item,
  expanded,
  isValidated,
  onDeviationChange,
  onItemValidate,
  onItemCorrect,
  hasDeviation,
  onItemRestore,
  showProductDetailsDialog,
  onClickProductDetailsButton,
  onCloseProductDetailsDialog,
  dplInformationItem,
  showProductNotesDialog,
  onClickProductNotesButton,
  onCloseProductNotesDialog
}: Props): ReactElement => {
  const { translate } = useTranslation();
  const onOkChange = useCallback(
    (value: number) => onDeviationChange(value, MaterialTransferItemStatus.Ok),
    [onDeviationChange]
  );
  const handleOnValidClick = useCallback(
    () => (expanded ? onItemCorrect() : onItemValidate()),
    [expanded, onItemValidate, onItemCorrect]
  );

  return (
    <div className="quantity-tile">
      <Row>
        <Column sm={9} className="quality-validator-item-header">
          <div className="quality-validator-item-title">
            {item.dplItem?.EricssonProductNumber}
          </div>
          <div className="quality-validator-item-subtitle">
            {item.materialCategory}
          </div>
          <div className="quality-validator-item-description">
            {item.dplItem?.EricssonProductDescription ?? ""}
          </div>
        </Column>
        <Column sm={3} className="space-align">
          <>
            {hasDeviation && (
              <Tooltip
                className="actions-icon"
                position="left"
                text={translate(
                  NamespaceKeys.ComponentSpecific,
                  ComponentSpecificKeys.ThisItemHasDeviations
                )}
                type="pointer"
              >
                <Icon name="flag" />
              </Tooltip>
            )}
            {item.note && (
              <Icon
                name="document"
                className="actions-icon"
                onClick={onClickProductNotesButton}
              />
            )}
            {!!dplInformationItem && (
              <Icon
                name="info"
                onClick={onClickProductDetailsButton}
                className="actions-icon"
              />
            )}
          </>
        </Column>
      </Row>
      <Row>
        <Column sm={6} className="quantity-container">
          <NumberField
            type="spinner"
            value={item?.validationQuantities?.Ok ?? 0}
            onChange={onOkChange}
          />
        </Column>
        <Column sm={3} className="quantity-container">
          {translate(
            NamespaceKeys.InstallationSpecific,
            InstallationSpecificKeys.QuantitySummary,
            {
              quantity: item.quantity.toString(),
              unitOfMeasurement: item.dplItem?.UnitOfMeasurement?.toLowerCase(),
              quantityTotal: item.quantityTotal
            }
          )}
        </Column>
        <Column sm={3} className="right-align-content">
          <div className="buttons-container">
            {!isValidated ? (
              <Button primary onClick={handleOnValidClick}>
                <Icon name="plus" />
                {translate(NamespaceKeys.General, GeneralKeys.Add)}
              </Button>
            ) : (
              <Button onClick={onItemRestore}>
                <Icon name="check" />
              </Button>
            )}
          </div>
        </Column>
      </Row>
      {showProductDetailsDialog && dplInformationItem && (
        <ProductDetailsDialog
          showModal
          item={dplInformationItem}
          onClose={onCloseProductDetailsDialog}
        />
      )}
      {showProductNotesDialog && item.note && (
        <Dialog
          title={translate(NamespaceKeys.General, GeneralKeys.Notes)}
          buttons={
            <Button onClick={onCloseProductNotesDialog}>
              {translate(NamespaceKeys.General, GeneralKeys.Close)}
            </Button>
          }
          className={"product-details-dialog"}
        >
          {item.note}
        </Dialog>
      )}
    </div>
  );
};

export default QuantityValidatorItem;
