import React, { ReactElement, useCallback, useMemo } from "react";

import { Kpi, KpiItem, Loader, Row } from "@react-gcc-eds/core";

import {
  InstallationSpecificKeys,
  NamespaceKeys
} from "../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../translation/translation-utils";
import CenteredContainer from "../../../../common/layout/centered-container";
import {
  NonSerializedInstallationItemState,
  SerializedInstallationItemState
} from "../material-installation-validation-component";
import { ValidationQuantities } from "../quantity-validator/quantity-validator-component";
import ReviewViewItem from "./review-view-item";

import "./review.scss";

type Props = {
  posting: boolean;
  statusAggregate: ValidationQuantities;
  serializedItems: SerializedInstallationItemState[];
  nonSerializedItems: NonSerializedInstallationItemState[];
  totalQuantity: number;
};

const ReviewView = ({
  posting,
  statusAggregate,
  serializedItems,
  nonSerializedItems,
  totalQuantity
}: Props): ReactElement => {
  const { translate } = useTranslation();

  const getInstalledQuantity = useCallback(() => {
    return (
      totalQuantity - (statusAggregate?.Ok ?? 0) - (statusAggregate?.None ?? 0)
    );
  }, [statusAggregate.None, statusAggregate.Ok, totalQuantity]);

  const renderStatus = useCallback(
    (translationKey: InstallationSpecificKeys, quantity: number) => (
      <div>
        <Kpi>
          <KpiItem inline>
            <KpiItem color="gray">
              {translate(NamespaceKeys.InstallationSpecific, translationKey)}
            </KpiItem>
          </KpiItem>
          <KpiItem inline>({quantity})</KpiItem>
        </Kpi>
      </div>
    ),
    [translate]
  );

  const statusSummary = useMemo<JSX.Element>(
    () => (
      <Row className="review-kpi-row">
        {renderStatus(
          InstallationSpecificKeys.InstallMaterial,
          statusAggregate?.Ok ?? 0
        )}
        {renderStatus(
          InstallationSpecificKeys.InstalledMaterial,
          getInstalledQuantity()
        )}
        {renderStatus(
          InstallationSpecificKeys.NonInstalledMaterial,
          statusAggregate?.None ?? 0
        )}
      </Row>
    ),
    [getInstalledQuantity, renderStatus, statusAggregate]
  );

  return posting ? (
    <div className="review-view-loader-container">
      <CenteredContainer>
        <Loader size="large" />
      </CenteredContainer>
    </div>
  ) : (
    <>
      {statusSummary}
      <ReviewViewItem
        serializedItems={serializedItems}
        nonSerializedItems={nonSerializedItems}
      />
    </>
  );
};

export default ReviewView;
