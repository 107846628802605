import React, { useState } from "react";

import { Button, Icon } from "@react-gcc-eds/core";

import { UserTooltipModel } from "../../domain/client-customer";
import UserDetailsModal from "./user-details-modal";

import "./user-information-button.scss";

type Props = {
  user: UserTooltipModel;
  inline?: boolean;
};

const UserInformationButton: (props: Props) => React.ReactElement = ({
  user,
  inline
}: Props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const handleOnClick: () => void = () => {
    setShowDialog(true);
  };
  const handleOnCloseDialog: () => void = () => {
    setShowDialog(false);
  };
  return (
    <>
      {inline ? (
        // Disabling rule because we are not controlling the jsx-a11y plugin.
        // Rule is regarding accessibility only
        // eslint-disable-next-line
        <a onClick={handleOnClick} className="user-details subtle-link">
          <Icon name="avatar" />
          {user.FirstName} {user.LastName}
        </a>
      ) : (
        <div className="user-details">
          <Button onClick={handleOnClick}>
            <Icon name="avatar" />
            {user.FirstName} {user.LastName}
          </Button>
        </div>
      )}

      {showDialog && (
        <UserDetailsModal user={user} onClose={handleOnCloseDialog} />
      )}
    </>
  );
};

export default UserInformationButton;
