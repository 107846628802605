import React, { useMemo } from "react";

import { Tile } from "@react-gcc-eds/core";
import moment from "moment";

import { ReleaseNote, ReleaseViewModel } from "../../domain/client-customer";
import { capitalizeFirstLetter } from "../../utils/utils";

type Props = {
  releaseSection: ReleaseViewModel;
};

const ReleaseNotesItem = ({ releaseSection }: Props): React.ReactElement => {
  const renderNote = (n: ReleaseNote, i: number): React.ReactElement => (
    <div key={`${n.Comment}_${i}`} className="release-note-item">
      <h4 className="release-note-type">
        {n.Type && capitalizeFirstLetter(n.Type)}
      </h4>
      <div className="release-note-text">{n.Comment}</div>
    </div>
  );

  const releaseDate = useMemo<string>(
    () => moment(releaseSection.Released).format("LL"),
    [releaseSection.Released]
  );

  return (
    releaseSection && (
      <Tile title={releaseSection.Version || ""} subtitle={releaseDate}>
        {releaseSection.Notes?.map(renderNote)}
      </Tile>
    )
  );
};

export default ReleaseNotesItem;
