import React from "react";
import cx from "classnames";
import { uniqueId } from "../../utils/utils";
import ISwitch from "./ISwitch";

const Switch = ({
  onLabel = "On",
  offLabel = "Off",
  value,
  disabled,
  onChange,
  className,
  style
}: ISwitch) => {
  const id = uniqueId();
  return (
    <label
      htmlFor={id}
      id={id}
      className={cx("switch", className)}
      style={style}
      onClick={() => !disabled && onChange(!value)}
    >
      <input type="checkbox" checked={value} disabled={disabled} onChange={() => {}} />
      <i className="ball" />
      <span className="checkbox-label" data-enabled={onLabel} data-disabled={offLabel} />
    </label>
  );
};
export default Switch;
