import { useCallback, useState, useEffect } from "react";

import { useHistory } from "react-router";

import {
  CustomerViewModel,
  CurrentUserModel,
  UserClient as CustomerUserClient,
  CommonCustomerClient
} from "../domain/client-customer";
import { AppSettings } from "./app-settings";
import { AuthenticationProps } from "./useAuthentication";

type CustomersProps = {
  customers: CustomerViewModel[] | undefined;
  currentCustomer: CustomerViewModel | undefined;
  currentUser: CurrentUserModel | undefined;
  changeCustomer: (customer: CustomerViewModel) => void;
  loading: boolean;
  userError: string | undefined;
  resetUserData: () => void;
};

const currentCustomerLocalStorageKey = "ESR-CurrentCustomer";

const useCustomers = (
  {
    getCustomerConfigurationProvider,
    isAuthenticated,
    resetAuthenticationData
  }: AuthenticationProps,
  { applicationLinkBaseUri }: AppSettings
): CustomersProps => {
  const [customers, setCustomers] = useState<CustomerViewModel[]>();
  const [currentCustomer, setCurrentCustomer] = useState<CustomerViewModel>();
  const [currentUser, setCurrentUser] = useState<CurrentUserModel>();
  const [loading, setLoading] = useState<number>(0);
  const [userError, setUserError] = useState<string>();
  const history = useHistory();

  const resetUserData = useCallback(() => {
    resetAuthenticationData();
    setCurrentUser(undefined);
    setCurrentCustomer(undefined);
    setCustomers(undefined);
    setUserError(
      "Your session may have expired. Please sign in again to continue using ESR."
    );
  }, [resetAuthenticationData]);

  const getUser = useCallback(
    async (customer: CustomerViewModel) => {
      setLoading((prev) => prev + 1);
      try {
        if (!customer.HeaderIdentifier)
          throw new Error("Customer does not have a HeaderIdentifier");
        const customerUserClient = new CustomerUserClient(
          await getCustomerConfigurationProvider()
        );
        const user = await customerUserClient.currentUser(
          customer.HeaderIdentifier
        );
        setCurrentCustomer(customer);
        setCurrentUser((prevUser) => {
          if (prevUser && user) {
            history.push("/");
          }
          return user;
        });
        localStorage.setItem(
          currentCustomerLocalStorageKey,
          customer.HeaderIdentifier
        );
      } catch (error) {
        // handle error
        console.error(error);
        resetUserData();
      } finally {
        setLoading((prev) => prev - 1);
      }
    },
    [getCustomerConfigurationProvider, history, resetUserData]
  );

  const getCustomers = useCallback(async () => {
    setLoading((prev) => prev + 1);
    try {
      const customersClient = new CommonCustomerClient(
        await getCustomerConfigurationProvider()
      );
      const customers = await customersClient.getValidCustomers();
      if (!customers.length) throw new Error("No customer found");

      setCustomers(customers);

      const cachedCustomerIdentifier = localStorage.getItem(
        currentCustomerLocalStorageKey
      );
      const cachedCustomer = customers.find(
        (c) => c.HeaderIdentifier === cachedCustomerIdentifier
      );
      const customer = cachedCustomer ?? customers[0];
      getUser(customer);
    } catch (error) {
      // handle error
      console.error(error);
      resetUserData();
    } finally {
      setLoading((prev) => prev - 1);
    }
  }, [getCustomerConfigurationProvider, getUser, resetUserData]);

  useEffect(() => {
    console.log("customers, isAuthenticated", customers, isAuthenticated);
    if (customers || !isAuthenticated || !applicationLinkBaseUri) return;
    getCustomers();
  }, [customers, getCustomers, isAuthenticated, applicationLinkBaseUri]);

  const changeCustomer = useCallback(
    (customer: CustomerViewModel): void => {
      if (!customers || !customers.includes(customer)) return;
      getUser(customer);
    },
    [customers, getUser]
  );

  return {
    customers,
    currentCustomer,
    currentUser,
    changeCustomer,
    loading: loading > 0,
    userError,
    resetUserData
  };
};

export default useCustomers;
