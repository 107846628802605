import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";

import App from "./app";
import * as serviceWorker from "./serviceWorker";
import "./translation/i18n";

if (!window.frameElement) {
  ReactDOM.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>,
    document.getElementById("root")
  );
  serviceWorker.register();
}
