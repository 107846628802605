import React, { useCallback, useMemo } from "react";

import { Dropdown, IDropdownItem } from "@react-gcc-eds/core";

import { ChecklistItemOptionViewModel } from "../../../../domain/client-obsolete";
import ChecklistStandardItem from "./generic/checklist-standard-item";
import { SingleItemComponent, SingleItemProps } from "./types";

const optionToDropdownItem = (
  option: ChecklistItemOptionViewModel
): IDropdownItem => ({
  title: option.Option ?? "",
  value: option.Option ?? ""
});

const DropdownElement: SingleItemComponent = ({
  item,
  onValueChanged
}: SingleItemProps) => {
  const handleOnChange = useCallback(
    (dropdownItem: IDropdownItem): void =>
      onValueChanged({
        ...item,
        Values: [dropdownItem.value]
      }),
    [item, onValueChanged]
  );

  const label = useMemo<string>(
    () => item.Values?.[0] ?? item.Options?.[0].Option ?? "",
    [item.Values, item.Options]
  );

  const dropdownItems = useMemo<IDropdownItem[]>(
    () => item.Options?.map(optionToDropdownItem) ?? [],
    [item.Options]
  );

  return (
    <ChecklistStandardItem item={item} onValueChanged={onValueChanged}>
      <Dropdown
        items={dropdownItems}
        label={label}
        onItemClick={handleOnChange}
      />
    </ChecklistStandardItem>
  );
};

export default DropdownElement;
