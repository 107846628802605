import React from "react";

import { Button, Column, Row } from "@react-gcc-eds/core";

import {
  GeneralKeys,
  NamespaceKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import MaterialTransferDetails from "../../material-transfer/details";
import { SelectableMaterialTransferOverviewViewModel } from "../shared/receive-material-models";

type Props = {
  onClickBackToList: () => void;
  item: SelectableMaterialTransferOverviewViewModel | undefined;
};

const ReceiveMaterialTransferDetailsView = ({
  item,
  onClickBackToList
}: Props): React.ReactElement => {
  const { translate } = useTranslation();
  const handleOnClickBackToList = React.useCallback(() => {
    onClickBackToList();
  }, [onClickBackToList]);

  return (
    <div className="rm-container">
      <div className="rm-body">
        <div className="list-transfers">
          <MaterialTransferDetails
            materialTransferId={item?.MaterialTransferId}
            externalHandlingUnit={item?.ExternalHandlingUnit}
          />
        </div>
      </div>
      <div className="rm-footer">
        <Row className="rm-row">
          <Column sm={12}>
            <Button onClick={handleOnClickBackToList}>
              {translate(NamespaceKeys.General, GeneralKeys.Back)}
            </Button>
          </Column>
        </Row>
      </div>
    </div>
  );
};

export default ReceiveMaterialTransferDetailsView;
