import cx from "classnames";
import React from "react";
import IEmptyState from "./IEmptyState";

const EmptyState = ({ message, action, className, style }: IEmptyState) => {
  return (
    <div className={cx("empty-state", className)} style={style}>
      <div className="message">
        <p>{message}</p>
        {action}
      </div>
    </div>
  );
};

export default EmptyState;
