import React, { ReactElement, useState, useCallback, useMemo } from "react";

import { DeliveryProductListItemInformation } from "../../../../../common/product-details-modal/product-details-dialog";
import { fromDplItemModelToInformationItem } from "../../../../../common/product-details-modal/product-details-utils";
import {
  SerialNumberValidationItem,
  SerialNumberType
} from "../serial-number-validator-component";
import SerialNumberValidatorItemView from "./serial-number-validator-item-view";

type Props = {
  item: SerialNumberValidationItem;
  materialTransferId: string;
  onItemChange: (
    materialTransferId: string,
    item: SerialNumberValidationItem
  ) => void;
};

const SerialNumberValidatorItemComponent = ({
  item,
  materialTransferId,
  onItemChange
}: Props): ReactElement => {
  const [serialNumberValidationItem, setSerialNumberValidationItem] =
    React.useState<SerialNumberValidationItem>(item);

  const [showScanner, setShowScanner] = useState<boolean>(false);
  const [serialNumberTypeToBeScanned, setSerialNumberTypeToBeScanned] =
    useState<SerialNumberType>(SerialNumberType.None);
  const [showProductDetailsDialog, setShowProductDetailsDialog] =
    useState<boolean>(false);
  const dplInformationItem = useMemo<
    DeliveryProductListItemInformation | undefined
  >(
    () => fromDplItemModelToInformationItem(serialNumberValidationItem.dplItem),
    [serialNumberValidationItem.dplItem]
  );

  const handleOnCustomerSerialNumberChange = useCallback(
    (serial: string): void => {
      const updatedItem: SerialNumberValidationItem = {
        ...serialNumberValidationItem,
        customerSerialNumber: serial
      };

      setSerialNumberValidationItem(updatedItem);
      onItemChange(materialTransferId, updatedItem);
    },
    [serialNumberValidationItem, onItemChange, materialTransferId]
  );

  const handleOnEricssonSerialNumberChange = useCallback(
    (serial: string): void => {
      const updatedItem: SerialNumberValidationItem = {
        ...serialNumberValidationItem,
        ericssonSerialNumber: serial
      };

      setSerialNumberValidationItem(updatedItem);
      onItemChange(materialTransferId, updatedItem);
    },

    [onItemChange, serialNumberValidationItem, materialTransferId]
  );

  const handleOnItemValidate = useCallback(() => {
    const updatedItem: SerialNumberValidationItem = {
      ...serialNumberValidationItem,
      serialNumbersValidated: true
    };

    setSerialNumberValidationItem(updatedItem);
    onItemChange(materialTransferId, updatedItem);
  }, [onItemChange, serialNumberValidationItem, materialTransferId]);

  const handleOnItemUndo = useCallback(() => {
    if (!serialNumberValidationItem.serialNumbersValidated) return;
    const updatedItem: SerialNumberValidationItem = {
      ...serialNumberValidationItem,
      serialNumbersValidated: false
    };

    setSerialNumberValidationItem(updatedItem);

    onItemChange(materialTransferId, updatedItem);
  }, [serialNumberValidationItem, onItemChange, materialTransferId]);

  const handleOnToggleScanner = useCallback(
    (serialNumberType?: SerialNumberType) => {
      setSerialNumberTypeToBeScanned(serialNumberType ?? SerialNumberType.None);
      setShowScanner((prev) => !prev);
    },
    []
  );

  const handleOnScan = useCallback(
    (serial: string) => {
      setShowScanner(false);
      switch (serialNumberTypeToBeScanned) {
        case SerialNumberType.CustomerSerialNumber:
          handleOnCustomerSerialNumberChange(serial);
          return;
        case SerialNumberType.EricssonSerialNumber:
          handleOnEricssonSerialNumberChange(serial);
          return;
        default:
          return;
      }
    },
    [
      serialNumberTypeToBeScanned,
      handleOnCustomerSerialNumberChange,
      handleOnEricssonSerialNumberChange
    ]
  );

  const handleOnValidateScanResult = useCallback(
    (scannedData: string): boolean => {
      return scannedData.length > 0;
    },
    []
  );

  const handleOnClickProductDetailsButton = useCallback(() => {
    setShowProductDetailsDialog(true);
  }, []);

  const handleOnCloseProductDetailsDialog = useCallback(() => {
    setShowProductDetailsDialog(false);
  }, []);

  return (
    <>
      <SerialNumberValidatorItemView
        materialTransferId={materialTransferId}
        dplInformationItem={dplInformationItem}
        item={serialNumberValidationItem}
        isValidated={serialNumberValidationItem.serialNumbersValidated}
        validButtonDisabled={false}
        onCustomerSerialNumberChange={handleOnCustomerSerialNumberChange}
        onEricssonSerialNumberChange={handleOnEricssonSerialNumberChange}
        onItemUndo={handleOnItemUndo}
        onItemValidate={handleOnItemValidate}
        showScanner={showScanner}
        onToggleScanner={handleOnToggleScanner}
        onScan={handleOnScan}
        onValidateScanResult={handleOnValidateScanResult}
        onClickProductDetailsButton={handleOnClickProductDetailsButton}
        onCloseProductDetailsDialog={handleOnCloseProductDetailsDialog}
        showProductDetailsDialog={showProductDetailsDialog}
      />
    </>
  );
};

export default SerialNumberValidatorItemComponent;
