import React, { useEffect, useCallback, useMemo } from "react";

import { BillOfMaterialItemDtoEditState } from "../bom-viewer-component";
import BomFilterView from "./bom-filter-view";

import "../bom-viewer.scss";

const getUniqueCommodities = (
  bom: BillOfMaterialItemDtoEditState[] | undefined
): string[] => {
  if (!bom) return [];
  return [
    ...new Set([
      "",
      ...bom.map((c) => c.bomItem.DeliveryProductListItem?.Commodity ?? "")
    ])
  ];
};

const getUniqueMaterialCategories = (
  bom: BillOfMaterialItemDtoEditState[] | undefined
): string[] => {
  if (!bom) return [];
  return [
    ...new Set(["", ...bom.map((c) => c.bomItem.MaterialCategory ?? "")])
  ];
};

const getUniqueSourcingProviders = (
  bom: BillOfMaterialItemDtoEditState[] | undefined
): string[] => {
  if (!bom) return [];
  return [
    ...new Set(["", ...bom.map((c) => c.bomItem.SourcingProvider ?? "")])
  ];
};

export type BomFilters = {
  Commodity: string;
  MaterialCategory: string;
  SourcingProvider: string;
  EricssonProductNumber: string;
};

type Props = {
  filters: BomFilters;
  hidden?: boolean;
  bom: BillOfMaterialItemDtoEditState[] | undefined;
  onFiltersChanged: (filters: BomFilters) => void;
  onToggleFilter: () => void;
  onFilterCommodityChanged: (commodity: string) => void;
  onFilterMaterialCategoryChanged: (materialCategory: string) => void;
  onFilterSourcingProviderChanged: (sourcingProvider: string) => void;
  onSearchChanged: (ericssonProductNumber: string) => void;
};
const BomFilterComponent = ({
  hidden,
  filters,
  bom,
  onFiltersChanged,
  onToggleFilter,
  onFilterCommodityChanged,
  onFilterMaterialCategoryChanged,
  onFilterSourcingProviderChanged,
  onSearchChanged
}: Props): React.ReactElement | null => {
  const commoditiesList = useMemo<string[]>(
    () => getUniqueCommodities(bom),
    [bom]
  );
  const materialCategoriesList = useMemo<string[]>(
    () => getUniqueMaterialCategories(bom),
    [bom]
  );
  const sourcingProvidersList = useMemo<string[]>(
    () => getUniqueSourcingProviders(bom),
    [bom]
  );

  useEffect(() => {
    onFiltersChanged({
      Commodity: filters.Commodity,
      SourcingProvider: filters.SourcingProvider,
      MaterialCategory: filters.MaterialCategory,
      EricssonProductNumber: filters.EricssonProductNumber
    });
  }, [
    filters.Commodity,
    filters.EricssonProductNumber,
    filters.MaterialCategory,
    filters.SourcingProvider,
    onFiltersChanged
  ]);

  const handleOnFilterCommodity: (commodity: string) => void = useCallback(
    (commodity) => onFilterCommodityChanged(commodity),
    [onFilterCommodityChanged]
  );

  const handleOnFilterMaterialCategory: (materialCategory: string) => void =
    useCallback(
      (materialCategory) => onFilterMaterialCategoryChanged(materialCategory),
      [onFilterMaterialCategoryChanged]
    );
  const handleOnFilterSourcingProvider: (sourcingProvider: string) => void =
    useCallback(
      (sourcingProvider) => onFilterSourcingProviderChanged(sourcingProvider),
      [onFilterSourcingProviderChanged]
    );

  const handleOnClearingFilters = useCallback((): void => {
    onFilterCommodityChanged("");
    onFilterMaterialCategoryChanged("");
    onFilterSourcingProviderChanged("");
    onSearchChanged("");
  }, [
    onFilterCommodityChanged,
    onFilterMaterialCategoryChanged,
    onFilterSourcingProviderChanged,
    onSearchChanged
  ]);

  const isFiltered = useCallback(
    (): boolean => !Object.values(filters).every((value) => value === ""),
    [filters]
  );

  return (
    (!hidden && (
      <BomFilterView
        commoditiesList={commoditiesList}
        materialCategoriesList={materialCategoriesList}
        sourcingProvidersList={sourcingProvidersList}
        searchQuery={filters.EricssonProductNumber}
        onToggleFilter={onToggleFilter}
        handleOnFilterCommodity={handleOnFilterCommodity}
        handleOnFilterMaterialCategory={handleOnFilterMaterialCategory}
        handleOnFilterSourcingProvider={handleOnFilterSourcingProvider}
        handleOnSearchChange={onSearchChanged}
        filteredCommodity={filters.Commodity}
        filteredMaterialCategory={filters.MaterialCategory}
        filteredSourcingProvider={filters.SourcingProvider}
        onFiltersChanged={onFiltersChanged}
        onClearFilters={handleOnClearingFilters}
        isFiltered={isFiltered}
      />
    )) ||
    null
  );
};

export default BomFilterComponent;
