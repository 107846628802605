import React from "react";

import { AuthenticationContext } from "../../../../contexts/authentication-context";
import { CustomerContext } from "../../../../contexts/customer-context";
import {
  MaterialTransfersClient,
  ValidateMaterialTransfersRequestModel
} from "../../../../domain/client-customer";
import {
  ComponentSpecificKeys,
  NamespaceKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";

type validateReceivedMaterialsType = {
  loading: boolean;
  error: string;
  success: boolean;
  getData: (data: ValidateMaterialTransfersRequestModel[]) => void;
};

const useValidateReceivedMaterial = (): validateReceivedMaterialsType => {
  const { currentCustomer } = React.useContext(CustomerContext);
  const { getCustomerConfigurationProvider } = React.useContext(
    AuthenticationContext
  );
  const { translate } = useTranslation();

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>("");
  const [success, setSuccess] = React.useState<boolean>(false);

  const getData = React.useCallback(
    async (data: ValidateMaterialTransfersRequestModel[]) => {
      setLoading(true);
      const customerIdentifier =
        currentCustomer && currentCustomer.HeaderIdentifier;

      const showError = () => {
        setError(
          translate(
            NamespaceKeys.ComponentSpecific,
            ComponentSpecificKeys.AnErrorOccurredPleaseTryAgain
          )
        );
      };

      if (!customerIdentifier) {
        setLoading(false);
        return;
      }
      await new MaterialTransfersClient(
        await getCustomerConfigurationProvider()
      )
        .validateMaterialTransfers(customerIdentifier, data)
        .then(() => {
          setSuccess(true);
        })
        .catch(() => showError())
        .finally(() => {
          setLoading(false);
        });
    },
    [getCustomerConfigurationProvider, currentCustomer, translate]
  );

  return { loading, error, success, getData };
};

export default useValidateReceivedMaterial;
