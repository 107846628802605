import { useState, useCallback, useEffect } from "react";

import { AuthenticationState } from "react-aad-msal";

import { authenticationProvider } from "../authentication/authentication-provider";
import { ConfigurationProvider as CustomerConfigurationProvider } from "../domain/client-customer";
import { getCookies } from "../utils/cookie-utils";
import { AppSettings } from "./app-settings";
import { useBaseUrls } from "./use-base-urls";

export type AuthenticationProps = {
  isAuthenticated: boolean;
  getCustomerConfigurationProvider: () => Promise<CustomerConfigurationProvider>;
  login: () => Promise<void>;
  logout: () => void;
  resetAuthenticationData: () => void;
};

export const getIdToken: () => Promise<string | undefined> = async () => {
  try {
    const tokenResponse = await authenticationProvider.acquireTokenSilent({
      scopes: ["email"],
      forceRefresh: false
    });
    return tokenResponse.idToken.rawIdToken;
  } catch (error) {
    console.log("getidtokenerror", error);
  }
};

const resetAuthenticationLocalStorageKeys = (): void => {
  const msalKeys: string[] = [];
  for (let i = 0; i < window.localStorage.length; i++) {
    const key = window.localStorage.key(i);
    if (!key) console.error("Failed to reset authentication data.");
    if (key && (key.startsWith("msal.") || key.startsWith('{"authority"'))) {
      msalKeys.push(key);
    }
  }
  msalKeys.forEach((key: string) => {
    console.log(
      `Removing localstorage ${key}: `,
      window.localStorage.getItem(key)
    );
    window.localStorage.removeItem(key);
  });
};

const resetAuthenticationCookies = (): void => {
  const cookies = getCookies();
  const cookieNames = Object.keys(cookies);
  cookieNames.forEach((cookieName: string) => {
    if (cookieName.startsWith("msal.")) {
      document.cookie = `${cookieName}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  });
};

export const resetAuthenticationData = (): void => {
  resetAuthenticationLocalStorageKeys();
  resetAuthenticationCookies();
};

const calculateAuthenticated = (
  authenticatedState: AuthenticationState
): boolean => authenticatedState === AuthenticationState.Authenticated;

const useAuthentication = (appSettings: AppSettings): AuthenticationProps => {
  const { scopingBaseUrl } = useBaseUrls(appSettings);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    calculateAuthenticated(authenticationProvider.authenticationState)
  );

  const authenticationStateHandler = useCallback(
    (state: AuthenticationState) => {
      console.log("AUTHENTICATED HANDLER CALLED");
      setIsAuthenticated(calculateAuthenticated(state));
    },
    []
  );

  useEffect((): (() => void) => {
    authenticationProvider.registerAuthenticationStateHandler(
      authenticationStateHandler
    );
    return (): void =>
      authenticationProvider.unregisterAuthenticationStateHandler(
        authenticationStateHandler
      );
  }, [authenticationStateHandler]);

  const getCustomerConfigurationProvider = useCallback(
    async (): Promise<CustomerConfigurationProvider> =>
      new CustomerConfigurationProvider(await getIdToken(), scopingBaseUrl),
    [scopingBaseUrl]
  );

  return {
    isAuthenticated,
    getCustomerConfigurationProvider,
    login: authenticationProvider.login,
    logout: authenticationProvider.logout,
    resetAuthenticationData
  };
};

export default useAuthentication;
