export const SortByAscending =
  <T>(getTextProperty: (object: T) => string | undefined) =>
  (objectA: T, objectB: T) => {
    const upperA = getTextProperty(objectA)?.toUpperCase();
    const upperB = getTextProperty(objectB)?.toUpperCase();
    if (upperA && upperB && upperA < upperB) {
      return -1;
    }
    if (upperA && upperB && upperA > upperB) {
      return 1;
    }
    return 0;
  };
