import React, { useContext } from "react";

import { Button, Checkbox, Dialog } from "@react-gcc-eds/core";

import { AppSettingsContext } from "../../../contexts/app-settings-context";
import {
  ApplicationKeys,
  NamespaceKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";

type Props = {
  isAuthenticatedPage: boolean;
  isCheckbox: boolean;
  setModalOpen: (res: boolean) => void;
  setIsChecked: (res: boolean) => void;
};
const NotificationModal: React.FC<Props> = ({
  isAuthenticatedPage,
  isCheckbox,
  setModalOpen,
  setIsChecked
}: Props) => {
  const { translate } = useTranslation();
  const appSettings = useContext(AppSettingsContext);
  const link =
    navigator.userAgent.toLowerCase().indexOf("android") > -1
      ? appSettings.ecoAndroidUrl
      : appSettings.ecoIosUrl;
  return (
    <Dialog
      buttons={
        <Button className="btn primary" onClick={() => setModalOpen(false)}>
          DISMISS
        </Button>
      }
      title="Alert"
    >
      <p>
        {translate(
          NamespaceKeys.Application,
          ApplicationKeys.NewAppNotificationDetail
        )}
      </p>
      <p>
        <a href={link} target="_blank" rel="noreferrer">
          Download new app
        </a>
      </p>
      {isAuthenticatedPage && (
        <Checkbox
          checked={isCheckbox}
          text={translate(
            NamespaceKeys.Application,
            ApplicationKeys.NewAppNotificationDoNotShow
          )}
          onChange={() => {
            setIsChecked(!isCheckbox);
          }}
        />
      )}
    </Dialog>
  );
};
export default NotificationModal;
