export const getYear = (date: Date) => {
  return date.toLocaleString("en-us", { year: "numeric" });
};
export const getMonth = (date: Date) => {
  return date.toLocaleString("en-us", { month: "long" });
};
export const changeDate = (date: Date, numOfmonths: number) => {
  return new Date(date.setMonth(date.getMonth() + numOfmonths));
};

export const daysInMonth = (date: Date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};
export const isEqual = (d1?: Date, d2?: Date) => {
  if (d1 === undefined || d2 === undefined || d1 === null || d2 === null) {
    return false;
  }
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
};
