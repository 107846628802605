import React from "react";
import cx from "classnames";
import AppBar from "./AppBar";
import useAppContext from "./context/ContextHelpers";
import AppMenu from "./AppMenu";
import IAppView from "./contracts/IAppView";

const AppView = ({ className, title, subtitle, actions, menu, children }: IAppView) => {
  const { settings, notifications } = useAppContext();
  return (
    <div
      className={cx("app", className, {
        "slide-right": !settings.visible && !notifications.visible,
        "slide-left": settings.visible || notifications.visible
      })}
    >
      <AppBar title={title} subtitle={subtitle} actions={actions} withMenu={!!menu} />
      <div className="appbody">
        {menu && <AppMenu>{menu}</AppMenu>}
        <div className="appcontent">{children}</div>
      </div>
    </div>
  );
};

export default AppView;
