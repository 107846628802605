import React from "react";
import cx from "classnames";

import IAccordion from "./IAccordion";

const Accordion = ({ children, disabled, lines, className }: IAccordion) => {
  return (
    <div className={cx("accordion", { disabled }, { lines }, className)}>
      <ul>{children}</ul>
    </div>
  );
};

export default Accordion;
