import { useState, useCallback } from "react";

import { Camera, CameraAccess } from "scandit-sdk";

import { removeIncompatibleCamerasFilter } from "../scanner-utils";

type CameraProps = {
  hasCameraAccess: boolean;
  cameras: Camera[] | undefined;
  askForPermission: () => void;
  cameraAccessError: boolean;
};

const useCameraPermissions = (): CameraProps => {
  const [cameras, setCameras] = useState<Camera[]>();
  const [hasCameraAccess, setHasCameraAccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const getCameras = useCallback(async (): Promise<Camera[] | undefined> => {
    try {
      return await CameraAccess.getCameras();
    } catch (error) {
      setError(true);
      return undefined;
    }
  }, []);

  const askForPermission = useCallback((): void => {
    if (hasCameraAccess || cameras) return;
    getCameras().then((cameraResponse: Camera[] | undefined) => {
      const filteredCameras = cameraResponse?.filter(
        removeIncompatibleCamerasFilter
      );
      setCameras(filteredCameras ?? []);
      setHasCameraAccess(!!filteredCameras);
    });
  }, [cameras, getCameras, hasCameraAccess]);

  return {
    askForPermission,
    cameras,
    hasCameraAccess,
    cameraAccessError: error
  };
};

export default useCameraPermissions;
