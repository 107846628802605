import React from "react";

export type PageTitle = {
  title?: string;
  subtitle?: string;
  actions?: React.ReactNode;
};

type LayoutContextProps = {
  pageTitle?: PageTitle;
  setPageTitle?: (title: PageTitle) => void;
};

export const LayoutContext = React.createContext<LayoutContextProps>({});
