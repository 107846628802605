import React, { useCallback } from "react";

import { TextField } from "@react-gcc-eds/core";

import { SingleItemProps, SingleItemComponent } from "../types";

const ChecklistItemComment: SingleItemComponent = ({
  item,
  onValueChanged
}: SingleItemProps) => {
  const handleOnChangeComment: (value: string) => void = useCallback(
    (value) =>
      onValueChanged({
        ...item,
        Comment: value
      }),
    [item, onValueChanged]
  );

  return (
    <div className="comment">
      <TextField
        value={item.Comment ?? ""}
        onChange={handleOnChangeComment}
        fullWidth
      />
    </div>
  );
};

export default ChecklistItemComment;
