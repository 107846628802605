import React from "react";

import { MaterialTransferItemStatus } from "../../../../domain/client-customer";
import { ReceiveMaterialContext } from "../contexts/receive-material-context";
import {
  DeviationSummaryItem,
  MaterialTransferHandlingUnitState
} from "./receive-material-models";

export type DeviationSummaryList = {
  deviationSummaryList: DeviationSummaryItem[];
  numberOfDamagedItems: number;
  numberOfMissingItems: number;
  materialTransferId: string;
};

export type DeviationSummaryProps = {
  deviationSummaryList: DeviationSummaryList[];
  totalMissing: number;
  totalDamaged: number;
};

const useDeviationSummaries = (): DeviationSummaryProps => {
  const deviationSummaryList = React.useMemo(
    (): DeviationSummaryList[] => [],
    []
  );

  const { transferDetailsStateItems } = React.useContext(
    ReceiveMaterialContext
  );

  const nonSerializedDeviations = (
    receiveMaterialTransferStateItem: MaterialTransferHandlingUnitState
  ) => {
    const nonSerializedValidationQuantities =
      receiveMaterialTransferStateItem?.NonSerializedItems.filter(
        (x) =>
          x.validationQuantities?.Damaged || x.validationQuantities?.Missing
      );

    const groupedByStatus: DeviationSummaryItem[] = [];

    nonSerializedValidationQuantities?.forEach((item) => {
      const damage = item.validationQuantities?.Damaged || 0;
      const missing = item.validationQuantities?.Missing || 0;

      if (damage > 0) {
        groupedByStatus.push({
          ericssonProductNumber: item.dplItem?.EricssonProductNumber || "",
          quantity: damage,
          status: MaterialTransferItemStatus[MaterialTransferItemStatus.Damaged]
        });
      }

      if (missing > 0) {
        groupedByStatus.push({
          ericssonProductNumber: item.dplItem?.EricssonProductNumber || "",
          quantity: missing,
          status: MaterialTransferItemStatus[MaterialTransferItemStatus.Missing]
        });
      }
    });

    return groupedByStatus;
  };

  const serializedDeviations = (
    receiveMaterialTransferStateItem: MaterialTransferHandlingUnitState
  ) => {
    const serializedStateItems =
      receiveMaterialTransferStateItem?.SerializedItems.filter(
        (x) =>
          x.status === MaterialTransferItemStatus.Damaged ||
          x.status === MaterialTransferItemStatus.Missing
      );
    const groupedByStatus: DeviationSummaryItem[] = [];
    serializedStateItems?.forEach((item) => {
      if (item.status) {
        const foundItem = groupedByStatus.find(
          (x) =>
            x.ericssonProductNumber === item.dplItem?.EricssonProductNumber &&
            x.status ===
              MaterialTransferItemStatus[
                item.status || MaterialTransferItemStatus.None
              ]
        );
        if (foundItem) {
          foundItem.quantity = foundItem.quantity + 1;
        } else {
          groupedByStatus.push({
            ericssonProductNumber: item.dplItem?.EricssonProductNumber || "",
            quantity: 1,
            status: MaterialTransferItemStatus[item.status]
          });
        }
      }
    });

    return groupedByStatus;
  };

  transferDetailsStateItems.forEach((transferDetailsStateItem) => {
    transferDetailsStateItem.HandlingUnits.forEach((handlingUnitState) => {
      const nonSerialized = nonSerializedDeviations(handlingUnitState);
      const serialized = serializedDeviations(handlingUnitState);

      const deviationsList = [...nonSerialized, ...serialized];

      const numberOfDamagedItems = deviationsList
        .filter(
          (x) =>
            x.status ===
            MaterialTransferItemStatus[MaterialTransferItemStatus.Damaged]
        )
        .map((i) => i.quantity)
        .reduce((a, b) => a + b, 0);

      const numberOfMissingItems = deviationsList
        .filter(
          (x) =>
            x.status ===
            MaterialTransferItemStatus[MaterialTransferItemStatus.Missing]
        )
        .map((i) => i.quantity)
        .reduce((a, b) => a + b, 0);

      deviationSummaryList.push({
        materialTransferId: transferDetailsStateItem.MaterialTransferId,
        deviationSummaryList: deviationsList,
        numberOfDamagedItems,
        numberOfMissingItems
      });
    });
  });

  const totalDamaged = React.useMemo((): number => {
    return deviationSummaryList
      .map((i) => i.numberOfDamagedItems)
      .reduce((a, b) => a + b, 0);
  }, [deviationSummaryList]);

  const totalMissing = React.useMemo((): number => {
    return deviationSummaryList
      .map((i) => i.numberOfMissingItems)
      .reduce((a, b) => a + b, 0);
  }, [deviationSummaryList]);

  return { deviationSummaryList, totalMissing, totalDamaged };
};

export default useDeviationSummaries;
