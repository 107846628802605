import { ReactNode } from "react";

export enum SortingDirection {
  None,
  Ascending,
  Descending
}

export interface TableColumn {
  /** Column property name */
  key: string;
  /** Column header */
  header: string | ReactNode;
  /** Cell renderer */
  Cell?: (row: object) => ReactNode;
  /** Sortable header */
  sortable?: boolean;
  /** Sorting direction */
  sortingDirection?: SortingDirection;
}

export interface ITable {
  /** Columns definition */
  columns: TableColumn[];
  /** Table data. In order to use the 'selectedRows' functionality, each data item must have a
  unique "key" property */
  rows: object[];
  /** Size */
  size?: "normal" | "compact" | "tiny";
  /** Type */
  rowsStyle?: "none" | "dashed" | "striped";
  /** On Row Click, returns entire row object. Also triggered for "keypress" events */
  onRowClick?(data: object): void;
  /** onSortClick */
  onSort?(key: string, sortingDirection: SortingDirection): void;
  /** Extra CSS class  */
  className?: string | string[];
  /** Selectable */
  selectable?: boolean;
  /** Selected rows. This should be an array of keys that match the "key" property of each row data
  object. Rows are only shown as selected if 'selectable' is true. */
  selectedRows?: string[];
  /** Show the table in the "disabled" variation */
  disabled?: boolean;
  /** Styling */
  style?: any;
}
