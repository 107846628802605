import React, { forwardRef, useCallback, useState, useEffect } from "react";

import { VariableSizeList } from "react-window";

import BomItem from "./bom-item";
import { BomItemComponentProps } from "./bom-item/bom-item-component";

const EXPANDED_HEIGHT = 168;
const COLLAPSED_HEIGHT = 46;

interface BomViewerListProps extends BomItemComponentProps {
  height: React.ReactText;
  width: React.ReactText;
}

const BomViewerList = forwardRef<VariableSizeList, BomViewerListProps>(
  (props: BomViewerListProps, ref) => {
    const [itemData, setItemData] = useState<BomItemComponentProps>({
      ...props
    });

    useEffect(() => {
      setItemData({ ...props });
    }, [props]);

    const calculateItemSize = useCallback(
      (index: number): number =>
        props.items[index].collapsed ? COLLAPSED_HEIGHT : EXPANDED_HEIGHT,
      [props.items]
    );

    return (
      <VariableSizeList
        height={props.height}
        width={props.width}
        ref={ref}
        itemData={itemData}
        itemCount={props.items.length}
        itemSize={calculateItemSize}
      >
        {BomItem}
      </VariableSizeList>
    );
  }
);

export default BomViewerList;
