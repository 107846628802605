import React, { useCallback, useEffect, useContext, useState } from "react";

import { AuthenticationContext } from "../../../../contexts/authentication-context";
import { CustomerContext } from "../../../../contexts/customer-context";
import {
  SitesClient,
  WeatherForecastViewModel,
  WeatherUnit
} from "../../../../domain/client-customer";
import { ComponentSpecificKeys } from "../../../../translation/dictionary-keys";
import WeatherTileView from "./weather-tile-view";

type Props = {
  siteId: string;
  collapsedDays: number;
};
export const WeatherTileComponent = ({
  siteId,
  collapsedDays
}: Props): JSX.Element => {
  const { currentCustomer } = useContext(CustomerContext);
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const [weather, setWeather] = useState<WeatherForecastViewModel>();
  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<ComponentSpecificKeys>();
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const [unit, setUnit] = useState<WeatherUnit>(WeatherUnit.Imperial);
  const getWeather = useCallback(async () => {
    if (!currentCustomer?.HeaderIdentifier) {
      return;
    }
    setLoading(true);
    new SitesClient(await getCustomerConfigurationProvider())
      .getWeatherForecastForSite(siteId, unit, currentCustomer.HeaderIdentifier)
      .then((w) => {
        setWeather(w);
      })
      .catch(() => {
        setErrorMessage(
          ComponentSpecificKeys.SitesCoordinatesErrorMessageTitle
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentCustomer, getCustomerConfigurationProvider, siteId, unit]);

  useEffect(() => {
    getWeather();
  }, [getWeather, unit]);

  const handleOnToggle = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  return (
    <WeatherTileView
      forecast={weather?.DailyForecasts}
      city={weather?.City}
      errorMessage={errorMessage}
      loading={loading}
      dayLimit={collapsed ? collapsedDays : undefined}
      onToggle={handleOnToggle}
      onUnit={(value): void => setUnit(value)}
    />
  );
};
export default WeatherTileComponent;
