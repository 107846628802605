import React, { MouseEventHandler } from "react";
import cx from "classnames";
import Button from "../button/Button";

interface Props {
  lastStep: boolean;
  onNextClick: MouseEventHandler;
  onPreviousClick: MouseEventHandler;
  onFinishClick: MouseEventHandler;
  onCancelClick: MouseEventHandler;
  onSaveClick?: MouseEventHandler;
  finished?: boolean;

  disablePrevious?: boolean;
  disableNext?: boolean;
  disableFinish?: boolean;
  disableSave?: boolean;
  disableCancel?: boolean;

  hideCancel: boolean;
  hideSave: boolean;
  hidePrevious: boolean;
  hideNext: boolean;
  hideFinish: boolean;

  labelCancel: string;
  labelSave: string;
  labelPrevious: string;
  labelNext: string;
  labelFinish: string;
  labelFinished: string;
}

const WizardFooter = ({
  lastStep,
  onNextClick,
  onPreviousClick,
  onFinishClick,
  onCancelClick,
  onSaveClick,
  finished = false,
  disablePrevious = false,
  disableNext = false,
  disableFinish = false,
  disableCancel = false,
  disableSave = false,

  hidePrevious = false,
  hideNext = false,
  hideCancel = false,
  hideSave = false,
  hideFinish = false,

  labelCancel,
  labelSave,
  labelPrevious,
  labelNext,
  labelFinish,
  labelFinished
}: Props) => {
  const defaultLabel = {
    cancel: "Cancel",
    save: "Save & finish later",
    previous: "Previous",
    next: labelNext !== "" ? labelNext : "Next",
    finish: labelFinish !== "" ? labelFinish : "Finish",
    finished: labelFinished !== "" ? labelFinished : "Finished"
  };

  const label = {
    cancel: labelCancel !== "" ? labelCancel : defaultLabel.cancel,
    save: labelSave !== "" ? labelSave : defaultLabel.save,
    previous: labelPrevious !== "" ? labelPrevious : defaultLabel.previous,
    next: labelNext !== "" ? labelNext : defaultLabel.next,
    finish: labelFinish !== "" ? labelFinish : defaultLabel.finish,
    finished: labelFinished !== "" ? labelFinished : defaultLabel.finished
  };

  const leftButtonGroup = (
    <div className="item">
      {!hideCancel && (
        <Button disabled={disableCancel} onClick={onCancelClick}>
          {label.cancel}
        </Button>
      )}
      {!hideSave && (
        <Button disabled={disableSave} onClick={onSaveClick}>
          {label.save}
        </Button>
      )}
    </div>
  );

  const btnNext = !hideNext && (
    <Button className="btnNext" disabled={disableNext} primary onClick={onNextClick}>
      <span className="term">{label.next}</span>
      <i className="icon icon-arrow-right" />
    </Button>
  );

  const btnFinish = !hideFinish && (
    <Button className="btnFinish" disabled={disableFinish} primary onClick={onFinishClick}>
      <span className={cx({ term: !lastStep })}>{!finished ? label.finish : label.finished}</span>
    </Button>
  );

  const rightButtonGroup = (
    <div className="item">
      {!hidePrevious && (
        <Button disabled={disablePrevious} onClick={onPreviousClick}>
          <i className="icon icon-arrow-left" />
          <span className="term">{label.previous}</span>
        </Button>
      )}
      {!lastStep ? btnNext : btnFinish}
    </div>
  );
  return (
    <div className="wizard-footer">
      {leftButtonGroup}
      {rightButtonGroup}
    </div>
  );
};

export default WizardFooter;
