import React, { useState } from "react";
import cx from "classnames";
import IAccordionItem from "./IAccordionItem";

const AccordionItem = ({
  title,
  children,
  disabled,
  className,
  defaultOpen = false,
  noInset = false
}: IAccordionItem) => {
  const [opened, toggleOpened] = useState(defaultOpen);

  return (
    <li className={cx("accordion-item", { disabled }, { opened }, className)}>
      <span role="presentation" style={{ display: "list-item" }}>
        <div role="presentation" onClick={() => toggleOpened(!opened)} className="title">
          {title}
        </div>
        <div className={cx("content", { "no-inset": noInset })}>{children}</div>
      </span>
    </li>
  );
};

export default AccordionItem;
