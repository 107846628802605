import React from "react";
import cx from "classnames";
import IColumn from "./IColumn";

const Column = ({ sm, md, lg, xl, asContainer, children, className, contentRef }: IColumn) => (
  <div
    ref={contentRef}
    className={cx(
      "column",
      { container: asContainer },
      {
        [`sm-${sm}`]: sm,
        [`md-${md}`]: md,
        [`lg-${lg}`]: lg,
        [`xl-${lg}`]: xl
      },
      className
    )}
  >
    {children}
  </div>
);
export default Column;
