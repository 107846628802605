import React, { ReactNode, createContext, useContext } from "react";
import useApplication from "../app/context/ApplicationHelpers"

const MenuContext = createContext<{ closeOnSelect?: boolean, toggle: Function }>({ closeOnSelect: false, toggle: () => {} });

const MenuContextProvider = ({
  value,
  children
}: {
  value: { closeOnSelect?: boolean };
  children: ReactNode;
}) => {
  const { toggleMenu } = useApplication()

  return <MenuContext.Provider value={{...value, toggle: toggleMenu}}>{children}</MenuContext.Provider>;
};
const useMenu = () => {
  return useContext(MenuContext);
};

export { MenuContextProvider, useMenu };
