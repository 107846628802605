import * as React from "react";

import { Accordion, AccordionItem } from "@react-gcc-eds/core";

import {
  BillOfMaterialItemIdentity,
  MaterialTransferItemStatus
} from "../../../../domain/client-customer";
import {
  NamespaceKeys,
  ReceivingMaterialSpecificKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import {
  MaterialTransferHandlingUnitState,
  SerializedItemState
} from "../shared/receive-material-models";
import ReceiveMaterialReportDeviationItem from "./receive-material-report-deviation-item";

import "./receive-material-report-deviation.scss";

type ReceiveMaterialReportDeviationViewProps = {
  handlingUnits: MaterialTransferHandlingUnitState[];
};

const ReceiveMaterialReportDeviationView: ({
  handlingUnits
}: ReceiveMaterialReportDeviationViewProps) => React.ReactElement = ({
  handlingUnits
}): React.ReactElement => {
  const transferDetailsStateItemsClone = [...handlingUnits];
  const { translate } = useTranslation();
  const handleOnUpdateMissingOrDamagedItemsForSerializedItem = (
    status: MaterialTransferItemStatus,
    quantity: number,
    handlingUnit: string,
    billOfMaterialIdentity: BillOfMaterialItemIdentity | number
  ) => {
    const transferDetailsIndex = transferDetailsStateItemsClone.findIndex(
      (f) => f.ExternalHandlingUnit === handlingUnit
    );
    const billOfMaterialItemIdentity =
      billOfMaterialIdentity as BillOfMaterialItemIdentity;
    if (transferDetailsIndex !== undefined) {
      const existingItemWithCurrentStatus = transferDetailsStateItemsClone[
        transferDetailsIndex
      ].SerializedItems.filter(
        (item) =>
          item.billOfMaterialItemIdentity.Value ===
            billOfMaterialItemIdentity.Value && item.status === status
      ).length;

      if (existingItemWithCurrentStatus < quantity) {
        let excess = quantity - existingItemWithCurrentStatus;
        transferDetailsStateItemsClone[
          transferDetailsIndex
        ].SerializedItems.forEach((item) => {
          if (
            item.billOfMaterialItemIdentity.Value ===
              billOfMaterialItemIdentity.Value &&
            item.status === MaterialTransferItemStatus.None &&
            excess
          ) {
            item.status = status;
            excess--;
          }
        });
      } else if (existingItemWithCurrentStatus > quantity) {
        let excess = existingItemWithCurrentStatus - quantity;
        transferDetailsStateItemsClone[
          transferDetailsIndex
        ].SerializedItems.forEach((item) => {
          if (item.status === status && excess) {
            item.status = MaterialTransferItemStatus.None;
            excess--;
          }
        });
      }
    }
  };

  const handleOnUpdateMissingOrDamagedItemsForNonSerializedItem = (
    status: MaterialTransferItemStatus,
    quantity: number,
    handlingUnit: string,
    stateItemsIndex: number | BillOfMaterialItemIdentity
  ) => {
    stateItemsIndex = stateItemsIndex as number;
    const transferDetailsIndex = transferDetailsStateItemsClone.findIndex(
      (f) => f.ExternalHandlingUnit === handlingUnit
    );
    if (
      transferDetailsIndex !== undefined &&
      stateItemsIndex !== undefined &&
      transferDetailsStateItemsClone[transferDetailsIndex].NonSerializedItems[
        stateItemsIndex
      ]?.validationQuantities
    ) {
      const statusAndNumber =
        transferDetailsStateItemsClone[transferDetailsIndex].NonSerializedItems[
          stateItemsIndex
        ].validationQuantities;
      if (statusAndNumber) {
        statusAndNumber[status] = quantity;
        statusAndNumber[MaterialTransferItemStatus.None] =
          (transferDetailsStateItemsClone[transferDetailsIndex]
            .NonSerializedItems[stateItemsIndex].handligUnitQuantity ?? 0) -
          (statusAndNumber[MaterialTransferItemStatus.Damaged] ?? 0) -
          (statusAndNumber[MaterialTransferItemStatus.Missing] ?? 0);
      }
    }
  };

  const getStatusAndNumberForSerializedItems = (
    billOfMaterialItemIdentity: BillOfMaterialItemIdentity,
    sItemList: SerializedItemState[]
  ): Partial<Record<MaterialTransferItemStatus, number>> => {
    const statusAndNumberForSerializedItems: Partial<
      Record<MaterialTransferItemStatus, number>
    > = {};
    const filterdItems = sItemList.filter(
      (sItem) =>
        sItem.billOfMaterialItemIdentity.Value ===
        billOfMaterialItemIdentity.Value
    );
    filterdItems.forEach((sItem) => {
      if (sItem.status)
        statusAndNumberForSerializedItems[sItem.status] =
          (statusAndNumberForSerializedItems[sItem.status] ?? 0) + 1;
    });
    return statusAndNumberForSerializedItems;
  };

  const aggregateSerializedItemByDplItemId = (
    sItemList: SerializedItemState[]
  ): SerializedItemState[] => {
    const aggregatedItems: SerializedItemState[] = [];
    sItemList.forEach((item) => {
      if (!aggregatedItems.find((o) => o.dplItem?.Id === item.dplItem?.Id)) {
        aggregatedItems.push(item);
      }
    });
    return aggregatedItems;
  };

  return (
    <Accordion className="deviation-accordian">
      {handlingUnits.map((item) => (
        <AccordionItem
          className="deviation-accordian-item-child"
          key={item.ExternalHandlingUnit}
          title={
            translate(
              NamespaceKeys.ReceivingMaterialSpecific,
              ReceivingMaterialSpecificKeys.HU
            ) + item.ExternalHandlingUnit
          }
        >
          <>
            {item.SerializedItems.length > 0 &&
              aggregateSerializedItemByDplItemId(item.SerializedItems).map(
                (serializedItem, index) => (
                  <ReceiveMaterialReportDeviationItem
                    handlingUnit={item.ExternalHandlingUnit}
                    stateItemsIndexOrBillOfMaterialIdentity={
                      serializedItem.billOfMaterialItemIdentity
                    }
                    key={index}
                    item={serializedItem}
                    onUpdateMissingOrDamagedItems={
                      handleOnUpdateMissingOrDamagedItemsForSerializedItem
                    }
                    statusAndNumber={getStatusAndNumberForSerializedItems(
                      serializedItem.billOfMaterialItemIdentity,
                      item.SerializedItems
                    )}
                  />
                )
              )}

            {item.NonSerializedItems.map((eachItem, index) => (
              <ReceiveMaterialReportDeviationItem
                handlingUnit={item.ExternalHandlingUnit}
                stateItemsIndexOrBillOfMaterialIdentity={index}
                key={index}
                item={eachItem}
                onUpdateMissingOrDamagedItems={
                  handleOnUpdateMissingOrDamagedItemsForNonSerializedItem
                }
                statusAndNumber={eachItem.validationQuantities ?? {}}
              />
            ))}
          </>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default ReceiveMaterialReportDeviationView;
