import React from "react";
import cx from "classnames";
import IRow from "./IRow";

const Row = ({ children, className, contentRef }: IRow) => {
  return (
    <div ref={contentRef} className={cx("row", className)}>
      {children}
    </div>
  );
};

export default Row;
