import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from "react";

import { Button, Icon, Loader, Row, Tile } from "@react-gcc-eds/core";
import { FixedSizeList, ListChildComponentProps } from "react-window";

import { AuthenticationContext } from "../../../contexts/authentication-context";
import { CustomerContext } from "../../../contexts/customer-context";
import { LayoutContext } from "../../../contexts/layout-context";
import {
  InstallationSitesOverviewViewModel,
  MaterialTransfersClient
} from "../../../domain/client-customer";
import {
  MaterialInstallationOverviewSortProperty,
  SortOrder,
  usePropertySorting
} from "../../../hooks/sorting";
import {
  ComponentSpecificKeys,
  GeneralKeys,
  InstallationSpecificKeys,
  NamespaceKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";
import CenteredContainer from "../../common/layout/centered-container";
import MaterialInstallationListItem from "./material-installation-list-item";
import MaterialInstallationOverviewFilters from "./material-installation-overview-filters";

import "./material-installation-overview.scss";

const MaterialInstallationOverview = (): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [sites, setSites] = useState<InstallationSitesOverviewViewModel[]>();
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const { currentCustomer, currentUser } = useContext(CustomerContext);
  const { translate } = useTranslation();
  const [listContainerDomObjectRef, setListContainerDomObjectRef] =
    useState<HTMLDivElement>();
  const [listContainerHeight, setListContainerHeight] = useState<number>(0);
  const { setPageTitle } = useContext(LayoutContext);
  const [hideFilters, setHideFilters] = useState<boolean>(true);
  const [filteredInstallationSites, setFilteredInstallationSites] = useState<
    InstallationSitesOverviewViewModel[]
  >([]);
  const {
    sortedList,
    setSortPropertyOrToggleSortOrder,
    sortProperty,
    sortOrder
  } = usePropertySorting<
    InstallationSitesOverviewViewModel,
    MaterialInstallationOverviewSortProperty
  >(filteredInstallationSites, "StartDate", SortOrder.Asc);

  const refFn: (instance: HTMLDivElement | null) => void = useCallback(
    (node) => {
      if (node) {
        setListContainerDomObjectRef(node);
        setListContainerHeight(node.getBoundingClientRect().height);
      }
    },
    []
  );

  useLayoutEffect(() => {
    listContainerDomObjectRef &&
      setListContainerHeight(
        listContainerDomObjectRef.getBoundingClientRect().height
      );
  }, [listContainerDomObjectRef, hideFilters]);

  const handleToggleFilterClick = useCallback(() => {
    setListContainerHeight(0);
    setHideFilters((prev) => !prev);
  }, []);

  const handleOnFiltersChanged = useCallback(
    (filteredList: InstallationSitesOverviewViewModel[]) => {
      setFilteredInstallationSites(filteredList);
      const title = translate(
        NamespaceKeys.InstallationSpecific,
        InstallationSpecificKeys.MaterialInstallationsTitle,
        { postfix: loading ? "" : `(${filteredList.length})` }
      );
      setPageTitle &&
        (loading
          ? setPageTitle({ title })
          : setPageTitle({
              title,
              actions: (
                <div className="action">
                  <div className="dropdown more">
                    <Button onClick={handleToggleFilterClick}>
                      <Icon name="filter" />
                    </Button>
                  </div>
                </div>
              )
            }));
    },
    [handleToggleFilterClick, loading, setPageTitle, translate]
  );

  const getInstallationSitesOverviewViewModel = useCallback(async () => {
    setLoading(true);
    const customerIdentifier =
      currentCustomer && currentCustomer.HeaderIdentifier;
    if (!customerIdentifier) {
      setError(
        translate(
          NamespaceKeys.ComponentSpecific,
          ComponentSpecificKeys.AnErrorOccurredPleaseTryAgain
        )
      );
      setLoading(false);
      return;
    }
    new MaterialTransfersClient(await getCustomerConfigurationProvider())
      .getInstallationSites(customerIdentifier)
      .then(setSites)
      .catch(() => {
        setError(
          translate(
            NamespaceKeys.ComponentSpecific,
            ComponentSpecificKeys.AnErrorOccurredPleaseTryAgain
          )
        );
      })
      .finally(() => setLoading(false));
  }, [currentCustomer, getCustomerConfigurationProvider, translate]);

  const renderItem = useCallback(
    ({ index, style }: ListChildComponentProps): JSX.Element => (
      <div style={style} className="overview-item">
        <MaterialInstallationListItem site={sortedList[index]} />
      </div>
    ),
    [sortedList]
  );

  const renderedList = useMemo(
    () => (
      <FixedSizeList
        height={listContainerHeight}
        width="100%"
        itemCount={sortedList.length ?? 0}
        itemSize={150}
      >
        {renderItem}
      </FixedSizeList>
    ),
    [sortedList, listContainerHeight, renderItem]
  );

  useEffect(() => {
    setPageTitle &&
      loading &&
      setPageTitle({
        title: translate(
          NamespaceKeys.InstallationSpecific,
          InstallationSpecificKeys.PageTitle,
          { amount: sites?.length ?? 0 }
        )
      });
    if (sites || error) return;
    getInstallationSitesOverviewViewModel();
  }, [
    error,
    getInstallationSitesOverviewViewModel,
    loading,
    setPageTitle,
    sites,
    translate
  ]);

  const onRetry = useCallback(() => {
    setError(undefined);
    getInstallationSitesOverviewViewModel();
  }, [getInstallationSitesOverviewViewModel]);

  return error ? (
    <CenteredContainer>
      <div className="material-installation-api-error">
        <div className="material-installation-retry-title">{error}</div>
        <Button
          className="material-installation-retry-button"
          onClick={onRetry}
        >
          {translate(NamespaceKeys.General, GeneralKeys.Retry)}
        </Button>
      </div>
    </CenteredContainer>
  ) : loading ? (
    <CenteredContainer>
      <Loader size="large" />
    </CenteredContainer>
  ) : (
    <div className="overview-list">
      {currentUser && (
        <MaterialInstallationOverviewFilters
          hidden={hideFilters}
          sites={sites}
          onFiltersChanged={handleOnFiltersChanged}
          setSortPropertyOrToggleSortOrder={setSortPropertyOrToggleSortOrder}
          sortProperty={sortProperty}
          sortOrder={sortOrder}
        />
      )}
      {!filteredInstallationSites.length ? (
        !sites?.length ? (
          <div>
            <Tile
              title={translate(
                NamespaceKeys.InstallationSpecific,
                InstallationSpecificKeys.NoMaterialInstallationsTitle
              )}
            >
              <p>
                {translate(
                  NamespaceKeys.InstallationSpecific,
                  InstallationSpecificKeys.NoMaterialInstallationsMessage,
                  {
                    customerName: currentCustomer && currentCustomer.DisplayName
                  }
                )}
              </p>
            </Tile>
          </div>
        ) : (
          <div>
            <Tile
              title={translate(
                NamespaceKeys.InstallationSpecific,
                InstallationSpecificKeys.NoMaterialInstallationsWithFiltersTitle
              )}
            >
              <p>
                {translate(
                  NamespaceKeys.InstallationSpecific,
                  InstallationSpecificKeys.NoMaterialInstallationsWithFiltersMessageOne
                )}
                <Icon name="filter" />
                {translate(
                  NamespaceKeys.InstallationSpecific,
                  InstallationSpecificKeys.NoMaterialInstallationsWithFiltersMessageTwo
                )}
              </p>
            </Tile>
          </div>
        )
      ) : (
        <Row className="list-row">
          <div ref={refFn} className="list-container">
            {renderedList}
          </div>
        </Row>
      )}
    </div>
  );
};

export default MaterialInstallationOverview;
