import { useCallback } from "react";

import { useTranslation as useI18NextTranslation } from "react-i18next";

import { NamespaceKeys } from "./dictionary-keys";

export const useTranslation: () => {
  translate: (
    namespace: NamespaceKeys,
    key: string,
    params?: Record<string, unknown>
  ) => string;
} = () => {
  const { t } = useI18NextTranslation();
  return {
    translate: useCallback(
      (
        namespace: NamespaceKeys,
        key: string,
        params?: Record<string, unknown>
      ) => t<string>(`${namespace}.${key}`, params),
      [t]
    )
  };
};
