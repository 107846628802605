import React, { useEffect } from "react";
import ISplash from "./contracts/ISplash";
import printCopyrightText from "../../copyright";

const Splash = (props: ISplash) => {
  const { productName, content } = {
    ...props
  };

  useEffect(() => {
    if (!document.body.classList.contains("dark")) {
      document.body.classList.remove("light", "dark");
      document.body.classList.add("dark");
    }
  }, []);

  return (
    <main>
      <div className="signin">
        <div className="watermark">
          <i className="icon icon-econ" />
          <div className="product">{productName}</div>
        </div>
        <div className="welcome">{content}</div>

        <div className="footer">
          {printCopyrightText()}
        </div>
      </div>
    </main>
  );
};
export default Splash;
