import React, { useState, KeyboardEvent } from "react";

import cx from "classnames";

import { uniqueId, useClickOutside } from "../../utils/utils";
import IDropdown from "./IDropdown";

const Dropdown = ({
  items,
  label,
  right,
  onItemClick,
  disabled,
  primary,
  divided,
  className
}: IDropdown) => {
  const [visible, setVisible] = useState(false);
  const [highlighted, setHighlighted] = useState(-1);

  const openDropdown = () => {
    setVisible(true);
  };

  const closeDropdown = () => setVisible(false);

  const containerRef = useClickOutside(closeDropdown);

  const itemPicked = (itemIndex: number) => {
    if (!onItemClick) {
      return;
    }

    closeDropdown();
    onItemClick(items[itemIndex]);
  };

  const handleButtonClick = () => (visible ? closeDropdown() : openDropdown());

  const handleContainerKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    const { keyCode } = e;

    if (!visible) {
      // menu open by eds on enter
      if (keyCode === 40) {
        openDropdown();
      }
    } else {
      switch (keyCode) {
        case 13:
          // menu closed by eds
          itemPicked(highlighted);
          break;
        case 38:
          setHighlighted(highlighted > 0 ? highlighted - 1 : items.length - 1);
          break;
        case 40:
          setHighlighted(highlighted < items.length - 1 ? highlighted + 1 : 0);
          break;
        default:
          break;
      }
    }
  };

  const handleMenuClick = (index: number) => {
    itemPicked(index);
  };

  const ignoreEvent = () => {};

  const renderItem = (title: string, index: number) => {
    return (
      <div
        key={uniqueId(title)}
        className="item"
        role="button"
        tabIndex={0}
        onClick={e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          handleMenuClick(index);
        }}
        onKeyDown={ignoreEvent}
      >
        {title}
      </div>
    );
  };

  const divType = () => {
    if (divided) {
      return "divided";
    }
    return "click";
  };

  const buttonType = !divided ? (
    <button
      type="button"
      className={cx("btn clickable", { primary })}
      onClick={e => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        handleButtonClick();
      }}
      disabled={disabled}
    >
      {label || ""}
    </button>
  ) : (
    <div className="btns-wrap">
      <button
        type="button"
        className="btn"
        onClick={e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          itemPicked(0);
        }}
        disabled={disabled}
      >
        {items[0].title || ""}
      </button>
      <button
        type="button"
        className="btn extra"
        disabled={disabled}
        onClick={e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          handleButtonClick();
        }}
      />
    </div>
  );

  const isOutOfViewport = (elem: HTMLDivElement) => {
    const itemHeightOffset = 32;
    const maxOffset = 130;
    const offsetHeight = items.length < 4 ? itemHeightOffset * items.length : maxOffset;
    const bounding = elem.getBoundingClientRect();

    return (
      bounding.bottom + offsetHeight > (window.innerHeight || document.documentElement.clientHeight)
    );
  };

  const outOfViewport = containerRef.current ? isOutOfViewport(containerRef.current) : false;

  return (
    <div
      id="dropdown"
      ref={containerRef}
      className={cx("dropdown", { open: visible }, { closed: !visible }, className)}
      data-type={divType()}
      role="button"
      tabIndex={0}
      onKeyDown={handleContainerKeyPress}
    >
      {buttonType}
      <div
        className={cx(
          "menu overflow",
          { visible },
          { right },
          { above: outOfViewport }
        )}
      >
        {items.map((i, index) => {
          return renderItem(i.title, index);
        })}
      </div>
    </div>
  );
};

export default Dropdown;
