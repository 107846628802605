import React from "react";

import { SearchAreaType } from "./search-area-hook";

type Props = {
  scanning: boolean;
  searchArea: SearchAreaType;
};

const Viewfinder = ({ searchArea, scanning }: Props): JSX.Element => (
  <div
    className={`viewfinder ${scanning ? "searching" : "idle"}`}
    style={{
      height: `${searchArea.height * 100}%`,
      width: `${searchArea.width * 100}%`,
      left: `${searchArea.x * 100}%`,
      top: `${searchArea.y * 100}%`
    }}
  />
);

export default Viewfinder;
