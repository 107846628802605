import * as React from "react";

import CustomTextField from "./common-components/custom-textfield";

type ScannerInputTextFieldProps = {
  textFieldClass: string;
  btnClass: string;
  placeholder: string;
  buttonLabel: string;
  disabled: boolean;
  value: string;
  onChange: (value: string) => void;
  onClick: (ev: React.MouseEvent<Element, MouseEvent>) => void;
};

const ScannerInputTextField = React.memo(
  ({
    textFieldClass,
    btnClass,
    placeholder,
    buttonLabel,
    disabled,
    value,
    onChange,
    onClick
  }: ScannerInputTextFieldProps) => {
    return (
      <CustomTextField
        disabled={disabled}
        textFieldClass={textFieldClass}
        btnClass={btnClass}
        placeHolder={placeholder}
        buttonLabel={buttonLabel}
        value={value}
        onChange={onChange}
        onClick={onClick}
      />
    );
  }
);

ScannerInputTextField.displayName = "ScannerInputTextField";

export default ScannerInputTextField;
