export enum ApplicationKeys {
  ApplicationAcronym = "acronym",
  ApplicationName = "name",
  SwitchCustomers = "switchCustomers",
  NewAppNotificationDetail = "newAppNotificationDetail",
  NewAppNotificationDoNotShow = "newAppNotificationDoNotShow"
}
export enum GeneralKeys {
  Cancel = "cancel",
  Correct = "correct",
  CreatedAt = "CreatedAt",
  Damaged = "damaged",
  Deviation = "deviation",
  Done = "done",
  Edit = "edit",
  Missing = "missing",
  Next = "next",
  Reload = "reload",
  No = "no",
  NoFilter = "NoFilter",
  Ok = "ok",
  PleaseTryAgainLater = "pleaseTryAgainLater",
  Restore = "restore",
  Save = "save",
  Valid = "valid",
  Yes = "yes",
  Quantity = "Quantity",
  NotAvailable = "NotAvailable",
  ShowMore = "ShowMore",
  ShowLess = "ShowLess",
  Undo = "Undo",
  EnterAtLeastXCharacters = "EnterAtLeastXCharacters",
  Title = "Title",
  Type = "Type",
  Retry = "Retry",
  Discard = "Discard",
  Details = "Details",
  Delete = "Delete",
  OpenInNewTab = "OpenInNewTab",
  Download = "Download",
  Close = "Close",
  Clear = "Clear",
  ClearFilter = "ClearFilter",
  UnknownUser = "UnknownUser",
  Upload = "Upload",
  Filters = "Filters",
  All = "All",
  Add = "Add",
  Search = "Search",
  Open = "Open",
  Excess = "Excess",
  None = "None",
  Reason = "Reason",
  Notes = "Notes",
  Date = "Date",
  Report = "Report",
  Back = "Back",
  Complete = "Complete",
  Validate = "Validate",
  Deviations = "Deviations",
  Damage = "Damage",
  Received = "Received",
  ScanBarcode = "ScanBarcode",
  PC = "PC"
}
export enum LoginSpecificKeys {
  EricssonPrivacyPolicy = "ericssonPrivacyPolicy",
  EricssonSingleSignOn = "ericssonSingleSignOn",
  AuthenticationDisabledLabel = "authenticationDisabledLabel",
  AuthenticationDisabledErrorMessage = "authenticationDisabledErrorMessage"
}
export enum MenuTitleKeys {
  SiteProjectMenuTitle = "siteProjectMenuTitle",
  SiteProjectsMenuTitle = "siteProjectsMenuTitle",
  HelpMenuTitle = "helpMenuTitle",
  HomeMenuTitle = "homeMenuTitle",
  ReleaseNotesMenuTitle = "releaseNotesMenuTitle",
  MaterialTransferOverviewTitle = "materialTransferOverviewTitle",
  ReceiveMaterialOverviewTitle = "receiveMaterialOverviewTitle",
  ReceiveMaterialListOverviewTitle = "receiveMaterialListOverviewTitle",
  MaterialInstallationOverviewTitle = "materialInstallationOverviewTitle"
}
export enum BomSpecificKeys {
  BillOfMaterial = "billOfMaterial",
  BomIsEmpty = "bomIsEmpty",
  BomSaved = "bomSaved",
  CouldNotSaveBom = "couldNotSaveBom",
  BomStatus = "bomStatus",
  Status = "status",
  TheBomWasSavedSuccessfully = "theBomWasSavedSuccessfully",
  View = "view",
  DrSupplementalReason = "drSupplementalReason",
  CrWillBeCreatedChangedFor = "crWillBeCreatedChangedFor",
  PleaseStateTheReasonForChangingTheOriginalScope = "pleaseStateTheReasonForChangingTheOriginalScope",
  SelectAreasonCode = "selectAreasonCode",
  EnterReasonHere = "enterReasonHere",
  Note = "Note",
  ChangeReasonCode = "ReasonCode",
  ChangeReasonMessage = "ChangeReasonMessage",
  MaterialCategory = "MaterialCategory"
}
export enum ChecklistSpecificKeys {
  Checklist = "Checklist",
  NoChecklistFound = "NoChecklistFound",
  ChecklistSaved = "checklistSaved",
  TheChecklistWasSavedSuccessfully = "thechecklistWasSavedSuccessfully",
  TheChecklistCouldNotBeSaved = "TheChecklistCouldNotBeSaved",
  PressTheButtonBelowToUploadImages = "PressTheButtonBelowToUploadAnImage",
  UploadImages = "UploadImages",
  Rotate = "Rotate",
  UploadFailed = "UploadFailed",
  Uploading = "Uploading",
  ReadingFile = "ReadingFile",
  ChecklistCouldNotBeRetrieved = "ChecklistCouldNotBeRetrieved"
}
export enum DplSpecificKeys {
  Category = "category",
  Comment = "comment",
  Commodity = "commodity",
  CriticalSpare = "criticalSpare",
  CustomerProductDescription = "customerProductDescription",
  CustomerProductNumber = "customerProductNumber",
  CustomerSerialNumber = "customerSerialNumber",
  DplItemId = "dplItemId",
  EricssonProductDescription = "ericssonProductDescription",
  EricssonProductNumber = "ericssonProductNumber",
  EricssonSerialNumber = "ericssonSerialNumber",
  FecProduct = "fecProduct",
  LeadTimeInWorkingDays = "leadTimeInWorkingDays",
  MaterialCategories = "materialCategories",
  MaterialCategory = "materialCategory",
  ProductPackageType = "productPackageType",
  Subcategory = "subcategory",
  SourcingProvider = "sourcingProvider",
  UnitOfMeasurement = "unitOfMeasurement",
  WarrantyPeriod = "warrantyPeriod"
}
export enum ActivitySpecificKeys {
  ActivityIsNotScheduled = "ActivityIsNotScheduled",
  CompletedUser = "CompletedUser",
  CouldNotSendForApproval = "CouldNotSendForApproval",
  CurrentActivity = "CurrentActivity",
  LeadConstructionManager = "LeadConstructionManager",
  FieldConstructionManager = "FieldConstructionManager",
  NotAssigned = "NotAssigned",
  ScheduledDate = "ScheduledDate",
  ScheduledUser = "ScheduledUser",
  SendForApproval = "SendForApproval",
  SentForApproval = "SentForApproval",
  SentForApprovalDate = "SentForApprovalDate",
  SentForApprovalErrorMessageTitle = "SentForApprovalErrorMessageTitle",
  SitewalkPlanned = "SitewalkPlanned",
  UnknownActivity = "UnknownActivity",
  ScheduledFor = "ScheduledFor",
  CompletedBy = "CompletedBy",
  SentForApprovalAt = "SentForApprovalAt",
  PlannedFor = "PlannedFor"
}
export enum SiteSpecificKeys {
  Address = "Address",
  Location = "Location",
  SiteIdByCustomer = "SiteIdByCustomer",
  Region = "Region",
  Name = "Name",
  Type = "Type",
  SiteInformation = "SiteInformation",
  CustomerRegion = "CustomerRegion",
  Weather = "Weather",
  Subregion = "Subregion",
  ZipCode = "ZipCode",
  City = "City",
  Latitude = "Latitude",
  Longitude = "Longitude",
  GoToMap = "GoToMap",
  Site = "Site"
}
export enum DocumentSpecificKeys {
  Documents = "Documents",
  UploadDocument = "UploadDocument",
  NoDocumentsUploaded = "NoDocumentsUploaded",
  DocumentDeleted = "DocumentDeleted",
  DocumentWasSuccessfullyDeleted = "DocumentWasSuccessfullyDeleted",
  DocumentLinkWithSpecifiedUrlAlreadyExists = "DocumentLinkWithSpecifiedUrlAlreadyExists",
  AddNewSiteDocumentLink = "AddNewSiteDocumentLink",
  LinkName = "TitleOfLink",
  Url = "Url",
  LinkUrl = "LinkUrl",
  LinkAdded = "LinkAdded",
  DocumentLinkWasSuccessfullyAdded = "DocumentLinkWasSuccessfullyAdded",
  UploadingDocument = "UploadingDocument",
  UploadFailed = "UploadFailed",
  ReadingDocument = "ReadingDocument",
  DeletingDocument = "DeletingDocument",
  RestrictedAccessTooltipMessage = "RestrictedAccessTooltipMessage",
  Name = "Name",
  UploadedBy = "UploadedBy",
  UploadAt = "UploadAt",
  RestrictedAccessDialogMessage = "RestrictedAccessDialogMessage",
  ConfirmDocumentDeletionDialogTitle = "ConfirmDocumentDeletionDialogTitle",
  ConfirmDocumentDeletionDialogMessage = "ConfirmDocumentDeletionDialogMessage",
  DocumentDetails = "DocumentDetails",
  ErrorWhileLoading = "ErrorWhileLoading",
  ErrorWhileDeletingNotificationTitle = "ErrorWhileDeletingNotificationTitle",
  ErrorWhileDeletingNotificationMessage = "ErrorWhileDeletingNotificationMessage",
  ErrorWhileAddingLink = "ErrorWhileAddingLink",
  AddLink = "AddLink",
  PendingUploadsDialogTitle = "PendingUploadsDialogTitle",
  PendingUploadsDialogMessageRow1 = "PendingUploadsDialogMessageRow1",
  PendingUploadsDialogMessageRow2 = "PendingUploadsDialogMessageRow2"
}

export enum ComponentSpecificKeys {
  Activity = "Activity",
  ActivitiesErrorMessageTitle = "ActivitiesErrorMessageTitle",
  AddSerialNumbers = "AddSerialNumbers",
  AnErrorOccurredPleaseTryAgainLater = "AnErrorOccurredPleaseTryAgainLater",
  AnErrorOccurredPleaseTryAgain = "AnErrorOccurredPleaseTryAgain",
  MaterialTransferValidationReviewResposabilityParagraph = "MaterialTransferValidationReviewResposabilityParagraph",
  MaterialTransferValidationReviewResultParagraph = "MaterialTransferValidationReviewResultParagraph",
  MaterialTransferValidationReviewSerialNumbersParagraph = "MaterialTransferValidationReviewSerialNumbersParagraph",
  MaterialTransferValidationValidateAllQuantitiesApproveButtonLabel = "MaterialTransferValidationValidateAllQuantitiesApproveButtonLabel",
  MaterialTransferValidationValidateAllQuantitiesDialogTitle = "MaterialTransferValidationValidateAllQuantitiesDialogTitle",
  MaterialTransferValidationValidateAllQuantitiesDialogMessage = "MaterialTransferValidationValidateAllQuantitiesDialogMessage",
  MaterialTransferValidationSmartScannerButtonLabel = "MaterialTransferValidationSmartScannerButtonLabel",
  MaterialTransferPostValidationErrorTitle = "MaterialTransferPostValidationErrorTitle",
  MaterialTransferPostValidationErrorDescription = "MaterialTransferPostValidationErrorDescription",
  MaterialInstallationPostValidationErrorTitle = "MaterialInstallationPostValidationErrorTitle",
  MaterialInstallationPostValidationErrorDescription = "MaterialInstallationPostValidationErrorDescription",
  BomErrorMessageTitle = "BomErrorMessageTitle",
  Contacts = "Contacts",
  CustomersErrorMessageTitle = "CustomersErrorMessageTitle",
  SiteProjectContactErrorMessageTitle = "SiteProjectContactErrorMessageTitle",
  SiteProjectsErrorMessageTitle = "SiteProjectsErrorMessageTitle",
  MaterialToValidate = "MaterialToValidate",
  MaterialToInstall = "MaterialToInstall",
  InstallAction = "InstallAction",
  NoSiteProjectsMessage = "NoSiteProjectsMessage",
  NoSiteProjectsTitle = "NoSiteProjectsTitle",
  NoSiteProjectsWithFiltersMessageOne = "NoSiteProjectsWithFiltersMessageOne",
  NoSiteProjectsWithFiltersMessageTwo = "NoSiteProjectsWithFiltersMessageTwo",
  NoSiteProjectsWithFiltersTitle = "NoSiteProjectsWithFiltersTitle",
  ProductTitle = "ProductTitle",
  ReleaseNotesErrorMessageTitle = "ReleaseNotesErrorMessageTitle",
  ReviewChanges = "ReviewChanges",
  ScannedSerialNumbers = "ScannedSerialNumbers",
  SerialNumber = "SerialNumber",
  SerialNumbersToScan = "SerialNumbersToScan",
  SitesCoordinatesErrorMessageTitle = "SitesCoordinatesErrorMessageTitle",
  SitesLocationErrorMessageTitle = "SitesLocationErrorMessageTitle",
  SitesWeatherErrorMessageTitle = "SitesWeatherErrorMessageTitle",
  ThisItemHasDeviations = "ThisItemHasDeviations",
  ValidatedMaterial = "ValidatedMaterial",
  ValidateMaterial = "ValidateMaterial",
  ValidateInstallation = "ValidateInstallation",
  BomSavingErrorDescription = "BomSavingErrorDescription",
  BomChangeValidationErrorDescription = "BomChangeValidationErrorDescription",
  ProductDetailsDialogTitle = "productDetailsDialogTitle",
  Validate = "Validate",
  ValidateAll = "ValidateAll",
  AddAll = "AddAll",
  TransferActivityBaseFailedToGetInformationTitle = "TransferActivityBaseFailedToGetInformationTitle",
  TransferActivityBaseFailedToGetInformationMessage = "TransferActivityBaseFailedToGetInformationMessage",
  FileTypeTitle = "FileTypeTitle",
  FileTypeMessage = "FileTypeMessage"
}
export enum ScannerSpecificKeys {
  BottomHelperPressToScan = "BottomHelperPressToScan",
  BottomHelperPointYourCameraToScan = "BottomHelperPointYourCameraToScan",
  FeedbackItemAlreadyValidated = "FeedbackItemAlreadyValidated",
  FeedbackNoItemFoundForScannedProductNumber = "FeedbackNoItemFoundForScannedProductNumber",
  FeedbackItemWasJustScanned = "FeedbackItemWasJustScanned",
  FeedbackNoProductNumberFound = "FeedbackNoProductNumberFound",
  TitleScanProductNumber = "TitleScanProductNumber",
  TitleProductValidated = "TitleProductValidated",
  TitleScanSerialNumbers = "TitleScanSerialNumbers",
  TitleScanCustomerSerialNumber = "TitleScanCustomerSerialNumber",
  TitleScanEricssonSerialNumber = "TitleScanEricssonSerialNumber",
  SubtitleValidationText = "SubtitleValidationText",
  ItemDidNotMeetValidationRequirements = "ItemDidNotMeetValidationRequirements",
  AnErrorOccurredWhileScanningTryAgain = "AnErrorOccurredWhileScanningTryAgain",
  CouldNotChangeCameraErrorMessage = "CouldNotChangeCameraErrorMessage",
  CouldNotStartOrStopScannerErrorMessage = "CouldNotStartOrStopScannerErrorMessage",
  ScanShipmentfromPackingSlip = "ScanShipmentfromPackingSlip",
  ClickHereToSeeExampleOf = "ClickHereToSeeExampleOf",
  ShipmentReference = "ShipmentReference",
  OrEnterShipmentReferenceNumber = "OrEnterShipment/ReferenceNumber",
  ShipmentReferenceNumber = "Shipment/ReferenceNumber"
}
export enum MaterialSpecificKeys {
  HandingOver = "HandingOver",
  Receiving = "Receiving",
  SearchSiteProjectOrSiteName = "SearchSiteProjectOrSiteName",
  Reason = "Reason",
  MaterialTransfersTitle = "MaterialTransfersTitle",
  NoMaterialTransfersWithFiltersTitle = "NoMaterialTransfersWithFiltersTitle",
  NoMaterialTransfersWithFiltersMessageOne = "NoMaterialTransfersWithFiltersMessageOne",
  NoMaterialTransfersWithFiltersMessageTwo = "NoMaterialTransfersWithFiltersMessageTwo",
  NoMaterialTransfersTitle = "NoMaterialTransfersTitle",
  NoMaterialTransfersMessage = "NoMaterialTransfersMessage",
  ToCompany = "ToCompany",
  FromCompany = "FromCompany",
  DispatchCreated = "DispatchCreated",
  MaterialRemovedFromSiteProject = "MaterialRemovedFromSiteProject",
  MaterialReplacementNeeded = "MaterialIsBeingReplaced",
  ValidationTitle = "ValidationTitle",
  ValidationDetailsTitle = "ValidationDetailsTitle",
  DeliveryTitle = "Delivery",
  ReturnTitle = "Return"
}
export enum NamespaceKeys {
  ActivitySpecific = "ActivitySpecific",
  Application = "Application",
  BomSpecific = "BomSpecific",
  ChecklistSpecific = "ChecklistSpecific",
  ComponentSpecific = "ComponentSpecific",
  DplSpecific = "DplSpecific",
  General = "General",
  LoginSpecific = "LoginSpecific",
  MenuTitles = "MenuTitles",
  SiteSpecific = "SiteSpecific",
  DocumentSpecific = "DocumentSpecific",
  ScannerSpecific = "ScannerSpecific",
  MaterialSpecific = "MaterialSpecific",
  InstallationSpecific = "InstallationSpecific",
  DeviationDialogMessageSpecificKeys = "DeviationDialogMessageSpecificKeys",
  DeviationDialogMessageSpecific = "DeviationDialogMessageSpecific",
  ReceivingMaterialSpecific = "ReceivingMaterialSpecific"
}
export enum InstallationSpecificKeys {
  StartDate = "StartDate",
  PageTitle = "PageTitle",
  DetailsTitle = "DetailsTitle",
  MaterialInstallationsTitle = "MaterialInstallationsTitle",
  NoMaterialInstallationsWithFiltersTitle = "NoMaterialTransfersWithFiltersTitle",
  NoMaterialInstallationsWithFiltersMessageOne = "NoMaterialInstallationsWithFiltersMessageOne",
  NoMaterialInstallationsWithFiltersMessageTwo = "NoMaterialInstallationsWithFiltersMessageTwo",
  NoMaterialInstallationsTitle = "NoMaterialInstallationsTitle",
  NoMaterialInstallationsMessage = "NoMaterialInstallationsMessage",
  InstallMaterial = "InstallMaterial",
  InstalledMaterial = "InstalledMaterial",
  NonInstalledMaterial = "NonInstalledMaterial",
  QuantitySummary = "QuantitySummary",
  QuantitySummaryReview = "QuantitySummaryReview"
}
export enum ReceivingMaterialSpecificKeys {
  ReportedAsDamaged = "ReportedAsDamage",
  ReportedAsMissing = "ReportedAsMissing",
  ValidationMessageWithHandlingUnit = "ValidationMessageWithHandlingUnit",
  ValidationMessageWithoutHandlingUnit = "ValidationMessageWithoutHandlingUnit",
  HandlingUnitReceived = "HandlingUnitReceived",
  And = "And",
  Summary = "Summary",
  NumberOfSiteProjects = "NumberOfSiteProjects",
  ItemsReceived = "ItemsReceived",
  TotalQtyReceived = "TotalQtyReceived",
  Damage = "Damage",
  ShipmentNumberReferenceNotFound = "ShipmentNumberReferenceNotFound",
  AddSerialNumber = "AddSerialNumber",
  FailedToLoadData = "FailedToLoadData",
  Received = "Received",
  HU = "HU",
  Items = "Items",
  Qty = "Qty",
  Deviations = "Deviations",
  Asterisk = "Asterisk"
}
export interface Namespace {
  [NamespaceKeys.Application]: { [keys in ApplicationKeys]: string };
  [NamespaceKeys.General]: { [keys in GeneralKeys]: string };
  [NamespaceKeys.LoginSpecific]: { [keys in LoginSpecificKeys]: string };
  [NamespaceKeys.MenuTitles]: { [keys in MenuTitleKeys]: string };
  [NamespaceKeys.BomSpecific]: { [keys in BomSpecificKeys]: string };
  [NamespaceKeys.ChecklistSpecific]: {
    [keys in ChecklistSpecificKeys]: string;
  };
  [NamespaceKeys.DplSpecific]: { [keys in DplSpecificKeys]: string };
  [NamespaceKeys.ActivitySpecific]: { [keys in ActivitySpecificKeys]: string };
  [NamespaceKeys.SiteSpecific]: { [keys in SiteSpecificKeys]: string };
  [NamespaceKeys.DocumentSpecific]: { [keys in DocumentSpecificKeys]: string };
  [NamespaceKeys.ComponentSpecific]: {
    [keys in ComponentSpecificKeys]: string;
  };
  [NamespaceKeys.ScannerSpecific]: { [keys in ScannerSpecificKeys]: string };
  [NamespaceKeys.MaterialSpecific]: { [keys in MaterialSpecificKeys]: string };
  [NamespaceKeys.InstallationSpecific]: {
    [keys in InstallationSpecificKeys]: string;
  };
  [NamespaceKeys.ReceivingMaterialSpecific]: {
    [keys in ReceivingMaterialSpecificKeys]: string;
  };
}
