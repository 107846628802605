import React, { useMemo } from "react";

import BooleanElement from "./boolean-element";
import ChecklistConditionalGroup from "./generic/checklist-conditional-group";
import { stringToBooleanOrUndefined } from "./string-to-type-deserializer";
import { HybridItemComponent, HybridItemProps } from "./types";

const ConditionalBooleanGroupElement: HybridItemComponent = ({
  item,
  onValueChanged,
  onRenderSections
}: HybridItemProps) => {
  const isMeetingCondition = useMemo<boolean>(
    () =>
      !!item.Values?.length &&
      (stringToBooleanOrUndefined(item.Values[0]) ?? false),
    [item.Values]
  );

  return (
    <ChecklistConditionalGroup
      item={item}
      onValueChanged={onValueChanged}
      onRenderSections={onRenderSections}
      isMeetingCondition={isMeetingCondition}
    >
      <BooleanElement item={item} onValueChanged={onValueChanged} />
    </ChecklistConditionalGroup>
  );
};

export default ConditionalBooleanGroupElement;
