import React, { useCallback, useMemo } from "react";

import { ChecklistItemImageRotationEditModel } from "../../../checklist-models";
import { getStylingFromRotation } from "../rotation-styling-helper";
import ImageDetailsDialog from "./image-details-dialog";
import { ImageDetailsProps } from "./unsaved-image-details-dialog";

const SavedImageDetailsDialog = ({
  image,
  onDelete,
  onClose,
  onRotateCW
}: ImageDetailsProps<ChecklistItemImageRotationEditModel>): React.ReactElement => {
  const handleOnDelete = useCallback(
    (): void => onDelete(image),
    [onDelete, image]
  );

  const handleOnRotateCW = useCallback(
    (): void => onRotateCW(image),
    [image, onRotateCW]
  );

  const styling = useMemo<React.CSSProperties | undefined>(
    () => getStylingFromRotation(image.rotation),
    [image.rotation]
  );

  return (
    <ImageDetailsDialog
      imageSrc={image.HostedUrl}
      onClose={onClose}
      onDelete={handleOnDelete}
      onRotateCW={handleOnRotateCW}
      imageStyling={styling}
    />
  );
};

export default SavedImageDetailsDialog;
