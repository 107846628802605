import React from "react";

import AppSysBar from "./AppSysBar";
import SysPanel from "./SysPanel";

import { ApplicationContextProvider } from "./context/ApplicationContext";
import IApp from "./contracts/IApp";

const App = ({
  className,
  applicationName,
  acronym,
  appId,
  onBrandClick,
  user,
  theme,
  forceTheme,
  onLogout,
  children,
  settingsContent,
  appBarItems,
  iconName
}: IApp) => {
  return (
    <ApplicationContextProvider
      user={user}
      appId={appId || acronym}
      theme={theme}
      forcedTheme={forceTheme}
    >
      <div className={className}>
        <AppSysBar
          applicationName={applicationName}
          acronym={acronym}
          onBrandClick={onBrandClick}
          appBarItems={appBarItems}
          iconName={iconName}
        />
        <main>
          <SysPanel
            user={user}
            settingsContent={settingsContent}
            onLogout={onLogout}
            iconName={iconName}
          />
          {children || <div className="empty-app" />}
        </main>
      </div>
    </ApplicationContextProvider>
  );
};

export default App;
