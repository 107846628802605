import { useContext } from "react";

import ApplicationContext, { IAppContext } from "./ApplicationContext";

const useAppContext = (): IAppContext => {
  const context = useContext(ApplicationContext);
  if (context === undefined) {
    throw new Error("Application context must be used within a Provider");
  }
  return context;
};

export default useAppContext;
