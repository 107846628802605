import React from "react";
import cx from "classnames";
import { useMenu } from "./menu-context"
import IMenuItem from "./IMenuItem";

const MenuItem = ({ tag, children, className, style, ...rest }: IMenuItem) => {
  const Tag = tag;
  const { closeOnSelect, toggle } = useMenu();
  
  return (
    <li role="presentation" onClick={() => {
      if(closeOnSelect) {
        toggle();
      }
    }}>
      <Tag className={cx("item", className)} style={style} {...rest}>
        {children}
      </Tag>
    </li>
  );
};

export default MenuItem;
