import React, { useMemo, useCallback } from "react";

import { MenuItem, Menu } from "@react-gcc-eds/core";
import { NavLink } from "react-router-dom";

import {
  MobileViewAccess,
  MobileViewAccessIdentifier
} from "../../domain/client-customer";
import { NamespaceKeys } from "../../translation/dictionary-keys";
import { useTranslation } from "../../translation/translation-utils";
import { MenuItemProps } from "../types";
import { viewAccessMenuDictionary } from "../view-access";

const useMenu = (viewAccess: MobileViewAccess[] | undefined): JSX.Element => {
  const { translate } = useTranslation();
  const getMenuItemFromViewAccess = useCallback(
    (
      viewAccessIdentifier: MobileViewAccessIdentifier
    ): React.ReactElement<typeof MenuItem> => {
      const item: MenuItemProps =
        viewAccessMenuDictionary[
          viewAccessIdentifier as keyof typeof MobileViewAccessIdentifier
        ];
      return (
        <MenuItem key={item.link} tag={NavLink} to={item.link}>
          {translate(NamespaceKeys.MenuTitles, item.menuTitleKey)}
        </MenuItem>
      );
    },
    [translate]
  );

  const menu = useMemo((): JSX.Element => {
    const menuItemsOrUndefined: (
      | React.ReactElement<typeof MenuItem>
      | undefined
    )[] =
      viewAccess?.map((viewAccessItem) =>
        getMenuItemFromViewAccess(viewAccessItem.Identifier)
      ) ?? [];
    const menuItems: React.ReactElement<typeof MenuItem>[] =
      menuItemsOrUndefined.reduce(
        (
          aggregate: React.ReactElement<typeof MenuItem>[],
          currentValue: React.ReactElement<typeof MenuItem> | undefined
        ) => {
          if (!currentValue) return aggregate;
          return [...aggregate, currentValue];
        },
        []
      );
    return (
      <Menu className="application-menu" closeOnSelect>
        {menuItems}
      </Menu>
    );
  }, [viewAccess, getMenuItemFromViewAccess]);

  return menu;
};

export default useMenu;
