import React, { useCallback, useMemo } from "react";

import { DateField } from "@react-gcc-eds/core";

import ChecklistStandardItem from "./generic/checklist-standard-item";
import { SingleItemComponent, SingleItemProps } from "./types";

const DatetimeElement: SingleItemComponent = ({
  item,
  onValueChanged
}: SingleItemProps) => {
  const selectedDate = useMemo<Date>(
    () => new Date(item.Values?.[0] ?? Date.now()),
    [item.Values]
  );

  const handleOnChangeDate = useCallback(
    (date: Date | undefined) =>
      onValueChanged({
        ...item,
        Values: [date?.toUTCString() ?? ""]
      }),
    [item, onValueChanged]
  );

  return (
    <ChecklistStandardItem item={item} onValueChanged={onValueChanged}>
      <DateField value={selectedDate} onDateSelected={handleOnChangeDate} />
    </ChecklistStandardItem>
  );
};

export default DatetimeElement;
