import React from "react";

import { CurrentUserModel, CustomerViewModel } from "../domain/client-customer";

type CustomerContextProps = {
  currentUser?: CurrentUserModel;
  customers?: CustomerViewModel[];
  onLogout?: () => void;
  currentCustomer?: CustomerViewModel;
  onChangeCustomer?: (customer: CustomerViewModel) => void;
  authenticatedCall: <T>(promise: Promise<T>) => Promise<T>;
};

export const CustomerContext = React.createContext<CustomerContextProps>({
  authenticatedCall: (promise) => promise
});
