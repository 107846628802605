import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo
} from "react";

import { mdiCameraRetakeOutline } from "@mdi/js";
import { Icon as MaterialDesignIcon } from "@mdi/react";
import { Icon, Dialog, Button } from "@react-gcc-eds/core";
import { ScanSettings, Barcode, ScanResult } from "scandit-sdk";

import useWindowDimensions from "../../../hooks/window-dimensions";
import {
  NamespaceKeys,
  GeneralKeys,
  ScannerSpecificKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";
import { FEEDBACK_TIMER_LENGTH } from "../barcode-scanning/barcode-scanning-constants";
import { FeedbackMessage } from "../barcode-scanning/barcode-scanning-models/feedback-message";
import FeedbackStatus from "../barcode-scanning/barcode-scanning-models/feedback-status";
import { ScannerContext } from "../barcode-scanning/scanner-context";
import useSearchArea from "../barcode-scanning/search-area-hook";
import Viewfinder from "../barcode-scanning/viewfinder";
import ScannerFeedbackCurtainView from "./scanner-feedback-curtain.view";

import "./serial-number-scanner.scss";

type Props = {
  title: string;
  subtitle?: string;
  defaultSmallSearchArea?: boolean;
  onValidateScanResult?: (barcode: string) => boolean;
  onScan?: (data: string) => void;
  onScanRawResult?: (
    scanResult: ScanResult,
    pauseScanning?: () => void
  ) => void;
  onClose: () => void;
};

const SerialNumberScannerComponent = ({
  title,
  subtitle,
  onClose,
  onValidateScanResult,
  defaultSmallSearchArea,
  onScan,
  onScanRawResult
}: Props): React.ReactElement => {
  const [showHowTo, setShowHowTo] = useState<boolean>(false);
  const [setupNeeded, setSetupNeeded] = useState<boolean>(true);
  const [feedbackMessage, setFeedbackMessage] = useState<FeedbackMessage>();

  const [searchArea, toggleSearchArea] = useSearchArea(defaultSmallSearchArea);
  const { width, height } = useWindowDimensions();
  const { translate } = useTranslation();

  const scannerContext = useContext(ScannerContext);

  const htmlRef = React.useRef<HTMLDivElement | null>(null);

  const clearFeedbackHandler = useCallback(() => {
    setFeedbackMessage((prev) => {
      if (prev) {
        window.clearTimeout(prev.timer);
      }
      return undefined;
    });
  }, []);

  const setFeedbackMessageForFailingToStartOrStopScanner = useCallback(
    (prev: FeedbackMessage | undefined) => {
      if (prev) window.clearTimeout(prev.timer);
      return {
        status: FeedbackStatus.Error,
        text: translate(
          NamespaceKeys.ScannerSpecific,
          ScannerSpecificKeys.CouldNotStartOrStopScannerErrorMessage
        ),
        timer: window.setTimeout(clearFeedbackHandler, FEEDBACK_TIMER_LENGTH)
      };
    },
    [clearFeedbackHandler, translate]
  );

  const handleOnScanStart = useCallback((): void => {
    try {
      scannerContext?.setCameraEnabled(true);
    } catch {
      setFeedbackMessage(setFeedbackMessageForFailingToStartOrStopScanner);
    }
  }, [scannerContext, setFeedbackMessageForFailingToStartOrStopScanner]);

  const handleOnScanEnd = useCallback((): void => {
    try {
      scannerContext?.setCameraEnabled(false);
    } catch {
      setFeedbackMessage(setFeedbackMessageForFailingToStartOrStopScanner);
    }
  }, [scannerContext, setFeedbackMessageForFailingToStartOrStopScanner]);

  const handleOnScan: (scanResult: ScanResult) => void = useCallback(
    (scanResult) => {
      if (
        onValidateScanResult &&
        !onValidateScanResult(scanResult.barcodes[0].data)
      ) {
        setFeedbackMessage((prev: FeedbackMessage | undefined) => {
          if (prev) {
            window.clearTimeout(prev.timer);
          }
          return {
            status: FeedbackStatus.Warning,
            text: translate(
              NamespaceKeys.ScannerSpecific,
              ScannerSpecificKeys.ItemDidNotMeetValidationRequirements
            ),
            timer: window.setTimeout(
              clearFeedbackHandler,
              FEEDBACK_TIMER_LENGTH
            )
          };
        });
        return;
      }
      onScanRawResult?.(scanResult);
      scannerContext?.stopUsingScanner();
      onScan?.(scanResult.barcodes[0].data);
    },
    [
      onValidateScanResult,
      scannerContext,
      onScan,
      translate,
      clearFeedbackHandler,
      onScanRawResult
    ]
  );

  const handleOnError: (scanResult: ScanResult) => void = useCallback(() => {
    setFeedbackMessage((prev: FeedbackMessage | undefined) => {
      if (prev) {
        window.clearTimeout(prev.timer);
      }
      return {
        status: FeedbackStatus.Error,
        text: translate(
          NamespaceKeys.ScannerSpecific,
          ScannerSpecificKeys.AnErrorOccurredWhileScanningTryAgain
        ),
        timer: window.setTimeout(clearFeedbackHandler, FEEDBACK_TIMER_LENGTH)
      };
    });
  }, [clearFeedbackHandler, translate]);

  const scanSettings = useMemo(
    () =>
      new ScanSettings({
        enabledSymbologies: [
          Barcode.Symbology.EAN13,
          Barcode.Symbology.PDF417,
          Barcode.Symbology.CODE128,
          Barcode.Symbology.CODE39
        ],
        codeDuplicateFilter: 3000,
        searchArea: searchArea
      }),
    [searchArea]
  );

  useEffect(() => {
    console.log("applying settings", scanSettings.getSearchArea());
    scannerContext?.applySettings(scanSettings);
  }, [scanSettings, scannerContext]);

  useEffect(() => {
    if (htmlRef.current && setupNeeded) {
      setSetupNeeded(() => {
        scannerContext?.startUsingScanner(htmlRef.current, scanSettings, true);
        return false;
      });
    }
  }, [
    scannerContext,
    searchArea,
    setupNeeded,
    htmlRef,
    handleOnScan,
    handleOnError,
    scanSettings
  ]);

  useEffect(() => {
    scannerContext?.setOnScanCallback(handleOnScan);
  }, [handleOnScan, scannerContext]);

  useEffect(() => {
    scannerContext?.setOnErrorCallback(handleOnError);
  }, [handleOnError, scannerContext]);

  const handleOnCloseHowTo = useCallback(() => {
    setShowHowTo(false);
  }, []);
  /*
  const handleOnShowHowTo = useCallback(() => {
    setShowHowTo(true);
  }, []);
*/
  const handleOnClose = useCallback(() => {
    scannerContext?.stopUsingScanner();
    onClose();
  }, [onClose, scannerContext]);

  const scanning = useMemo(
    (): boolean => scannerContext?.cameraEnabled ?? false,
    [scannerContext]
  );

  const handleOnNextCamera = useCallback((): void => {
    const showFeedback = (): void =>
      setFeedbackMessage((prev: FeedbackMessage | undefined) => {
        if (prev) {
          window.clearTimeout(prev.timer);
        }
        return {
          status: FeedbackStatus.Error,
          text: translate(
            NamespaceKeys.ScannerSpecific,
            ScannerSpecificKeys.CouldNotChangeCameraErrorMessage
          ),
          timer: window.setTimeout(clearFeedbackHandler, FEEDBACK_TIMER_LENGTH)
        };
      });

    if (!scannerContext) {
      showFeedback();
      return;
    }
    try {
      scannerContext.nextCamera();
    } catch {
      showFeedback();
    }
  }, [clearFeedbackHandler, scannerContext, translate]);

  return (
    <div
      className="scanner-container tile"
      style={{
        width: width,
        height: height
      }}
    >
      <div className="header">
        <div className="left">
          <Icon name="cross" onClick={handleOnClose} />
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
        </div>
        <div className="right">
          {scannerContext && scannerContext.cameraToggleEnabled && (
            <div
              className="action material-design-action-icon-fix"
              onClick={handleOnNextCamera}
            >
              <MaterialDesignIcon path={mdiCameraRetakeOutline} />
            </div>
          )}
          <div className="action" onClick={toggleSearchArea}>
            <Icon name={searchArea.toggleIcon} />
          </div>
          {/*
            <div className="action">
              <Icon name="help" onClick={handleOnShowHowTo} />
            </div>
          */}
        </div>
      </div>
      <div className="content no-margins" ref={htmlRef}>
        <Viewfinder scanning={scanning} searchArea={searchArea} />
        {feedbackMessage && (
          <ScannerFeedbackCurtainView feedbackMessage={feedbackMessage} />
        )}
        <div className="scan-status">
          {!scanning
            ? translate(
                NamespaceKeys.ScannerSpecific,
                ScannerSpecificKeys.BottomHelperPressToScan
              )
            : translate(
                NamespaceKeys.ScannerSpecific,
                ScannerSpecificKeys.BottomHelperPointYourCameraToScan
              )}
        </div>
        <div
          onTouchStart={handleOnScanStart}
          onTouchCancel={handleOnScanEnd}
          onTouchEnd={handleOnScanEnd}
          className={`tap-to-scan${scanning ? " active" : " awaiting"}`}
        ></div>
      </div>
      {showHowTo && (
        <Dialog
          buttons={
            <Button onClick={handleOnCloseHowTo}>
              {translate(NamespaceKeys.General, GeneralKeys.Close)}
            </Button>
          }
          fullscreen
          onClose={handleOnCloseHowTo}
        >
          An animated GIF followed by some text on how to use scanner
        </Dialog>
      )}
    </div>
  );
};

export default SerialNumberScannerComponent;
