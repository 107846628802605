import React, { useMemo, ReactNode } from "react";

import {
  ChecklistConditionalGroupComponent,
  ChecklistConditionalGroupProps
} from "../types";

const ChecklistConditionalGroup: ChecklistConditionalGroupComponent = ({
  item,
  onRenderSections,
  isMeetingCondition,
  children
}: ChecklistConditionalGroupProps) => {
  const collapsableChildren = useMemo<ReactNode>(
    () => isMeetingCondition && item.Children.map(onRenderSections),
    [isMeetingCondition, item.Children, onRenderSections]
  );

  return (
    <>
      {children}
      {collapsableChildren}
    </>
  );
};

export default ChecklistConditionalGroup;
