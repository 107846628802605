import React, { useState, useCallback, ReactElement, useMemo } from "react";

import { MaterialTransferItemStatus } from "../../../../../../domain/client-customer";
import { DeliveryProductListItemInformation } from "../../../../../common/product-details-modal/product-details-dialog";
import { fromDplItemModelToInformationItem } from "../../../../../common/product-details-modal/product-details-utils";
import { QuantityValidationStateItem } from "../quantity-validator-component";
import QuantityValidatorItem from "./quantity-validator-item";

type Props = {
  item: QuantityValidationStateItem;
  isValidated: boolean;
  onItemChange: (item: QuantityValidationStateItem) => void;
};

const QuantityValidatorItemComponent = ({
  item,
  isValidated,
  onItemChange
}: Props): ReactElement => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [showProductDetailsDialog, setShowProductDetailsDialog] =
    useState<boolean>(false);
  const [showProductNotesDialog, setShowProductNotesDialog] =
    useState<boolean>(false);
  const dplInformationItem = useMemo<
    DeliveryProductListItemInformation | undefined
  >(() => fromDplItemModelToInformationItem(item.dplItem), [item.dplItem]);

  const handleOnDeviationCancel = useCallback(() => {
    if (!item.quantityValidated) {
      onItemChange({
        ...item,
        validationQuantities: {
          None: item.quantity
        }
      });
    }
  }, [item, onItemChange]);

  const handleOnToggleDeviation = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  const handleOnDeviationChange = useCallback(
    (value: number, status: MaterialTransferItemStatus) => {
      const quantityToRemoveFromStatus =
        value - (item.validationQuantities?.[status] ?? 0);

      const resultingNoneQuantity =
        (item.validationQuantities?.None ?? 0) - quantityToRemoveFromStatus;

      if (resultingNoneQuantity > item.quantity || resultingNoneQuantity < 0)
        return;

      const updatedItem: QuantityValidationStateItem = {
        ...item,
        validationQuantities: {
          ...item.validationQuantities,
          None:
            (item.validationQuantities?.None ?? 0) - quantityToRemoveFromStatus,
          [status]: value
        }
      };
      onItemChange(updatedItem);
    },
    [item, onItemChange]
  );

  const handleOnItemValidated = useCallback(() => {
    setExpanded(false);
    onItemChange({
      ...item,
      quantityValidated: true,
      validationQuantities: {
        Ok: item.quantity
      }
    });
  }, [item, onItemChange]);

  const handleOnItemCorrect = useCallback(() => {
    setExpanded(false);
    onItemChange({
      ...item,
      quantityValidated: true
    });
  }, [item, onItemChange]);

  const handleOnItemRestore = useCallback(() => {
    setExpanded(false);
    onItemChange({
      ...item,
      quantityValidated: false,
      validationQuantities: {
        None: item.quantity
      }
    });
  }, [item, onItemChange]);

  const itemHasDeviation = useCallback(
    (): boolean =>
      (item.validationQuantities?.Ok ?? 0) +
        (item.validationQuantities?.None ?? 0) !==
      item.quantity,
    [item.quantity, item.validationQuantities]
  );

  const handleOnClickProductDetailsButton = useCallback(() => {
    setShowProductDetailsDialog(true);
  }, []);

  const handleOnClickProductNotesButton = useCallback(() => {
    setShowProductNotesDialog(true);
  }, []);

  const handleOnCloseProductNotesDialog = useCallback(() => {
    setShowProductNotesDialog(false);
  }, []);

  const handleOnCloseProductDetailsDialog = useCallback(() => {
    setShowProductDetailsDialog(false);
  }, []);

  return (
    <QuantityValidatorItem
      dplInformationItem={dplInformationItem}
      onItemValidate={handleOnItemValidated}
      onItemCorrect={handleOnItemCorrect}
      onItemRestore={handleOnItemRestore}
      item={item}
      onDeviationCancel={handleOnDeviationCancel}
      onToggleDeviation={handleOnToggleDeviation}
      onDeviationChange={handleOnDeviationChange}
      expanded={expanded}
      isValidated={isValidated}
      hasDeviation={itemHasDeviation()}
      onClickProductDetailsButton={handleOnClickProductDetailsButton}
      onCloseProductDetailsDialog={handleOnCloseProductDetailsDialog}
      showProductNotesDialog={showProductNotesDialog}
      onClickProductNotesButton={handleOnClickProductNotesButton}
      onCloseProductNotesDialog={handleOnCloseProductNotesDialog}
      showProductDetailsDialog={showProductDetailsDialog}
    />
  );
};

export default QuantityValidatorItemComponent;
