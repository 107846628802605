import { createContext } from "react";

import { ScanResult, ScanSettings } from "scandit-sdk";

export enum ScannerErrorType {
  BarcodePickerInitializationError = "Could not instantiate barcode picker.",
  ScanditLicenseConfigurationError = "Could not configure scandit scanner license.",
  CameraAccessError = "Could not get access to cameras."
}

export type ScannerProps = {
  startUsingScanner: (
    htmlDivReference: HTMLDivElement | null,
    scanSettings: ScanSettings,
    startPaused: boolean
  ) => void;
  setOnScanCallback: (handleOnScan: (scanResult: ScanResult) => void) => void;
  setOnErrorCallback: (handleOnError: (scanResult: ScanResult) => void) => void;
  setCameraEnabled: (enabled: boolean) => void;
  cameraEnabled: boolean;
  nextCamera: () => void;
  cameraToggleEnabled: boolean;
  toggleTorch: () => void;
  stopUsingScanner: () => void;
  scannerError: ScannerErrorType | undefined;
  applySettings: (settings: ScanSettings) => void;
};

export const ScannerContext = createContext<ScannerProps | undefined>(
  undefined
);
