import React from "react";
import cx from "classnames";
import IWizardContent from "./IWizardContent";

const WizardColumn = ({ children, type, className }: IWizardContent) => {
  
  const defaultContent = <div className={cx("column sm-12 md-6 lg-4", className)}>{children}</div>;
  
  const notes = (
      <div className={cx("notes column sm-12 lg-4", className)}>
        <p>Notes</p>
        {children}
      </div>
  );
  
  return type === "note" ? notes: defaultContent;
};

export default WizardColumn;
