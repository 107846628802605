type Cookies = {
  // KEY: Cookie name, VALUE: Cookie value
  [key: string]: string;
};

export const getCookies = (): Cookies => {
  const cookies: Cookies = {};

  if (document.cookie) {
    const split = document.cookie.split(";");
    for (let i = 0; i < split.length; i++) {
      const cookie = split[i].split("=");
      cookie[0] = cookie[0].replace(/^ /, "");
      cookies[decodeURIComponent(cookie[0])] = decodeURIComponent(cookie[1]);
    }
  }
  return cookies;
};
