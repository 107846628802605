import { BillOfMaterialItemDto } from "../../../domain/client-customer";
import { BomFilters } from "./bom-filter/bom-filter-component";
import { BillOfMaterialItemDtoEditState } from "./bom-viewer-component";

export const itemIsSplitIntoMultipleMaterialCategories = (
  bomItem: BillOfMaterialItemDto,
  allBomItems: BillOfMaterialItemDto[]
): boolean =>
  allBomItems.some(
    (i) =>
      i.DeliveryProductListItem?.Id === bomItem.DeliveryProductListItem?.Id &&
      i.MaterialCategory !== bomItem.MaterialCategory
  );
// TODO: To be aligned in story 21416
// export const toBillOfMaterialItemRequestModel = (
//   item: BillOfMaterialItemDto
// ): BillOfMaterialItemRequestModel => ({
//   DeliveryProductListItemId: item.DeliveryProductListItem?.Id,
//   Note: item.Note,
//   Quantity: item.Quantity,
//   SourcingProvider: item.SourcingProvider,
//   MaterialCategory: item.MaterialCategory
// });

// Copied from dropdown.d.ts
export interface DropdownItem {
  /** Item title */
  title: string;
  /** Item value */
  value: string | Record<string, unknown>;
  /** Disabled */
  disabled?: boolean;
}

export const applyFiltersOnBom = (
  bom: BillOfMaterialItemDtoEditState[] | undefined,
  filters: BomFilters
): BillOfMaterialItemDtoEditState[] => {
  let filteredBom = [...(bom ?? [])];

  if (filters.Commodity) {
    filteredBom = filteredBom.filter(
      (c) => c.bomItem.DeliveryProductListItem?.Commodity === filters.Commodity
    );
  }
  if (filters.MaterialCategory) {
    filteredBom = filteredBom.filter(
      (c) => c.bomItem.MaterialCategory === filters.MaterialCategory
    );
  }
  if (filters.SourcingProvider) {
    filteredBom = filteredBom.filter(
      (c) => c.bomItem.SourcingProvider === filters.SourcingProvider
    );
  }
  if (filters.EricssonProductNumber) {
    filteredBom = filteredBom.filter((c) => {
      return (
        c.bomItem.DeliveryProductListItem?.EricssonProductNumber?.toLowerCase().includes(
          filters.EricssonProductNumber.toLowerCase()
        ) ?? false
      );
    });
  }
  return filteredBom;
};
