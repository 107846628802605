import React, { useState } from "react";
import cx from "classnames";
import IBreadcrumb from "./IBreadcrumb";
import { uniqueId, useClickOutside } from "../../utils/utils";

const Breadcrumb = ({ items, onItemClick }: IBreadcrumb) => {
  const size = items.length < 4 ? "short" : "long";
  const clName = cx("breadcrumb", size);

  const [visible, setVisible] = useState(false);
  const closeDropdown = () => setVisible(false);
  const openDropdown = () => setVisible(true);
  const containerRef = useClickOutside(closeDropdown);
  const handleMenuClick = () => (visible ? closeDropdown() : openDropdown());

  const [highlighted, setHighlighted] = useState(-1);

  const itemPicked = (index: number) => {
    if (!onItemClick) {
      return;
    }

    closeDropdown();
    onItemClick(items[index]);
  };

  const handleItemClick = (index: number) => {
    itemPicked(index);
  };

  const handleContainerKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const { keyCode } = e;

    if (!visible) {
      // menu open by eds on enter
      if (keyCode === 13) {
        openDropdown();
      }
    } else {
      switch (keyCode) {
        case 13:
          // menu closed by eds
          itemPicked(highlighted);
          break;
        case 38:
          setHighlighted(highlighted > 0 ? highlighted - 1 : items.length - 1);
          break;
        case 40:
          setHighlighted(highlighted < items.length - 1 ? highlighted + 1 : 0);
          break;
        default:
          break;
      }
    }
  };

  const renderItem = (text: string, index: number, isLink = true) => (
    <span
      role="presentation"
      key={uniqueId(text)}
      className="link"
      onClick={
        isLink
          ? e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              handleItemClick(index);
            }
          : undefined
      }
    >
      {text}
    </span>
  );

  if (items.length === 1) {
    const [b0] = items;
    return <div className={clName}>{renderItem(b0.title, 0, false)}</div>;
  }

  const breadcrumbMenu = (
    <span
      className="more"
      ref={containerRef}
      tabIndex={0}
      role="button"
      onKeyDown={handleContainerKeyPress}
    >
      <div role="presentation" onClick={() => handleMenuClick()} onKeyPress={() => {}}>
        <i className="icon"></i>
      </div>
      <div className={cx("menu overflow", { visible })}>
        {items.slice(0, items.length - 1).map((b, index) => (
          <div
            role="presentation"
            className="item"
            onClick={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              handleItemClick(index);
            }}
          >
            {b.title}
          </div>
        ))}
      </div>
    </span>
  );

  if (items.length === 2) {
    const [b0, b1] = items;
    return (
      <div className={clName}>
        {breadcrumbMenu}
        {renderItem(b0.title, 0)}
        <span className="separator"></span>
        {renderItem(b1.title, 1, false)}
      </div>
    );
  }

  if (items.length === 3) {
    const [b0, b1, b2] = items;
    return (
      <div className={clName}>
        {breadcrumbMenu}
        {renderItem(b0.title, 0)}
        <span className="separator"></span>
        {renderItem(b1.title, 1)}
        <span className="separator"></span>
        {renderItem(b2.title, 2, false)}
      </div>
    );
  }

  if (items.length >= 4) {
    const b = items;
    const lastIndex = items.length - 1;
    return (
      <div className={clName}>
        {renderItem(b[0].title, 0)}
        <span className="separator"></span>
        {breadcrumbMenu}
        <span className="separator"></span>
        {renderItem(b[lastIndex - 1].title, lastIndex - 1)}
        <span className="separator"></span>
        {renderItem(b[lastIndex].title, lastIndex, false)}
      </div>
    );
  }

  return null;
};

export default Breadcrumb;
