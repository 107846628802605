import React, { ReactElement, useState, useCallback, useMemo } from "react";

import {
  DeliveryProductListItemModel,
  MaterialTransferItemStatus
} from "../../../../../domain/client-customer";
import { ComponentSpecificKeys } from "../../../../../translation/dictionary-keys";
import {
  NonSerializedItemState,
  SerializedItemState
} from "../../../receive-material-overview/shared/receive-material-models";
import {
  fromSerializedToGeneric,
  fromNonSerializedToGeneric
} from "../material-transfer-validation-utils";
import QuantityValidator from "./quantity-validator";

export interface QuantityValidationPropItem {
  dplItem: DeliveryProductListItemModel | undefined;
  quantity: number;
  materialCategory: string;
}

export type ValidationQuantities = Partial<
  Record<MaterialTransferItemStatus, number>
>;

export interface QuantityValidationStateItem
  extends QuantityValidationPropItem {
  quantityValidated: boolean;
  isSerialized: boolean;
  validationQuantities?: ValidationQuantities;
  note?: string;
}

const mapItemsFromPropsToNonValidatedItems = (
  serializedItems: SerializedItemState[],
  nonSerializedItems: NonSerializedItemState[]
): QuantityValidationStateItem[] => [
  ...fromSerializedToGeneric(
    serializedItems.filter((si) => !si.quantityValidated)
  ),
  ...fromNonSerializedToGeneric(
    nonSerializedItems.filter((nsi) => !nsi.quantityValidated)
  )
];

const mapItemsFromPropsToValidatedItems = (
  serializedItems: SerializedItemState[],
  nonSerializedItems: NonSerializedItemState[]
): QuantityValidationStateItem[] => [
  ...fromSerializedToGeneric(
    serializedItems.filter((si) => si.quantityValidated)
  ),
  ...fromNonSerializedToGeneric(
    nonSerializedItems.filter((nsi) => nsi.quantityValidated)
  )
];

const mapItemStatusesToItems = (
  item: QuantityValidationStateItem,
  statusToMap: MaterialTransferItemStatus,
  itemsToMapAgainst: SerializedItemState[]
): SerializedItemState[] => {
  const mappedItems: SerializedItemState[] = [];
  for (let i = 0; i < (item.validationQuantities?.[statusToMap] ?? 0); i++) {
    const itemToMap = itemsToMapAgainst.pop();
    if (!itemToMap) {
      throw new Error("Could not map validated item to serialized item state.");
    }
    mappedItems.push({
      ...itemToMap,
      status: MaterialTransferItemStatus[statusToMap],
      quantityValidated: item.quantityValidated
    });
  }

  return mappedItems;
};

type Props = {
  nonSerializedItems: NonSerializedItemState[];
  serializedItems: SerializedItemState[];
  showApproveAllItemsDialog: boolean;
  onSmartScanClicked: () => void;
  onSerializedItemsUpdated: (
    serializedItemsToUpdate: SerializedItemState[]
  ) => void;
  onNonSerializedItemUpdated: (
    nonSerializedItemToUpdate: NonSerializedItemState
  ) => void;
  onApproveAllItemsDialogCancel: () => void;
  onApproveAllItemsDialogCompleted: () => void;
};

const QuantityValidatorComponent = ({
  nonSerializedItems,
  serializedItems,
  showApproveAllItemsDialog,
  onSmartScanClicked,
  onNonSerializedItemUpdated,
  onSerializedItemsUpdated,
  onApproveAllItemsDialogCancel,
  onApproveAllItemsDialogCompleted
}: Props): ReactElement => {
  const [errorMessage, setErrorMessage] = useState<ComponentSpecificKeys>();

  const validatedItems = useMemo(
    () =>
      mapItemsFromPropsToValidatedItems(serializedItems, nonSerializedItems),
    [nonSerializedItems, serializedItems]
  );
  const nonValidatedItems = useMemo(
    () =>
      mapItemsFromPropsToNonValidatedItems(serializedItems, nonSerializedItems),
    [nonSerializedItems, serializedItems]
  );

  const handleOnItemChange = useCallback(
    (item: QuantityValidationStateItem): void => {
      if (!item.isSerialized) {
        const nonSerializedItemToUpdate = nonSerializedItems.find(
          (nsi) =>
            nsi.materialCategory === item.materialCategory &&
            nsi.dplItem?.Id === item.dplItem?.Id
        );
        if (!nonSerializedItemToUpdate) {
          setErrorMessage(
            ComponentSpecificKeys.AnErrorOccurredPleaseTryAgainLater
          );
          return;
        }

        const updatedItem: NonSerializedItemState = {
          ...nonSerializedItemToUpdate,
          quantityValidated: item.quantityValidated,
          validationQuantities: { ...item.validationQuantities }
        };

        onNonSerializedItemUpdated(updatedItem);
      } else {
        const serializedItemsToUpdate = serializedItems.filter(
          (si) =>
            si.dplItem?.Id === item.dplItem?.Id &&
            si.materialCategory === item.materialCategory
        );
        if (!serializedItemsToUpdate || serializedItemsToUpdate.length === 0) {
          setErrorMessage(
            ComponentSpecificKeys.AnErrorOccurredPleaseTryAgainLater
          );
          return;
        }

        if (item.quantity !== serializedItemsToUpdate.length) {
          setErrorMessage(
            ComponentSpecificKeys.AnErrorOccurredPleaseTryAgainLater
          );
          return;
        }

        const updatedItems = Object.values(MaterialTransferItemStatus)
          .map((status) =>
            mapItemStatusesToItems(item, status, serializedItemsToUpdate)
          )
          .reduce((allItems, someItems) => [...allItems, ...someItems], []);

        onSerializedItemsUpdated(updatedItems);
      }
    },
    [
      nonSerializedItems,
      onNonSerializedItemUpdated,
      onSerializedItemsUpdated,
      serializedItems
    ]
  );

  const handleOnApproveAllItemsClicked = useCallback(() => {
    nonValidatedItems.forEach((item: QuantityValidationStateItem) => {
      handleOnItemChange({
        ...item,
        quantityValidated: true,
        validationQuantities: {
          Ok: item.quantity
        }
      });
    });
    onApproveAllItemsDialogCompleted();
  }, [handleOnItemChange, nonValidatedItems, onApproveAllItemsDialogCompleted]);

  return (
    <QuantityValidator
      nonValidatedItems={nonValidatedItems}
      validatedItems={validatedItems}
      errorMessage={errorMessage}
      showApproveAllItemsDialog={showApproveAllItemsDialog}
      onSmartScanClicked={onSmartScanClicked}
      onItemChange={handleOnItemChange}
      onApproveAllItemsDialogCancel={onApproveAllItemsDialogCancel}
      onApproveAllItemsClicked={handleOnApproveAllItemsClicked}
    />
  );
};

export default QuantityValidatorComponent;
