import React, { useEffect } from "react";
import Loader from "../loader/Loader";
import IWelcome from "./contracts/IWelcome";
import printCopyrightText from "../../copyright";

const Welcome = (props: IWelcome) => {
  const { productName, signedInUser, welcomeText = "Welcome" } = {
    ...props
  };

  useEffect(() => {
    if (!document.body.classList.contains("dark")) {
      document.body.classList.remove("light", "dark");
      document.body.classList.add("dark");
    }
  }, []);

  return (
    <main>
      <div className="signin">
        <div className="watermark">
          <i className="icon icon-econ" />
          <div className="product">{productName}</div>
        </div>

        <div className="welcome">
          <div className="message">{welcomeText}</div>
          {signedInUser && (
            <div className="username">{`${signedInUser.firstName} ${signedInUser.lastName}`}</div>
          )}
          <Loader size="medium" />
        </div>

        <div className="footer">
          {printCopyrightText()}
        </div>
      </div>
    </main>
  );
};
export default Welcome;
