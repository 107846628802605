import React, { useState } from "react";
import ICalendar from "./ICalendar";
import DateCalendar from "../datefield/DateCalendar";

const Calendar = ({ value, onDateSelected, disabled }: ICalendar) => {
  const [visibleDate, setVisibleDate] = useState(new Date());

  return (
    <DateCalendar
      className="calendar-no-overlay"
      disabled={disabled}
      visible
      visibleDate={visibleDate}
      selectedDate={value}
      onVisibleDateChanged={(date: Date) => {
        setVisibleDate(date);
      }}
      onDateSelected={(date: Date) => onDateSelected && onDateSelected(date)}
    ></DateCalendar>
  );
};

export default Calendar;
