import React, { ReactElement, useState, useCallback, useMemo } from "react";

import { DeliveryProductListItemInformation } from "../../../../../common/product-details-modal/product-details-dialog";
import { fromDplItemModelToInformationItem } from "../../../../../common/product-details-modal/product-details-utils";
import {
  SerialNumberValidationItem,
  SerialNumberType
} from "../serial-number-validator-component";
import SerialNumberValidatorItemView from "./serial-number-validator-item-view";

type Props = {
  item: SerialNumberValidationItem;
  onItemChange: (item: SerialNumberValidationItem) => void;
};

const SerialNumberValidatorItemComponent = ({
  item,
  onItemChange
}: Props): ReactElement => {
  const [showScanner, setShowScanner] = useState<boolean>(false);
  const [serialNumberTypeToBeScanned, setSerialNumberTypeToBeScanned] =
    useState<SerialNumberType>(SerialNumberType.None);
  const [showProductDetailsDialog, setShowProductDetailsDialog] =
    useState<boolean>(false);
  const dplInformationItem = useMemo<
    DeliveryProductListItemInformation | undefined
  >(() => fromDplItemModelToInformationItem(item.dplItem), [item.dplItem]);

  const handleOnCustomerSerialNumberChange = useCallback(
    (serial: string): void => {
      const updatedItem: SerialNumberValidationItem = {
        ...item,
        customerSerialNumber: serial
      };
      onItemChange(updatedItem);
    },
    [item, onItemChange]
  );

  const handleOnEricssonSerialNumberChange = useCallback(
    (serial: string): void => {
      const updatedItem: SerialNumberValidationItem = {
        ...item,
        ericssonSerialNumber: serial
      };
      onItemChange(updatedItem);
    },
    [item, onItemChange]
  );

  const handleOnItemValidate = useCallback(() => {
    const updatedItem: SerialNumberValidationItem = {
      ...item,
      serialNumbersValidated: true
    };
    onItemChange(updatedItem);
  }, [item, onItemChange]);

  const handleOnItemUndo = useCallback(() => {
    if (!item.serialNumbersValidated) return;
    const updatedItem: SerialNumberValidationItem = {
      ...item,
      serialNumbersValidated: false
    };
    onItemChange(updatedItem);
  }, [item, onItemChange]);

  const handleOnToggleScanner = useCallback(
    (serialNumberType?: SerialNumberType) => {
      setSerialNumberTypeToBeScanned(serialNumberType ?? SerialNumberType.None);
      setShowScanner((prev) => !prev);
    },
    []
  );

  const handleOnScan = useCallback(
    (serial: string) => {
      setShowScanner(false);
      switch (serialNumberTypeToBeScanned) {
        case SerialNumberType.CustomerSerialNumber:
          handleOnCustomerSerialNumberChange(serial);
          return;
        case SerialNumberType.EricssonSerialNumber:
          handleOnEricssonSerialNumberChange(serial);
          return;
        default:
          return;
      }
    },
    [
      serialNumberTypeToBeScanned,
      handleOnCustomerSerialNumberChange,
      handleOnEricssonSerialNumberChange
    ]
  );

  const handleOnValidateScanResult = useCallback(
    (scannedData: string): boolean => {
      return scannedData.length > 0;
    },
    []
  );

  const handleOnClickProductDetailsButton = useCallback(() => {
    setShowProductDetailsDialog(true);
  }, []);

  const handleOnCloseProductDetailsDialog = useCallback(() => {
    setShowProductDetailsDialog(false);
  }, []);

  return (
    <SerialNumberValidatorItemView
      dplInformationItem={dplInformationItem}
      item={item}
      isValidated={item.serialNumbersValidated}
      validButtonDisabled={false}
      onCustomerSerialNumberChange={handleOnCustomerSerialNumberChange}
      onEricssonSerialNumberChange={handleOnEricssonSerialNumberChange}
      onItemUndo={handleOnItemUndo}
      onItemValidate={handleOnItemValidate}
      showScanner={showScanner}
      onToggleScanner={handleOnToggleScanner}
      onScan={handleOnScan}
      onValidateScanResult={handleOnValidateScanResult}
      onClickProductDetailsButton={handleOnClickProductDetailsButton}
      onCloseProductDetailsDialog={handleOnCloseProductDetailsDialog}
      showProductDetailsDialog={showProductDetailsDialog}
    />
  );
};

export default SerialNumberValidatorItemComponent;
