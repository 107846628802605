import React from "react";
import cx from "classnames";

import Tooltip from "../tooltip/Tooltip";
import IAppBarItem from "./contracts/IAppBarItem";

const AppBarItem = ({
  iconName,
  text,
  tooltip,
  onClick,
  className
}: IAppBarItem) => {
  const content = (
    <>
      {iconName && <i className={cx(`icon icon-${iconName}`, className)} />}
      {text && <span>{text}</span>}
    </>
  );
   
  return (
    <div
      role="presentation"
      className={cx("item", {"defaultCursor": !onClick})}
      onClick={e => {
        if (onClick) onClick(e);
      }}
    >
      {tooltip ? (
        <Tooltip text={tooltip} position="bottom" type="pointer">
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </div>
  );
};
export default AppBarItem;
