import React from "react";

import { Button } from "@react-gcc-eds/core";

type Props = {
  loading?: boolean;
  primary?: boolean;
  onClick: () => void;
};

const FileDownloadButtonView: React.FC<Props> = ({
  loading,
  onClick,
  children,
  primary
}: React.PropsWithChildren<Props>): React.ReactElement => {
  return (
    <Button onClick={onClick} loading={loading} primary={primary}>
      {children}
    </Button>
  );
};

export default FileDownloadButtonView;
