import React, { ReactElement, useState } from "react";
import cx from "classnames";
import WizardSteps from "./WizardSteps";
// @ts-ignore
import WizardFooter from "./WizardFooter";
// @ts-ignore
import WizardPageWrapper from "./WizardPageWrapper";
import * as utils from "../../utils/utils";
import IWizard from "./IWizard";

const Wizard = ({
  children,
  titles,
  completedSteps,
  onCancel,
  onSave,
  onFinish,
  onPreviousPage,
  onNextPage,
  onPageChange,
  onStepLeftClick,
  onStepRightClick,
  className,
  disablePreviousButton = false,
  disableNextButton = false,
  disableFinishButton = false,
  disableCancelButton = false,
  disableSaveButton = false,
  disableStepLeftButton = false,
  disableStepRightButton = false,
  pageId,
  finished = false,
  
  disableSteps = false,
  hideStepLeftButton = false,
  hideStepRightButton = false,
  hideSteps = false,
  hideCancelButton = false,
  hideSaveButton = false,
  hidePreviousButton = false,
  hideNextButton = false,
  hideFinishButton = false,

  labelCancelButton = "",
  labelSaveButton = "",
  labelPreviousButton = "",
  labelNextButton = "",
  labelFinishButton = "",
  labelFinishedButton = "",

}: IWizard) => {
  
  const nPages = React.Children.count(children);
  const pages = children !== undefined && children !== null ? children : [];

  const Titles =
    titles !== undefined 
      ? titles.slice()
      : React.Children.map(children, (element: ReactElement) => {
        return element.props.title;
      }).slice()

  const pageSteps = utils.isMobileDevice() ? 1 : 4;
  const pageChunks = utils.chunkArray(Titles.slice(), utils.isMobileDevice() ? 1 : pageSteps);

  const [pageChunkId, setPageChunkId] = useState(0);
  const [visibleSteps, setVisibleSteps] = useState(pageChunks[pageChunkId]);

  const getVisibleSteps = (pageTitle: string) =>
    pageChunks.find((pageChunk: string[]) =>
      pageChunk.find((currentTitle: string) => pageTitle === currentTitle)
  );

  const getPageChunkId = () => {
    let id = -1;
    pageChunks.forEach((pageChunk: string[], i:number) => {
      if(utils.compareArray(visibleSteps, pageChunk)){
        id = i;
      }
    });
    return id;
  }

  return (
    <div className={cx("wizard", className)}>
      <WizardSteps
        titles={Titles}
        currentStep={pageId >= 0 && pageId < nPages ? pageId : 0}
        completedPages={completedSteps}
        visibleSteps={visibleSteps}
        stepLeftDisabled={disableStepLeftButton}
        stepRightDisabled={disableStepRightButton}
        stepsDisabled={disableSteps}

        hideStepLeft={hideStepLeftButton}
        hideStepRight={hideStepRightButton}
        hideSteps={hideSteps}

        onStepClick={(clickedId:number) => onPageChange && onPageChange(clickedId)}
        onLeftClick={() => {
          
          const chunkId = getPageChunkId();
          if (chunkId > 0) {
            setPageChunkId(chunkId - 1);
            setVisibleSteps(pageChunks[chunkId-1])
          }
          if(onStepLeftClick){
            onStepLeftClick();
          }
        }}
        onRightClick={() => {
          if (pageChunkId < nPages) {
            setPageChunkId(pageChunkId + 1);
            setVisibleSteps(pageChunks[pageChunkId+1])
          }
          if(onStepRightClick){
            onStepRightClick();
          }
        }}
      />
      <WizardPageWrapper pageNumber={pageId + 1} maxPages={nPages}>
        {pages[pageId]}
      </WizardPageWrapper>
      <WizardFooter
        finished={finished}
        lastStep={pageId === nPages - 1}
        disablePrevious={disablePreviousButton}
        disableNext={disableNextButton}
        disableFinish={disableFinishButton}
        disableCancel={disableCancelButton}
        disableSave={disableSaveButton}
        
        hideCancel={hideCancelButton}
        hideSave={hideSaveButton}
        hidePrevious={pageId === 0 ||hidePreviousButton}
        hideNext={hideNextButton}
        hideFinish={hideFinishButton}
        
        labelCancel={labelCancelButton}
        labelSave={labelSaveButton}
        labelPrevious={labelPreviousButton}
        labelNext={labelNextButton}
        labelFinish={labelFinishButton}
        labelFinished={labelFinishedButton}
        
        onNextClick={() => {
          
          if(pageId+1 < nPages) {
            setVisibleSteps(getVisibleSteps(Titles[pageId+1]));
            setPageChunkId(getPageChunkId());
          }
          
          if(onNextPage) {
            onNextPage(pageId + 1, pageId);
          } 
        }}
        onPreviousClick={() => {
          
          if(pageId-1 >= 0) {
            setVisibleSteps(getVisibleSteps(Titles[pageId-1]));
          }

          if(onPreviousPage && pageId - 1 >= 0 ) {
            onPreviousPage(pageId - 1);
          }
        }}
        onFinishClick={() => {
          if(onFinish) {
            onFinish();
          }
        }}
        onCancelClick={() => {
          if(onCancel !== undefined) {
            onCancel();
          }
        }}
        onSaveClick={() => {
          if(onSave !== undefined) {
            onSave();
          }
        }}
      />
    </div>
  );
};

export default Wizard;
