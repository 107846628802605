import { useMemo } from "react";

import { LOCALHOST_PORT, AZURE_PATH } from "../domain/constants";
import { AppSettings } from "./app-settings";

type BaseUrls = {
  scopingBaseUrl: string;
};

export const useBaseUrls = ({
  applicationLinkBaseUri
}: AppSettings): BaseUrls => {
  const scopingBaseUrl = useMemo<string>(() => {
    if (!applicationLinkBaseUri) return "";

    const localhostRegex = /\/localhost/;
    const urlIsLocalhost = !!applicationLinkBaseUri.match(localhostRegex)?.[0];

    return urlIsLocalhost
      ? `${applicationLinkBaseUri}:${LOCALHOST_PORT}`
      : `${applicationLinkBaseUri}/${AZURE_PATH}`;
  }, [applicationLinkBaseUri]);

  return {
    scopingBaseUrl
  };
};
