import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";

import { Tile } from "@react-gcc-eds/core";
import { useHistory } from "react-router";
import { ScanResult } from "scandit-sdk";

import { LayoutContext } from "../../../contexts/layout-context";
import {
  GeneralKeys,
  MenuTitleKeys,
  NamespaceKeys,
  ScannerSpecificKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";
import SerialNumberScannerComponent from "../../common/serial-number-scanner/serial-number-scanner-component";
import ReceiveMaterialSampleBarcode from "./receive-material-sample-barcode";
import ScannerInputTextField from "./scanner-input-textfield";

import "./receive-material-overview-component.scss";

const ReceiveMaterialOverview = (): React.ReactElement => {
  const { setPageTitle } = useContext(LayoutContext);
  const { translate } = useTranslation();

  const history = useHistory();

  useEffect(() => {
    setPageTitle &&
      setPageTitle({
        title: translate(
          NamespaceKeys.MenuTitles,
          MenuTitleKeys.ReceiveMaterialOverviewTitle
        )
      });
  }, [translate, setPageTitle]);

  const [showScanner, setShowScanner] = useState(false);
  const [showSampleBarcode, setShowSampleBarcode] = useState(false);

  const onClickCloseButton = useCallback(() => {
    setShowSampleBarcode(false);
  }, []);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageBoxRef = useRef<HTMLDivElement>(null);
  const scanResult = useRef<ScanResult | undefined>(undefined);
  const [barCode, setBarCode] = useState("");

  useEffect(() => {
    if (scanResult.current && canvasRef.current) {
      const { imageData, imageSettings } = scanResult.current;

      const newCanvas = document.createElement("canvas");

      newCanvas.width = imageSettings.width;
      newCanvas.height = imageSettings.height;

      const clampedImageArray = new Uint8ClampedArray(imageData.buffer);

      const imgData = new ImageData(
        clampedImageArray,
        newCanvas.width,
        newCanvas.height
      );

      newCanvas.getContext("2d")?.putImageData(imgData, 0, 0);

      const canvas = canvasRef.current;
      canvas.width = imageBoxRef.current?.clientWidth ?? 0;
      canvas.height = imageBoxRef.current?.clientHeight ?? 0;
      const ctx = canvas.getContext("2d");
      const finalWidth =
        canvas.width * Math.ceil(canvas.height / newCanvas?.height);

      const x = (canvas.width - finalWidth) / 2;

      ctx?.drawImage(newCanvas, x, 0, finalWidth, canvas.height);
    }
  }, [showScanner, showSampleBarcode]);

  useLayoutEffect(() => {
    if (imageBoxRef.current && scanResult.current) {
      imageBoxRef.current.style.border = "none";
    }
  }, [showScanner, showSampleBarcode]);

  const onChangeBarCodeInput = useCallback((value: string) => {
    setBarCode(value);
  }, []);

  return (
    <>
      {showSampleBarcode ? (
        <ReceiveMaterialSampleBarcode onClose={onClickCloseButton} />
      ) : (
        <div className="receive-scanner-wrapper">
          {showScanner && (
            <SerialNumberScannerComponent
              title={""}
              onClose={function (): void {
                setShowScanner(false);
              }}
              onScanRawResult={(rawResult) => {
                scanResult.current = rawResult;
                setBarCode(rawResult.barcodes[0].data);
                setShowScanner(false);
              }}
            />
          )}
          <div className="scanner-box">
            <div
              ref={imageBoxRef}
              className="scanner-box--image"
              onClick={(ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                ev.stopPropagation();
                const ctx = canvasRef.current?.getContext("2d");
                ctx?.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                setBarCode("");
                scanResult.current = undefined;
                setShowScanner(true);
              }}
            >
              <canvas ref={canvasRef} />
            </div>
            <h3 className="scanner-box--text">
              {translate(
                NamespaceKeys.ScannerSpecific,
                ScannerSpecificKeys.ScanShipmentfromPackingSlip
              )}
            </h3>
            <p className="scanner-box--helper-text">
              {translate(
                NamespaceKeys.ScannerSpecific,
                ScannerSpecificKeys.ClickHereToSeeExampleOf
              )}
            </p>
            <a onClick={() => setShowSampleBarcode(true)}>
              {translate(
                NamespaceKeys.ScannerSpecific,
                ScannerSpecificKeys.ShipmentReference
              )}
            </a>
          </div>
          <div className="receive-scanner-spacing"></div>

          <div className="display-flex">
            <Tile className="scanner-box__tile">
              <div className="scanner-box__tile--text padding--15px">
                {translate(
                  NamespaceKeys.ScannerSpecific,
                  ScannerSpecificKeys.OrEnterShipmentReferenceNumber
                )}
              </div>
              <ScannerInputTextField
                onClick={() => {
                  history.push(`/receivematerial/${barCode}`);
                }}
                value={barCode}
                disabled={barCode === ""}
                textFieldClass="scanner-box__tile--text-input"
                btnClass="btn--rectangle scanner-box__tile--btn-small"
                placeholder={translate(
                  NamespaceKeys.ScannerSpecific,
                  ScannerSpecificKeys.ShipmentReferenceNumber
                )}
                buttonLabel={translate(NamespaceKeys.General, GeneralKeys.Ok)}
                onChange={onChangeBarCodeInput}
              />
            </Tile>
          </div>
        </div>
      )}
    </>
  );
};

export default ReceiveMaterialOverview;
