import React, { useState } from "react";
import cx from "classnames";
import TileActionButton from "./TileActionButton";
import ITile from "./ITile";

const Tile = ({
  type = "tile",
  noMargin,
  title,
  subtitle,
  children,
  bottom,
  actions,
  leftActions,
  contentClassName,
  bottomClassName,
  canMaximize,
  onMaximize,
  sm = 12,
  md = 12,
  lg = 12,
  className,
  contentRef,
  dropdownMenu
}: ITile) => {
  const [isFullscreen, setFullScreen] = useState(false);
  return (
    <div
      className={cx(
        type,
        {
          fullscreen: isFullscreen,
          [`sm-${sm}`]: sm,
          [`md-${md}`]: md,
          [`lg-${lg}`]: lg
        },
        className
      )}
    >
      {(title || subtitle || leftActions || actions || canMaximize) && (
        <div className="header">
          <div className="left">
            {leftActions && (
              <>
                {leftActions}
                <span className="separator" />
              </>
            )}
            {title && <span className="title">{title}</span>}
            {subtitle && <span className="subtitle">{subtitle}</span>}
          </div>
          <div className="right">
            {actions}
            {canMaximize && (
              <TileActionButton
                key="act_maximize"
                tooltipText={isFullscreen ? "Minimize" : "Maximize"}
                onClick={() => {
                  if (onMaximize) {
                    onMaximize(!isFullscreen);
                  }
                  setFullScreen(!isFullscreen);
                }}
                iconName={isFullscreen ? "minimize" : "maximize"}
              />
            )}
            {dropdownMenu}
          </div>
        </div>
      )}

      {children && (
        <div
          ref={contentRef}
          className={cx("content", { "no-margins": noMargin }, contentClassName)}
        >
          {children}
        </div>
      )}

      {bottom && <div className={cx("bottom", bottomClassName)}>{bottom}</div>}
    </div>
  );
};

export default Tile;
