(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"));
	else if(typeof define === 'function' && define.amd)
		define("@react-gcc-eds/core", ["React"], factory);
	else if(typeof exports === 'object')
		exports["@react-gcc-eds/core"] = factory(require("react"));
	else
		root["@react-gcc-eds/core"] = factory(root["React"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__) {
return 