import React, { useCallback, useContext, useEffect, useState } from "react";

import { Tile, Row, Loader } from "@react-gcc-eds/core";

import { AuthenticationContext } from "../../../../contexts/authentication-context";
import { CustomerContext } from "../../../../contexts/customer-context";
import {
  SiteProjectContactsViewModel,
  SiteProjectsClient
} from "../../../../domain/client-customer";
import {
  ComponentSpecificKeys,
  NamespaceKeys,
  GeneralKeys,
  ActivitySpecificKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import CenteredContainer from "../../../common/layout/centered-container";
import TitleValuePair from "../../../common/layout/title-value-pair";
import UserInformationButton from "../../../common/user-information-button";

import "./contacts-tile.scss";

type Props = {
  siteProjectId: string;
};
export const ContactsTileComponent = ({
  siteProjectId
}: Props): JSX.Element => {
  const { currentCustomer } = useContext(CustomerContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [contacts, setContacts] = useState<SiteProjectContactsViewModel>();
  const [onError, setOnError] = useState<boolean>(false);
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const { translate } = useTranslation();

  const getSiteProjectContacts = useCallback(async () => {
    const customerIdentifier =
      currentCustomer && currentCustomer.HeaderIdentifier;
    if (!customerIdentifier) {
      return;
    }
    setLoading(true);
    new SiteProjectsClient(await getCustomerConfigurationProvider())
      .getContactsForSiteProject(siteProjectId, customerIdentifier)
      .then((fetchedContacts) => {
        setContacts(fetchedContacts);
      })
      .catch(() => {
        setOnError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentCustomer, getCustomerConfigurationProvider, siteProjectId]);

  useEffect(() => {
    getSiteProjectContacts();
  }, [getSiteProjectContacts]);

  return (
    <Row>
      {onError ? (
        <Tile
          title={translate(
            NamespaceKeys.ComponentSpecific,
            ComponentSpecificKeys.SiteProjectContactErrorMessageTitle
          )}
        >
          {translate(NamespaceKeys.General, GeneralKeys.PleaseTryAgainLater)}
        </Tile>
      ) : (
        <Tile
          title={translate(
            NamespaceKeys.ComponentSpecific,
            ComponentSpecificKeys.Contacts
          )}
          className="site-project-contacts-tile"
        >
          {loading ? (
            <CenteredContainer>
              <Loader size="medium" />
            </CenteredContainer>
          ) : (
            <>
              <TitleValuePair
                title={translate(
                  NamespaceKeys.ActivitySpecific,
                  ActivitySpecificKeys.LeadConstructionManager
                )}
                value={
                  (contacts && contacts.LeadConstructionManager && (
                    <UserInformationButton
                      user={contacts.LeadConstructionManager}
                    />
                  )) ||
                  translate(
                    NamespaceKeys.ActivitySpecific,
                    ActivitySpecificKeys.NotAssigned
                  )
                }
              />
              <TitleValuePair
                title={translate(
                  NamespaceKeys.ActivitySpecific,
                  ActivitySpecificKeys.FieldConstructionManager
                )}
                value={
                  (contacts && contacts.FieldConstructionManager && (
                    <UserInformationButton
                      user={contacts.FieldConstructionManager}
                    />
                  )) ||
                  translate(
                    NamespaceKeys.ActivitySpecific,
                    ActivitySpecificKeys.NotAssigned
                  )
                }
              />
            </>
          )}
        </Tile>
      )}
    </Row>
  );
};

export default ContactsTileComponent;
