import React, { useState } from "react";
import cx from "classnames";
import Icon from "../icon/Icon";
import ITreeNode from "./ITreeNode";

const TreeNode = ({ title, children, icon, customComponent, onClick, className, defaultExpanded = false, disabled }: ITreeNode) => {
  const [expanded, setExpanded] = useState(defaultExpanded);
  
  const defaultItem = (
    <span>
      {icon && typeof icon === "string" ? <Icon name={icon} small base /> : icon}
      {title}
    </span>
  )

  const item = customComponent !== undefined ? customComponent : defaultItem;
  
  return (
    <li>
      <span
        role="presentation"
        className={cx("item title", { opened: expanded, disabled }, className)}
        onKeyPress={() => setExpanded(!expanded)}
        onClick={() => {
          setExpanded(!expanded);
          if(onClick !== undefined){
            onClick(!expanded);
          }
        }}
      >
        {item}
      </span>
      <ul>{children}</ul>
    </li>
  );
};

export default TreeNode;
