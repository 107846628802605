/**
 * @hidden
 */
export class CustomError extends Error {
  // istanbul ignore next
  constructor({
    name = "",
    message = "",
  }: {
    name?: string;
    message?: string;
  } = {}) {
    super(message);
    Object.setPrototypeOf(this, CustomError.prototype);
    this.name = name;
  }
}
