import React from "react";
import cx from "classnames";
import Icon from "../icon/Icon";
import IPill from "./IPill";

const Pill = ({
  icon,
  severity,
  disabled,
  onToggle,
  toggled,
  onRemove,
  onMouseMove,
  onMouseLeave,
  children,
  className,
  style
}: IPill) => {
  const withIcon = typeof icon === "string" ? <Icon name={icon} /> : icon;
  const severityClass = severity ? `severity-${severity}` : null;

  const clName = cx(
    "pill",
    severityClass,
    { toggle: onToggle },
    { unselected: toggled && onToggle },
    { removable: onRemove },
    { disabled },
    className
  );

  const onClickHandler = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (onRemove) {
      onRemove(e);
    }
    if (onToggle) {
      onToggle(!toggled);
    }
  };

  return (
    <span
      role="presentation"
      className={clName}
      style={style}
      onClick={onClickHandler}
      onMouseMove={onMouseMove !== undefined ? onMouseMove : undefined}
      onMouseLeave={onMouseLeave !== undefined ? onMouseLeave : undefined}
    >
      {withIcon}
      {children}
    </span>
  );
};

export default Pill;
