import React from "react";
import cx from "classnames";
import IPagination from "./IPagination";

const Pagination = ({
  pages,
  start,
  end,
  activePage = 1,
  onLeftClick,
  onRightClick,
  onPageClick,
  onUpClick,
  onDownClick,
  vertical,
  reverse,
  className,
  disabled
}: IPagination) => {
  const nPages = pages !== undefined ? pages : 0;
  const firstPage = start !== undefined ? start : 1;
  const lastPage = end !== undefined ? end : nPages;

  /** Listeners */
  const handleUpClick = () => {
    if (activePage && activePage < lastPage && onUpClick && !reverse) {
      onUpClick(activePage + 1);
    }
    if (activePage && activePage > firstPage && onUpClick && reverse) {
      onUpClick(activePage - 1);
    }
  };

  const handleDownClick = () => {
    if (activePage && activePage > firstPage && onDownClick && !reverse) {
      onDownClick(activePage - 1);
    }
    if (activePage && activePage < lastPage && onDownClick && reverse) {
      onDownClick(activePage + 1);
    }
  };

  const handleLeftClick = () => {
    if (activePage && activePage > firstPage && onLeftClick) {
      onLeftClick(activePage - 1);
    }
  };

  const handleRightClick = () => {
    if (activePage && activePage < lastPage && onRightClick) {
      onRightClick(activePage + 1);
    }
  };

  const handlePageClick = (clickedPage: number) => {
    onPageClick(clickedPage);
  };

  /** Components */
  const ellipsis = () => <li className="ellipsis"key={Math.random() * 100}/>;
  const page = (index: number, active: boolean) => {
    return (
      <li key={index} className={cx("pageItem",{ active })} role="presentation" >
        <span className="pageItemClickable" role="presentation" onClick={() => handlePageClick(index)}>
          {index}
        </span>
      </li>
    );
  };

  /* Pagination including negative values */
  const data = [];
  for (let i = firstPage - 1; i < lastPage; i += 1) {
    data.push(i + 1);
  }
  const activeIndex = data.indexOf(activePage);

  const paginationArray = [];
  const willNotUseEllipsis = data.length <= 7;

  if (willNotUseEllipsis) {
    for (let i = 0; i < data.length; i += 1) {
      paginationArray.push(page(data[i], i === activeIndex));
    }
  } else if (activeIndex < 4) {
    for (let i = 0; i < data.length; i += 1) {
      if (i === 5) {
        paginationArray.push(ellipsis());
        paginationArray.push(page(lastPage, i === activeIndex));
        break;
      }
      paginationArray.push(page(data[i], i === activeIndex));
    }
  } else if (activeIndex < data.indexOf(lastPage) - 3) {
    paginationArray.push(page(firstPage, false));
    paginationArray.push(ellipsis());

    for (let i = activeIndex - 1; i <= activeIndex + 1; i += 1) {
      paginationArray.push(page(data[i], i === activeIndex));
    }

    paginationArray.push(ellipsis());
    paginationArray.push(page(lastPage, false));
  } else {
    paginationArray.push(page(firstPage, false));
    paginationArray.push(ellipsis());
    for (let i = data.indexOf(lastPage) - 4; i <= data.indexOf(lastPage); i += 1) {
      paginationArray.push(page(data[i], i === activeIndex));
    }
  }

  /** Arrows */
  const arrowDownDisabled =
    (activePage <= firstPage && !reverse) || (activePage >= lastPage && reverse);
  const arrowUpDisabled =
    (activePage >= lastPage && !reverse) || (activePage <= firstPage && reverse);

  const arrowDown = (
    <li
      role="presentation"
      className={cx("arrowDown",{ disabled: arrowDownDisabled })}
    >
      <span className="arrowDownClickable" role="presentation" onClick={() => (!arrowDownDisabled ? handleDownClick() : null)}>
        <i className="icon icon-arrow-down" />
      </span>
    </li>
  );

  const arrowUp = (
    <li
      role="presentation"
      className={cx("arrowUp",{ disabled: arrowUpDisabled })}
    >
      <span
      className="arrowUpClickable" role="presentation" onClick={() => (!arrowUpDisabled ? handleUpClick() : null)}>
        <i className="icon icon-arrow-up" />
      </span>
    </li>
  );

  const verticalPagination = vertical ? (
    <div className={cx("pagination-group vertical",{disabled}, className)}>
      <ul className={cx("pagination", { reverse })}>
        {reverse ? arrowUp : arrowDown}

        {paginationArray}

        {reverse ? arrowDown : arrowUp}
      </ul>
    </div>
  ) : null;

  const defaultPagination = (
    <div className={cx("pagination-group",{disabled}, className)}>
      <ul className="pagination">
        <li
          role="presentation"
          onClick={handleLeftClick}
          className={cx("arrowLeft",{ disabled: activePage <= firstPage })}
        >
          <span
          className="arrowLeftClickable" role="presentation" onClick={handleLeftClick}>
            <i className="icon icon-arrow-left" />
          </span>
        </li>

        {paginationArray}
        <li
          role="presentation"
          className={cx("arrowRight",{ disabled: activePage >= lastPage })}
        >
          <span className="arrowRightClickable" role="presentation" onClick={handleRightClick}>
            <i className="icon icon-arrow-right" />
          </span>
        </li>
      </ul>
    </div>
  );

  return !vertical ? defaultPagination : verticalPagination;
};

export default Pagination;
