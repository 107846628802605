import React from "react";
import cx from "classnames";
import Icon from "../icon/Icon";
import IButton from "./IButton";

const Button = ({
  primary,
  warning,
  disabled,
  href,
  iconName,
  onClick,
  big,
  children,
  loading = false,
  className,
  style,
  submit = false
}: IButton) => {
  const emptyChildren = children === "" || children === null || children === undefined;

  const clName = cx("btn", { big }, { primary }, { warning }, className);
  const withIcon = iconName ? <Icon name={cx(iconName, { notext: emptyChildren })} /> : null;
  const buttonType = submit ? "submit" : "button";

  if (loading) {
    return <button type={buttonType} className={cx("btn", { loading }, className)}></button>;
  }
  return href ? (
    <a className={clName} href={href}>
      {withIcon}
      {children}
    </a>
  ) : (
    <button type={buttonType} className={clName} style={style} disabled={disabled} onClick={onClick}>
      {withIcon}
      {!emptyChildren ? children : null}
    </button>
  );
};

export default Button;
