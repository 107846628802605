import React from "react";
import cx from "classnames";
import { ISettingsNode } from "./contracts";


const SettingsNode = ({ title, children, className }: ISettingsNode) => {
  // <div className={cx("title", className)}>
  return (
    <>
      <div className={cx("title", className)}>
        {title}
      </div>
      {children}
    </>
  );
};
export default SettingsNode;
