import React, { useCallback, useState, useContext, useEffect } from "react";

import { Loader, Tile, Row } from "@react-gcc-eds/core";

import { AuthenticationContext } from "../../contexts/authentication-context";
import { CustomerContext } from "../../contexts/customer-context";
import { LayoutContext } from "../../contexts/layout-context";
import { ReleaseViewModel, UtilityClient } from "../../domain/client-customer";
import {
  ComponentSpecificKeys,
  NamespaceKeys,
  GeneralKeys,
  MenuTitleKeys
} from "../../translation/dictionary-keys";
import { useTranslation } from "../../translation/translation-utils";
import CenteredContainer from "../common/layout/centered-container";
import ReleaseNotesItem from "./release-notes-item";

const ReleaseNotes: React.FC = () => {
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const { currentCustomer } = useContext(CustomerContext);
  const [releaseNotes, setReleaseNotes] = useState<ReleaseViewModel[]>();
  const [loading, setLoading] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);
  const { setPageTitle } = useContext(LayoutContext);
  const { translate } = useTranslation();

  const getReleaseNotes = useCallback(async () => {
    const customerIdentifier =
      currentCustomer && currentCustomer.HeaderIdentifier;
    if (!customerIdentifier) {
      setError(true);
      return;
    }

    setLoading((prev) => prev + 1);
    new UtilityClient(await getCustomerConfigurationProvider())
      .getReleaseNotes("mobile", customerIdentifier)
      .then((notes) => {
        setReleaseNotes(notes);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setLoading((prev) => prev - 1));
  }, [currentCustomer, getCustomerConfigurationProvider]);

  useEffect(() => {
    getReleaseNotes();
  }, [getReleaseNotes]);

  useEffect(() => {
    setPageTitle &&
      setPageTitle({
        title: translate(
          NamespaceKeys.MenuTitles,
          MenuTitleKeys.ReleaseNotesMenuTitle
        )
      });
  }, [translate, setPageTitle]);

  return (
    (loading > 0 && (
      <CenteredContainer>
        <Loader size="large" />
      </CenteredContainer>
    )) || (
      <div>
        {error ? (
          <Tile
            title={translate(
              NamespaceKeys.ComponentSpecific,
              ComponentSpecificKeys.ReleaseNotesErrorMessageTitle
            )}
          >
            {translate(NamespaceKeys.General, GeneralKeys.PleaseTryAgainLater)}
          </Tile>
        ) : (
          releaseNotes &&
          releaseNotes.map((r, i) => (
            <Row key={i}>
              <ReleaseNotesItem releaseSection={r} />
            </Row>
          ))
        )}
      </div>
    )
  );
};
export default ReleaseNotes;
