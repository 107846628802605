import React from "react";
import cx from "classnames";
import { uniqueId } from "../../utils/utils";
import * as dateHelper from "./helpers";
import Icon from "../icon/Icon";

const CalendarBodyContent = (
  visibleDate: Date,
  onDaySelected: (date: Date) => void,
  selectedDate?: Date
) => {
  const numOfDaysInMonth = dateHelper.daysInMonth(visibleDate);
  const firstDayIndex = new Date(visibleDate.getFullYear(), visibleDate.getMonth(), 1).getDay() - 1;
  const result = [];
  let day = 1;
  const row = (from: number, to: number) => {
    const tds = [];
    for (let x = from; x <= to; x += 1) {
      if (x > firstDayIndex && day <= numOfDaysInMonth) {
        const fixedDate = new Date(visibleDate.getFullYear(), visibleDate.getMonth(), day);

        tds.push(
          <td
            key={uniqueId()}
            role="gridcell"
            className={cx({ selected: dateHelper.isEqual(selectedDate, fixedDate) })}
            onClick={() => onDaySelected(fixedDate)}
          >
            {dateHelper.isEqual(new Date(), fixedDate) ? <span className="today">{day}</span> : day}
          </td>
        );
        day += 1;
      } else {
        tds.push(<td key={x} />);
      }
    }
    return <tr key={uniqueId()}>{tds}</tr>;
  };
  result.push(row(1, 7));
  result.push(row(8, 14));
  result.push(row(15, 21));
  result.push(row(22, 28));
  result.push(row(29, 35));
  return result;
};

const DateCalendar = ({
  visible,
  visibleDate,
  selectedDate,
  onVisibleDateChanged,
  onDateSelected,
  className,
  disabled
}: {
  visible: boolean;
  visibleDate: Date;
  selectedDate?: Date;
  onVisibleDateChanged: (date: Date) => void;
  onDateSelected: (date: Date) => void;
  className?: string | string[];
  disabled?: boolean;
}) => {
  return (
    <div className={cx("calendar", { closed: !visible, disabled }, className)}>
      <div className="head">
        <Icon
          name="chevron-left"
          onClick={() => onVisibleDateChanged(dateHelper.changeDate(visibleDate, -1))}
        />
        <span className="month">{dateHelper.getMonth(visibleDate)}</span>
        <Icon
          name="chevron-right"
          onClick={() => onVisibleDateChanged(dateHelper.changeDate(visibleDate, 1))}
        />

        <Icon
          name="chevron-left"
          onClick={() => onVisibleDateChanged(dateHelper.changeDate(visibleDate, -12))}
        />
        <span className="year">{dateHelper.getYear(visibleDate)}</span>
        <Icon
          name="chevron-right"
          onClick={() => onVisibleDateChanged(dateHelper.changeDate(visibleDate, 12))}
        />
      </div>
      <table className="body">
        <thead>
          <tr>
            <th>Mo</th>
            <th>Tu</th>
            <th>We</th>
            <th>Th</th>
            <th>Fr</th>
            <th>Sa</th>
            <th>Su</th>
          </tr>
        </thead>
        <tbody>{CalendarBodyContent(visibleDate, onDateSelected, selectedDate)}</tbody>
      </table>
    </div>
  );
};
export default DateCalendar;
