import React, { useState } from "react";
import cx from "classnames";
import { useClickOutside } from "../../utils/utils";
import IDropdownMenuItem from "./IDropdownMenuItem";
import IDropdownMenu from "./IDropdownMenu";

const DropdownMenu = ({
  menuItems,
  onChange,
  className,
  onClick,
  disabled
}: IDropdownMenu) => {
  const handleMenuClick = (index: number) => {
    if (onChange !== undefined) {
      onChange(menuItems[index]);
    }
  };

  const [visible, setVisible] = useState(false);

  const closeDropdown = () => setVisible(false);

  const containerRef = useClickOutside(closeDropdown);
  return (
    <div
      ref={containerRef}
      role="presentation"
      className={cx("dropdown more", { open: visible, disabled }, className)}
      data-type="click"
      onClick={() => {
        if (onClick) {
          onClick(!visible);
        }
        setVisible(!visible);
      }}
    >
      <button type="button" className="btn clickable">
        <i className="icon icon-more" />
      </button>
      <div className={cx("menu", { visible })}>
        {menuItems.map((menuItem: IDropdownMenuItem, index: number) => {
          const keyIndex = index;

          return !menuItem.separator ? (
            <div
              key={keyIndex}
              role="presentation"
              onClick={() => (!menuItem.disabled ? handleMenuClick(index) : null)}
              className={cx(
                "item",
                { "space-between": menuItem.spacebetween },
                { disabled: menuItem.disabled }
              )}
            >
              {menuItem.item}
            </div>
          ) : (
            <hr key={keyIndex} />
          );
        })}
      </div>
    </div>
  );
};

export default DropdownMenu;
