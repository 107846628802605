import React, { PropsWithChildren, useMemo, ReactNode } from "react";

import { SingleItemComponent, SingleItemProps } from "../types";
import ChecklistItemComment from "./checklist-item-comment";
import ChecklistItemHeader from "./checklist-item-header";
import ChecklistItemImage from "./checklist-item-image";

const ChecklistStandardItem: SingleItemComponent = ({
  item,
  children,
  onValueChanged
}: PropsWithChildren<SingleItemProps>) => {
  const comment = useMemo<ReactNode>(
    () =>
      item.Commentable ? (
        <ChecklistItemComment item={item} onValueChanged={onValueChanged} />
      ) : null,
    [item, onValueChanged]
  );
  const image = useMemo<ReactNode>(
    () =>
      item.SupportsImage ? (
        <ChecklistItemImage item={item} onValueChanged={onValueChanged} />
      ) : null,
    [item, onValueChanged]
  );

  return (
    <>
      <ChecklistItemHeader item={item} />
      {children}
      {comment}
      {image}
    </>
  );
};

export default ChecklistStandardItem;
