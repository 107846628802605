import React from "react";
import cx from "classnames";
import { uniqueId } from "../../utils/utils";
import IRadioButton from "./IRadioButton";

const Radiobutton = ({
  text,
  value,
  disabled,
  onChange,
  group,
  className
}: IRadioButton) => {
  const id = uniqueId();
  return (
    <>
      <input
        className={cx(className)}
        disabled={disabled}
        onChange={() => !disabled && onChange(!value)}
        id={id}
        name={group || ""}
        type="radio"
        checked={value}
      />
      <label className={cx(className)} htmlFor={id}>
        {text}
      </label>
    </>
  );
};
export default Radiobutton;
