import React, { useCallback, useContext, useState, useEffect } from "react";

import { AuthenticationContext } from "../../../contexts/authentication-context";
import { CustomerContext } from "../../../contexts/customer-context";
import {
  MaterialTransferOverviewViewModel,
  MaterialTransfersClient
} from "../../../domain/client-customer";
import {
  NamespaceKeys,
  ComponentSpecificKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";
import MaterialTransferOverviewView from "./material-transfer-overview-view";

const MaterialTransferOverviewComponent = (): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(true);
  const [errorState, setErrorState] = useState<string>();
  const [items, setItems] = useState<MaterialTransferOverviewViewModel[]>();
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const { currentCustomer } = useContext(CustomerContext);
  const { translate } = useTranslation();

  const getMaterialTransferOverviewViewModel = useCallback(async () => {
    setLoading(true);
    const customerIdentifier =
      currentCustomer && currentCustomer.HeaderIdentifier;
    if (!customerIdentifier) {
      setErrorState(
        translate(
          NamespaceKeys.ComponentSpecific,
          ComponentSpecificKeys.AnErrorOccurredPleaseTryAgain
        )
      );
      setLoading(false);
      return;
    }
    new MaterialTransfersClient(await getCustomerConfigurationProvider())
      .getMaterialTransfers(customerIdentifier)
      .then(setItems)
      .catch(() => {
        setErrorState(
          translate(
            NamespaceKeys.ComponentSpecific,
            ComponentSpecificKeys.AnErrorOccurredPleaseTryAgain
          )
        );
      })
      .finally(() => setLoading(false));
  }, [currentCustomer, translate, getCustomerConfigurationProvider]);

  useEffect(() => {
    if (items) return;
    getMaterialTransferOverviewViewModel();
  }, [getMaterialTransferOverviewViewModel, items]);

  const handleOnRetry = useCallback(() => {
    setErrorState(undefined);
    getMaterialTransferOverviewViewModel();
  }, [getMaterialTransferOverviewViewModel]);

  return (
    <MaterialTransferOverviewView
      error={errorState}
      loading={loading}
      items={items ?? []}
      onRetry={handleOnRetry}
    />
  );
};

export default MaterialTransferOverviewComponent;
