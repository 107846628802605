import React, { useCallback } from "react";

import { ChecklistItemImageEditModel } from "../../../checklist-models";
import ImageDetailsDialog from "./image-details-dialog";

export type ImageDetailsProps<T> = {
  image: T;
  onDelete: (image: T) => void;
  onRotateCW: (image: T) => void;
  onClose: () => void;
};

const UnsavedImageDetailsDialog = ({
  image,
  onDelete,
  onClose,
  onRotateCW
}: ImageDetailsProps<ChecklistItemImageEditModel>): React.ReactElement => {
  const handleOnDelete = useCallback(() => {
    onDelete(image);
  }, [onDelete, image]);

  const handleOnRotateCW = useCallback(async () => {
    onRotateCW(image);
  }, [onRotateCW, image]);

  return (
    <ImageDetailsDialog
      imageSrc={image.hostedUrl}
      onClose={onClose}
      onDelete={handleOnDelete}
      onRotateCW={handleOnRotateCW}
    />
  );
};

export default UnsavedImageDetailsDialog;
