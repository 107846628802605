import React, { useContext } from "react";

import { Button, Column, Row } from "@react-gcc-eds/core";
import { useHistory, useParams } from "react-router";

import UrlParameters from "../../../../domain/enums/url-parameters";
import {
  GeneralKeys,
  NamespaceKeys,
  ReceivingMaterialSpecificKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import { ReceiveMaterialContext } from "../contexts/receive-material-context";
import { SelectableMaterialTransferOverviewViewModel } from "../shared/receive-material-models";
import useDeviationSummaries from "../shared/use-deviation-summaries";
import ReceiveMaterialValidationSummaryItem from "./receive-material-validation-summary-item";

import "./receive-material-validation-summary.scss";

type MatchProps = {
  [UrlParameters.shipmentReferenceNumber]?: string;
};

const ReceiveMaterialValidationSummary = (): React.ReactElement => {
  const { shipmentReferenceNumber } = useParams<MatchProps>();
  const history = useHistory();
  const { translate } = useTranslation();
  const handleOnClickClose = (): void => {
    history.replace("/receivematerial");
  };

  const { transfersOverviewStateItems } = useContext(ReceiveMaterialContext);

  const { totalMissing, totalDamaged } = useDeviationSummaries();

  const selectedTransfersOverviewStateItems =
    React.useMemo((): SelectableMaterialTransferOverviewViewModel[] => {
      return transfersOverviewStateItems.filter((x) => x.Selected);
    }, [transfersOverviewStateItems]);

  const summary = translate(
    NamespaceKeys.ReceivingMaterialSpecific,
    ReceivingMaterialSpecificKeys.Summary
  );

  const numberOfSiteProjects = translate(
    NamespaceKeys.ReceivingMaterialSpecific,
    ReceivingMaterialSpecificKeys.NumberOfSiteProjects
  );

  const totalQtyReceived = translate(
    NamespaceKeys.ReceivingMaterialSpecific,
    ReceivingMaterialSpecificKeys.TotalQtyReceived
  );
  const handlingUnitReceived = translate(
    NamespaceKeys.ReceivingMaterialSpecific,
    ReceivingMaterialSpecificKeys.HandlingUnitReceived
  );

  const itemsReceived = translate(
    NamespaceKeys.ReceivingMaterialSpecific,
    ReceivingMaterialSpecificKeys.ItemsReceived
  );

  const damage = translate(
    NamespaceKeys.ReceivingMaterialSpecific,
    ReceivingMaterialSpecificKeys.Damage
  );

  const missing = translate(NamespaceKeys.General, GeneralKeys.Missing);
  const totalSiteProjects = React.useMemo((): number => {
    const siteProjectsIds = selectedTransfersOverviewStateItems.map(
      (o) => o.SiteProjectId
    );
    return selectedTransfersOverviewStateItems.filter(
      ({ SiteProjectId }, index) =>
        !siteProjectsIds.includes(SiteProjectId, index + 1)
    ).length;
  }, [selectedTransfersOverviewStateItems]);

  const totalItems = React.useMemo((): number => {
    return selectedTransfersOverviewStateItems
      .map((i) => i.NumberOfItems)
      .reduce((a, b) => a + b, 0);
  }, [selectedTransfersOverviewStateItems]);

  const totalQty = React.useMemo((): number => {
    return selectedTransfersOverviewStateItems
      .map((i) => i.TotalQuantity)
      .reduce((a, b) => a + b, 0);
  }, [selectedTransfersOverviewStateItems]);

  const totalHandlingUnit = React.useMemo((): number => {
    return selectedTransfersOverviewStateItems
      .map((i) => i.HandlingUnitCount)
      .reduce((a, b) => a + b, 0);
  }, [selectedTransfersOverviewStateItems]);

  const isSiteProjectsNotWithFullHandlingUnit = React.useMemo((): boolean => {
    return (
      selectedTransfersOverviewStateItems.filter(
        (f) => !f.IsWithFullHandlingUnit
      ).length > 0
    );
  }, [selectedTransfersOverviewStateItems]);

  return (
    <div className="rm-container">
      <div className="rm-header">
        {summary}: {shipmentReferenceNumber}
      </div>
      <div className="rm-body">
        <div className="rm-summary-list">
          <ReceiveMaterialValidationSummaryItem
            label={numberOfSiteProjects}
            value={totalSiteProjects?.toString()}
          />
          {!isSiteProjectsNotWithFullHandlingUnit && (
            <ReceiveMaterialValidationSummaryItem
              label={handlingUnitReceived}
              value={totalHandlingUnit?.toString()}
            />
          )}

          <ReceiveMaterialValidationSummaryItem
            label={itemsReceived}
            value={totalItems?.toString()}
          />

          <ReceiveMaterialValidationSummaryItem
            label={totalQtyReceived}
            value={totalQty?.toString()}
          />
          <ReceiveMaterialValidationSummaryItem
            label={damage}
            value={totalDamaged.toString()}
          />

          <ReceiveMaterialValidationSummaryItem
            label={missing}
            value={totalMissing.toString()}
          />
        </div>
      </div>
      <div className="rm-footer">
        <Row className="rm-row">
          <Column sm={12}>
            <Button onClick={handleOnClickClose}>
              {translate(NamespaceKeys.General, GeneralKeys.Close)}
            </Button>
          </Column>
        </Row>
      </div>
    </div>
  );
};

export default ReceiveMaterialValidationSummary;
