import React, { MouseEventHandler, KeyboardEventHandler, ReactElement } from "react";
import cx from "classnames";

import useAppContext from "./context/ContextHelpers";

import Icon from "../icon/Icon";
import AppBarItem from "./AppBarItem";
import AppBarAnchor from "./AppBarAnchor";

import * as Listeners from "../../utils/listeners";

const AppSysBar = ({
  applicationName,
  acronym,
  onBrandClick,
  onBrandKeyDown,
  appBarItems,
  iconName
}: {
  applicationName: string;
  acronym: string;
  onBrandClick?: MouseEventHandler;
  onBrandKeyDown?: KeyboardEventHandler;
  appBarItems?: ReactElement<typeof AppBarItem | typeof AppBarAnchor>[];
  iconName?: string;
}) => {
  const { user, settings, notifications } = useAppContext();

  const settingsIconPressed = async () => {
    await notifications.clearActive();

    if (notifications.visible) {
      notifications.toggle();
      notifications.clearNew();
    }

    settings.toggle();
    Listeners.toggleSettings();
  };

  const notificationIconPressed = () => {
    if (notifications.visible) {
      notifications.clearNew();
    } else {
      notifications.clearActive();
    }

    if (settings.visible) {
      settings.toggle();
    }

    notifications.toggle();
    Listeners.toggleNotifications();
  };

  const logIndicator = () => {
    return (
      <div
        role="button"
        onKeyDown={notificationIconPressed}
        onClick={notificationIconPressed}
        className={cx("item notification-log-trigger", { "has-notification": notifications.new })}
        tabIndex={0}
      >
        <span className="notification-log-number">{notifications.new || undefined}</span>
        <Icon name="notification" />
        <Icon name="notification-ring" />
      </div>
    );
  };

  const settingsToggleButton = () =>
    user && (
      <div className="item hover">
        <div
          className="btn-no-border"
          role="button"
          onKeyDown={settingsIconPressed}
          onClick={settingsIconPressed}
          tabIndex={0}
        >
          <Icon name={iconName || "profile"} />
          {user && (
            <span className="username">
              {`${user.firstName}${user.lastName ? ` ${user.lastName}` : ""}`}
            </span>
          )}
        </div>
      </div>
    );

  return (
    <header className="sysbar">
      <div className="items-container">
        <div
          className="item"
          role="link"
          onKeyDown={e => onBrandKeyDown && onBrandKeyDown(e)}
          onClick={onBrandClick}
          tabIndex={0}
          style={{ cursor: onBrandClick ? "pointer" : "default" }}
        >
          <Icon name="icon icon-econ" />
          <span className="product">{applicationName}</span>
          <span className="acronym">{acronym}</span>
        </div>
      </div>
      <div className="items-container">
        {appBarItems}
        {logIndicator()}
        {settingsToggleButton()}
      </div>
    </header>
  );
};
export default AppSysBar;
