import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo
} from "react";

import { ScanResult, ScanSettings } from "scandit-sdk";

import {
  MaterialTransferItemStatus,
  DplItemCustomerSerialNumberEnum
} from "../../../domain/client-customer";
import {
  NamespaceKeys,
  ScannerSpecificKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";
import { NonSerializedInstallationItemState } from "../../material-handling/material-installation/validation/material-installation-validation-component";
import {
  NonSerializedItemState,
  SerializedItemState
} from "../../material-handling/receive-material-overview/shared/receive-material-models";
import {
  RESULT_PROGRESS_UPDATE_INTERVAL,
  RESULT_PROGRESS_TIMER_LENGTH,
  FEEDBACK_TIMER_LENGTH
} from "../barcode-scanning/barcode-scanning-constants";
import { FeedbackMessage } from "../barcode-scanning/barcode-scanning-models/feedback-message";
import FeedbackStatus from "../barcode-scanning/barcode-scanning-models/feedback-status";
import { ScannerContext } from "../barcode-scanning/scanner-context";
import {
  ScanResultItem,
  toScanResultItem,
  getScanSettings
} from "../barcode-scanning/scanner-utils";
import useSearchArea from "../barcode-scanning/search-area-hook";
import SmartScannerView from "./smart-scanner-view";

export type ScannedItem = {
  serializedItem?: SerializedItemState;
  nonSerializedItem?: NonSerializedItemState;
  valid: boolean;
};

export type ValidTimer = {
  remaining: number;
  progress: number;
};

const validItemsCount = (
  nonSerializedItems: NonSerializedItemState[],
  serializedItems: SerializedItemState[]
): number =>
  nonSerializedItems.filter((i) => i.quantityValidated).length +
  serializedItems.filter((i) => i.quantityValidated).length;

type Props = {
  serializedItems: SerializedItemState[];
  nonSerializedItems: NonSerializedItemState[];
  onClose: () => void;
  onSerializedItemsUpdate: (updatedItems: SerializedItemState[]) => void;
  onNonSerializedItemUpdate: (
    updatedNonSerializedItem:
      | NonSerializedItemState
      | NonSerializedInstallationItemState
  ) => void;
};

const SmartScannerComponent = ({
  onClose,
  serializedItems,
  nonSerializedItems,
  onNonSerializedItemUpdate,
  onSerializedItemsUpdate
}: Props): JSX.Element => {
  const scannerContext = useContext(ScannerContext);

  const { translate } = useTranslation();
  const [searchArea] = useSearchArea();

  const [setupNeeded, setSetupNeeded] = useState<boolean>(true);
  const [htmlRef, setHtmlRef] = useState<HTMLDivElement>();
  const [scannedItem, setScannedItem] = useState<ScannedItem>();
  const [validItems, setValidItems] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [feedbackMessage, setFeedbackMessage] = useState<FeedbackMessage>();
  const [intervalData, setIntervalData] = useState<ValidTimer>();

  const scanSettings = useMemo<ScanSettings>(
    (): ScanSettings => getScanSettings(searchArea),
    [searchArea]
  );

  const pageTitle = useMemo<string>(
    (): string =>
      !scannedItem
        ? translate(
            NamespaceKeys.ScannerSpecific,
            ScannerSpecificKeys.TitleScanProductNumber
          )
        : scannedItem.valid
        ? translate(
            NamespaceKeys.ScannerSpecific,
            ScannerSpecificKeys.TitleProductValidated
          )
        : scannedItem.serializedItem
        ? !scannedItem.serializedItem.ericssonSerialNumber &&
          !scannedItem.serializedItem.customerSerialNumber
          ? translate(
              NamespaceKeys.ScannerSpecific,
              ScannerSpecificKeys.TitleScanSerialNumbers
            )
          : scannedItem.serializedItem.ericssonSerialNumber
          ? translate(
              NamespaceKeys.ScannerSpecific,
              ScannerSpecificKeys.TitleScanCustomerSerialNumber
            )
          : translate(
              NamespaceKeys.ScannerSpecific,
              ScannerSpecificKeys.TitleScanEricssonSerialNumber
            )
        : "",
    [scannedItem, translate]
  );

  const pageSubtitle = useMemo<string>(
    (): string =>
      translate(
        NamespaceKeys.ScannerSpecific,
        ScannerSpecificKeys.SubtitleValidationText,
        { validItems, totalItems }
      ),
    [totalItems, translate, validItems]
  );

  const refFn: (instance: HTMLDivElement | null) => void = useCallback(
    (node) => {
      if (node) {
        setHtmlRef(node);
      }
    },
    []
  );

  const updateSerializedItem = useCallback(
    (serializedItem: SerializedItemState): void => {
      onSerializedItemsUpdate([
        {
          ...serializedItem,
          status: serializedItem.status ?? MaterialTransferItemStatus.Ok,
          quantityValidated: true,
          serialNumbersValidated:
            (!!serializedItem.ericssonSerialNumber &&
              serializedItem.serialNumbersNeeded
                ?.TypeOfCustomerSerialNumberNeeded !==
                DplItemCustomerSerialNumberEnum.None &&
              !!serializedItem.customerSerialNumber) ||
            (!!serializedItem.ericssonSerialNumber &&
              serializedItem.serialNumbersNeeded
                ?.TypeOfCustomerSerialNumberNeeded ===
                DplItemCustomerSerialNumberEnum.None &&
              !serializedItem.customerSerialNumber)
        }
      ]);
    },
    [onSerializedItemsUpdate]
  );

  const updateNonSerializedItem = useCallback(
    (nonSerializedItem: NonSerializedItemState): void => {
      onNonSerializedItemUpdate({
        ...nonSerializedItem,
        validationQuantities: nonSerializedItem.validationQuantities ?? {
          Ok: nonSerializedItem.quantity,
          Damaged: 0,
          Missing: 0,
          Excess: 0,
          Incorrect: 0
        },
        quantityValidated: true
      });
    },
    [onNonSerializedItemUpdate]
  );

  const handleValidItem = useCallback(
    (scannedItem: ScannedItem): void => {
      if (scannedItem.nonSerializedItem) {
        updateNonSerializedItem(scannedItem.nonSerializedItem);
      } else if (scannedItem.serializedItem) {
        updateSerializedItem(scannedItem.serializedItem);
      }
    },
    [updateNonSerializedItem, updateSerializedItem]
  );

  useEffect(() => {
    if (!intervalData || !scannedItem) return;
    const interval = window.setInterval(() => {
      setIntervalData((prev) => {
        if (!prev) return;
        const newRemaining = prev.remaining - RESULT_PROGRESS_UPDATE_INTERVAL;
        if (newRemaining < 0) {
          handleValidItem(scannedItem);
          setScannedItem(undefined);
          setIntervalData(undefined);
          window.clearInterval(interval);
          return undefined;
        }
        return {
          remaining: newRemaining,
          progress:
            ((RESULT_PROGRESS_TIMER_LENGTH - newRemaining) /
              RESULT_PROGRESS_TIMER_LENGTH) *
            100
        };
      });
    }, RESULT_PROGRESS_UPDATE_INTERVAL);
    return (): void => window.clearInterval(interval);
  }, [handleValidItem, intervalData, scannedItem]);

  const clearFeedbackHandler = useCallback(() => {
    setFeedbackMessage((prev) => {
      if (prev) {
        window.clearTimeout(prev.timer);
      }
      return undefined;
    });
  }, []);

  const populateNewScannedItem = useCallback(
    (newScannedItem: ScanResultItem): void => {
      const matchingSerializedItems = serializedItems.filter(
        (i) =>
          i.dplItem?.EricssonProductNumber ===
          newScannedItem.ericssonProductNumber
      );
      const matchingNonValidatedSerializedItems =
        matchingSerializedItems.filter(
          (i) => !i.quantityValidated && !i.serialNumbersValidated
        );
      if (matchingNonValidatedSerializedItems.length) {
        const matchingSerializedItem = matchingNonValidatedSerializedItems[0];
        setScannedItem(() => ({
          serializedItem: {
            ...matchingSerializedItem,
            quantityValidated: true,
            ericssonSerialNumber: newScannedItem.serialNumber,
            customerSerialNumber:
              matchingSerializedItem.serialNumbersNeeded
                ?.TypeOfCustomerSerialNumberNeeded ===
              DplItemCustomerSerialNumberEnum.EricssonSerialNumber
                ? newScannedItem.serialNumber
                : undefined,
            status: MaterialTransferItemStatus.Ok
          },
          valid:
            matchingSerializedItem.serialNumbersNeeded
              ?.TypeOfCustomerSerialNumberNeeded ===
            DplItemCustomerSerialNumberEnum.EricssonSerialNumber
        }));
        if (
          matchingSerializedItem.serialNumbersNeeded
            ?.TypeOfCustomerSerialNumberNeeded ===
          DplItemCustomerSerialNumberEnum.EricssonSerialNumber
        ) {
          setIntervalData({
            remaining: RESULT_PROGRESS_TIMER_LENGTH,
            progress: 0
          });
        }
        return;
      }
      if (matchingSerializedItems.length) {
        setFeedbackMessage((prev) => {
          if (prev) {
            window.clearTimeout(prev.timer);
          }
          return {
            text: translate(
              NamespaceKeys.ScannerSpecific,
              ScannerSpecificKeys.FeedbackItemAlreadyValidated
            ),
            status: FeedbackStatus.Warning,
            timer: window.setTimeout(
              clearFeedbackHandler,
              FEEDBACK_TIMER_LENGTH
            )
          };
        });
        return;
      }
      const matchingNonSerializedItems = nonSerializedItems.filter(
        (i) =>
          i.dplItem.EricssonProductNumber ===
          newScannedItem.ericssonProductNumber
      );
      const matchingNonValidatedNonSerializedItems =
        matchingNonSerializedItems.filter((i) => !i.quantityValidated);
      if (matchingNonValidatedNonSerializedItems.length) {
        const matchingNonSerializedItem =
          matchingNonValidatedNonSerializedItems[0];
        setScannedItem({
          nonSerializedItem: {
            ...matchingNonSerializedItem,
            validationQuantities: {
              [MaterialTransferItemStatus.Ok]:
                matchingNonSerializedItem.quantity,
              [MaterialTransferItemStatus.Damaged]: 0,
              [MaterialTransferItemStatus.Missing]: 0,
              [MaterialTransferItemStatus.Excess]: 0,
              [MaterialTransferItemStatus.Incorrect]: 0
            }
          },
          valid: true
        });
        setIntervalData({
          remaining: RESULT_PROGRESS_TIMER_LENGTH,
          progress: 0
        });
        return;
      }
      if (matchingNonSerializedItems.length) {
        setFeedbackMessage((prev) => {
          if (prev) {
            window.clearTimeout(prev.timer);
          }
          return {
            text: translate(
              NamespaceKeys.ScannerSpecific,
              ScannerSpecificKeys.FeedbackItemAlreadyValidated
            ),
            status: FeedbackStatus.Warning,
            timer: window.setTimeout(
              clearFeedbackHandler,
              FEEDBACK_TIMER_LENGTH
            )
          };
        });
        return;
      }
      setFeedbackMessage((prev) => {
        if (prev) {
          window.clearTimeout(prev.timer);
        }
        return {
          text: translate(
            NamespaceKeys.ScannerSpecific,
            ScannerSpecificKeys.FeedbackNoItemFoundForScannedProductNumber,
            { productNumber: newScannedItem.serialNumber }
          ),
          status: FeedbackStatus.Warning,
          timer: window.setTimeout(clearFeedbackHandler, FEEDBACK_TIMER_LENGTH)
        };
      });
    },
    [translate, serializedItems, nonSerializedItems, clearFeedbackHandler]
  );

  useEffect(() => {
    setValidItems(validItemsCount(nonSerializedItems, serializedItems));
    setTotalItems(nonSerializedItems.length + serializedItems.length);
  }, [nonSerializedItems, serializedItems]);

  const handleOnScanStart = useCallback((): void => {
    try {
      scannerContext?.setCameraEnabled(true);
    } catch {
      setFeedbackMessage((prev: FeedbackMessage | undefined) => {
        if (prev) window.clearTimeout(prev.timer);
        return {
          status: FeedbackStatus.Error,
          text: translate(
            NamespaceKeys.ScannerSpecific,
            ScannerSpecificKeys.CouldNotStartOrStopScannerErrorMessage
          ),
          timer: window.setTimeout(clearFeedbackHandler, FEEDBACK_TIMER_LENGTH)
        };
      });
    }
  }, [clearFeedbackHandler, scannerContext, translate]);

  const handleOnScanEnd = useCallback((): void => {
    try {
      scannerContext?.setCameraEnabled(false);
    } catch {
      setFeedbackMessage((prev: FeedbackMessage | undefined) => {
        if (prev) window.clearTimeout(prev.timer);
        return {
          status: FeedbackStatus.Error,
          text: translate(
            NamespaceKeys.ScannerSpecific,
            ScannerSpecificKeys.CouldNotStartOrStopScannerErrorMessage
          ),
          timer: window.setTimeout(clearFeedbackHandler, FEEDBACK_TIMER_LENGTH)
        };
      });
    }
  }, [clearFeedbackHandler, scannerContext, translate]);

  const handleOnStatusOkToggle = useCallback((newValue: boolean) => {
    if (!newValue) return;
    setScannedItem((prev) => {
      if (!prev) return prev;
      if (prev.serializedItem) {
        return {
          ...prev,
          serializedItem: {
            ...prev.serializedItem,
            status: MaterialTransferItemStatus.Ok
          }
        };
      }
      if (prev.nonSerializedItem) {
        return {
          ...prev,
          nonSerializedItem: {
            ...prev.nonSerializedItem,
            validationQuantities: {
              [MaterialTransferItemStatus.Ok]: prev.nonSerializedItem.quantity,
              [MaterialTransferItemStatus.Damaged]: 0,
              [MaterialTransferItemStatus.Missing]: 0,
              [MaterialTransferItemStatus.Excess]: 0,
              [MaterialTransferItemStatus.Incorrect]: 0
            }
          }
        };
      }
      return prev;
    });
  }, []);

  const handleOnStatusDamagedToggle = useCallback((newValue: boolean) => {
    if (!newValue) return;
    setScannedItem((prev) => {
      if (!prev) return prev;
      if (prev.serializedItem) {
        return {
          ...prev,
          serializedItem: {
            ...prev.serializedItem,
            status: MaterialTransferItemStatus.Damaged
          }
        };
      }
      if (prev.nonSerializedItem) {
        return {
          ...prev,
          nonSerializedItem: {
            ...prev.nonSerializedItem,
            validationQuantities: {
              [MaterialTransferItemStatus.Ok]: 0,
              [MaterialTransferItemStatus.Damaged]:
                prev.nonSerializedItem.quantity,
              [MaterialTransferItemStatus.Missing]: 0,
              [MaterialTransferItemStatus.Excess]: 0,
              [MaterialTransferItemStatus.Incorrect]: 0
            }
          }
        };
      }
      return prev;
    });
  }, []);

  const handleOnClickRestoreScannedItem = useCallback(() => {
    setScannedItem((prev) => {
      prev && prev.valid && setIntervalData(undefined);
      return undefined;
    });
  }, []);

  const handleOnClickRestoreEricssonSerialNumber = useCallback(() => {
    setScannedItem((prev) => {
      if (!prev || !prev.serializedItem) return prev;
      if (prev.valid) {
        setIntervalData(undefined);
      }
      return {
        ...prev,
        serializedItem: {
          ...prev.serializedItem,
          ericssonSerialNumber: undefined
        },
        valid: false
      };
    });
  }, []);

  const handleOnClickRestoreCustomerSerialNumber = useCallback(() => {
    setScannedItem((prev) => {
      if (!prev || !prev.serializedItem) return prev;
      if (prev.valid) {
        setIntervalData(undefined);
      }
      return {
        ...prev,
        serializedItem: {
          ...prev.serializedItem,
          customerSerialNumber: undefined
        },
        valid: false
      };
    });
  }, []);

  const handleOnScan: (scanResult: ScanResult) => void = useCallback(
    (scanResult) => {
      const newScannedItem = toScanResultItem(scanResult.barcodes[0]);
      // Do we already have a result state?
      if (scannedItem) {
        // Is it valid and only awaiting timer to disappear?
        if (scannedItem.valid) {
          // If new scanned item is the same one as the already scanned item, we should do nothing
          if (
            newScannedItem.ericssonProductNumber &&
            (newScannedItem.ericssonProductNumber ===
              scannedItem.nonSerializedItem?.dplItem.EricssonProductNumber ||
              newScannedItem.ericssonProductNumber ===
                scannedItem.serializedItem?.dplItem?.EricssonProductNumber)
          ) {
            setFeedbackMessage((prev) => {
              if (prev) {
                window.clearTimeout(prev.timer);
              }
              return {
                text: translate(
                  NamespaceKeys.ScannerSpecific,
                  ScannerSpecificKeys.FeedbackItemWasJustScanned
                ),
                status: FeedbackStatus.Information,
                timer: window.setTimeout(
                  clearFeedbackHandler,
                  FEEDBACK_TIMER_LENGTH
                )
              };
            });
            return;
          }

          // Call onItemUpdate, proceed with next item (clear existing scanned items)
          handleValidItem(scannedItem);
          setScannedItem((prev) => {
            if (prev && prev.valid) {
              setIntervalData(undefined);
            }
            return undefined;
          });
          populateNewScannedItem(newScannedItem);
        } else {
          // Do we have an identifier (that is different from current product) in the new barcode?
          if (
            newScannedItem.ericssonProductNumber &&
            (scannedItem.nonSerializedItem?.dplItem.EricssonProductNumber !==
              newScannedItem.ericssonProductNumber ||
              scannedItem.serializedItem?.dplItem?.EricssonProductNumber !==
                newScannedItem.ericssonProductNumber)
          ) {
            // If so, call onItemUpdate with data we have so far, then reset state
            handleValidItem(scannedItem);
            setScannedItem(undefined);
            populateNewScannedItem(newScannedItem);
            return;
          }
          // proceed with new data
          if (newScannedItem.serialNumber) {
            setScannedItem((prev) => {
              if (!prev || !prev.serializedItem) return prev;
              let ericssonSerialNumberPopulated =
                !!prev.serializedItem?.ericssonSerialNumber ?? false;
              let customerSerialNumberPopulated =
                !!prev.serializedItem?.customerSerialNumber ?? false;
              const shouldHaveCustomerSerialNumber = !!(
                prev.serializedItem?.serialNumbersNeeded
                  ?.TypeOfCustomerSerialNumberNeeded !==
                DplItemCustomerSerialNumberEnum.None
              );
              const shouldHaveEricssonAsCustomerSerialNumber = !!(
                prev.serializedItem?.serialNumbersNeeded
                  ?.TypeOfCustomerSerialNumberNeeded ===
                DplItemCustomerSerialNumberEnum.EricssonSerialNumber
              );

              if (!ericssonSerialNumberPopulated) {
                prev.serializedItem.ericssonSerialNumber =
                  newScannedItem.serialNumber;
                if (
                  shouldHaveCustomerSerialNumber &&
                  shouldHaveEricssonAsCustomerSerialNumber &&
                  !customerSerialNumberPopulated
                ) {
                  prev.serializedItem.customerSerialNumber =
                    newScannedItem.serialNumber;
                }
              }

              if (
                ericssonSerialNumberPopulated &&
                shouldHaveCustomerSerialNumber &&
                !shouldHaveEricssonAsCustomerSerialNumber
              ) {
                prev.serializedItem.customerSerialNumber =
                  newScannedItem.serialNumber;
              }

              if (prev.valid) {
                setIntervalData(undefined);
                prev.valid = false;
              }

              ericssonSerialNumberPopulated =
                !!prev.serializedItem?.ericssonSerialNumber ?? false;
              customerSerialNumberPopulated =
                !!prev.serializedItem?.customerSerialNumber ?? false;
              if (
                ericssonSerialNumberPopulated &&
                (!shouldHaveCustomerSerialNumber ||
                  (shouldHaveCustomerSerialNumber &&
                    customerSerialNumberPopulated))
              ) {
                prev.valid = true;
                setIntervalData({
                  remaining: RESULT_PROGRESS_TIMER_LENGTH,
                  progress: 0
                });
              }
              return prev;
            });
          }
        }
      } else {
        // Do we have an identifier in the barcode?
        if (newScannedItem.ericssonProductNumber) {
          // If so, populate data and validate
          populateNewScannedItem(newScannedItem);
          return;
        }
        // Else, either set error state or do nothing
        else {
          setFeedbackMessage((prev) => {
            if (prev) {
              window.clearTimeout(prev.timer);
            }
            return {
              text: translate(
                NamespaceKeys.ScannerSpecific,
                ScannerSpecificKeys.FeedbackNoProductNumberFound,
                { productNumber: newScannedItem.serialNumber }
              ),
              status: FeedbackStatus.Warning,
              timer: window.setTimeout(
                clearFeedbackHandler,
                FEEDBACK_TIMER_LENGTH
              )
            };
          });
        }
      }
    },
    [
      clearFeedbackHandler,
      handleValidItem,
      populateNewScannedItem,
      scannedItem,
      translate
    ]
  );

  const handleOnError: (scanResult: ScanResult) => void = useCallback(
    (scanResult) => {
      console.log(scanResult);
    },
    []
  );

  useEffect((): void => {
    if (htmlRef && setupNeeded) {
      setSetupNeeded(() => {
        scannerContext?.startUsingScanner(htmlRef, scanSettings, true);
        return false;
      });
    }
  }, [htmlRef, scanSettings, scannerContext, setupNeeded]);

  useEffect(() => {
    scannerContext?.setOnScanCallback(handleOnScan);
  }, [handleOnScan, scannerContext]);

  useEffect(() => {
    scannerContext?.setOnErrorCallback(handleOnError);
  }, [handleOnError, scannerContext]);

  useEffect(() => {
    scannerContext?.applySettings(scanSettings);
  }, [scanSettings, scannerContext]);

  const handleOnClose = useCallback((): void => {
    scannerContext?.stopUsingScanner();
    onClose();
  }, [onClose, scannerContext]);

  const handleOnClickChangeCamera = useCallback((): void => {
    const displayFeedback = (): void =>
      setFeedbackMessage((prev: FeedbackMessage | undefined) => {
        if (prev) window.clearTimeout(prev.timer);
        return {
          status: FeedbackStatus.Error,
          text: translate(
            NamespaceKeys.ScannerSpecific,
            ScannerSpecificKeys.CouldNotChangeCameraErrorMessage
          ),
          timer: window.setTimeout(clearFeedbackHandler, FEEDBACK_TIMER_LENGTH)
        };
      });
    if (!scannerContext?.cameraToggleEnabled) {
      displayFeedback();
      return;
    }
    try {
      scannerContext?.nextCamera();
    } catch {
      displayFeedback();
    }
  }, [clearFeedbackHandler, scannerContext, translate]);

  return (
    <SmartScannerView
      showChangeCameraButton={scannerContext?.cameraToggleEnabled ?? false}
      onClickChangeCamera={handleOnClickChangeCamera}
      progressData={intervalData}
      onClose={handleOnClose}
      refFn={refFn}
      searchArea={searchArea}
      onScanStart={handleOnScanStart}
      onScanEnd={handleOnScanEnd}
      scanning={scannerContext?.cameraEnabled ?? false}
      scannedItem={scannedItem}
      title={pageTitle}
      subtitle={pageSubtitle}
      feedbackMessage={feedbackMessage}
      onStatusOkToggle={handleOnStatusOkToggle}
      onStatusDamagedToggle={handleOnStatusDamagedToggle}
      onClickRestoreScannedItem={handleOnClickRestoreScannedItem}
      onClickRestoreEricssonSerialNumber={
        handleOnClickRestoreEricssonSerialNumber
      }
      onClickRestoreCustomerSerialNumber={
        handleOnClickRestoreCustomerSerialNumber
      }
    />
  );
};

export default SmartScannerComponent;
