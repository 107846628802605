import React from "react";
import cx from "classnames";

import Tooltip from "../tooltip/Tooltip";
import IAppBarAnchor from "./contracts/IAppBarAnchor";

const AppBarAnchor = ({
  iconName,
  href,
  openInNewTab,
  tooltip,
  className
}: IAppBarAnchor) => {
  const content = iconName && (
    <a href={href} target={openInNewTab ? "_blank" : ""}>
      <i className={cx(`icon icon-${iconName}`, className)} />
    </a>
  );

  return (
    <div role="presentation" className="item hover">
      {tooltip ? (
        <Tooltip text={tooltip} position="bottom" type="pointer">
          {content}
        </Tooltip>
      ) : (
        content
      )}
    </div>
  );
};

export default AppBarAnchor;
