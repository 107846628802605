import {
  DeliveryProductListItemModel,
  InstallMaterialItemAtSiteRequestModel,
  MaterialInstallationItemDto,
  MaterialTransferItemStatus
} from "../../../../domain/client-customer";
import {
  NonSerializedInstallationItemState,
  SerializedInstallationItemState
} from "./material-installation-validation-component";
import { QuantityValidationStateItem } from "./quantity-validator/quantity-validator-component";

export const fromNonSerializedToGeneric = (
  nonSerializedItems: NonSerializedInstallationItemState[]
): QuantityValidationStateItem[] =>
  nonSerializedItems.map(
    (i: NonSerializedInstallationItemState): QuantityValidationStateItem => ({
      isSerialized: false,
      quantityValidated: i.quantityValidated,
      dplItem: i.dplItem,
      materialCategory: i.materialCategory,
      quantity: i.quantity,
      quantityTotal: i.quantityTotal ?? i.quantity,
      billOfMaterialItemIdentity: i.billOfMaterialItemIdentity,
      note: i.note,
      validationQuantities: {
        None: i.validationQuantities?.None ?? 0,
        Ok: i.validationQuantities?.Ok ?? 0,
        Damaged: i.validationQuantities?.Damaged ?? 0,
        Excess: i.validationQuantities?.Excess ?? 0,
        Incorrect: i.validationQuantities?.Incorrect ?? 0,
        Missing: i.validationQuantities?.Missing ?? 0
      }
    })
  );

export const fromSerializedToGeneric = (
  serializedItems: SerializedInstallationItemState[]
): QuantityValidationStateItem[] =>
  serializedItems.reduce((items, currentItem) => {
    const matchingItem = items.find(
      (i) =>
        i.dplItem?.Id === currentItem.dplItem?.Id &&
        i.materialCategory === currentItem.materialCategory &&
        i.billOfMaterialItemIdentity?.Value ===
          currentItem.billOfMaterialItemIdentity.Value
    );
    const status = currentItem.status ?? MaterialTransferItemStatus.None;
    if (matchingItem) {
      matchingItem.quantity++;
      matchingItem.validationQuantities = {
        ...matchingItem.validationQuantities
      };
      matchingItem.validationQuantities[status] =
        (matchingItem.validationQuantities[status] ?? 0) + 1;
      return items;
    } else {
      return [
        ...items,
        {
          isSerialized: true,
          quantityValidated: currentItem.quantityValidated,
          dplItem: currentItem.dplItem,
          materialCategory: currentItem.materialCategory,
          billOfMaterialItemIdentity: currentItem.billOfMaterialItemIdentity,
          quantity: currentItem.quantity ?? 1,
          quantityTotal: currentItem.quantityTotal ?? 0,
          note: currentItem.note,
          validationQuantities: {
            [status]: 1
          }
        }
      ];
    }
  }, [] as QuantityValidationStateItem[]);

export const findDplItemById = (
  dplItemId: string | undefined,
  dplItems: DeliveryProductListItemModel[] | undefined
): DeliveryProductListItemModel => {
  if (!dplItemId) throw new Error("Parameter dplItemId was not provided.");
  if (!dplItems) throw new Error("Parameter dplItems was not provided.");

  const dplItem = dplItems.find((dplItem) => dplItem.Id === dplItemId);
  if (!dplItem) throw new Error("No DPL item was matching");

  return dplItem;
};

export const toNonInstallMaterialItemAtSiteRequestModel = (
  item: NonSerializedInstallationItemState
): InstallMaterialItemAtSiteRequestModel => {
  return {
    DplItemId: item.dplItem?.Id,
    MaterialCategory: item.materialCategory,
    Quantity: item.validationQuantities?.Ok,
    BillOfMaterialItemIdentity: item.billOfMaterialItemIdentity
  };
};

export const toInstallMaterialItemAtSiteRequestModel = (
  item: SerializedInstallationItemState
): InstallMaterialItemAtSiteRequestModel => {
  return {
    DplItemId: item.dplItem?.Id,
    MaterialCategory: item.materialCategory,
    Quantity: 1,
    CustomerSerialNumber: item.customerSerialNumber,
    EricssonSerialNumber: item.ericssonSerialNumber,
    BillOfMaterialItemIdentity: item.billOfMaterialItemIdentity,
    HasNewSerialNumber:
      !item.isSerialNumbersLocked &&
      (!!item.customerSerialNumber || !!item.ericssonSerialNumber)
  };
};

export const toNonSerializedItemState = (
  nonSerializedViewModel: MaterialInstallationItemDto,
  dplItems: DeliveryProductListItemModel[] | undefined
): NonSerializedInstallationItemState => {
  const dplItem = findDplItemById(nonSerializedViewModel.DplItemId, dplItems);
  if (
    !nonSerializedViewModel.MaterialCategory ||
    !nonSerializedViewModel.Quantity
  ) {
    throw new Error("Item is missing values and could not be mapped.");
  }
  return {
    dplItem: dplItem,
    materialCategory: nonSerializedViewModel.MaterialCategory,
    billOfMaterialItemIdentity:
      nonSerializedViewModel.BillOfMaterialItemIdentity,
    quantity: nonSerializedViewModel.Quantity,
    quantityTotal: nonSerializedViewModel.QuantityTotal ?? 0,
    quantityValidated: false,
    note: nonSerializedViewModel.Note,
    validationQuantities: {
      None: nonSerializedViewModel.Quantity
    }
  };
};

export const toSerializedItemState = (
  serializedViewModel: MaterialInstallationItemDto,
  dplItems: DeliveryProductListItemModel[] | undefined
): SerializedInstallationItemState => {
  const dplItem = findDplItemById(serializedViewModel.DplItemId, dplItems);
  if (
    !serializedViewModel.MaterialCategory ||
    !serializedViewModel.SerialNumbersNeeded
  ) {
    throw new Error("Item is missing values and could not be mapped.");
  }
  return {
    dplItem: dplItem,
    materialCategory: serializedViewModel.MaterialCategory,
    billOfMaterialItemIdentity: serializedViewModel.BillOfMaterialItemIdentity,
    quantityValidated: false,
    serialNumbersValidated: false,
    ericssonSerialNumber: serializedViewModel.EricssonSerialNumber,
    customerSerialNumber: serializedViewModel.CustomerSerialNumber,
    isSerialNumbersLocked:
      !!serializedViewModel.EricssonSerialNumber ||
      !!serializedViewModel.CustomerSerialNumber,
    serialNumbersNeeded: serializedViewModel.SerialNumbersNeeded,
    status: serializedViewModel.Status,
    quantity: serializedViewModel.Quantity,
    quantityTotal: serializedViewModel.QuantityTotal,
    note: serializedViewModel.Note
  };
};
