import React from "react";

import ChecklistItemHeader from "./generic/checklist-item-header";
import { MultiItemComponent, MultiItemProps } from "./types";

const SectionElement: MultiItemComponent = ({
  item,
  onRenderSections
}: MultiItemProps) => (
  <>
    <ChecklistItemHeader item={item} />
    {item.Children.map(onRenderSections)}
  </>
);

export default SectionElement;
