import {
  DeliveryProductListItemDto,
  DeliveryProductListItemModel,
  MaterialCategoryModel
} from "../../../domain/client-customer";
import { DeliveryProductListItemInformation } from "./product-details-dialog";

export const fromDplItemDtoToInformationItem = (
  dplItemDto: DeliveryProductListItemDto | undefined
): DeliveryProductListItemInformation | undefined => {
  if (!dplItemDto) return undefined;
  return {
    alternativePart: dplItemDto.AlternativePart,
    category: dplItemDto.Category,
    comment: dplItemDto.Comment,
    commodity: dplItemDto.Commodity,
    criticalSpare: dplItemDto.CriticalSpare,
    customerProductDescription: dplItemDto.CustomerProductDescription,
    customerProductNumber: dplItemDto.CustomerProductNumber,
    dplItemId: dplItemDto.DplItemId,
    ericssonProductDescription: dplItemDto.EricssonProductDescription,
    ericssonProductNumber: dplItemDto.EricssonProductNumber,
    fecProduct: dplItemDto.FecProduct,
    generalConstructionKit: dplItemDto.GeneralConstructionKit,
    id: dplItemDto.Id,
    leadTimeInWorkingDays: dplItemDto.LeadTimeInWorkingDays,
    materialCategories: dplItemDto.MaterialCategories,
    phaseOutDate: dplItemDto.PhaseOutDate,
    productPackageType: dplItemDto.ProductPackageType,
    sIPPStatus: dplItemDto.SIPPStatus,
    showNote: dplItemDto.ShowNote,
    sourcings: dplItemDto.Sourcings,
    subcategory: dplItemDto.Subcategory,
    unitOfMeasurement: dplItemDto.UnitOfMeasurement,
    validQuantities: dplItemDto.ValidQuantities,
    warrantyPeriod: dplItemDto.WarrantyPeriod
  };
};

export const fromDplItemModelToInformationItem = (
  dplItemModel: DeliveryProductListItemModel | undefined
): DeliveryProductListItemInformation | undefined => {
  if (!dplItemModel) return undefined;
  return {
    alternativePart: dplItemModel.AlternativePart,
    category: dplItemModel.Category,
    comment: dplItemModel.Comment,
    commodity: dplItemModel.Commodity,
    criticalSpare: dplItemModel.CriticalSpare,
    customerProductDescription: dplItemModel.CustomerProductDescription,
    customerProductNumber: dplItemModel.CustomerProductNumber,
    dplItemId: dplItemModel.DplItemId,
    ericssonProductDescription: dplItemModel.EricssonProductDescription,
    ericssonProductNumber: dplItemModel.EricssonProductNumber,
    fecProduct: dplItemModel.FecProduct,
    generalConstructionKit: dplItemModel.GeneralConstructionKit,
    id: dplItemModel.Id,
    leadTimeInWorkingDays: dplItemModel.LeadTimeInWorkingDays,
    materialCategories:
      dplItemModel.MaterialCategories?.filter(
        (materialCategory: MaterialCategoryModel) =>
          !!materialCategory.MaterialCategory
      ).map(
        (materialCategory: MaterialCategoryModel) =>
          materialCategory.MaterialCategory || ""
      ) ?? [],
    phaseOutDate: dplItemModel.PhaseOutDate,
    productPackageType: dplItemModel.ProductPackageType,
    sIPPStatus: dplItemModel.SIPPStatus,
    showNote: dplItemModel.ShowNote,
    sourcings: dplItemModel.Sourcings,
    subcategory: dplItemModel.Subcategory,
    unitOfMeasurement: dplItemModel.UnitOfMeasurement,
    validQuantities: dplItemModel.ValidQuantities,
    warrantyPeriod: dplItemModel.WarrantyPeriod
  };
};
