import React, { useContext, useCallback, useState, useEffect } from "react";

import { SignIn as EDSSignIn, Loader } from "@react-gcc-eds/core";

import { AuthenticationContext } from "../../contexts/authentication-context";
import {
  NamespaceKeys,
  ApplicationKeys,
  LoginSpecificKeys
} from "../../translation/dictionary-keys";
import { useTranslation } from "../../translation/translation-utils";
import NotificationModal from "../common/notification-modal/modal";
import BundleInformation from "../developer-information/bundle-information";

import "./sign-in.scss";

type Props = {
  errorMessage: string | undefined;
};
const SignIn: React.FC<Props> = ({ errorMessage }: Props) => {
  const cookieConsentProvided = "cookieConsentProvided";
  const { translate } = useTranslation();
  const { login, isAuthenticated } = useContext(AuthenticationContext);

  const [showModal, setShowModal] = useState(true);
  const [cookieConsent, showCookieConsent] = useState(false);
  const [checked, setIsChecked] = useState(false);

  const handleOnLoginClick = useCallback(() => {
    login();
  }, [login]);

  const handleNotificationModal = useCallback((res) => {
    setShowModal(res);
  }, []);

  const handleOnchecked = (e: boolean) => {
    if (e) {
      localStorage.setItem("hide", cookieConsentProvided);
    } else {
      localStorage.setItem("hide", "");
    }
    setIsChecked(e);
  };

  useEffect(() => {
    const modalStorage = localStorage.getItem("hide");
    if (modalStorage && modalStorage === cookieConsentProvided) {
      setShowModal(false);
      showCookieConsent(true);
    }
  }, []);

  return (
    <>
      {!isAuthenticated && showModal && !cookieConsent && (
        <NotificationModal
          isAuthenticatedPage={true}
          isCheckbox={checked}
          setModalOpen={handleNotificationModal}
          setIsChecked={handleOnchecked}
        ></NotificationModal>
      )}
      <EDSSignIn
        anonymousSignIn
        productName={translate(
          NamespaceKeys.Application,
          ApplicationKeys.ApplicationName
        )}
        signInButtons={[
          {
            text: translate(
              NamespaceKeys.LoginSpecific,
              LoginSpecificKeys.EricssonSingleSignOn
            ),
            onClick: handleOnLoginClick
          }
        ]}
        support={
          <>
            {errorMessage && <div className="login-error">{errorMessage}</div>}
            <a
              className="subtle-link"
              href="https://www.ericsson.com/en/legal/privacy"
              rel="noopener noreferrer"
              target="_blank"
            >
              {translate(
                NamespaceKeys.LoginSpecific,
                LoginSpecificKeys.EricssonPrivacyPolicy
              )}
            </a>
            <BundleInformation />
          </>
        }
        signedInUser={
          (!errorMessage &&
            isAuthenticated && { firstName: "", lastName: "" }) ||
          undefined
        }
      />
      {/* Temporary solution to fix safari loader-issue */}
      {isAuthenticated && !errorMessage && (
        <Loader className="login-loader" size="medium" />
      )}
    </>
  );
};

export default SignIn;
