import React from "react";

import { AuthenticationContext } from "../../../../contexts/authentication-context";
import { CustomerContext } from "../../../../contexts/customer-context";
import {
  MaterialTransferByShipmentNumberViewModel,
  MaterialTransferDetailsByHandlingUnitRequestModel,
  MaterialTransfersClient
} from "../../../../domain/client-customer";
import {
  ComponentSpecificKeys,
  NamespaceKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import {
  toNonSerializedItemState,
  toSerializedItemState
} from "../../material-transfer/validation/material-transfer-validation-utils";
import { ReceiveMaterialTransferStateItem } from "./receive-material-models";

export type fetchTransfersType = {
  data: ReceiveMaterialTransferStateItem[];
  loading: boolean;
  error: string;
};

const useFetchTransfersDetails = (
  transfers: MaterialTransferByShipmentNumberViewModel[]
): fetchTransfersType => {
  const { currentCustomer } = React.useContext(CustomerContext);
  const { getCustomerConfigurationProvider } = React.useContext(
    AuthenticationContext
  );
  const { translate } = useTranslation();
  const [data, setData] = React.useState<ReceiveMaterialTransferStateItem[]>(
    []
  );
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>("");

  const getData = React.useCallback(async () => {
    setLoading(true);
    const customerIdentifier =
      currentCustomer && currentCustomer.HeaderIdentifier;

    const showError = () => {
      setError(
        translate(
          NamespaceKeys.ComponentSpecific,
          ComponentSpecificKeys.AnErrorOccurredPleaseTryAgain
        )
      );
    };

    if (!customerIdentifier) {
      setLoading(false);
      return;
    }
    const transferRequestModels: MaterialTransferDetailsByHandlingUnitRequestModel[] =
      transfers.map((item) => {
        return {
          TransferId: item.MaterialTransferId,
          ExternalHandlingUnit: item.ExternalHandlingUnit
        };
      });

    await new MaterialTransfersClient(await getCustomerConfigurationProvider())
      .getMaterialTransfersValidationDetails(
        customerIdentifier,
        transferRequestModels
      )
      .then((newItems) => {
        if (newItems.length <= 0) showError();
        else {
          const receiveMaterialTransferStateItem: ReceiveMaterialTransferStateItem[] =
            newItems.map((item) => ({
              HandlingUnits: item.HandlingUnits.map((hu) => {
                const materialTransferHandlingUnitState = {
                  ...hu,
                  SerializedItems: hu.SerializedItems.map((s) =>
                    toSerializedItemState(s, hu.DeliveryProductListItems)
                  ),
                  NonSerializedItems: hu.NonSerializedItems.map((n) =>
                    toNonSerializedItemState(n, hu.DeliveryProductListItems)
                  )
                };
                return materialTransferHandlingUnitState;
              }),
              Reason: item.Reason,
              MaterialTransferId: item.MaterialTransferId ?? "",
              SiteProjectId: item.SiteProjectId,
              SiteProjectName: item.SiteProjectName
            }));
          setData(receiveMaterialTransferStateItem);
        }
      })
      .catch(() => showError())
      .finally(() => {
        setLoading(false);
      });
  }, [transfers, getCustomerConfigurationProvider, currentCustomer, translate]);

  React.useEffect(() => {
    if (transfers.length > 0) getData();
  }, [getData, transfers]);

  return { data, loading, error };
};

export default useFetchTransfersDetails;
