import React, { useMemo } from "react";

import {
  mdiWeatherCloudy,
  mdiWeatherRainy,
  mdiWeatherSunny,
  mdiWeatherPartlyCloudy,
  mdiWeatherPouring,
  mdiWeatherLightning,
  mdiWeatherSnowy,
  mdiWeatherFog
} from "@mdi/js";
import Icon from "@mdi/react";
import { Row, Column } from "@react-gcc-eds/core";
import moment from "moment";

import {
  WeatherDailyForecastViewModel,
  WeatherUnit
} from "../../../../../domain/client-customer";

import "./weather-item.scss";

type Props = {
  dailyForecast: WeatherDailyForecastViewModel;
  unit: string;
};

const WeatherItem = (props: Props): JSX.Element | null => {
  const weatherIcon = useMemo<string>(() => {
    switch (props.dailyForecast.Icon) {
      case "01d.png":
      case "01n.png":
        return mdiWeatherSunny;
      case "02d.png":
      case "02n.png":
        return mdiWeatherPartlyCloudy;
      case "03d.png":
      case "03n.png":
      case "04d.png":
      case "04n.png":
        return mdiWeatherCloudy;
      case "09d.png":
      case "09n.png":
        return mdiWeatherRainy;
      case "10d.png":
      case "10n.png":
        return mdiWeatherPouring;
      case "11d.png":
      case "11n.png":
        return mdiWeatherLightning;
      case "13d.png":
      case "13n.png":
        return mdiWeatherSnowy;
      case "50d.png":
      case "50n.png":
        return mdiWeatherFog;
      default:
        return mdiWeatherCloudy;
    }
  }, [props.dailyForecast.Icon]);

  if (!props.dailyForecast.Time) {
    return null;
  }

  return (
    <Row className="weather-item">
      <Column sm={6} className="weather-item-left">
        <div className="weather-date">
          {moment.utc(new Date(props.dailyForecast.Time)).local().format("ll")}
        </div>
        <div>{`${
          props.dailyForecast.Temperature
            ? Math.round(props.dailyForecast.Temperature)
            : ""
        }°${props.unit === WeatherUnit.Metric ? "C" : "F"}`}</div>
        <div>{`${props.dailyForecast.WindSpeed} ${
          props.unit === WeatherUnit.Metric ? "m/s" : "mph"
        }`}</div>
      </Column>
      <Column sm={6} className="weather-item-right">
        <Icon path={weatherIcon} />
        <p>{props.dailyForecast.Description}</p>
      </Column>
    </Row>
  );
};

export default WeatherItem;
