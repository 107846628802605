import React from "react";
import IKpiWarning from "./IKpiWarning";

const KpiWarning = ({label,className=""}:IKpiWarning) => {
  return (
    <div className={className}>
      <i className="icon icon-triangle"></i>
      <span className="text-sm">{label}</span>
    </div>
  );
};

export default KpiWarning;
