import {
  BarcodePicker,
  Camera,
  CameraSettings,
  ScanSettings,
  SingleImageModeSettings,
  TextRecognitionSettings,
} from "..";

type ValidationType =
  | "booleanAttribute"
  | "boolean"
  | "integer"
  | "array"
  | "jsonArray"
  | "videoFit"
  | "camera"
  | "cameraType"
  | "cameraSettings"
  | "codeDirection"
  | "recognitionMode"
  | "guiStyle"
  | "searchArea"
  | "singleImageModeSettings"
  | "textRecognitionSettings";

// tslint:disable:no-any no-unnecessary-class
export abstract class Validator {
  public static expectationMessage: Map<Function, string> = new Map();

  @validationMessage("booleanAttribute")
  public static isBooleanAttribute(value: any): boolean {
    return value === "true" || value === "false";
  }

  @validationMessage("boolean")
  public static isBooleanProperty(value: any): boolean {
    return typeof value === "boolean";
  }

  @validationMessage("integer")
  public static isIntegerAttribute(value: any): boolean {
    return typeof value === "string" && /^-?\d+$/.test(value);
  }

  @validationMessage("integer")
  public static isIntegerProperty(value: any): boolean {
    return Number.isInteger(value);
  }

  @validationMessage("cameraType")
  public static isValidCameraType(value: any): boolean {
    return Object.values(Camera.Type).includes(<Camera.Type>value);
  }

  @validationMessage("guiStyle")
  public static isValidGuiStyle(value: any): boolean {
    return Object.values(BarcodePicker.GuiStyle).includes(<BarcodePicker.GuiStyle>value);
  }

  @validationMessage("videoFit")
  public static isValidVideoFit(value: any): boolean {
    return Object.values(BarcodePicker.ObjectFit).includes(<BarcodePicker.ObjectFit>value);
  }

  @validationMessage("codeDirection")
  public static isValidCodeDirection(value: any): boolean {
    return Object.values(ScanSettings.CodeDirection).includes(<ScanSettings.CodeDirection>value);
  }

  @validationMessage("recognitionMode")
  public static isValidRecognitionMode(value: any): boolean {
    return Object.values(ScanSettings.RecognitionMode).includes(<ScanSettings.RecognitionMode>value);
  }

  @validationMessage("array")
  public static isArray(value: any): boolean {
    return Array.isArray(value);
  }

  @validationMessage("jsonArray")
  public static isValidJsonArray(value: any): boolean {
    let json: any;
    try {
      json = JSON.parse(value);
    } catch (e) {
      return false;
    }

    return Array.isArray(json);
  }

  @validationMessage("searchArea")
  public static isValidSearchAreaAttribute(value: any): boolean {
    let areaObject: any;
    try {
      areaObject = JSON.parse(value);
    } catch (e) {
      return false;
    }

    return Validator.isValidSearchAreaProperty(areaObject);
  }

  @validationMessage("searchArea")
  public static isValidSearchAreaProperty(areaObject: any): boolean {
    if (areaObject == null || typeof areaObject !== "object") {
      return false;
    }

    return (
      areaObject.x >= 0 &&
      areaObject.x <= 1 &&
      areaObject.y >= 0 &&
      areaObject.y <= 1 &&
      areaObject.width >= 0 &&
      areaObject.width <= 1 &&
      areaObject.height >= 0 &&
      areaObject.height <= 1
    );
  }

  @validationMessage("camera")
  public static isValidCameraObject(value: any): boolean {
    let camera: Camera;
    try {
      camera = JSON.parse(value);
    } catch (e) {
      return false;
    }

    return typeof camera?.deviceId === "string";
  }

  @validationMessage("cameraSettings")
  public static isValidCameraSettingsObject(value: any): boolean {
    let settings: CameraSettings;
    try {
      settings = JSON.parse(value);
    } catch (e) {
      return false;
    }

    return typeof settings?.resolutionPreference === "string";
  }

  @validationMessage("singleImageModeSettings")
  public static isValidSingleImageModeSettingsObject(value: any): boolean {
    let settings: SingleImageModeSettings;
    try {
      settings = JSON.parse(value);
    } catch (e) {
      return false;
    }

    // TODO: improve checks
    return settings != null && typeof settings === "object";
  }

  @validationMessage("textRecognitionSettings")
  public static isValidTextRecognitionSettingsObject(value: any): boolean {
    let settings: TextRecognitionSettings;
    try {
      settings = JSON.parse(value);
    } catch (e) {
      return false;
    }

    // TODO: improve checks
    return settings != null && typeof settings === "object";
  }

  public static getExpectationMessageForType(type: ValidationType): string {
    const messageByType: { [key in ValidationType]: string } = {
      booleanAttribute: `Expected one of "true" or "false"`,
      boolean: `Boolean expected`,
      integer: `Integer expected`,
      array: `Array expected`,
      jsonArray: `Expected JSON array`,
      videoFit: `Expected a valid BarcodePicker.ObjectFit"`,
      camera: `Expected JSON object having properties of a Camera object`,
      cameraSettings: `Expected JSON object having properties of a CameraSettings object`,
      cameraType: `Expected a valid Camera.Type"`,
      codeDirection: `Expected a valid ScanSettings.CodeDirection"`,
      recognitionMode: `Expected a valid ScanSettings.RecognitionMode"`,
      guiStyle: `Expected a valid BarcodePicker.GuiStyle"`,
      searchArea: `Expected JSON object having properties of a SearchArea object`,
      singleImageModeSettings: `Expected JSON object having properties of a SingleImageModeSettings object`,
      textRecognitionSettings: `Expected JSON object having properties of a TextRecognitionSettings object`,
    };

    return messageByType[type];
  }
}

function validationMessage(type: ValidationType): MethodDecorator {
  return (target: any, key: string | symbol): void => {
    target.expectationMessage.set(target[key], target.getExpectationMessageForType(type));
  };
}
