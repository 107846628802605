import React, { ReactElement, useCallback, useMemo } from "react";

import { Column, Kpi, KpiItem, Loader, Row } from "@react-gcc-eds/core";

import {
  ComponentSpecificKeys,
  GeneralKeys,
  NamespaceKeys
} from "../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../translation/translation-utils";
import CenteredContainer from "../../../../common/layout/centered-container";
import { ValidationQuantities } from "../quantity-validator/quantity-validator-component";

import "./review.scss";

type Props = {
  posting: boolean;
  statusAggregate: ValidationQuantities;
};

const ReviewView = ({ posting, statusAggregate }: Props): ReactElement => {
  const { translate } = useTranslation();

  const renderStatus = useCallback(
    (translationKey: GeneralKeys, quantity: number) => (
      <div>
        <Kpi>
          <KpiItem inline xl>
            {quantity}
          </KpiItem>
          <KpiItem inline>
            <KpiItem color="gray">
              {translate(NamespaceKeys.General, translationKey)}
            </KpiItem>
          </KpiItem>
        </Kpi>
      </div>
    ),
    [translate]
  );

  const statusSummary = useMemo<JSX.Element>(
    () => (
      <Row className="review-kpi-row">
        {renderStatus(GeneralKeys.None, statusAggregate?.None ?? 0)}
        {renderStatus(GeneralKeys.Ok, statusAggregate?.Ok ?? 0)}
        {renderStatus(GeneralKeys.Damaged, statusAggregate?.Damaged ?? 0)}
        {renderStatus(GeneralKeys.Missing, statusAggregate?.Missing ?? 0)}
        {renderStatus(GeneralKeys.Excess, statusAggregate?.Excess ?? 0)}
      </Row>
    ),
    [renderStatus, statusAggregate]
  );

  const renderReviewTextRow = useCallback(
    (translationKey: ComponentSpecificKeys) => (
      <Row key={translationKey}>
        <Column sm={12}>
          <p>{translate(NamespaceKeys.ComponentSpecific, translationKey)}</p>
        </Column>
      </Row>
    ),
    [translate]
  );

  const reviewText = useMemo<JSX.Element[]>(
    () => [
      renderReviewTextRow(
        ComponentSpecificKeys.MaterialTransferValidationReviewResultParagraph
      ),
      renderReviewTextRow(
        ComponentSpecificKeys.MaterialTransferValidationReviewSerialNumbersParagraph
      )
    ],
    [renderReviewTextRow]
  );

  return posting ? (
    <div className="review-view-loader-container">
      <CenteredContainer>
        <Loader size="large" />
      </CenteredContainer>
    </div>
  ) : (
    <>
      {statusSummary}
      {reviewText}
    </>
  );
};

export default ReviewView;
