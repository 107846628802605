import { useCallback, useEffect, useMemo } from "react";

import BlobWorker from "worker-loader!./base64-worker";

import { UploadWorkerStatus } from "../domain/enums/upload-worker-status";
import { Base64WorkerResults } from "./base64-worker-types";

type Base64ConversionProps = {
  convertFileIntoBase64String: (file: File) => Promise<string>;
};

const useBase64Conversion = (): Base64ConversionProps => {
  const workerInstance = useMemo<BlobWorker>(() => {
    return new BlobWorker();
  }, []);

  useEffect(() => {
    return (): void => {
      workerInstance.terminate();
    };
  }, [workerInstance]);

  const convertFileIntoBase64String = useCallback(
    (file: File): Promise<string> =>
      new Promise((resolve, reject): void => {
        workerInstance.onerror = (ev: ErrorEvent): void => {
          reject(ev.message);
        };
        workerInstance.onmessage = (ev: MessageEvent): void => {
          const typedResults = ev.data as Base64WorkerResults;
          if (typedResults.status === UploadWorkerStatus.FileContentsReceived) {
            resolve(typedResults.results);
          } else {
            reject("Something went wrong");
          }
        };
        workerInstance.postMessage(file);
      }),
    [workerInstance]
  );
  return {
    convertFileIntoBase64String
  };
};

export default useBase64Conversion;
