import React, { useState } from "react";
import cx from "classnames";
import IImageCard from "./IImageCard";

const ImageCard = (props: IImageCard) => {
  const [selected, setSelected] = useState(false);
  const {
    title,
    subTitle,
    headerActions,
    actions,
    content,
    className,
    imagePath,
    selectable = true,
    disabled = false,
    style
  } = { ...props };

  return (
    <div
      role="presentation"
      // eslint-disable-next-line
      className={cx(
        "card imagecard",
        { selected, disabled },
        // { draggable: props["draggable"] },
        className !== undefined && className
      )}
      style={style}
      onClick={() => {
        if (selectable) {
          setSelected(!selected);
        }
      }}
    >
      <div className="header">
        {imagePath !== undefined && (
          <div className="image">
            <img alt="img" src={imagePath} />
          </div>
        )}
        <div className="left">
          <div className="title">{title}</div>
          {subTitle && <div className="subtitle">{subTitle}</div>}
          {content && <div className="content">{content}</div>}
        </div>

        {headerActions && <div className="right">{headerActions}</div>}
      </div>
      {actions && <p>{actions}</p>}
    </div>
  );
};
export default ImageCard;
