import React, { useCallback, useState } from "react";

import { Tile, Loader } from "@react-gcc-eds/core";

import {
  WeatherDailyForecastViewModel,
  WeatherUnit
} from "../../../../domain/client-customer";
import {
  ComponentSpecificKeys,
  NamespaceKeys,
  SiteSpecificKeys,
  GeneralKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import CenteredContainer from "../../../common/layout/centered-container";
import WeatherItem from "./weather-item/weather-item";

import "./weather-tile.scss";

type Props = {
  forecast: WeatherDailyForecastViewModel[] | undefined;
  city: string | undefined;
  loading: boolean;
  errorMessage: ComponentSpecificKeys | undefined;
  dayLimit?: number;
  onToggle?: () => void;
  onUnit: (unit: WeatherUnit) => void;
};

const WeatherTileView = ({
  forecast,
  city,
  loading,
  errorMessage,
  dayLimit,
  onToggle,
  onUnit
}: Props): React.ReactElement => {
  const { translate } = useTranslation();
  const [unit, setUnit] = useState<WeatherUnit>(WeatherUnit.Imperial);
  const onChangeUnit = useCallback(
    (unit: WeatherUnit): void => {
      onUnit(unit);
      setUnit(unit);
    },
    [onUnit]
  );
  const onClickMetric = useCallback(
    (): void => onChangeUnit(WeatherUnit.Metric),
    [onChangeUnit]
  );
  const onClickImperial = useCallback(
    (): void => onChangeUnit(WeatherUnit.Imperial),
    [onChangeUnit]
  );
  return (
    <Tile
      title={translate(NamespaceKeys.SiteSpecific, SiteSpecificKeys.Weather)}
      subtitle={
        errorMessage
          ? translate(NamespaceKeys.ComponentSpecific, errorMessage)
          : city
      }
      className="weather-tile"
    >
      <button
        className="unit-link"
        onClick={unit == WeatherUnit.Metric ? onClickImperial : onClickMetric}
      >
        Toggle unit
      </button>
      {loading ? (
        <CenteredContainer>
          <Loader size="medium" />
        </CenteredContainer>
      ) : errorMessage ? (
        translate(NamespaceKeys.General, GeneralKeys.PleaseTryAgainLater)
      ) : (
        forecast && (
          <>
            {(dayLimit && forecast.length > dayLimit
              ? forecast.slice(0, dayLimit)
              : forecast
            ).map((item, i) => (
              <WeatherItem unit={unit} key={i} dailyForecast={item} />
            ))}
            {onToggle && (
              // Disabling rule because we are not controlling the jsx-a11y plugin.
              // Rule is regarding accessibility only
              // eslint-disable-next-line
              <a className="show-more subtle-link" onClick={onToggle}>
                {dayLimit && forecast.length > dayLimit
                  ? translate(NamespaceKeys.General, GeneralKeys.ShowMore)
                  : translate(NamespaceKeys.General, GeneralKeys.ShowLess)}
              </a>
            )}
          </>
        )
      )}
    </Tile>
  );
};

export default WeatherTileView;
