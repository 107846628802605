import React, { useMemo } from "react";

const MAIN_SCRIPT_NAME_IDENTIFIER = "main";
const SCRIPT_NAME_IDENTIFIER_INDEX = 0;
const SCRIPT_FILENAME_REGEX = /(main|\d)\.[a-z, 0-9]*\.js/;
const SCRIPT_FILENAME_SEPARATOR = ".";
const SCRIPT_FILENAME_REGEX_RESULT_INDEX = 0;
const SCRIPT_FILENAME_PARTS_EXPECTED_LENGTH = 3;
const SCRIPT_FILENAME_HASH_PLACEMENT_INDEX = 1;

type BundleHashes = {
  main?: string;
  secondary?: string;
};

const BundleInformation = (): JSX.Element => {
  const bundleHashes = useMemo<BundleHashes>((): BundleHashes => {
    const bundleHashes: BundleHashes = {};
    const scriptsInApplication: HTMLCollectionOf<HTMLScriptElement> =
      document.scripts;

    for (let i = 0; i < scriptsInApplication.length; i++) {
      const currentScript: HTMLScriptElement | undefined =
        scriptsInApplication.item(i) ?? undefined;
      const scriptSourceUrl: string | undefined = currentScript?.src;
      const filenameMatchResults: RegExpMatchArray | undefined =
        scriptSourceUrl?.match(SCRIPT_FILENAME_REGEX) ?? undefined;
      const filename: string | undefined =
        filenameMatchResults?.[SCRIPT_FILENAME_REGEX_RESULT_INDEX];
      const splitFilename: string[] | undefined = filename?.split(
        SCRIPT_FILENAME_SEPARATOR
      );
      const bundleHash: string | undefined =
        splitFilename?.[SCRIPT_FILENAME_HASH_PLACEMENT_INDEX] ?? undefined;
      const isMainBundleHash: boolean =
        splitFilename?.[SCRIPT_NAME_IDENTIFIER_INDEX] ===
        MAIN_SCRIPT_NAME_IDENTIFIER;
      if (
        splitFilename?.length === SCRIPT_FILENAME_PARTS_EXPECTED_LENGTH &&
        bundleHash
      ) {
        if (isMainBundleHash) bundleHashes.main = bundleHash;
        else bundleHashes.secondary = bundleHash;
      }
    }

    return bundleHashes;
  }, []);

  return (
    <div>
      <div>Main bundle hash: {bundleHashes.main ?? "Not found"}</div>
      <div>Secondary bundle hash: {bundleHashes.secondary ?? "Not found"}</div>
    </div>
  );
};

export default BundleInformation;
