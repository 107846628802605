import React from "react";
import cx from "classnames";
import { ITable, SortingDirection, TableColumn } from "./ITable";

const Table = ({
  columns,
  rows,
  size,
  rowsStyle,
  onRowClick,
  className,
  selectable = false,
  selectedRows,
  onSort,
  disabled,
  style
}: ITable) => {
  const handleClick = (key: string, sortingDirection: SortingDirection) => {
    if (sortingDirection === SortingDirection.None && onSort) {
      onSort(key, SortingDirection.Ascending);
    } else if (sortingDirection === SortingDirection.Ascending && onSort) {
      onSort(key, SortingDirection.Descending);
    } else if (sortingDirection === SortingDirection.Descending && onSort) {
      onSort(key, SortingDirection.None);
    }
  };

  return (
    <table
      className={cx(
        "table",
        size !== "normal" ? size : "",
        rowsStyle !== "none" ? rowsStyle : "",
        { sortable: columns.filter((c: TableColumn) => c.sortable).length > 0, disabled },
        className
      )}
      style={style}
    >
      <thead>
        <tr>
          {columns.map(c => (
            <th
              key={`h_${c.key}`}
              className={cx(
                { "is-sortable": c.sortable },
                { asc: c.sortingDirection === SortingDirection.Ascending },
                { desc: c.sortingDirection === SortingDirection.Descending }
              )}
              onClick={() =>
                c.sortable && onSort
                  ? handleClick(
                      c.key,
                      c.sortingDirection !== undefined ? c.sortingDirection : SortingDirection.None
                    )
                  : null
              }
            >
              {c.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((r: { key: string }, i: number) => {
          const key = `r_${i}`;
          return (
            <tr
              key={key}
              // @ts-ignore
              className={cx({ selected: selectable && selectedRows?.includes(r.key) })}
            >
              {columns.map(c => (
                <td
                  role="presentation"
                  onKeyPress={() => onRowClick && onRowClick(r)}
                  onClick={() => onRowClick && onRowClick(r)}
                  key={`r_${key}_${c.key}`}
                >
                  {c.Cell ? c.Cell(r) : r[c.key]}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
