import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo
} from "react";

import {
  Tile,
  Loader,
  Icon,
  Row,
  Button,
  useNotifications,
  Dialog,
  Checkbox
} from "@react-gcc-eds/core";

import { AcceptedFileFormatContext } from "../../../../contexts/accepted-file-formats-context";
import { AuthenticationContext } from "../../../../contexts/authentication-context";
import { CustomerContext } from "../../../../contexts/customer-context";
import {
  SiteProjectDocumentsClient,
  DocumentModel
} from "../../../../domain/client-customer";
import { userRoles } from "../../../../domain/constants";
import {
  NamespaceKeys,
  DocumentSpecificKeys,
  GeneralKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import FileUploadButton from "../../../common/file-upload-button";
import CenteredContainer from "../../../common/layout/centered-container";
import TitleValuePair from "../../../common/layout/title-value-pair";
import DocumentsAddLinkDialog from "./documents-tile-add-link-dialog/documents-tile-add-link-dialog";
import SiteProjectDocumentsTileItem from "./documents-tile-item";
import SiteProjectDocumentsTileUploadingItem from "./documents-tile-uploading-item";

import "./documents-tile.scss";

const MAXIMUM_COLLAPSED_ITEMS = 3;

export interface DocumentModelState extends DocumentModel {
  isDeleting?: boolean;
}

type Props = {
  siteProjectId: string;
  editable: boolean;
};

type FileToUpload = {
  file: File;
  restrictedAccess: boolean;
};

const SiteProjectDocumentsTileComponent = ({
  siteProjectId,
  editable
}: Props): React.ReactElement => {
  const { translate } = useTranslation();
  const { createInstantNotification } = useNotifications();
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const { currentCustomer, currentUser } = useContext(CustomerContext);

  const [loading, setLoading] = useState<boolean>(true);
  const [documents, setDocuments] = useState<DocumentModelState[]>();
  const [pendingUploads, setPendingUploads] = useState<FileToUpload[]>();
  const [uploadingDocuments, setUploadingDocuments] = useState<FileToUpload[]>(
    []
  );
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showNewLinkModal, setShowNewLinkModal] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const totalNumberOfRenderedItems = useMemo<number>(
    () => (documents || []).length + uploadingDocuments.length,
    [documents, uploadingDocuments.length]
  );

  const { fileFormats, imageFormats } = useContext(AcceptedFileFormatContext);
  const allowedFormats =
    fileFormats && imageFormats ? [...fileFormats, ...imageFormats] : [];
  const getDocuments = useCallback(async (): Promise<void> => {
    if (!currentCustomer || !currentCustomer.HeaderIdentifier) return;
    setLoading(true);
    setErrorMessage(undefined);
    setDocuments(undefined);
    try {
      const documents: DocumentModel[] = await new SiteProjectDocumentsClient(
        await getCustomerConfigurationProvider()
      ).getDocumentsForSiteProject(
        siteProjectId,
        currentCustomer?.HeaderIdentifier
      );

      setDocuments(documents);
    } catch {
      setErrorMessage(
        translate(
          NamespaceKeys.DocumentSpecific,
          DocumentSpecificKeys.ErrorWhileLoading
        )
      );
    } finally {
      setLoading(false);
    }
  }, [
    currentCustomer,
    getCustomerConfigurationProvider,
    siteProjectId,
    translate
  ]);

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  const handleOnDelete = useCallback(
    async (documentId: string, documentIndex: number): Promise<void> => {
      if (!currentCustomer?.HeaderIdentifier) return;
      setDocuments((prev) => {
        if (!prev) return;
        return prev.map((item, index) =>
          index === documentIndex
            ? {
                ...item,
                isDeleting: true
              }
            : item
        );
      });
      try {
        await new SiteProjectDocumentsClient(
          await getCustomerConfigurationProvider()
        ).deleteDocumentOnSiteProject(
          siteProjectId,
          documentId,
          currentCustomer.HeaderIdentifier
        );

        createInstantNotification(
          translate(
            NamespaceKeys.DocumentSpecific,
            DocumentSpecificKeys.DocumentDeleted
          ),
          translate(
            NamespaceKeys.DocumentSpecific,
            DocumentSpecificKeys.DocumentWasSuccessfullyDeleted
          )
        );
        getDocuments();
      } catch {
        createInstantNotification(
          translate(
            NamespaceKeys.DocumentSpecific,
            DocumentSpecificKeys.ErrorWhileDeletingNotificationTitle
          ),
          translate(
            NamespaceKeys.DocumentSpecific,
            DocumentSpecificKeys.ErrorWhileDeletingNotificationMessage
          ),
          "warning"
        );
      }
    },
    [
      createInstantNotification,
      currentCustomer,
      siteProjectId,
      getCustomerConfigurationProvider,
      getDocuments,
      translate
    ]
  );

  const userCanEditDocumentsOfRestrictedAccess = useMemo<boolean>(
    () => currentUser?.Role !== userRoles.CrewLeader,
    [currentUser]
  );

  const handleOnChangeUpload = useCallback(
    (fileList: File[]): void => {
      if (userCanEditDocumentsOfRestrictedAccess) {
        setPendingUploads(
          fileList.map(
            (file: File): FileToUpload => ({
              file: file,
              restrictedAccess: false
            })
          )
        );
      } else {
        for (const file of fileList) {
          setUploadingDocuments((prev) => {
            if (!prev) return prev;
            return [...prev, { file: file, restrictedAccess: false }];
          });
        }
      }
    },
    [userCanEditDocumentsOfRestrictedAccess]
  );

  const handleOnDocumentSuccessfulUpload = useCallback(
    (result: DocumentModel, indexToDelete: number): void => {
      setDocuments((prev) => {
        if (!prev) return prev;
        return [result, ...prev];
      });
      setUploadingDocuments((prev) => {
        return prev.filter((_item, index) => index !== indexToDelete);
      });
    },
    []
  );

  const handleOnDocumentUploadRemoval = useCallback(
    (indexToRemove: number): void => {
      setUploadingDocuments((prev) =>
        prev?.filter((_prevItem, prevIndex) => prevIndex !== indexToRemove)
      );
    },
    []
  );

  const handleOnClickConfirmPendingDialog = useCallback((): void => {
    setPendingUploads((prev) => {
      if (prev) {
        setUploadingDocuments(prev);
      }
      return undefined;
    });
  }, []);

  const handleOnClickCancelPendingDialog = useCallback((): void => {
    setPendingUploads(undefined);
  }, []);

  const handleNewLinkModal = useCallback(() => {
    setShowNewLinkModal((prev) => !prev);
  }, []);

  const handleOnSaveAddLinkDialog = useCallback(
    async (title: string, url: string): Promise<void> => {
      if (!currentCustomer?.HeaderIdentifier) {
        return;
      }
      setLoading(true);
      try {
        await new SiteProjectDocumentsClient(
          await getCustomerConfigurationProvider()
        ).addDocumentLinkToSiteProject(
          siteProjectId,
          currentCustomer.HeaderIdentifier,
          {
            Path: url,
            Title: title
          }
        );
        setShowNewLinkModal(false);
        createInstantNotification(
          translate(
            NamespaceKeys.DocumentSpecific,
            DocumentSpecificKeys.LinkAdded
          ),
          translate(
            NamespaceKeys.DocumentSpecific,
            DocumentSpecificKeys.DocumentLinkWasSuccessfullyAdded
          )
        );
        getDocuments();
      } catch {
        setErrorMessage(
          translate(
            NamespaceKeys.DocumentSpecific,
            DocumentSpecificKeys.ErrorWhileAddingLink
          )
        );
      } finally {
        setLoading(false);
      }
    },
    [
      createInstantNotification,
      currentCustomer,
      siteProjectId,
      getCustomerConfigurationProvider,
      getDocuments,
      translate
    ]
  );

  const handleOnCloseAddLinkDialog = useCallback(
    (): void => setShowNewLinkModal(false),
    []
  );

  const handleOnClickToggleExpanded = useCallback(
    () => setExpanded((prev) => !prev),
    []
  );

  return (
    <Row>
      <Tile
        title={translate(
          NamespaceKeys.DocumentSpecific,
          DocumentSpecificKeys.Documents
        )}
        className="site-project-documents-tile"
        bottom={
          !errorMessage &&
          !loading &&
          editable && (
            <>
              <FileUploadButton
                title={translate(
                  NamespaceKeys.DocumentSpecific,
                  DocumentSpecificKeys.UploadDocument
                )}
                onChange={handleOnChangeUpload}
                multiple
                allowedFileFormats={allowedFormats}
              />
              <Button onClick={handleNewLinkModal}>
                <Icon name="link" />
                {translate(
                  NamespaceKeys.DocumentSpecific,
                  DocumentSpecificKeys.AddLink
                )}
              </Button>
            </>
          )
        }
      >
        {errorMessage ? (
          <CenteredContainer>
            <div>{errorMessage}</div>
            <Button onClick={getDocuments}>
              {translate(NamespaceKeys.General, GeneralKeys.Retry)}
            </Button>
          </CenteredContainer>
        ) : loading ? (
          <CenteredContainer>
            <Loader size="medium" />
          </CenteredContainer>
        ) : totalNumberOfRenderedItems > 0 ? (
          <>
            <div className="documents-list">
              {uploadingDocuments.length > 0 &&
                uploadingDocuments.map((d, i) => (
                  <SiteProjectDocumentsTileUploadingItem
                    siteProjectId={siteProjectId}
                    currentCustomer={currentCustomer?.HeaderIdentifier}
                    key={`${d.file.name}${d.file.lastModified}`}
                    index={i}
                    file={d.file}
                    restrictedAccess={d.restrictedAccess}
                    onSuccessfulUpload={handleOnDocumentSuccessfulUpload}
                    onRemoval={handleOnDocumentUploadRemoval}
                    hidden={!expanded && i >= MAXIMUM_COLLAPSED_ITEMS}
                  />
                ))}
              {documents &&
                documents.length > 0 &&
                documents
                  .filter(
                    (d, i) =>
                      totalNumberOfRenderedItems <= MAXIMUM_COLLAPSED_ITEMS ||
                      expanded ||
                      uploadingDocuments.length + i + 1 <=
                        MAXIMUM_COLLAPSED_ITEMS
                  )
                  .map((d: DocumentModelState, i: number) => (
                    <SiteProjectDocumentsTileItem
                      key={d.Id}
                      document={d}
                      editable={editable}
                      siteProjectId={siteProjectId}
                      onDelete={handleOnDelete}
                      index={i}
                    />
                  ))}
            </div>
            {totalNumberOfRenderedItems > MAXIMUM_COLLAPSED_ITEMS && (
              // Disabling rule because we are not controlling the jsx-a11y plugin.
              // Rule is regarding accessibility only
              // eslint-disable-next-line
              <a className="subtle-link" onClick={handleOnClickToggleExpanded}>
                {expanded
                  ? translate(NamespaceKeys.General, GeneralKeys.ShowLess)
                  : translate(NamespaceKeys.General, GeneralKeys.ShowMore)}
              </a>
            )}
          </>
        ) : (
          <TitleValuePair
            title={translate(
              NamespaceKeys.DocumentSpecific,
              DocumentSpecificKeys.NoDocumentsUploaded
            )}
            value=""
          />
        )}
      </Tile>
      <>
        {pendingUploads && (
          <Dialog
            title={translate(
              NamespaceKeys.DocumentSpecific,
              DocumentSpecificKeys.PendingUploadsDialogTitle
            )}
            buttons={
              <>
                <Button onClick={handleOnClickConfirmPendingDialog} primary>
                  {translate(NamespaceKeys.General, GeneralKeys.Upload)}
                </Button>
                <Button onClick={handleOnClickCancelPendingDialog}>
                  {translate(NamespaceKeys.General, GeneralKeys.Cancel)}
                </Button>
              </>
            }
            className="site-project-documents-pending-uploads-dialog"
          >
            <div>
              {translate(
                NamespaceKeys.DocumentSpecific,
                DocumentSpecificKeys.PendingUploadsDialogMessageRow1
              )}
            </div>
            <div>
              {translate(
                NamespaceKeys.DocumentSpecific,
                DocumentSpecificKeys.PendingUploadsDialogMessageRow2
              )}
            </div>
            <div className="file-list">
              {pendingUploads.map((item, index) => (
                <div key={index}>
                  <Checkbox
                    onChange={(newValue: boolean): void => {
                      setPendingUploads((prev) => {
                        if (!prev) return prev;
                        return prev.map(
                          (prevItem, prevIndex): FileToUpload =>
                            prevIndex === index
                              ? { ...prevItem, restrictedAccess: newValue }
                              : prevItem
                        );
                      });
                    }}
                    checked={item.restrictedAccess}
                    text={item.file.name}
                  />
                </div>
              ))}
            </div>
          </Dialog>
        )}
        {showNewLinkModal && (
          <DocumentsAddLinkDialog
            onSave={handleOnSaveAddLinkDialog}
            onClose={handleOnCloseAddLinkDialog}
            existingDocuments={documents}
          />
        )}
      </>
    </Row>
  );
};

export default SiteProjectDocumentsTileComponent;
