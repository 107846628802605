import {
  deviceId,
  highEndBlurryRecognition,
  scanditDataCaptureLocation,
  textRecognition,
  userLicenseKey,
} from "../index";
import { BrowserHelper } from "./browserHelper";
import { DataCaptureWorker, dataCaptureWorkerBlob } from "./workers/dataCaptureWorker";

export class DataCaptureLoader {
  private preloadedDataCaptureWorker?: DataCaptureWorker;
  private preloadedDataCaptureWorkerAvailable: boolean;

  constructor(preload: boolean) {
    if (preload) {
      this.preloadedDataCaptureWorker = new Worker(URL.createObjectURL(dataCaptureWorkerBlob));
      DataCaptureLoader.load(this.preloadedDataCaptureWorker);
    }
    this.preloadedDataCaptureWorkerAvailable = preload;
  }

  public static load(
    dataCaptureWorker: DataCaptureWorker,
    preload: boolean = false,
    delayedRegistration: boolean = false
  ): void {
    dataCaptureWorker.postMessage({
      type: "load-library",
      deviceId,
      libraryLocation: scanditDataCaptureLocation,
      path: window.location.pathname,
      preload,
      delayedRegistration,
      highEndBlurryRecognition,
      textRecognition,
      licenseKey: userLicenseKey,
      deviceModelName: BrowserHelper.userAgentInfo.getDevice().model,
      writableDataPathOverride: localStorage.getItem("scanditWritableDataPathOverride") ?? undefined,
    });
  }

  public getDataCaptureWorker(): DataCaptureWorker {
    if (this.preloadedDataCaptureWorkerAvailable && this.preloadedDataCaptureWorker != null) {
      this.preloadedDataCaptureWorkerAvailable = false;

      return this.preloadedDataCaptureWorker;
    } else {
      return new Worker(URL.createObjectURL(dataCaptureWorkerBlob));
    }
  }

  public returnDataCaptureWorker(dataCaptureWorker: DataCaptureWorker): void {
    if (this.preloadedDataCaptureWorker == null) {
      this.preloadedDataCaptureWorker = dataCaptureWorker;
    }
    if (this.preloadedDataCaptureWorker === dataCaptureWorker) {
      this.preloadedDataCaptureWorker.onmessage = null;
      this.preloadedDataCaptureWorker.postMessage({
        type: "reset",
      });
      this.preloadedDataCaptureWorkerAvailable = true;
    } else {
      dataCaptureWorker.terminate();
    }
  }
}
