import { createContext } from "react";

import { authenticationProvider } from "../authentication/authentication-provider";
import { ConfigurationProvider } from "../domain/client-customer";
import {
  getIdToken,
  resetAuthenticationData,
  AuthenticationProps
} from "../hooks/useAuthentication";

export const AuthenticationContext = createContext<AuthenticationProps>({
  getCustomerConfigurationProvider: async () =>
    new ConfigurationProvider(await getIdToken(), undefined),
  login: authenticationProvider.login,
  logout: authenticationProvider.logout,
  isAuthenticated: false,
  resetAuthenticationData
});
