import { useRef, useEffect } from "react";

export const useClickOutside = (handler: EventListener) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseClick = (e: Event) => {
    if (containerRef.current && containerRef.current.contains(e.target as Node)) {
      // inside click
      return;
    }

    // outside click
    handler(e);
  };

  useEffect(() => {
    document.addEventListener("click", handleMouseClick, true);
    return () => {
      document.removeEventListener("click", handleMouseClick, true);
    };
  }, []);

  return containerRef;
};

export const useMoveOutside = (handler: EventListener) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (e: Event) => {
    if (containerRef.current && containerRef.current.contains(e.target as Node)) {
      // inside click
      return;
    }

    // outside click
    handler(e);
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove, true);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove, true);
    };
  }, []);

  return containerRef;
};

export const uniqueId = (prefix?: string) => {
  return `${prefix || "id"}-${Math.random()
    .toString(36)
    .substr(2, 16)}`;
};

export const areEqual = (a: object | string, b: object | string) =>
  a && b && JSON.stringify(a) === JSON.stringify(b);

export const useFocus = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return { inputRef };
};

export const isMobileDevice = () => {
  return window.navigator.userAgent.toLowerCase().includes("mobi") || window.innerWidth <= 768;
  
  // return (
  //   typeof window.orientation !== "undefined" || 
  //   window.navigator.userAgent.indexOf("IEMobile") !== -1
  // );
};

export const round = (number: number, decimals = 2) => {
  return (parseFloat((Math.round(number * 100) / 100).toFixed(decimals)) as unknown) as number;
};

export type FlexSize =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | "1"
  | "2"
  | "3"
  | "4"
  | "5"
  | "6"
  | "7"
  | "8"
  | "9"
  | "10"
  | "11"
  | "12";

  
  export const chunkArray = (array:string[], size:number) => {
    const results = [] as any;
    while(array.length) {
      results.push(array.splice(0,size));
    }
    return results;
  };

  export const compareArray = (array1: any, array2: any) => {
    return array1.length === array2.length && array1.every((value:any, index:number) => value === array2[index])
  };
  export const getStringDomLength = (s:string, fontSize:number = 14)  => {
    return s.length * fontSize;
  };