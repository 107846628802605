import { Configuration, AuthenticationParameters } from "msal";
import { MsalAuthProvider, LoginType } from "react-aad-msal";

const config: Configuration = {
  auth: {
    authority:
      "https://login.microsoftonline.com/92e84ceb-fbfd-47ab-be52-080c6b87953f",
    clientId: "74d5926e-4883-4427-90db-b9e54cdcf3f1",
    redirectUri: window.location.origin + "/login/callback"
  },
  cache: {
    storeAuthStateInCookie: true,
    cacheLocation: "localStorage"
  }
};

const authenticationParameters: AuthenticationParameters = {
  scopes: ["email"]
};

export const authenticationProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  { loginType: LoginType.Redirect }
);
