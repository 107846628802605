import React, { useCallback, useContext } from "react";

import { useHistory } from "react-router";

import { AuthenticationContext } from "../../../../contexts/authentication-context";
import {
  MaterialTransfersClient,
  MaterialTransferToValidateDetailsViewModel,
  ValidateMaterialTransferRequestModel,
  MobileViewAccessIdentifier
} from "../../../../domain/client-customer";
import { viewAccessMenuDictionary } from "../../../../view-access/view-access";

import MaterialTransferValidationComponent from ".";

type Props = {
  materialTransferId: string;
};

const MaterialTransferValidationWrapper = ({
  materialTransferId
}: Props): JSX.Element => {
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const history = useHistory();

  const onGetValidationData = useCallback(
    async (
      customerIdentifier: string
    ): Promise<MaterialTransferToValidateDetailsViewModel> => {
      return new MaterialTransfersClient(
        await getCustomerConfigurationProvider()
      ).getMaterialTransferValidationDetails(
        materialTransferId,
        "",
        customerIdentifier
      );
    },
    [materialTransferId, getCustomerConfigurationProvider]
  );

  const onPostValidationData = useCallback(
    async (
      siteProjectId: string,
      requestModel: ValidateMaterialTransferRequestModel,
      customerIdentifier: string
    ): Promise<void> =>
      new MaterialTransfersClient(
        await getCustomerConfigurationProvider()
      ).validateMaterialTransfer(
        siteProjectId,
        materialTransferId,
        customerIdentifier,
        requestModel
      ),
    [materialTransferId, getCustomerConfigurationProvider]
  );

  const onNavigateBack = useCallback(
    () =>
      history.push(
        viewAccessMenuDictionary[
          MobileViewAccessIdentifier.MaterialTransfersOverview
        ].link
      ),
    [history]
  );

  return (
    <MaterialTransferValidationComponent
      getValidationData={onGetValidationData}
      postValidationData={onPostValidationData}
      navigateBack={onNavigateBack}
    />
  );
};

export default MaterialTransferValidationWrapper;
