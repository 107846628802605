import React, { useState, useCallback, useContext, useEffect } from "react";

import { Loader, Button, Icon } from "@react-gcc-eds/core";

import { CustomerContext } from "../../../../../../contexts/customer-context";
import { UploadWorkerStatus } from "../../../../../../domain/enums/upload-worker-status";
import {
  NamespaceKeys,
  GeneralKeys,
  ChecklistSpecificKeys
} from "../../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../../translation/translation-utils";
import useBase64Conversion from "../../../../../../workers/base64-worker-hook";
import { ChecklistItemImageEditModel } from "../../../checklist-models";

type Props = {
  image: ChecklistItemImageEditModel;
  onImageUpdated: (updatedImage: ChecklistItemImageEditModel) => void;
  showImageDetails: (image: ChecklistItemImageEditModel) => void;
};

const UnsavedImage = ({
  image,
  onImageUpdated,
  showImageDetails
}: Props): React.ReactElement => {
  const { currentCustomer } = useContext(CustomerContext);
  const { translate } = useTranslation();
  const { convertFileIntoBase64String: convertFileIntoBlob } =
    useBase64Conversion();

  const [status, setStatus] = useState<UploadWorkerStatus>(
    UploadWorkerStatus.NotStarted
  );

  const uploadImage = useCallback(async (): Promise<void> => {
    if (!currentCustomer) {
      setStatus(UploadWorkerStatus.UploadFailed);
      return;
    }
    setStatus(UploadWorkerStatus.Uploading);
    try {
      const imageString = await convertFileIntoBlob(image.file);
      onImageUpdated({
        ...image,
        hostedUrl: imageString,
        hostedId: `${image.file.name}${image.timestampInTicks.toString()}`
      });
    } catch {
      setStatus(UploadWorkerStatus.UploadFailed);
    }
  }, [convertFileIntoBlob, currentCustomer, image, onImageUpdated]);

  useEffect(() => {
    if (!image.hostedUrl && status === UploadWorkerStatus.NotStarted) {
      uploadImage();
    }
  }, [image.hostedUrl, status, uploadImage]);

  const handleOnClickImage = useCallback(
    () => showImageDetails(image),
    [image, showImageDetails]
  );

  const handleOnClickRetryButton = useCallback(() => {
    setStatus(UploadWorkerStatus.NotStarted);
  }, []);

  return (
    <div className="new-image">
      {(status === UploadWorkerStatus.UploadFailed && (
        <>
          <span>
            {translate(
              NamespaceKeys.ChecklistSpecific,
              ChecklistSpecificKeys.UploadFailed
            )}
          </span>
          <Button onClick={handleOnClickRetryButton}>
            <Icon name="reload" />
            {translate(NamespaceKeys.General, GeneralKeys.Retry)}
          </Button>
        </>
      )) ||
        (image.hostedUrl && (
          <>
            <img
              src={image.hostedUrl}
              alt={image.file.name}
              onClick={handleOnClickImage}
            />
            <span>{image.file.name}</span>
          </>
        )) || (
          <>
            <Loader size="small" />
            <span>
              {translate(
                NamespaceKeys.ChecklistSpecific,
                status === UploadWorkerStatus.Uploading
                  ? ChecklistSpecificKeys.Uploading
                  : ChecklistSpecificKeys.ReadingFile
              )}
            </span>
          </>
        )}
    </div>
  );
};

export default UnsavedImage;
