import * as React from "react";
import { useState } from "react";

import { NumberField, Text, Tile } from "@react-gcc-eds/core";

import {
  BillOfMaterialItemIdentity,
  MaterialTransferItemStatus
} from "../../../../domain/client-customer";
import {
  GeneralKeys,
  NamespaceKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import {
  NonSerializedItemState,
  SerializedItemState
} from "../shared/receive-material-models";

import "./receive-material-report-deviation.scss";

type ReceiveMaterialReportDeviationItemProps = {
  handlingUnit: string;
  stateItemsIndexOrBillOfMaterialIdentity: number | BillOfMaterialItemIdentity;
  item: SerializedItemState | NonSerializedItemState;
  onUpdateMissingOrDamagedItems: (
    status: MaterialTransferItemStatus,
    quantity: number,
    handlingUnit: string,
    stateItemsIndexOrBillOfMaterialIdentity: number | BillOfMaterialItemIdentity
  ) => void;
  statusAndNumber: Partial<Record<MaterialTransferItemStatus, number>>;
};

const ReceiveMaterialReportDeviationItem: ({
  handlingUnit,
  stateItemsIndexOrBillOfMaterialIdentity,
  item,
  onUpdateMissingOrDamagedItems,
  statusAndNumber
}: ReceiveMaterialReportDeviationItemProps) => React.ReactElement = ({
  handlingUnit,
  stateItemsIndexOrBillOfMaterialIdentity,
  item,
  onUpdateMissingOrDamagedItems,
  statusAndNumber
}: ReceiveMaterialReportDeviationItemProps): React.ReactElement => {
  const [damagedItems, setDamagedItems] = useState(
    statusAndNumber[MaterialTransferItemStatus.Damaged] ?? 0
  );
  const { translate } = useTranslation();
  const [missingItems, setMissingItems] = useState(
    statusAndNumber[MaterialTransferItemStatus.Missing] ?? 0
  );
  return (
    <Tile className="deviation-tile">
      <div className="deviation-tile-content">
        <Text size="lg">{item.dplItem?.CustomerProductNumber}</Text>
        <div className="deviation-margin-top-5">
          <Text size="sm">{item.materialCategory}</Text>
        </div>
        <div className="deviation-margin-top-8">
          <Text size="sm">{item.dplItem?.CustomerProductDescription}</Text>
        </div>
        <div className="deviation-margin-top-10">
          <Text>
            {translate(NamespaceKeys.General, GeneralKeys.PC)}:{item.quantity}
          </Text>
        </div>
      </div>
      <div className="deviation-tile-number-fields">
        <NumberField
          focus
          label={translate(NamespaceKeys.General, GeneralKeys.Damaged)}
          min={0}
          onChange={(value: number) => {
            setDamagedItems(value);
            onUpdateMissingOrDamagedItems(
              MaterialTransferItemStatus.Damaged,
              value,
              handlingUnit,
              stateItemsIndexOrBillOfMaterialIdentity
            );
          }}
          step={1}
          type="spinner"
          value={damagedItems}
          max={(item.quantity ?? 0) - missingItems}
        />

        <NumberField
          focus
          label={translate(NamespaceKeys.General, GeneralKeys.Missing)}
          min={0}
          onChange={(value: number) => {
            setMissingItems(value);
            onUpdateMissingOrDamagedItems(
              MaterialTransferItemStatus.Missing,
              value,
              handlingUnit,
              stateItemsIndexOrBillOfMaterialIdentity
            );
          }}
          step={1}
          type="spinner"
          value={missingItems}
          max={(item.quantity ?? 0) - damagedItems}
        />
      </div>
    </Tile>
  );
};

export default ReceiveMaterialReportDeviationItem;
