import React from "react";
import IText from "./IText";

const Text = ({ size, as, children, style }: IText) => {
  if (as === "span") {
    return (
      <span className={`text-${size}`} style={style}>
        {children}
      </span>
    );
  }
  return (
    <div className={`text-${size}`} style={style}>
      {children}
    </div>
  );
};

export default Text;
