import React, { ReactNode, useState, ReactElement } from "react";

import cx from "classnames";

import { uniqueId } from "../../utils/utils";
import Tab from "./Tab";
import ITabs from "./ITabs";
import ITabItem from "./ITabItem";

interface ReactNodeTab {
  title: string;
  selected?: boolean;
  children?: ReactNode[] | ReactNode;
  disabled?: boolean;
  onSelected?(): void;
}

const Tabs = ({ tabs, className, contentRef, children , onChange, disabled}: ITabs) => {
  const [selectedTab, setSelected] = useState(0);
  const [tabKey] = useState(uniqueId());

  const handleTabSelected = (i: number) => {
    setSelected(i);
    const callBack = (tabs && tabs[i].onSelected) || (() => {});
    callBack();
  };

  const titles =
    children &&
    React.Children.map(children, (child: ReactElement<typeof Tab>, idx: number) => {
      const { props } = child;

      const fixedProps = (props as unknown) as ReactNodeTab;
       
      return (
        <div
          key={`${tabKey}-m-${fixedProps.title}`}
          role="button"
          tabIndex={0}
          className={cx("title", { selected: fixedProps.selected, disabled: fixedProps.disabled })}
          onClick={() => {
            if(onChange){
              onChange(idx);
            } 
            if(fixedProps.onSelected){
              fixedProps.onSelected();
            }

          }}
          onKeyDown={() => {}}
        >
          {fixedProps.title}
        </div>
      );
    });

  return (
    <div className={cx("tabs",{disabled}, className)}>
      <div className="titles">
        {tabs &&
          tabs.map((t: ITabItem, idx: number) => (
            <div
              key={`${tabKey}-m-${t.title}`}
              role="button"
              tabIndex={0}
              className={cx("title", { selected: idx === selectedTab ,disabled:t.disabled})}
              onClick={() => handleTabSelected(idx)}
              onKeyDown={() => {}}
            >
              {t.title}
            </div>
          ))}
        {children && titles}
      </div>

      <div className="contents" ref={contentRef}>
        {tabs &&
          tabs.map((t: ITabItem, idx: number) => (
            <div
              key={`${tabKey}-c-${t.title}`}
              className={cx("content", { selected: idx === selectedTab })}
            >
              {t.render && t.render()}
            </div>
          ))}
        {children}
      </div>
    </div>
  );
};

export default Tabs;
