import React, { ReactNode } from "react";
import cx from "classnames";
import useAppContext from "./context/ContextHelpers";

const AppMenu = ({ children }: { children: ReactNode }) => {
  const { menu } = useAppContext();
  return <div className={cx("appnav", { hidden: !menu.visible })}>{menu.visible && children}</div>;
};

export default AppMenu;
