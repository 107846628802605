import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo
} from "react";

import { Loader, Icon, Button } from "@react-gcc-eds/core";

import { AuthenticationContext } from "../../../../../contexts/authentication-context";
import {
  SiteProjectDocumentsClient,
  DocumentModel
} from "../../../../../domain/client-customer";
import { UploadWorkerStatus } from "../../../../../domain/enums/upload-worker-status";
import {
  NamespaceKeys,
  DocumentSpecificKeys,
  GeneralKeys
} from "../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../translation/translation-utils";

type Props = {
  file: File;
  index: number;
  onSuccessfulUpload: (uploadResult: DocumentModel, index: number) => void;
  onRemoval: (index: number) => void;
  siteProjectId: string;
  currentCustomer: string | undefined;
  restrictedAccess: boolean;
  hidden?: boolean;
};

const SiteProjectDocumentsTileUploadingItemComponent = ({
  file,
  index,
  onSuccessfulUpload,
  onRemoval,
  siteProjectId,
  currentCustomer,
  restrictedAccess,
  hidden
}: Props): React.ReactElement => {
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const { translate } = useTranslation();
  const [status, setStatus] = useState<UploadWorkerStatus>();

  const uploadDocument = useCallback(async (): Promise<void> => {
    if (!currentCustomer) {
      setStatus(UploadWorkerStatus.UploadFailed);
      return;
    }
    try {
      setStatus(UploadWorkerStatus.Uploading);
      new SiteProjectDocumentsClient(await getCustomerConfigurationProvider())
        .uploadDocumentToSiteProject(
          siteProjectId,
          currentCustomer,
          { data: file, fileName: file.name },
          restrictedAccess
        )
        .then((result) => {
          onSuccessfulUpload(result, index);
        })
        .catch(() => {
          setStatus(UploadWorkerStatus.UploadFailed);
        });
    } catch (error) {
      console.log("rejection caught");
    }
  }, [
    currentCustomer,
    file,
    getCustomerConfigurationProvider,
    siteProjectId,
    restrictedAccess,
    onSuccessfulUpload,
    index
  ]);

  useEffect(() => {
    if (status) return;
    uploadDocument();
  }, [uploadDocument, status]);

  const statusText = useMemo(
    () =>
      status === UploadWorkerStatus.Uploading
        ? translate(
            NamespaceKeys.DocumentSpecific,
            DocumentSpecificKeys.UploadingDocument
          )
        : status === UploadWorkerStatus.UploadFailed
        ? translate(
            NamespaceKeys.DocumentSpecific,
            DocumentSpecificKeys.UploadFailed
          )
        : translate(
            NamespaceKeys.DocumentSpecific,
            DocumentSpecificKeys.ReadingDocument
          ),
    [status, translate]
  );

  const handleOnClickDiscardButton = useCallback((): void => {
    onRemoval(index);
  }, [index, onRemoval]);

  const handleOnClickRetryButton = useCallback((): void => {
    setStatus(undefined);
  }, []);

  return hidden ? (
    <></>
  ) : (
    <div className="document-row">
      <div className="icon-column">
        {status === UploadWorkerStatus.UploadFailed ? (
          <Icon name="cross" />
        ) : (
          <Loader size="small" />
        )}
      </div>
      <div className="text-column">
        <div className="title">{file.name}</div>
        <div className="meta-data">{statusText}</div>
      </div>
      <div className="actions-column">
        {status === UploadWorkerStatus.UploadFailed && (
          <>
            <Button primary onClick={handleOnClickRetryButton}>
              {translate(NamespaceKeys.General, GeneralKeys.Retry)}
            </Button>
            <Button onClick={handleOnClickDiscardButton}>
              {translate(NamespaceKeys.General, GeneralKeys.Discard)}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default SiteProjectDocumentsTileUploadingItemComponent;
