import React, { useCallback } from "react";

import {
  Row,
  Tile,
  TextField,
  Dropdown,
  Button,
  Pill,
  IDropdownItem
} from "@react-gcc-eds/core";

import {
  NamespaceKeys,
  GeneralKeys,
  BomSpecificKeys,
  DplSpecificKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import TitleValuePair from "../../../common/layout/title-value-pair";
import { DropdownItem } from "../bom-viewer-utils";
import { BomFilters } from "./bom-filter-component";

import "../bom-viewer.scss";

type Props = {
  commoditiesList?: string[];
  materialCategoriesList?: string[];
  sourcingProvidersList?: string[];
  searchQuery: string;
  onToggleFilter: () => void;
  handleOnFilterCommodity: (commodity: string) => void;
  handleOnFilterMaterialCategory: (materialCategory: string) => void;
  handleOnFilterSourcingProvider: (sourcingProvider: string) => void;
  handleOnSearchChange: (newValue: string) => void;
  filteredCommodity: string;
  filteredMaterialCategory: string;
  filteredSourcingProvider: string;
  onFiltersChanged: (filters: BomFilters) => void;
  onClearFilters: () => void;
  isFiltered: () => boolean;
};

const renderFilterList = (filterList: string[] | undefined): DropdownItem[] =>
  (filterList || []).map((fl) => ({
    title: fl || "All",
    value: fl || ""
  }));

const BomFilterView = ({
  commoditiesList,
  materialCategoriesList,
  sourcingProvidersList,
  searchQuery,
  onToggleFilter,
  handleOnFilterCommodity,
  handleOnFilterMaterialCategory,
  handleOnFilterSourcingProvider,
  handleOnSearchChange,
  filteredMaterialCategory,
  filteredSourcingProvider,
  onClearFilters,
  isFiltered
}: Props): React.ReactElement => {
  const { translate } = useTranslation();

  const handleMaterialCategoryDropdownSelect = useCallback(
    (dropdownItem: IDropdownItem) => {
      const materialCategory: string = dropdownItem.value;
      handleOnFilterMaterialCategory(materialCategory);
    },
    [handleOnFilterMaterialCategory]
  );

  const handleSourcingProviderDropdownSelect = useCallback(
    (dropdownItem: IDropdownItem) => {
      const sourcingProvider: string = dropdownItem.value;
      handleOnFilterSourcingProvider(sourcingProvider);
    },
    [handleOnFilterSourcingProvider]
  );

  return (
    <Row className="bom-viewer-filter-row">
      <Tile
        sm={12}
        title={translate(NamespaceKeys.General, GeneralKeys.Filters)}
      >
        <div className="bom-filter">
          <TitleValuePair
            title={translate(
              NamespaceKeys.DplSpecific,
              DplSpecificKeys.Commodity
            )}
            value=""
          />
          <div className="bom-commodity">
            {renderFilterList(commoditiesList)
              .filter(
                (a) =>
                  a.title !== translate(NamespaceKeys.General, GeneralKeys.All)
              )
              .map((c) => (
                <Pill
                  key={c.title}
                  onToggle={(): void => handleOnFilterCommodity(c.title)}
                >
                  {c.title}
                </Pill>
              ))}
          </div>
          <TitleValuePair
            title={translate(
              NamespaceKeys.BomSpecific,
              BomSpecificKeys.MaterialCategory
            )}
            value={
              <Dropdown
                items={renderFilterList(materialCategoriesList)}
                label={
                  filteredMaterialCategory && filteredMaterialCategory.length
                    ? filteredMaterialCategory
                    : translate(NamespaceKeys.General, GeneralKeys.All)
                }
                onItemClick={handleMaterialCategoryDropdownSelect}
                right
              />
            }
          />
          <TitleValuePair
            title={translate(
              NamespaceKeys.DplSpecific,
              DplSpecificKeys.SourcingProvider
            )}
            value={
              <Dropdown
                items={renderFilterList(sourcingProvidersList)}
                label={
                  filteredSourcingProvider && filteredSourcingProvider.length
                    ? filteredSourcingProvider
                    : translate(NamespaceKeys.General, GeneralKeys.All)
                }
                onItemClick={handleSourcingProviderDropdownSelect}
              />
            }
          />
          <div className="bom-textfield-filter">
            <TextField
              placeholder={translate(
                NamespaceKeys.General,
                GeneralKeys.Search,
                {
                  name: translate(
                    NamespaceKeys.DplSpecific,
                    DplSpecificKeys.EricssonProductNumber
                  )
                }
              )}
              value={searchQuery}
              onChange={handleOnSearchChange}
              fullWidth
            />
          </div>
          <div className="bom-filter-close-btn">
            <Button onClick={onToggleFilter}>
              {translate(NamespaceKeys.General, GeneralKeys.Close)}
            </Button>
            {isFiltered() && (
              <Button onClick={onClearFilters}>
                {translate(NamespaceKeys.General, GeneralKeys.Clear)}
              </Button>
            )}
          </div>
        </div>
      </Tile>
    </Row>
  );
};

export default BomFilterView;
