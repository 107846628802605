import React, { ReactNode, ReactElement } from "react";

interface Props {
  children?: ReactNode
  pageNumber: number;
  maxPages: number;
}

const WizardPageWrapper = ({ children, pageNumber, maxPages }: Props) => {
  const childrenWithProps = React.Children.map(children, (child) => React.cloneElement(child as ReactElement, { page: pageNumber, totalPages: maxPages }));
  return <div className="wizard-content">{childrenWithProps}</div>;
};

export default WizardPageWrapper;
