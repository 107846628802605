import React from "react";

import { Accordion, AccordionItem } from "@react-gcc-eds/core";

import {
  NamespaceKeys,
  ReceivingMaterialSpecificKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import { MaterialTransferHandlingUnitState } from "../shared/receive-material-models";
import ReceiveMaterialScanSerialNumbersTransferItem from "./scan-serial-numbers-transfer-item";

import "./scan-serial-numbers.scss";

type ReceiveMaterialScanSerialNumbersTransferListProps = {
  materialTransferId: string;
  handlingUnits: MaterialTransferHandlingUnitState[];
};

const ReceiveMaterialScanSerialNumbersTransferList = ({
  materialTransferId,
  handlingUnits
}: ReceiveMaterialScanSerialNumbersTransferListProps): React.ReactElement => {
  const { translate } = useTranslation();
  return (
    <div className="rm-container">
      <div className="rm-body">
        <div className="rm-scan-serial-numbers">
          <Accordion className="rm-scan-serial-numbers-child" lines>
            {handlingUnits?.map((handlingUnitStateItem) => {
              return (
                <AccordionItem
                  className="rm-scan-serial-numbers-child-title"
                  key={
                    handlingUnitStateItem.ExternalHandlingUnit + "serialItem"
                  }
                  title={
                    translate(
                      NamespaceKeys.ReceivingMaterialSpecific,
                      ReceivingMaterialSpecificKeys.HU
                    ) + handlingUnitStateItem.ExternalHandlingUnit
                  }
                >
                  <ReceiveMaterialScanSerialNumbersTransferItem
                    materialTransferId={materialTransferId}
                    handlingUnit={handlingUnitStateItem}
                  />
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default ReceiveMaterialScanSerialNumbersTransferList;
