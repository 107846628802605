import React from "react";

import { Icon, Dialog, Button } from "@react-gcc-eds/core";

import {
  NamespaceKeys,
  ChecklistSpecificKeys,
  GeneralKeys
} from "../../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../../translation/translation-utils";

import "./image-details-dialog.scss";

type Props = {
  imageSrc: string | undefined;
  imageStyling?: React.CSSProperties;
  onDelete: () => void;
  onRotateCW: () => void;
  onClose: () => void;
};

const ImageDetailsDialog = ({
  imageSrc,
  imageStyling,
  onDelete,
  onRotateCW,
  onClose
}: Props): React.ReactElement => {
  const { translate } = useTranslation();

  const buttons: React.ReactElement = (
    <>
      <Button onClick={onRotateCW}>
        <Icon className="flipped" name="arrow-rotate-vertical" />
        {translate(
          NamespaceKeys.ChecklistSpecific,
          ChecklistSpecificKeys.Rotate
        )}
      </Button>
      <Button onClick={onDelete}>
        <Icon name="trashcan" />
        {translate(NamespaceKeys.General, GeneralKeys.Delete)}
      </Button>
    </>
  );

  return (
    <Dialog
      fullscreen
      onClose={onClose}
      buttons={buttons}
      className="image-details"
    >
      {imageSrc && <img src={imageSrc} style={imageStyling} />}
    </Dialog>
  );
};

export default ImageDetailsDialog;
