import moment from "moment";

import {
  SiteProjectDetailsModel,
  ResourceAccessIdentifier
} from "../domain/client-customer";
import UrlParameters from "../domain/enums/url-parameters";

export const capitalizeFirstLetter: (value: string) => string = (value) =>
  value.charAt(0).toUpperCase() + value.slice(1);

export const utcDateToLocal: (
  date: Date | undefined
) => moment.Moment | undefined = (date) =>
  (!!date && moment.utc(date).local()) || undefined;

export const InjectUrlParameter: (parameter: UrlParameters) => string = (
  parameter
) => `:${parameter}`;

export const HasSiteProjectPermission = (
  siteProject: SiteProjectDetailsModel,
  permission: ResourceAccessIdentifier
): boolean => {
  return (
    !!siteProject.Permissions &&
    siteProject.Permissions.some((p) => p === permission)
  );
};

export const rotateBase64 = (
  base64data: string,
  degreesProvided?: number
): Promise<string> =>
  new Promise((resolve, reject): void => {
    const degrees = degreesProvided ?? 90;
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      reject("Could not get 2d context");
      return;
    }

    const image = new Image();
    image.src = base64data;
    image.onload = (): void => {
      const w = image.width;
      const h = image.height;
      const rads = (degrees * Math.PI) / 180;
      let c = Math.cos(rads);
      let s = Math.sin(rads);
      if (s < 0) {
        s = -s;
      }
      if (c < 0) {
        c = -c;
      }
      //use translated width and height for new canvas
      canvas.width = h * s + w * c;
      canvas.height = h * c + w * s;
      //draw the rect in the center of the newly sized canvas
      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate((degrees * Math.PI) / 180);
      ctx.drawImage(image, -image.width / 2, -image.height / 2);

      resolve(canvas.toDataURL());
    };
    image.onerror = (): void => {
      reject("Unable to rotate data\n" + image.src);
    };
  });
