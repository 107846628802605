import { useState, useEffect, useContext, useCallback } from "react";

import { AuthenticationContext } from "../../../../contexts/authentication-context";
import { CustomerContext } from "../../../../contexts/customer-context";
import { MaterialTransfersClient } from "../../../../domain/client-customer";
import {
  ComponentSpecificKeys,
  NamespaceKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import { SelectableMaterialTransferOverviewViewModel } from "./receive-material-models";

export type fetchTransfersOverviewType = {
  data: SelectableMaterialTransferOverviewViewModel[];
  loading: boolean;
  error: string;
};

const useFetchMaterialTransfersByShipmentNumber = (
  shipmentReferenceNumber: string
): fetchTransfersOverviewType => {
  const { currentCustomer } = useContext(CustomerContext);
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const { translate } = useTranslation();
  const [data, setData] = useState<
    SelectableMaterialTransferOverviewViewModel[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const getMaterialTransfersValidationDetails = useCallback(async () => {
    setLoading(true);
    const customerIdentifier =
      currentCustomer && currentCustomer.HeaderIdentifier;

    const showError = () => {
      setError(
        translate(
          NamespaceKeys.ComponentSpecific,
          ComponentSpecificKeys.AnErrorOccurredPleaseTryAgain
        )
      );
    };

    if (!customerIdentifier) {
      showError();
      setLoading(false);
      return;
    }
    new MaterialTransfersClient(await getCustomerConfigurationProvider())
      .getMaterialTransfersByShipmentNumber(
        shipmentReferenceNumber,
        customerIdentifier
      )
      .then((newItems) => {
        if (newItems.length <= 0) showError();
        else setData(newItems.map((q) => ({ ...q, Selected: false })));
      })
      .catch(() => showError())
      .finally(() => {
        setLoading(false);
      });
  }, [
    currentCustomer,
    shipmentReferenceNumber,
    translate,
    getCustomerConfigurationProvider
  ]);

  useEffect(() => {
    if (data.length > 0) return;
    getMaterialTransfersValidationDetails();
  }, [getMaterialTransfersValidationDetails, data]);

  return { data, loading, error };
};

export default useFetchMaterialTransfersByShipmentNumber;
