/* Limits */

const getLowerLimit = (format: string) => {
  return format === "12" ? 1 : 0;
};

const getUpperLimit = (format: string) => {
  return format === "12" ? 12 : 23;
};

const convertLimit = (value: number, meridiem: string) => {
  if (meridiem === "AM" && value === 12) return 0;
  if (meridiem === "AM") return value;
  if (meridiem === "PM" && value === 12) return 12;
  return value + 12;
};

/* Parsing */

const parseInteger = (value: string) => {
  if (/^(0?0|0?[1-9]\d*)$/.test(value)) {
    return Number(value);
  }

  return NaN;
};

const parseMeridiem = (defaultMeridiem: string, time?: string) => {
  if (!time || time.length !== 4) {
    return defaultMeridiem;
  }

  const meridiem = time.slice(2, 4);
  if (meridiem !== "AM" && meridiem !== "PM") {
    return defaultMeridiem;
  }

  return meridiem;
};

const parseMin = (format: "12" | "24", defaultMin: number, time?: string) => {
  // 12 hours
  if (format === "12") {
    if (!time || time.length !== 4) {
      return defaultMin;
    }

    const min = parseInteger(time.slice(0, 2));
    if (!min || min < 1 || min > 12) {
      return defaultMin;
    }

    const meridiem = parseMeridiem("", time);
    if (!meridiem) {
      return defaultMin;
    }

    // Convert limits to 24 hours to deal with edge cases
    return convertLimit(min, meridiem);
  }

  // 24 hours
  if (!time || time.length !== 2) {
    return defaultMin;
  }

  const min = parseInteger(time);
  if (!min || min < 0 || min > 23) {
    return defaultMin;
  }

  return min;
};

const parseMax = (format: "12" | "24", defaultMax: number, time?: string) => {
  // 12 hours
  if (format === "12") {
    if (!time || time.length !== 4) {
      return defaultMax;
    }

    const max = parseInteger(time.slice(0, 2));
    if (!max || max < 1 || max > 12) {
      return defaultMax;
    }

    const meridiem = parseMeridiem("", time);
    if (!meridiem) {
      return defaultMax;
    }

    return convertLimit(max, meridiem);
  }

  // 24 hours
  if (!time || time.length !== 2) {
    return defaultMax;
  }

  const max = parseInteger(time);
  if (!max || max < 0 || max > 23) {
    return defaultMax;
  }

  return max;
};

/* String methods */

const timePad = (time: number) => {
  return time.toString().padStart(2, "0");
};

const timeToString = (format: "12" | "24", hour: number, minute: number) => {
  if (format === "12") {
    const tempHour = hour > 12 ? hour - 12 : hour;
    const hourString = timePad(tempHour);
    const minuteString = timePad(minute);
    const meridiemString = hour > 11 ? "PM" : "AM";
    return `${hourString}:${minuteString} ${meridiemString}`;
  }

  const hourString = timePad(hour);
  const minuteString = timePad(minute);
  return `${hourString}:${minuteString}`;
};

const listHours = (min: number, max: number) => {
  const hours: number[] = [];
  for (let i = min; i <= max; i += 1) {
    hours.push(i);
  }
  return hours;
};

const listMinutes = (step: number) => {
  const minutes: number[] = [];
  for (let i = 0; i < 60; i += step) {
    minutes.push(i);
  }
  return minutes;
};

export const getHours = (format: "12" | "24", min?: string, max?: string) => {
  const tempMin = parseMin(format, getLowerLimit(format), min);
  const tempMax = parseMax(format, getUpperLimit(format), max);
  const hours = listHours(tempMin, tempMax);
  const times: string[] = [];
  for (let i = 0; i < hours.length; i += 1) {
    times.push(timePad(hours[i]));
  }
  return times;
};

export const getMinutes = (step?: string) => {
  const tempStep = step ? parseInteger(step) : 5;
  const minutes = listMinutes(tempStep);
  const times: string[] = [];
  for (let i = 0; i < minutes.length; i += 1) {
    times.push(timePad(minutes[i]));
  }
  return times;
};

export const getCombined = (format: "12" | "24", step?: string, min?: string, max?: string) => {
  const tempStep = step ? parseInteger(step) : 5;
  const minutes = listMinutes(tempStep);
  const combined: string[] = [];

  // 12 hours
  if (format === "12") {
    const tempMin = parseMin(format, 0, min);
    const tempMax = parseMax(format, 23, max);
    const hours = listHours(tempMin, tempMax - 1);
    for (let i = 0; i < hours.length; i += 1) {
      for (let j = 0; j < minutes.length; j += 1) {
        combined.push(timeToString(format, hours[i], minutes[j]));
      }
    }
    return combined;
  }

  // 24 hours
  const tempMin = parseMin(format, 0, min);
  const tempMax = parseMax(format, 23, max) - 1;
  const hours = listHours(tempMin, tempMax);
  for (let i = 0; i < hours.length; i += 1) {
    for (let j = 0; j < minutes.length; j += 1) {
      combined.push(timeToString(format, hours[i], minutes[j]));
    }
  }
  return combined;
};
