import { useCallback, useMemo, useState } from "react";

import { InstallationSitesOverviewViewModel } from "../domain/client-customer";

export enum SortOrder {
  Asc,
  Desc
}

export type SortProperty<
  TModel,
  TSortProperties extends keyof TModel
> = TSortProperties;

export type MaterialInstallationOverviewSortProperty = SortProperty<
  InstallationSitesOverviewViewModel,
  "SiteName" | "StartDate"
>;

export type UsePropertySorting<TModel, TSortProperty extends keyof TModel> = {
  setSortOrder(sortOrder: SortOrder): void;
  toggleSortOrder(): void;
  setSortProperty(sortProperty: TSortProperty): void;
  setSortPropertyOrToggleSortOrder(sortProperty: TSortProperty): void;
  readonly sortedList: TModel[];
  readonly sortOrder: SortOrder;
  readonly sortProperty: TSortProperty;
};

const applySortOrder = (sortOrder: SortOrder, sortResult: number): number =>
  sortOrder === SortOrder.Asc ? sortResult : -sortResult;

export const usePropertySorting = <TModel, TSortProperty extends keyof TModel>(
  list: TModel[],
  defaultProperty: TSortProperty,
  defaultOrder: SortOrder = SortOrder.Asc
): UsePropertySorting<TModel, TSortProperty> => {
  const [sortOrder, setSortOrder] = useState<SortOrder>(defaultOrder);
  const [sortProperty, setSortProperty] =
    useState<TSortProperty>(defaultProperty);

  const sortedList = useMemo(
    () =>
      list
        .slice()
        .sort((a, b) =>
          applySortOrder(
            sortOrder,
            a[sortProperty] < b[sortProperty]
              ? -1
              : a[sortProperty] > b[sortProperty]
              ? 1
              : 0
          )
        ),
    [list, sortOrder, sortProperty]
  );

  const toggleSortOrder = useCallback(
    () =>
      setSortOrder(
        sortOrder === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc
      ),
    [sortOrder]
  );

  const setSortPropertyOrToggleSortOrder: (property: TSortProperty) => void =
    useCallback(
      (property) => {
        if (property === sortProperty) {
          toggleSortOrder();
        } else {
          setSortProperty(property);
        }
      },
      [sortProperty, toggleSortOrder]
    );

  return {
    setSortOrder,
    setSortProperty,
    toggleSortOrder,
    setSortPropertyOrToggleSortOrder,
    sortedList,
    sortOrder,
    sortProperty
  };
};
