import React, { useState } from "react";
import cx from "classnames";
import ICard from "./ICard";

const Card = (props: ICard) => {
  const [selected, setSelected] = useState(false);
  const {
    title,
    subTitle,
    headerActions,
    actions,
    content,
    className,
    selectable = true,
    disabled = false,
    style
  } = { ...props };

  return (
    <div
      role="presentation"
      // eslint-disable-next-line
      className={cx(
        "card",
        { selected, disabled },
        // { draggable: props["draggable"] },
        className !== undefined && className
      )}
      style={style}
      onClick={() => {
        if (selectable) {
          setSelected(!selected);
        }
      }}
    >
      <div className="header">
        <div className="left">
          <div className="title">{title}</div>
          {subTitle && <div className="subtitle">{subTitle}</div>}
        </div>
        {headerActions && <div className="right">{headerActions}</div>}
      </div>
      {content && <div className="content">{content}</div>}
      {actions && <p>{actions}</p>}
    </div>
  );
};
export default Card;
