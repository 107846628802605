import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo
} from "react";

import { Button } from "@react-gcc-eds/core";
import { useHistory } from "react-router";

import { CustomerContext } from "../../../contexts/customer-context";
import { LayoutContext } from "../../../contexts/layout-context";
import {
  ChecklistItemViewModel,
  ChecklistViewModel
} from "../../../domain/client-obsolete";
import {
  NamespaceKeys,
  MenuTitleKeys,
  GeneralKeys,
  ChecklistSpecificKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";
import { ChecklistItemEditModel } from "./checklist-models";
import ChecklistViewerView from "./checklist-viewer-view";

const MapFromChecklistItemViewModelsToEditModels = (
  viewModels: ChecklistItemViewModel[]
): ChecklistItemEditModel[] =>
  viewModels.map((viewModel: ChecklistItemViewModel) => ({
    ...viewModel,
    unsavedImages: [],
    savedImagesToDelete: [],
    Children: MapFromChecklistItemViewModelsToEditModels(
      viewModel.Children ?? []
    )
  }));

type Props = {
  siteProjectId: string;
  activityId: number;
  surveyId: string;
};

const ChecklistViewerComponent = ({ siteProjectId }: Props): JSX.Element => {
  const { translate } = useTranslation();
  const historyHook = useHistory();

  const { currentCustomer } = useContext(CustomerContext);
  const { setPageTitle } = useContext(LayoutContext);

  const [surveyTitle, setSurveyTitle] = useState<string>();
  const [siteProjectName, setSiteProjectName] = useState<string>();
  const [originalSurvey, setOriginalSurvey] =
    useState<ChecklistItemEditModel[]>();
  const [editedSurvey, setEditedSurvey] = useState<ChecklistItemEditModel[]>();

  const [loading, setLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<ChecklistSpecificKeys>();

  const hasSurveyChanged: boolean = useMemo<boolean>(
    () =>
      originalSurvey && editedSurvey
        ? JSON.stringify(originalSurvey) !== JSON.stringify(editedSurvey)
        : false,
    [editedSurvey, originalSurvey]
  );

  const getSurvey = useCallback(async (): Promise<undefined> => {
    const customerIdentifier =
      currentCustomer && currentCustomer.HeaderIdentifier;
    if (!customerIdentifier || !siteProjectId) {
      setErrorMessage(ChecklistSpecificKeys.ChecklistCouldNotBeRetrieved);
      return;
    }
    setLoading(true);
    try {
      const result = await new Promise<ChecklistViewModel>((resolve) => {
        resolve({});
      });

      setSurveyTitle(result.Title);
      setSiteProjectName(result.SiteProjectName);
      const survey = MapFromChecklistItemViewModelsToEditModels(
        result.Items ?? []
      );
      setOriginalSurvey(survey);
      setEditedSurvey(survey);
    } catch {
      setErrorMessage(ChecklistSpecificKeys.ChecklistCouldNotBeRetrieved);
    } finally {
      setLoading(false);
    }
  }, [currentCustomer, siteProjectId]);

  useEffect(() => {
    if (!editedSurvey) {
      getSurvey();
    }
  }, [getSurvey, editedSurvey]);

  const onUndo = useCallback(() => {
    setEditedSurvey(originalSurvey);
    setErrorMessage(undefined);
  }, [setEditedSurvey, originalSurvey]);

  const saveSurvey = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const customerIdentifier = currentCustomer?.HeaderIdentifier;
      if (!customerIdentifier) return;
      historyHook.push(`/siteProjects/${siteProjectId}`);
      /*
      // TODO: When notification methods in EDS are memoized, use them instead of redirecting
      createInstantNotification(
        translate(
          NamespaceKeys.ChecklistSpecific,
          ChecklistSpecificKeys.ChecklistSaved
        ),
        translate(
          NamespaceKeys.ChecklistSpecific,
          ChecklistSpecificKeys.TheChecklistWasSavedSuccessfully
        ),
        "primary",
        "check",
        5000
      );
      */
    } catch {
      setErrorMessage(ChecklistSpecificKeys.TheChecklistCouldNotBeSaved);
    } finally {
      setLoading(false);
    }
  }, [currentCustomer, siteProjectId, historyHook]);

  useEffect(() => {
    const titleToSet =
      siteProjectName ||
      translate(NamespaceKeys.MenuTitles, MenuTitleKeys.SiteProjectMenuTitle);
    const actions: React.ReactNode = hasSurveyChanged && (
      <>
        <Button onClick={onUndo} iconName="undo">
          {translate(NamespaceKeys.General, GeneralKeys.Undo)}
        </Button>
        <Button primary onClick={saveSurvey} iconName="check">
          {translate(NamespaceKeys.General, GeneralKeys.Save)}
        </Button>
      </>
    );
    setPageTitle && setPageTitle({ title: titleToSet, actions: actions });
  }, [
    translate,
    setPageTitle,
    siteProjectName,
    hasSurveyChanged,
    onUndo,
    saveSurvey
  ]);

  const handleOnItemChanged = useCallback(
    (updatedItem: ChecklistItemEditModel): void => {
      setEditedSurvey(
        (
          prev: ChecklistItemEditModel[] | undefined
        ): ChecklistItemEditModel[] | undefined =>
          prev?.map((item: ChecklistItemEditModel) =>
            item.Id !== updatedItem.Id ? item : updatedItem
          )
      );
    },
    []
  );

  return (
    <ChecklistViewerView
      survey={editedSurvey}
      surveyTitle={surveyTitle}
      errorMessage={errorMessage}
      loading={loading}
      onChecklistRootItemChanged={handleOnItemChanged}
    />
  );
};

export default ChecklistViewerComponent;
