import React, { useCallback, useMemo, useState } from "react";

import { Loader, Wizard, WizardPage, Button, Icon } from "@react-gcc-eds/core";

import { MaterialTransferItemStatus } from "../../../../domain/client-customer";
import {
  ComponentSpecificKeys,
  NamespaceKeys,
  GeneralKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import CenteredContainer from "../../../common/layout/centered-container";
import SmartScanner from "../../../common/smart-scanner";
import {
  NonSerializedItemState,
  SerializedItemState
} from "../../receive-material-overview/shared/receive-material-models";
import { WizardPages } from "./material-transfer-validation-component";
import QuantityValidator from "./quantity-validator";
import Review from "./review";
import SerialNumberValidator from "./serial-number-validator";

type Props = {
  error?: string;
  nonSerializedStateItems: NonSerializedItemState[];
  serializedStateItems: SerializedItemState[];
  loading: boolean;
  completedSteps: WizardPages[];
  currentPage: WizardPages;
  nextButtonDisabled: boolean;
  posting: boolean;
  onNextPage: (nextPage: number, oldPage: number) => void;
  onPreviousPage: (previousPage: number) => void;
  onFinish: () => void | undefined;
  onCancel: () => void;
  onSerializedItemsUpdated: (
    serializedItemsToUpdate: SerializedItemState[]
  ) => void;
  onNonSerializedItemUpdated: (
    nonSerializedItemToUpdate: NonSerializedItemState
  ) => void;
  onStepCompletionStatusChanged: (
    page: WizardPages,
    isCompletable: boolean
  ) => void;
  onRetry: () => void;
};

const MaterialTransferValidationView: (
  props: Props
) => React.ReactElement | null = ({
  error,
  loading,
  nonSerializedStateItems,
  serializedStateItems,
  completedSteps,
  currentPage,
  nextButtonDisabled,
  onNextPage,
  onPreviousPage,
  onFinish,
  posting,
  onCancel,
  onNonSerializedItemUpdated,
  onSerializedItemsUpdated,
  onStepCompletionStatusChanged,
  onRetry
}: Props) => {
  const [showSmartScan, setShowSmartScan] = useState<boolean>(false);
  const [showApproveAllItemsDialog, setShowApproveAllItemsDialog] =
    useState<boolean>(false);
  const { translate } = useTranslation();
  const onApproveAllItemsDialogCancel = useCallback(
    () => setShowApproveAllItemsDialog(false),
    []
  );
  const onValidateAllButtonClicked = useCallback(
    () => setShowApproveAllItemsDialog(true),
    []
  );
  const handleOnApproveAllItemsDialogCompleted = useCallback(() => {
    setShowApproveAllItemsDialog(false);
  }, []);
  const handleOnSmartScanClicked = useCallback(() => {
    setShowSmartScan(true);
  }, []);
  const handleOnCloseSmartScan = useCallback(() => {
    setShowSmartScan(false);
  }, []);

  const noItemsAreValidated = useMemo(
    (): boolean =>
      serializedStateItems.filter(
        (i) => i.status !== MaterialTransferItemStatus.None
      ).length +
        nonSerializedStateItems.filter(
          (i) => i.validationQuantities?.None !== i.quantity
        ).length ===
      0,
    [nonSerializedStateItems, serializedStateItems]
  );

  return error ? (
    <CenteredContainer>
      <div className="material-transfer-error">
        {error}
        <Button className="btn-icon api-call-retry-button" onClick={onRetry}>
          <Icon name="reload" className="btn-icon" />
          {translate(NamespaceKeys.General, GeneralKeys.Retry)}
        </Button>
      </div>
    </CenteredContainer>
  ) : loading ? (
    <CenteredContainer>
      <Loader size="large" />
    </CenteredContainer>
  ) : (
    <>
      <Wizard
        className="material-transfer-validator-container"
        completedSteps={completedSteps}
        pageId={currentPage}
        disableNextButton={nextButtonDisabled}
        hideSaveButton={currentPage !== WizardPages.QuantityValidator}
        labelSaveButton={translate(
          NamespaceKeys.ComponentSpecific,
          ComponentSpecificKeys.ValidateAll
        )}
        onSave={onValidateAllButtonClicked}
        onNextPage={onNextPage}
        onPreviousPage={onPreviousPage}
        onFinish={onFinish}
        onCancel={onCancel}
        disableFinishButton={noItemsAreValidated}
      >
        <WizardPage
          title={translate(
            NamespaceKeys.ComponentSpecific,
            ComponentSpecificKeys.ValidateMaterial
          )}
          page={WizardPages.QuantityValidator}
        >
          <QuantityValidator
            nonSerializedItems={nonSerializedStateItems}
            serializedItems={serializedStateItems}
            showApproveAllItemsDialog={showApproveAllItemsDialog}
            onSmartScanClicked={handleOnSmartScanClicked}
            onSerializedItemsUpdated={onSerializedItemsUpdated}
            onNonSerializedItemUpdated={onNonSerializedItemUpdated}
            onApproveAllItemsDialogCancel={onApproveAllItemsDialogCancel}
            onApproveAllItemsDialogCompleted={
              handleOnApproveAllItemsDialogCompleted
            }
          />
        </WizardPage>
        <WizardPage
          title={translate(
            NamespaceKeys.ComponentSpecific,
            ComponentSpecificKeys.AddSerialNumbers
          )}
          page={WizardPages.SerialNumberValidator}
        >
          <SerialNumberValidator
            serializedItems={serializedStateItems}
            onSmartScanClicked={handleOnSmartScanClicked}
            onSerializedItemsUpdated={onSerializedItemsUpdated}
            onStepCompletionStatusChanged={onStepCompletionStatusChanged}
          />
        </WizardPage>
        <WizardPage
          title={translate(
            NamespaceKeys.ComponentSpecific,
            ComponentSpecificKeys.ReviewChanges
          )}
          page={WizardPages.Review}
        >
          <Review
            posting={posting}
            serializedItems={serializedStateItems}
            nonSerializedItems={nonSerializedStateItems}
          />
        </WizardPage>
      </Wizard>
      {showSmartScan && (
        <SmartScanner
          serializedItems={serializedStateItems}
          nonSerializedItems={nonSerializedStateItems}
          onSerializedItemsUpdate={onSerializedItemsUpdated}
          onNonSerializedItemUpdate={onNonSerializedItemUpdated}
          onClose={handleOnCloseSmartScan}
        />
      )}
    </>
  );
};

export default MaterialTransferValidationView;
