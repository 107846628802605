import React from "react";

import { Tile } from "@react-gcc-eds/core";

type Props = {
  children: JSX.Element[];
};
const ErrorPage = ({ children }: Props): JSX.Element => {
  return (
    <Tile>
      <div>
        <div className="inner-container">{children}</div>
        <hr />
        <div>
          Please contact Ericsson IT Service Desk at +60-362074513 for further
          assistance.
        </div>
      </div>
    </Tile>
  );
};

export default ErrorPage;
