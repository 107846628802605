import React from "react";

import { GenericItemComponent, GenericItemProps } from "../types";
import ChecklistItemDescription from "./checklist-item-description";
import ChecklistItemTitle from "./checklist-item-title";

const ChecklistItemHeader: GenericItemComponent = ({
  item
}: GenericItemProps) => (
  <>
    <ChecklistItemTitle item={item} />
    <ChecklistItemDescription item={item} />
  </>
);

export default ChecklistItemHeader;
