import React from "react";

import ImageElement from "../image-element";
import { SingleItemComponent, SingleItemProps } from "../types";

const ChecklistItemImage: SingleItemComponent = ({
  item,
  onValueChanged
}: SingleItemProps) => (
  <div className="checklist-item">
    <ImageElement item={item} onValueChanged={onValueChanged} />
  </div>
);

export default ChecklistItemImage;
