import React from "react";

import cx from "classnames";
import ITree from "./ITree";

const Tree = ({ children, className, disabled }: ITree) => {
  
  return (
    <div className={cx("tree",{disabled}, className)}>
      <ul>{children}</ul>
    </div>
  );
};

export default Tree;
