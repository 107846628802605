import React from "react";

import cx from "classnames";

import { uniqueId } from "../../utils/utils";
import IProgressBar from "./IProgressBar";

const ProgressBar = ({
  max,
  value = 0,
  buffer,
  color,
  label,
  thin,
  className,
  disabled,
  format = "percent",
  indeterminate = false,
  style
}: IProgressBar) => {
  const id = uniqueId();

  const progressBarLabel = (
    <label className="progress-bar-label" htmlFor={id}>
      {label}
    </label>
  );

  const percent = Math.round((value / max) * 100);
  let progressbarValue = "";
  if (format === "percent") {
    progressbarValue = `${percent}%`;
  }
  if (format === "value") {
    progressbarValue = `${value}/${max}`;
  }
  const progressbarValueWidth = (progressbarValue.toString().length * 12) / 1.5;

  if (indeterminate) {
    return (
      <div
        className={cx("progress-bar", { disabled }, color, thin ? "thin" : "", className)}
        style={style}
      >
        {buffer && label ? (
          <div style={{ marginBottom: "-17px" }}>{progressBarLabel}</div>
        ) : (
          label && progressBarLabel
        )}
        {buffer && <progress className="buffer" value={buffer} max={max} />}
        <progress
          className={cx("progress-bar-progress", { shorten: format === "value" })}
          id={id}
          max={max}
          style={format === "value" ? { width: `calc(100% - ${15}px)` } : {}}
        />
      </div>
    );
  }
  return (
    <div
      className={cx("progress-bar", { disabled }, color, thin ? "thin" : "", className)}
      style={style}
    >
      {buffer && label ? (
        <div style={{ marginBottom: "-17px" }}>{progressBarLabel}</div>
      ) : (
        label && progressBarLabel
      )}
      {buffer && <progress className="buffer" value={buffer} max={max} />}
      <progress
        className={cx("progress-bar-progress", { shorten: format === "value" })}
        id={id}
        value={value}
        max={max}
        style={format === "value" ? { width: `calc(100% - ${15 + progressbarValueWidth}px)` } : {}}
      />
      <span className="progress-bar-value">{progressbarValue}</span>
    </div>
  );
};

export default ProgressBar;
