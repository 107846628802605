import React from "react";
import cx from "classnames";
import { MenuContextProvider } from "./menu-context";
import IMenu from "./IMenu";

const Menu = ({ children, className, closeOnSelect }: IMenu) => {
  return (
    <div className={cx("tree navigation", className)}>
      <ul>
        <MenuContextProvider value={{ closeOnSelect }}>{children}</MenuContextProvider>
      </ul>
    </div>
  );
};

export default Menu;
