import React from "react";

import { MaterialTransferItemStatus } from "../../../../domain/client-customer";
import { DeviationSummaryItem } from "../shared/receive-material-models";

type Props = {
  deviationSummaryItem: DeviationSummaryItem;
};

const constants = {
  reportedAsDamaged: "Reported as damage",
  reportedAsMissing: "Reported as missing"
};

const ReceiveMaterialDeviationsSummaryItem = ({
  deviationSummaryItem
}: Props): React.ReactElement => {
  const renderDeviationSummaryItem = () => {
    let output = `${deviationSummaryItem.quantity} ${deviationSummaryItem.ericssonProductNumber} `;

    output +=
      deviationSummaryItem.status === MaterialTransferItemStatus.Damaged
        ? constants.reportedAsDamaged
        : constants.reportedAsMissing;

    return output;
  };

  return (
    <div className="rm-deviation-summary-list-item">
      {renderDeviationSummaryItem()}
    </div>
  );
};

export default ReceiveMaterialDeviationsSummaryItem;
