import React from "react";
import cx from "classnames";
import { ISettingsItem } from "./contracts";

const SettingsItem = ({ text, children, className}: ISettingsItem) => {
  return (
    <div className={cx("item", className)}>
      {text ? (
        <>
          <div className="left">{text}</div>
          <div className="right">{children}</div>
        </>
      ) : (
        <div>{children}</div>
      )}
    </div>
  );
};
export default SettingsItem;
