import React from "react";

import { Checkbox, Tile, Icon, Button } from "@react-gcc-eds/core";

import {
  GeneralKeys,
  NamespaceKeys,
  ReceivingMaterialSpecificKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import { SelectableMaterialTransferOverviewViewModel } from "../shared/receive-material-models";
import useDeviationSummaries from "../shared/use-deviation-summaries";

type Props = {
  onChangeItem: (
    checked: boolean,
    materialTransferId: string,
    externalHandlingUnit: string
  ) => void;
  onClickDetails: (item: SelectableMaterialTransferOverviewViewModel) => void;
  onShowDeviationSummary: (materialTransferId: string) => void | undefined;
  item: SelectableMaterialTransferOverviewViewModel;
  selectionMode: boolean;
  index: number;
};

const ReceiveMaterialTransferListItem = ({
  item,
  onChangeItem,
  onClickDetails,
  onShowDeviationSummary,
  selectionMode,
  index
}: Props): React.ReactElement => {
  const handleOnChangeItem = (checked: boolean): void => {
    onChangeItem(checked, item.MaterialTransferId, item.ExternalHandlingUnit);
  };

  const { translate } = useTranslation();
  const { deviationSummaryList } = useDeviationSummaries();

  const deviations = React.useMemo(() => {
    if (
      deviationSummaryList != undefined &&
      deviationSummaryList[index]?.deviationSummaryList?.length > 0
    ) {
      return deviationSummaryList[index]?.deviationSummaryList?.length;
    }
  }, [deviationSummaryList, index]);

  return (
    <Tile>
      <div className="rm-transfer-item">
        {selectionMode && (
          <div className="rm-input">
            <Checkbox
              checked={item.Selected}
              text=""
              onChange={handleOnChangeItem}
            />
          </div>
        )}
        <div className="rm-content">
          <div className="rm-company">
            <div className="title">{item.SiteProjectName}</div>
            <div className="subtitle">{item.ToCompanyName}</div>
          </div>
          <div className="rm-info">
            <div className="rm-info-hu">
              <span>
                {translate(
                  NamespaceKeys.ReceivingMaterialSpecific,
                  ReceivingMaterialSpecificKeys.HU
                )}
              </span>
              <span>{item.ExternalHandlingUnit}</span>
            </div>
            <div className="rm-info-items">
              <span>
                {translate(
                  NamespaceKeys.ReceivingMaterialSpecific,
                  ReceivingMaterialSpecificKeys.Items
                )}
              </span>
              <span>{item.NumberOfItems}</span>
            </div>
            <div className="rm-info-qty">
              <span>
                {translate(
                  NamespaceKeys.ReceivingMaterialSpecific,
                  ReceivingMaterialSpecificKeys.Qty
                )}
              </span>
              <span>{item.TotalQuantity}</span>
            </div>
          </div>
        </div>
        <div className="rm-actions">
          <div className="rm-actions-top">
            <Button onClick={() => onClickDetails(item)}>
              {translate(NamespaceKeys.General, GeneralKeys.Details)}
            </Button>
          </div>
          {!selectionMode && (
            <div className="rm-actions-bottom">
              {deviations && deviations > 0 && (
                <div className="rm-actions-bottom-deviations">
                  <span>
                    {translate(
                      NamespaceKeys.ReceivingMaterialSpecific,
                      ReceivingMaterialSpecificKeys.Deviations
                    )}
                  </span>
                  <span>{deviations} </span>
                  <Icon
                    onClick={() =>
                      onShowDeviationSummary(item.MaterialTransferId)
                    }
                    color="blue"
                    name="info"
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Tile>
  );
};

export default ReceiveMaterialTransferListItem;
