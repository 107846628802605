import { useState, useCallback, useEffect, useMemo } from "react";

import { BarcodePicker, Camera, ScanSettings, ScanResult } from "scandit-sdk";

import { AppSettings } from "../../../hooks/app-settings";
import useCameraPermissions from "./helper-hooks/camera-permission-hook";
import useScanditLicense from "./helper-hooks/scandit-license-hook";
import { ScannerProps, ScannerErrorType } from "./scanner-context";
import {
  getDevelopmentCameraIfAvailable,
  getBackFacingCameraOrDefault
} from "./scanner-utils";

export enum ScannerExceptionType {
  ChangeCameraException = "Failed to change camera.",
  EnableCameraException = "Failed to set camera enable status."
}

/**
 * `useScanner` is providing an easy to use interface towards the scandit barcode scanner.
 * @param askForCameraPermissions Async method for requesting camera access from the user, returns `Promise<boolean>` to indicate if access was provided.
 */
const useScanner = (appSettings: AppSettings): ScannerProps => {
  const { licenseConfigured, scanditLicenseError } =
    useScanditLicense(appSettings);
  const { hasCameraAccess, cameras, askForPermission, cameraAccessError } =
    useCameraPermissions();

  const [htmlDivReference, setHtmlDivReference] = useState<HTMLDivElement>();
  const [scanSettings, setScanSettings] = useState<ScanSettings>();
  const [barcodePicker, setBarcodePicker] = useState<BarcodePicker>();
  const [cameraToUse, setCameraToUse] = useState<Camera>();
  const [torchEnabled, setTorchEnabled] = useState<boolean>(false);
  const [startScannerPaused, setStartScannerPaused] = useState<boolean>(false);
  const [cameraEnabled, setCameraEnabled] = useState<boolean>(false);
  const [error, setError] = useState<ScannerErrorType>();

  const cameraToggleEnabled = useMemo<boolean>(
    () => (cameras?.length ?? 0) > 1,
    [cameras]
  );

  useEffect(() => {
    if (
      scanditLicenseError &&
      error !== ScannerErrorType.ScanditLicenseConfigurationError
    ) {
      setError(ScannerErrorType.ScanditLicenseConfigurationError);
    }
  }, [error, scanditLicenseError]);

  useEffect(() => {
    if (cameraAccessError && error !== ScannerErrorType.CameraAccessError) {
      setError(ScannerErrorType.CameraAccessError);
    }
  }, [cameraAccessError, error]);

  const createBarcodePicker = useCallback(
    async (
      htmlDivReference: HTMLDivElement,
      cameraToUse: Camera,
      torchEnabled: boolean,
      scanSettings: ScanSettings,
      startPaused: boolean
    ) => {
      let barcodePickerInstance: BarcodePicker | undefined = undefined;
      try {
        barcodePickerInstance = await BarcodePicker.create(htmlDivReference, {
          camera: cameraToUse,
          guiStyle: BarcodePicker.GuiStyle.NONE,
          enableCameraSwitcher: false,
          enablePinchToZoom: false,
          enableTapToFocus: true,
          enableTorchToggle: true,
          videoFit: BarcodePicker.ObjectFit.COVER,
          playSoundOnScan: true,
          vibrateOnScan: true,
          scanningPaused: startPaused
        });
      } catch (error) {
        setError(ScannerErrorType.BarcodePickerInitializationError);
      }
      barcodePickerInstance?.setTorchEnabled(torchEnabled);
      barcodePickerInstance?.applyScanSettings(scanSettings);
      setCameraEnabled(!startPaused);
      barcodePickerInstance?.applyScanSettings(scanSettings);
      setBarcodePicker(barcodePickerInstance);
    },
    []
  );

  const handleSetCameraEnabled = useCallback(
    (enabled: boolean) => {
      if (!barcodePicker) {
        throw Error(ScannerExceptionType.EnableCameraException);
      }
      setCameraEnabled(enabled);
      if (enabled) barcodePicker.resumeScanning();
      if (!enabled) barcodePicker.pauseScanning(false);
    },
    [barcodePicker]
  );

  const destroyBarcodePickerInstance = useCallback((): void => {
    if (barcodePicker) {
      barcodePicker.destroy(true);
      setBarcodePicker(undefined);
    }
  }, [barcodePicker]);

  useEffect(() => {
    if (
      !htmlDivReference ||
      !scanSettings ||
      barcodePicker ||
      !licenseConfigured ||
      !hasCameraAccess ||
      !cameras ||
      !cameraToUse
    )
      return;
    createBarcodePicker(
      htmlDivReference,
      cameraToUse,
      torchEnabled,
      scanSettings,
      startScannerPaused
    );
  }, [
    barcodePicker,
    cameraToUse,
    cameras,
    createBarcodePicker,
    hasCameraAccess,
    htmlDivReference,
    licenseConfigured,
    scanSettings,
    startScannerPaused,
    torchEnabled
  ]);

  useEffect(() => {
    if (hasCameraAccess || cameras) return;
    if (!htmlDivReference) return;
    askForPermission();
  }, [askForPermission, cameras, hasCameraAccess, htmlDivReference]);

  useEffect(() => {
    if (!cameraToUse && cameras && cameras.length > 0) {
      setCameraToUse(
        getDevelopmentCameraIfAvailable(cameras) ??
          getBackFacingCameraOrDefault(cameras)
      );
    }
  }, [cameraToUse, cameras]);

  useEffect(() => {
    if (!barcodePicker) return;
    return (): void => {
      barcodePicker.destroy(true);
      setBarcodePicker(undefined);
    };
  }, [barcodePicker]);

  const startUsingScanner = useCallback(
    (
      htmlDivReference: HTMLDivElement | null,
      scanSettings: ScanSettings,
      startPaused: boolean
    ) => {
      if (!htmlDivReference) return;
      setHtmlDivReference(htmlDivReference);
      setScanSettings(scanSettings);
      setStartScannerPaused(startPaused);
    },
    []
  );

  const stopUsingScanner = useCallback(() => {
    setHtmlDivReference(undefined);
    destroyBarcodePickerInstance();
  }, [destroyBarcodePickerInstance]);

  const updateOnScanCallback = useCallback(
    (handleOnScan: (scanResult: ScanResult) => void): void => {
      barcodePicker?.removeAllListeners("scan");
      barcodePicker?.on("scan", handleOnScan);
    },
    [barcodePicker]
  );

  const updateOnErrorCallback = useCallback(
    (handleOnError: (scanResult: ScanResult) => void): void => {
      barcodePicker?.removeAllListeners("scanError");
      barcodePicker?.on("scanError", handleOnError);
    },
    [barcodePicker]
  );

  const nextCamera = useCallback(() => {
    if (!cameraToUse || !cameras || !(cameras.length > 1) || !barcodePicker)
      return;
    setCameraToUse((prev: Camera | undefined) => {
      const index = prev ? cameras.indexOf(prev) : 0;
      if (index === 0) {
        const newCamera = cameras[cameras.length - 1];
        barcodePicker.setActiveCamera(newCamera).catch(() => {
          throw Error(ScannerExceptionType.ChangeCameraException);
        });
        return newCamera;
      }
      const newCamera = cameras[index - 1];
      barcodePicker.setActiveCamera(newCamera).catch(() => {
        throw Error(ScannerExceptionType.ChangeCameraException);
      });
      return newCamera;
    });
  }, [barcodePicker, cameraToUse, cameras]);

  const toggleTorch = useCallback(() => {
    if (!barcodePicker) return;
    barcodePicker.setTorchEnabled(!torchEnabled);
    setTorchEnabled((prev: boolean) => !prev);
  }, [barcodePicker, torchEnabled]);

  const handleApplySettings = useCallback(
    (settings: ScanSettings): void => {
      barcodePicker?.applyScanSettings(settings);
      setScanSettings(settings);
    },
    [barcodePicker]
  );

  return {
    startUsingScanner,
    setOnScanCallback: updateOnScanCallback,
    setOnErrorCallback: updateOnErrorCallback,
    nextCamera: nextCamera,
    toggleTorch: toggleTorch,
    cameraToggleEnabled,
    stopUsingScanner,
    cameraEnabled,
    setCameraEnabled: handleSetCameraEnabled,
    scannerError: error,
    applySettings: handleApplySettings
  };
};

export default useScanner;
