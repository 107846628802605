/* tslint:disable:no-console */
export namespace Logger {
  /**
   * Log level.
   */
  export enum Level {
    DEBUG = "debug",
    INFO = "info",
    WARN = "warn",
    ERROR = "error",
    QUIET = "quiet",
  }

  const levelToNumber: Map<Level, number> = new Map([
    [Level.DEBUG, 1],
    [Level.INFO, 2],
    [Level.WARN, 3],
    [Level.ERROR, 4],
    [Level.QUIET, 5],
  ]);

  let currentLevel: Level = Level.DEBUG;

  /**
   * @hidden
   *
   * @param level The log level.
   */
  export function setLevel(level: Level): void {
    currentLevel = level;
  }

  /**
   * @hidden
   *
   * @param level The log level.
   * @param data The log contents.
   */
  // tslint:disable-next-line: no-any
  export function log(level: Exclude<Level, Level.QUIET>, ...data: any[]): void {
    // tslint:disable-next-line: no-non-null-assertion
    if (levelToNumber.get(currentLevel)! > levelToNumber.get(level)!) {
      return;
    }
    switch (level) {
      case Level.DEBUG:
        console.debug(...data);
        break;
      case Level.INFO:
        console.log(...data);
        break;
      case Level.WARN:
        console.warn(...data);
        break;
      case Level.ERROR:
        console.error(...data);
        break;
      default:
        break;
    }
  }
}
