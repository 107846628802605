export const stringToBooleanOrUndefined = (
  stringValue: string
): boolean | undefined => {
  if (stringValue === "true") return true;
  if (stringValue === "false") return false;
  return undefined;
};

export const stringToNumberOrUndefined = (
  stringValue: string
): number | undefined => {
  const numberValue: number = +stringValue;
  return !isNaN(numberValue) ? numberValue : undefined;
};
