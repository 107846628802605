import React, { useState, useEffect, ReactNode, ReactElement, isValidElement } from "react";
import cx from "classnames";

import TileActionButton from "./TileActionButton";
import { isMobileDevice, FlexSize } from "../../utils/utils";
import Icon from "../icon/Icon";

/** @deprecated This component is deprecated, please use MultipleTile instead. */
interface Props {
  /* Tile title */
  title?: string | ReactNode;
  /* Tile sub title */
  subtitle?: string | ReactNode;
  /* Icon for toggling left panel */
  icon?: string | ReactNode;
  /* Icon for toggling left panel */
  /** @deprecated This property is deprecated, please use icon instead. */
  iconName?: string;
  /* Tooltip of icon for toggling left panel */
  iconTooltip?: string;
  /* Tile top right actions */
  actions?: ReactElement<typeof TileActionButton>[] | ReactElement<typeof TileActionButton>;
  /* Mid panel content */
  content?: string | ReactNode | (() => ReactNode);

  /* Left panel title */
  leftTitle?: string | ReactNode;
  /* Tooltip of icon for closing left panel */
  leftCloseTooltip?: string;
  /* Left panel content */
  leftContent?: string | ReactNode | (() => ReactNode);
  leftDefaultOpened?: boolean;
  leftActions?: ReactElement<typeof TileActionButton>[] | ReactElement<typeof TileActionButton>;
  /* show right panel */
  showRight?: boolean;
  /* Right panel title */
  rightTitle?: string;
  /* Right panel sub title */
  rightSubtitle?: string;
  /* Tooltip of icon for closing right panel */
  rightCloseTooltip?: string;
  /* right close callback */
  onRightClosed?(): void;
  /* Right panel content */
  rightContent?: string | ReactNode | (() => ReactNode);

  /* Tile size when rendering in a Row */
  sm?: FlexSize;
  /* Tile size when rendering in a Row */
  md?: FlexSize;
  /* Tile size when rendering in a Row */
  lg?: FlexSize;

  /** Extra css class  */
  /* Root container */
  className?: string | string[];
  /* Left container */
  leftClassName?: string | string[];
  /* Middle container */
  middleClassName?: string | string[];
  /* Right container */
  rightClassName?: string | string[];
}
 /** @deprecated This component is deprecated, please use MultipleTile instead. */
const MultiTile = ({
  title,
  subtitle,
  icon,
  iconName,
  iconTooltip,
  actions,
  content,

  leftTitle,
  leftCloseTooltip,
  leftContent,
  leftDefaultOpened,
  leftActions,

  showRight,
  rightTitle,
  rightSubtitle,
  rightCloseTooltip,
  onRightClosed,
  rightContent,

  sm,
  md,
  lg,

  className,
  leftClassName,
  middleClassName,
  rightClassName
}: Props) => {
  const [leftPanelVisible, setLeftPanelVisible] = useState(leftDefaultOpened);
  const [middlePanelVisible, setMiddlePanelVisible] = useState(true);

  useEffect(() => {
    if (isMobileDevice()) {
      setMiddlePanelVisible(!leftPanelVisible);
    }
  }, [leftPanelVisible]);

  useEffect(() => {
    if (isMobileDevice()) {
      setMiddlePanelVisible(!showRight);
    }
  }, [showRight]);

  const toggleLeftPanel = () => setLeftPanelVisible(visible => !visible);

  const renderPanelContent = (panelContent: string | ReactNode | (() => ReactNode)) => {
    if (typeof panelContent === "function") {
      return panelContent();
    }
    if (isValidElement(panelContent)) {
      return panelContent;
    }
    if (typeof panelContent === "string") {
      return panelContent;
    }
    return null;
  };

  const leftPanel = () => {
    return (
      <div className="left-panel">
        <div className="header">
          <div className="left">
            <span className="title">{leftTitle || ""}</span>
          </div>
          <div className="right">
            {leftActions}
            <div className="action">
              <span role="presentation" className="tooltip pointer" onClick={toggleLeftPanel}>
                <i className="icon icon-cross actionable left-panel-trigger"></i>
                <span className="message bottom-end">{leftCloseTooltip || "Collapse"}</span>
              </span>
            </div>
          </div>
        </div>
        <div className={cx("content", leftClassName)}>
          {leftContent && renderPanelContent(leftContent)}
        </div>
      </div>
    );
  };

  const middlePanel = () => {
    return (
      <div className="mid-panel">
        <div className="header">
          <div className="left">
            <span role="presentation" className="tooltip pointer" onClick={toggleLeftPanel}>
              {typeof icon === "string" && (
                <Icon className="actionable left-panel-trigger" name={icon || "filter"} />
              )}
              {typeof icon !== "string" && icon}
              {icon === undefined && (
                <Icon className="actionable left-panel-trigger" name={iconName || "filter"} />
              )}
              {iconTooltip && (
                <span className="message bottom" style={{ left: -10 }}>
                  {iconTooltip}
                </span>
              )}
            </span>
            <span className="separator"></span>
            <span className="title">{title || ""}</span>
            <span className="subtitle">{subtitle || ""}</span>
          </div>
          <div className="right">{actions}</div>
        </div>

        <div className={cx("content", middleClassName)}>
          {content && renderPanelContent(content)}{" "}
        </div>
      </div>
    );
  };

  const rightPanel = () => {
    return (
      <div className="right-panel">
        <div className="header">
          <div className="left">
            <span className="title">{rightTitle || ""}</span>
            <span className="subtitle">{rightSubtitle || ""}</span>
          </div>
          <div className="right">
            <span role="presentation" className="tooltip pointer" onClick={onRightClosed}>
              <i className="icon icon-cross actionable right-panel-trigger"></i>
              <span className="message bottom-end">{rightCloseTooltip || "Collapse"}</span>
            </span>
          </div>
        </div>
        <div className={cx("content", rightClassName)}>
          {rightContent && renderPanelContent(rightContent)}{" "}
        </div>
      </div>
    );
  };

  return (
    <div
      className={cx(
        "tile sm-12",
        {
          [`sm-${sm}`]: sm,
          [`md-${md}`]: md,
          [`lg-${lg}`]: lg
        },
        className
      )}
      id="t-one-tile"
    >
      <div className="content">
        <div className="row multi-panel-tile">
          {leftPanelVisible && leftPanel()}
          {middlePanelVisible && middlePanel()}
          {showRight && rightPanel()}
        </div>
      </div>
    </div>
  );
};

export default MultiTile;
