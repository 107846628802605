import React, { useMemo } from "react";

import { GenericItemComponent, GenericItemProps } from "../types";

const ChecklistItemTitle: GenericItemComponent = ({
  item
}: GenericItemProps) => {
  const title = useMemo<string>(
    () => `${item.Title}${item.Mandatory ? " *" : ""}`,
    [item.Title, item.Mandatory]
  );

  return <div className="title">{title}</div>;
};

export default ChecklistItemTitle;
