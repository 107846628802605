export const VIEW_ACCESS_KEYS = {
  ACTIVITIES: "Activities",
  SITEPROJECTS: "SiteProjects"
};

export const DocumentType = {
  ErisiteLink: "ErisiteLink",
  Link: "Link",
  Blob: "Blob",
  RestrictedAccess: "RestrictedAccess"
};

export const userRoles = {
  None: "None",
  CrewLeader: "CrewLeader",
  ConstructionManager: "ConstructionManager",
  SupplyResponsible: "SupplyResponsible",
  DplAdministrator: "DplAdministrator",
  SuperUser: "SuperUser",
  ServiceUser: "ServiceUser"
};

export const EventListenerTypeMessage = "message";
export const EventListenerTypeLoadEnd = "loadend";

export const LOCALHOST_PORT = "45187";
export const AZURE_PATH = "scoping";
