import { ReactNode } from "react";

import { MobileViewAccess } from "../../domain/client-customer";
import useMenu from "./useMenu";
import useRoutes from "./useRoutes";

type ViewAccess = {
  menu: JSX.Element;
  routes: ReactNode;
};

const useViewAccess = (
  viewAccess: MobileViewAccess[] | undefined
): ViewAccess => {
  const menu = useMenu(viewAccess);
  const routes = useRoutes(viewAccess);

  return { menu, routes };
};
export default useViewAccess;
