import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useMemo
} from "react";

import { saveAs } from "file-saver";
import FileDownloadWorker from "worker-loader!./file-download-button.worker";

import { AuthenticationContext } from "../../../contexts/authentication-context";
import { CustomerContext } from "../../../contexts/customer-context";
import { FileResponse } from "../../../domain/client-customer";
import FileDownloadButtonView from "./file-download-button-view";
import {
  FileDownloadWorkerProps,
  FileDownloadWorkerResults
} from "./file-download-worker-types";

export type SaveAsData = {
  data: Blob | string;
  filename?: string;
};

type Props = {
  siteProjectId: string;
  documentId: string;
  primary?: boolean;
  saveAsFn: (response: FileResponse) => SaveAsData;
};

const FileDownloadButtonComponent: React.FC<Props> = ({
  siteProjectId,
  documentId,
  primary,
  children,
  saveAsFn
}: React.PropsWithChildren<Props>): React.ReactElement => {
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const { currentCustomer } = useContext(CustomerContext);
  const [loading, setLoading] = useState<boolean>(false);

  const workerInstance = useMemo<FileDownloadWorker>(
    () => new FileDownloadWorker(),
    []
  );

  useEffect(() => {
    return (): void => {
      workerInstance.terminate();
    };
  }, [workerInstance]);

  const handleDownloadClicked = useCallback(async () => {
    if (!currentCustomer?.HeaderIdentifier) return;
    setLoading(true);
    try {
      workerInstance.onerror = (ev: ErrorEvent): void => {
        console.log(ev.message);
      };
      workerInstance.onmessage = (ev): void => {
        const typedMessage = ev.data as FileDownloadWorkerResults;
        if (typedMessage.status !== "success" || !typedMessage.results) {
          console.log(typedMessage.status);
          return;
        }
        const saveAsData = saveAsFn(typedMessage.results);
        saveAs(saveAsData.data, saveAsData.filename);
      };
      const message: FileDownloadWorkerProps = {
        configurationProvider: await getCustomerConfigurationProvider(),
        siteProjectId: siteProjectId,
        documentId: documentId,
        customerHeaderIdentifier: currentCustomer.HeaderIdentifier
      };
      workerInstance.postMessage(message);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    currentCustomer,
    siteProjectId,
    documentId,
    getCustomerConfigurationProvider,
    saveAsFn,
    workerInstance
  ]);

  return (
    <FileDownloadButtonView
      onClick={handleDownloadClicked}
      loading={loading}
      primary={primary}
    >
      {children}
    </FileDownloadButtonView>
  );
};

export default FileDownloadButtonComponent;
