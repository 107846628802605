import React, { ReactElement, useCallback, useState, useEffect } from "react";

import { MaterialTransferItemStatus } from "../../../../../domain/client-customer";
import {
  SerializedInstallationItemState,
  NonSerializedInstallationItemState
} from "../material-installation-validation-component";
import { ValidationQuantities } from "../quantity-validator/quantity-validator-component";
import ReviewView from "./review-view";

type Props = {
  posting: boolean;
  serializedItems: SerializedInstallationItemState[];
  nonSerializedItems: NonSerializedInstallationItemState[];
  totalQuantity: number;
};

const ReviewComponent = ({
  posting,
  serializedItems,
  nonSerializedItems,
  totalQuantity
}: Props): ReactElement => {
  const getStatusAggregate = useCallback(
    (
      serializedItems: SerializedInstallationItemState[],
      nonSerializedItems: NonSerializedInstallationItemState[]
    ) => {
      const statusAggregate: ValidationQuantities = {
        Ok: 0,
        Damaged: 0,
        Missing: 0,
        Excess: 0,
        Incorrect: 0
      };

      Object.values(MaterialTransferItemStatus).forEach((status) => {
        const numberOfSerializedItems = serializedItems.filter(
          (item) => item.status === status
        ).length;
        const numberOfNonSerializedItems = nonSerializedItems
          .map((item) => item.validationQuantities?.[status] ?? 0)
          .reduce((sum, quantity) => sum + quantity, 0);

        statusAggregate[status] =
          numberOfSerializedItems + numberOfNonSerializedItems;
      });

      return statusAggregate;
    },
    []
  );

  const [statusAggregate, setStatusAggregate] = useState<ValidationQuantities>(
    getStatusAggregate(serializedItems, nonSerializedItems)
  );

  useEffect(() => {
    setStatusAggregate(getStatusAggregate(serializedItems, nonSerializedItems));
  }, [getStatusAggregate, serializedItems, nonSerializedItems]);

  return (
    <ReviewView
      statusAggregate={statusAggregate}
      posting={posting}
      serializedItems={serializedItems}
      nonSerializedItems={nonSerializedItems}
      totalQuantity={totalQuantity}
    />
  );
};

export default ReviewComponent;
