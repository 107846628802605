import React, { useCallback } from "react";

import { ChecklistItemImageViewModel } from "../../../../../domain/client-obsolete";
import {
  ChecklistItemImageEditModel,
  ChecklistItemImageRotationEditModel
} from "../../checklist-models";
import { SingleItemProps, SingleItemComponent } from "../types";
import ImageElementView from "./image-element-view";

const ImageElement: SingleItemComponent = ({
  item,
  onValueChanged
}: SingleItemProps) => {
  const handleOnNewImagesAdded = useCallback(
    (newFiles: File[]): void =>
      onValueChanged({
        ...item,
        unsavedImages: [
          ...item.unsavedImages,
          ...newFiles.map(
            (file: File): ChecklistItemImageEditModel => ({
              file: file,
              timestampInTicks: Date.now()
            })
          )
        ]
      }),
    [item, onValueChanged]
  );

  const handleOnUnsavedImageUpdated = useCallback(
    (updatedImage: ChecklistItemImageEditModel): void =>
      onValueChanged({
        ...item,
        unsavedImages: item.unsavedImages.map(
          (
            originalImage: ChecklistItemImageEditModel
          ): ChecklistItemImageEditModel => {
            if (
              originalImage.file.name === updatedImage.file.name &&
              originalImage.file.size === updatedImage.file.size &&
              originalImage.timestampInTicks === updatedImage.timestampInTicks
            ) {
              originalImage.hostedId = updatedImage.hostedId;
              originalImage.hostedUrl = updatedImage.hostedUrl;
            }

            return originalImage;
          }
        )
      }),
    [item, onValueChanged]
  );

  const handleOnSavedImageUpdated = useCallback(
    (updatedImage: ChecklistItemImageRotationEditModel): void =>
      onValueChanged({
        ...item,
        SavedImages: item.SavedImages?.map(
          (
            originalImage: ChecklistItemImageRotationEditModel
          ): ChecklistItemImageRotationEditModel => {
            if (originalImage.Id === updatedImage.Id) {
              originalImage.rotation = updatedImage.rotation;
            }

            return originalImage;
          }
        )
      }),
    [item, onValueChanged]
  );

  const handleOnDeleteUnsavedImage = useCallback(
    (imageToDelete: ChecklistItemImageEditModel): void =>
      onValueChanged({
        ...item,
        unsavedImages: item.unsavedImages.filter(
          (image) => image !== imageToDelete
        )
      }),
    [item, onValueChanged]
  );

  const handleOnDeleteSavedImage = useCallback(
    (imageToDelete: ChecklistItemImageViewModel): void =>
      onValueChanged({
        ...item,
        SavedImages: item.SavedImages?.filter(
          (image) => image.Id !== imageToDelete.Id
        ),
        savedImagesToDelete: [...item.savedImagesToDelete, imageToDelete]
      }),
    [item, onValueChanged]
  );

  return (
    <ImageElementView
      item={item}
      onNewImagesAdded={handleOnNewImagesAdded}
      onUnsavedImageUpdated={handleOnUnsavedImageUpdated}
      onSavedImageUpdated={handleOnSavedImageUpdated}
      onDeleteUnsavedImage={handleOnDeleteUnsavedImage}
      onDeleteSavedImage={handleOnDeleteSavedImage}
    />
  );
};

export default ImageElement;
