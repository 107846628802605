import React, {
  ReactNode,
  isValidElement,
  useEffect,
  useState,
  useCallback
} from "react";
import cx from "classnames";
import { isMobileDevice } from "../../utils/utils";
import * as Listeners from "../../utils/listeners";
import IMultipleTile from "./IMultipleTile";

const MultipleTile = ({
  centerTitle,
  centerSubtitle,
  centerLeftIcon,
  centerRightActions,
  centerContent,

  leftTitle,
  leftSubtitle,
  leftVisible,
  leftContent,
  leftActions,

  // showRight,
  rightTitle,
  rightSubtitle,
  rightVisible,
  rightContent,
  rightActions,

  sm = 12,
  md = 12,
  lg = 12,

  className,
  leftClassName,
  middleClassName,
  rightClassName
}: IMultipleTile) => {
  const [leftInitialized, setLeftInitialized] = useState(false);
  const [rightInitialized, setRightInitialized] = useState(false);
  const [LeftVisible, setLeftVisible] = useState(leftVisible);
  const [RightVisible, setRightVisible] = useState(rightVisible);

  useEffect(() => {
    if (leftVisible !== LeftVisible) {
      setLeftVisible(leftVisible);
      setLeftInitialized(true);
    }

    if (rightVisible !== RightVisible) {
      setRightVisible(rightVisible);
      setRightInitialized(true);
    }
    Listeners.toggleTiles();
  });

  // Windowresize listener
  const [, updateState] = React.useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  useEffect(() => {
    const handleResize = () => {
      forceUpdate();
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const renderPanelContent = (panelContent: string | ReactNode | (() => ReactNode)) => {
    if (typeof panelContent === "function") {
      return panelContent();
    }
    if (isValidElement(panelContent)) {
      return panelContent;
    }
    if (typeof panelContent === "string") {
      return panelContent;
    }
    return null;
  };

  const willHideLeftPanel = !leftInitialized && !leftVisible && !isMobileDevice();

  const leftPanelMobileStyling = isMobileDevice() ? {} : undefined;
  const leftPanelStyling = willHideLeftPanel ? {} : leftPanelMobileStyling;

  const willHideRightPanel = !rightInitialized && !rightVisible && !isMobileDevice();
  const rightPanelMobileStyling = isMobileDevice() ? {} : undefined;
  const rightPanelStyling = willHideRightPanel ? {} : rightPanelMobileStyling;

  const leftPanel = () => {
    return (
      <div
        className={cx("left-panel ", {
          animateLeftPanel: leftInitialized,
          hidden:
            !leftInitialized ||
            (leftInitialized && !LeftVisible) ||
            (isMobileDevice() && !leftInitialized && !LeftVisible) || 
            (isMobileDevice() && LeftVisible && RightVisible)
        })}
        style={leftPanelStyling}
      >
        <div className="header">
          <div className="left">
            <span className="title">{leftTitle || ""}</span>
            <span className="subtitle">{leftSubtitle || ""}</span>
          </div>
          <div className="right">{leftActions}</div>
        </div>
        <div className={cx("content", leftClassName)}>
          {leftContent && renderPanelContent(leftContent)}
        </div>
      </div>
    );
  };
  const middlePanel = () => {
    return (
      <div
        className={cx("mid-panel", {
          hidden: isMobileDevice() && (LeftVisible || RightVisible)
        })}
      >
        <div className="header">
          <div className="left">
            {centerLeftIcon && (
              <>
                {centerLeftIcon}
                <span className="separator"></span>
              </>
            )}

            <span className="title">{centerTitle || ""}</span>
            <span className="subtitle">{centerSubtitle || ""}</span>
          </div>
          <div className="right">{centerRightActions}</div>
        </div>

        <div className={cx("content", middleClassName)}>
          {centerContent &&
            !(isMobileDevice() && (LeftVisible || RightVisible)) &&
            renderPanelContent(centerContent)}
        </div>
      </div>
    );
  };

  const rightPanel = () => {
    return (
      <div
        className={cx("right-panel", {
          hidden:
            !rightInitialized ||
            (rightInitialized && !RightVisible) ||
            (isMobileDevice() && !rightInitialized && !RightVisible)
        })}
        style={rightPanelStyling}
      >
        <div className="header">
          <div className="left">
            <span className="title">{rightTitle || ""}</span>
            <span className="subtitle">{rightSubtitle || ""}</span>
          </div>
          <div className="right">{rightActions}</div>
        </div>
        <div className={cx("content", rightClassName)}>
          {rightContent && renderPanelContent(rightContent)}
        </div>
      </div>
    );
  };

  return (
    <div
      className={cx(
        "tile",
        {
          [`sm-${sm}`]: sm,
          [`md-${md}`]: md,
          [`lg-${lg}`]: lg
        },
        className
      )}
      id="t-one-tile"
    >
      <div className="content">
        <div className="row multi-panel-tile ">
          {leftPanel()}
          {middlePanel()}
          {rightPanel()}
        </div>
      </div>
    </div>
  );
};

export default MultipleTile;
