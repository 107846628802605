import React from "react";

import { useParams } from "react-router";

import { ReceiveMaterialProvider } from "../components/material-handling/receive-material-overview/providers/receive-material-provider";
import ReceiveMaterialValidationWrapper from "../components/material-handling/receive-material-overview/validation/receive-material-validation-wrapper";
import UrlParameters from "../domain/enums/url-parameters";

type MatchProps = {
  [UrlParameters.shipmentReferenceNumber]?: string;
};

const ReceiveMaterialValidationRoute = (): React.ReactElement | null => {
  const { shipmentReferenceNumber } = useParams<MatchProps>();

  return (
    (shipmentReferenceNumber && (
      <ReceiveMaterialProvider>
        <ReceiveMaterialValidationWrapper
          shipmentReferenceNumber={shipmentReferenceNumber}
        />
      </ReceiveMaterialProvider>
    )) ||
    null
  );
};

export default ReceiveMaterialValidationRoute;
