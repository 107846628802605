import React, { ChangeEvent } from "react";
import cx from "classnames";
import Icon from "../icon/Icon";
import IFileUpload from "./IFileUpload";

const FileUpload = ({
  title,
  onChange,
  className,
  disabled,
  accept,
  hideIcon = false
}: IFileUpload) => {
  return (
    <label className={cx("fileupload", { disabled }, className)} >
      <input
        type="file"
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange && onChange(e)}
        accept={accept}
      />
      <span className="btn">
        {!hideIcon && <Icon name="upload" />}
        {title !== undefined ? title : "Choose file"}
      </span>
    </label>
  );
};

export default FileUpload;
