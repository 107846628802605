import * as React from "react";

import { Button, TextField } from "@react-gcc-eds/core";

type CustomTextFieldProps = {
  placeHolder: string;
  buttonLabel: string;
  onClick?: (event: React.MouseEvent<Element>) => void;
  textFieldClass?: string;
  btnClass?: string;
  onChange?: (value: string) => void;
  value: string;
  disabled?: boolean;
};

const CustomTextField = ({
  placeHolder,
  buttonLabel,
  btnClass,
  textFieldClass,
  onClick,
  onChange,
  value,
  disabled
}: CustomTextFieldProps) => {
  return (
    <div className="scanner-box-input-wrap">
      <TextField
        className={textFieldClass || ""}
        placeholder={placeHolder}
        value={value}
        onChange={onChange}
      />
      <Button
        primary
        disabled={disabled}
        className={btnClass || ""}
        onClick={(event: React.MouseEvent<Element>) => {
          onClick?.(event);
        }}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

export default CustomTextField;
