import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import EN from "./dictionaries/en";

const resources: i18n.Resource = {
  en: {
    translation: EN
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  keySeparator: ".",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
