import React, { ReactNode } from "react";
import cx from "classnames";
import useAppContext from "./context/ContextHelpers";
import * as Listeners from "../../utils/listeners";

const AppBar = ({
  title,
  subtitle,
  actions,
  withMenu
}: {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  actions?: React.ReactNode;
  withMenu: boolean;
}) => {
  const noMenuStyle = {
    left: "1em"
  };

  const { menu } = useAppContext();

  return (
    <nav className={cx("appbar")}>
      <div className="actions-left">
        {withMenu && (
          <>
            <div
              className="item"
              onClick={() => {
                menu.toggle();
                Listeners.toggleNavigation();
              }}
              onKeyDown={() => menu.toggle()}
              role="button"
              tabIndex={0}
            >
              <i className={cx("navigation-toggle", { closed: menu.visible })}></i>
            </div>
            <div
              className={cx("menu-anchor", { "open-menu": menu.visible })}
              onClick={() => {
                menu.toggle();
                Listeners.toggleNavigation();
              }}
              onKeyDown={() => menu.toggle()}
              role="button"
              tabIndex={0}
            >
              Menu
            </div>
          </>
        )}
        <div
          style={!withMenu ? noMenuStyle : undefined}
          className={cx("title", { "open-menu": withMenu && menu.visible })}
        >
          {title}
          {subtitle && <span className="subtitle">{subtitle}</span>}
        </div>
      </div>
      <div className="actions-right">{actions}</div>
    </nav>
  );
};

export default AppBar;
