import React, { useCallback, useContext, useEffect } from "react";

import { Button } from "@react-gcc-eds/core";
import { useHistory } from "react-router";

import { LayoutContext } from "../../contexts/layout-context";
import ErrorPage from "./error-page";

const NotFound = (): JSX.Element => {
  const history = useHistory();

  const { pageTitle, setPageTitle } = useContext(LayoutContext);

  useEffect(() => {
    if (!pageTitle && setPageTitle) setPageTitle({ title: "Page not found" });
  }, [pageTitle, setPageTitle]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
      e.preventDefault();
      history.push("/");
    },
    [history]
  );
  return (
    <ErrorPage>
      <p className="error-heading">
        We are sorry, but we cannot find what you were looking for...
      </p>
      <div>
        <Button primary className="btn btn-primary" onClick={handleClick}>
          Return to home
        </Button>
      </div>
    </ErrorPage>
  );
};
export default NotFound;
