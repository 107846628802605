import React, { useCallback, useMemo } from "react";

import { NumberField } from "@react-gcc-eds/core";

import ChecklistStandardItem from "./generic/checklist-standard-item";
import { stringToNumberOrUndefined } from "./string-to-type-deserializer";
import { SingleItemProps, SingleItemComponent } from "./types";

const NumericElement: SingleItemComponent = ({
  item,
  onValueChanged
}: SingleItemProps) => {
  const valueToNumber: number = useMemo(
    (): number =>
      item.Values?.length ? stringToNumberOrUndefined(item.Values[0]) ?? 0 : 0,
    [item]
  );

  const handleOnChange = useCallback(
    (value: number): void =>
      onValueChanged({
        ...item,
        Values: [value.toString()]
      }),
    [item, onValueChanged]
  );

  return (
    <ChecklistStandardItem item={item} onValueChanged={onValueChanged}>
      <NumberField
        type="spinner"
        value={valueToNumber}
        onChange={handleOnChange}
        className="number-input"
      />
    </ChecklistStandardItem>
  );
};

export default NumericElement;
