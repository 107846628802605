import React, { useState, useEffect, useContext, useCallback } from "react";

import { LayoutContext } from "../../../../contexts/layout-context";
import { MaterialTransferByShipmentNumberViewModel } from "../../../../domain/client-customer";
import {
  MenuTitleKeys,
  NamespaceKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import { ReceiveMaterialContext } from "../contexts/receive-material-context";
import ReceiveMaterialReportDeviationViewComponent from "../deviation/receive-material-report-deviation-view-component";
import ReceiveMaterialScanSerialNumbersTransferListComponent from "../scan-serial-numbers/scan-serial-numbers-transfer-list-component";
import {
  ComponentViews,
  SelectableMaterialTransferOverviewViewModel
} from "../shared/receive-material-models";
import useFetchMaterialTransfersByShipmentNumber from "../shared/use-fetch-material-transfers-by-shipment-number";
import useFetchTransfersDetails from "../shared/use-fetch-transfers-details";
import ReceiveMaterialValidationSummary from "../transfer-summary/receive-material-validation-summary";
import ReceiveMaterialTransferList from "./../transfer-list/receive-material-transfer-list";

type Props = {
  shipmentReferenceNumber: string;
};

const ReceiveMaterialValidationComponent = ({
  shipmentReferenceNumber
}: Props): React.ReactElement => {
  const { setPageTitle } = useContext(LayoutContext);
  const {
    transfersOverviewStateItems,
    setTransfersOverviewStateItems,
    setTransferDetailsStateItems
  } = useContext(ReceiveMaterialContext);

  const { currentView, setCurrentView } = useContext(ReceiveMaterialContext);
  const { translate } = useTranslation();
  const {
    data: transfersOverviewItems,
    loading,
    error
  } = useFetchMaterialTransfersByShipmentNumber(shipmentReferenceNumber ?? "");

  const [transfersSelectionMode, setTransfersSelectionMode] =
    useState<boolean>(true);
  const [selectedTransferIds, setSelectedTransferIds] = useState<
    MaterialTransferByShipmentNumberViewModel[]
  >([]);

  const { data: transferDetailsItems } =
    useFetchTransfersDetails(selectedTransferIds);

  useEffect(() => {
    setTransfersOverviewStateItems?.(transfersOverviewItems);
  }, [transfersOverviewItems, setTransfersOverviewStateItems]);

  useEffect(() => {
    setTransferDetailsStateItems?.([...transferDetailsItems]);
  }, [transferDetailsItems, setTransferDetailsStateItems]);

  const handleOnChangeViewMode = useCallback(
    (viewMode: boolean) => {
      setTransfersSelectionMode(viewMode);
      setSelectedTransferIds(
        transfersOverviewStateItems?.filter((q) => q.Selected)
      );
    },
    [transfersOverviewStateItems]
  );

  const handleOnChangeSelectAll = React.useCallback(
    (newValue: boolean) => {
      setTransfersOverviewStateItems?.(
        (preItems) =>
          preItems?.map((item) => {
            return { ...item, Selected: newValue };
          }) as SelectableMaterialTransferOverviewViewModel[]
      );
    },
    [setTransfersOverviewStateItems]
  );

  const handleOnChangeItem = React.useCallback(
    (
      checked: boolean,
      materialTransferId: string,
      externalHandlingUnit: string
    ): void => {
      setTransfersOverviewStateItems?.((prevList) =>
        prevList?.map((item) => {
          return item.MaterialTransferId === materialTransferId &&
            item.ExternalHandlingUnit === externalHandlingUnit
            ? { ...item, Selected: checked }
            : item;
        })
      );
    },
    [setTransfersOverviewStateItems]
  );

  useEffect(() => {
    setPageTitle &&
      setPageTitle({
        title: translate(
          NamespaceKeys.MenuTitles,
          MenuTitleKeys.ReceiveMaterialListOverviewTitle
        )
      });
  }, [translate, setPageTitle, setCurrentView]);

  return (
    <>
      {currentView === ComponentViews.TransferList && (
        <ReceiveMaterialTransferList
          error={error}
          loading={loading}
          items={transfersOverviewStateItems ?? []}
          selectionMode={transfersSelectionMode}
          onChangeItem={handleOnChangeItem}
          onChangeSelectionMode={handleOnChangeViewMode}
          onChangeSelectAllItems={handleOnChangeSelectAll}
        />
      )}
      {currentView === ComponentViews.ScanSerialNumbers && (
        <ReceiveMaterialScanSerialNumbersTransferListComponent />
      )}

      {currentView === ComponentViews.ReportDeviation && (
        <ReceiveMaterialReportDeviationViewComponent />
      )}

      {currentView === ComponentViews.ValidationSummery && (
        <ReceiveMaterialValidationSummary />
      )}
    </>
  );
};

export default ReceiveMaterialValidationComponent;
