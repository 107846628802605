import React, { useState, useEffect, ChangeEvent } from "react";
import cx from "classnames";
import ITimepicker from "./ITimepicker";
import { useClickOutside } from "../../utils/utils";
import * as timeHelper from "./helpers";

const Timepicker = ({
  value,
  meridiem,
  type,
  format,
  disabled,
  onChange,
  onHourChange,
  onMinuteChange,
  onMeridiemChange,
  min,
  max,
  step,
  disable
}: ITimepicker) => {
  const isMeridiem = format === "12";
  const combinedPlaceholder = isMeridiem ? "HH:MM AM" : "HH:MM";
  const combinedDisabled = disable ? disable.split(", ") : [];

  // Opening and closing menus
  const [hourOpen, setHourOpen] = useState(false);
  const [minuteOpen, setMinuteOpen] = useState(false);
  const [meridiemOpen, setMeridiemOpen] = useState(false);
  const [combinedOpen, setCombinedOpen] = useState(false);

  const containerHourRef = useClickOutside(() => setHourOpen(false));
  const containerMinuteRef = useClickOutside(() => setMinuteOpen(false));
  const containerMeridiemRef = useClickOutside(() => setMeridiemOpen(false));
  const containerCombinedRef = useClickOutside(() => setCombinedOpen(false));

  // Generate menu options
  const meridiemItems = ["AM", "PM"];
  const hourItems = timeHelper.getHours(format);
  const minuteItems = timeHelper.getMinutes(step);
  const combinedItems = [combinedPlaceholder, ...timeHelper.getCombined(format, step, min, max)];

  const combinedText = value;
  const hourText = value && value.includes(":") ? value.split(":")[0] : "";
  const minuteText = value && value.includes(":") ? value.split(":")[1] : "";
  const meridiemText = isMeridiem && meridiem ? meridiem : "";

  // Scrolling
  const hourRefs = hourItems.reduce((acc, invalue) => {
    acc[hourItems.indexOf(invalue)] = React.createRef();
    return acc;
  }, {});

  const minuteRefs = minuteItems.reduce((acc, invalue) => {
    acc[minuteItems.indexOf(invalue)] = React.createRef();
    return acc;
  }, {});

  const handleHourScroll = (id: number) => {
    return hourRefs[id].current.scrollIntoView({
      block: "start"
    });
  };

  const handleMinuteScroll = (id: number) => {
    return minuteRefs[id].current.scrollIntoView({
      block: "start"
    });
  };

  // Highlighting
  const [hourHighlight, setHourHighlight] = useState<number>(-1);
  const [minuteHighlight, setMinuteHighlight] = useState<number>(-1);

  const findFirstMatch = (items: string[], typedValue: string) => {
    if (!typedValue) {
      return -1;
    }
    for (let i = 0; i < items.length; i += 1) {
      if (items[i].includes(typedValue)) {
        return i;
      }
    }
    return -1;
  };

  useEffect(() => {
    if (type === "separate") {
      const index = findFirstMatch(hourItems, hourText);
      if (index !== -1) {
        setHourHighlight(index);
        handleHourScroll(index);
      } else {
        setHourHighlight(-1);
        handleHourScroll(0);
      }
    }
  }, [hourText]);

  useEffect(() => {
    if (type === "separate") {
      const index = findFirstMatch(minuteItems, minuteText);
      if (index !== -1) {
        setMinuteHighlight(index);
        handleMinuteScroll(index);
      } else {
        setMinuteHighlight(-1);
        handleMinuteScroll(0);
      }
    }
  }, [minuteText]);

  const isOutOfViewport = (elem: HTMLDivElement, items: string[]) => {
    const itemHeightOffset = 32;
    const maxOffset = 130;
    const offsetHeight = items.length < 4 ? itemHeightOffset * items.length : maxOffset;
    const bounding = elem.getBoundingClientRect();

    return (
      bounding.bottom + offsetHeight > (window.innerHeight || document.documentElement.clientHeight)
    );
  };

  const outOfViewport = (ref: React.RefObject<HTMLDivElement>, items: string[]) => {
    return ref.current ? isOutOfViewport(ref.current, items) : false;
  };

  const meridiemButton = isMeridiem ? (
    <div
      className={cx(
        "tp-interval-select tp-select",
        { open: meridiemOpen },
        { closed: !meridiemOpen },
        { "open-top": outOfViewport(containerMeridiemRef, meridiemItems) }
      )}
      ref={containerMeridiemRef}
      role="presentation"
      onClick={e => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        setMeridiemOpen(!meridiemOpen);
      }}
    >
      <button type="button" className="btn tp-select-current">
        {meridiemText}
      </button>
      <div className="tp-select-options">
        {meridiemItems.map((item: string) => (
          <div
            key={item}
            title={item}
            role="presentation"
            onClick={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              if (onMeridiemChange !== undefined) {
                onMeridiemChange(item);
              }
              setMeridiemOpen(false);
            }}
            className={cx("tp-select-option", { active: item === meridiemText })}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  ) : null;

  const hourButton = (
    <div
      className={cx(
        "tp-select tp-hours-select",
        { open: hourOpen },
        { closed: !hourOpen },
        { "open-top": outOfViewport(containerHourRef, hourItems) }
      )}
      ref={containerHourRef}
      role="presentation"
      onClick={e => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        setHourOpen(!hourOpen);
      }}
    >
      <input
        value={hourText || ""}
        type="text"
        maxLength={2}
        className="tp-select-current tp-hh-input"
        placeholder="HH"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.value === "") {
            setHourOpen(true);
          }
          if (onHourChange) {
            onHourChange(e.target.value);
          }
        }}
      />
      <div className="tp-select-options">
        {hourItems.map((item: string, index: number) => (
          <div
            ref={hourRefs[index]}
            key={item}
            title={item}
            role="presentation"
            onClick={e => {
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
              if (onHourChange !== undefined) {
                onHourChange(item);
              }
              setHourOpen(false);
            }}
            className={cx("tp-select-option", { active: index === hourHighlight })}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );

  const minuteButton = (
    <div
      className={cx(
        "tp-select tp-minutes-select",
        { open: minuteOpen },
        { closed: !minuteOpen },
        { "open-top": outOfViewport(containerMinuteRef, minuteItems) }
      )}
      ref={containerMinuteRef}
      role="presentation"
      onClick={e => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        setMinuteOpen(!minuteOpen);
      }}
    >
      <input
        value={minuteText || ""}
        type="text"
        maxLength={2}
        className="tp-select-current tp-mm-input"
        placeholder="MM"
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          if (e.target.value === "") {
            setMinuteOpen(true);
          }
          if (onMinuteChange) {
            onMinuteChange(e.target.value);
          }
        }}
      ></input>
      <div className="tp-select-options">
        {minuteItems.map((item: string, index: number) => {
          return (
            <div
              ref={minuteRefs[index]}
              key={item}
              title={item}
              role="presentation"
              onClick={e => {
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                if (onMinuteChange !== undefined) {
                  onMinuteChange(item);
                }
                setMinuteOpen(false);
              }}
              className={cx("tp-select-option", { active: index === minuteHighlight })}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );

  if (type === "separate") {
    return (
      <div className={cx("time-picker", { disabled })}>
        {hourButton}
        <span className="tp-col-sep">:</span>
        {minuteButton}
        {meridiemButton}
      </div>
    );
  }

  if (type === "combined") {
    return (
      <div className={cx("time-picker", { disabled })}>
        <div
          className={cx(
            "tp-single-select tp-select",
            `time-format-${format}`,
            { open: combinedOpen },
            { closed: !combinedOpen }
          )}
          ref={containerCombinedRef}
          role="presentation"
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            setCombinedOpen(!combinedOpen);
          }}
        >
          <button type="button" className="btn tp-select-current">
            {combinedText || combinedPlaceholder}
          </button>
          <div className="tp-select-options">
            {combinedItems.map((item: string) => {
              return (
                <div
                  key={item}
                  title={item}
                  role="presentation"
                  onClick={e => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    if (onChange !== undefined) {
                      onChange(item);
                    }
                    setCombinedOpen(false);
                  }}
                  className={cx(
                    "tp-select-option",
                    { active: item === combinedText },
                    { disabled: combinedDisabled.includes(item) }
                  )}
                >
                  {item}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  if (type === "time") {
    return (
      <input
        type="time"
        value={value}
        onChange={(e: any) => e.target.value && onChange && onChange(e.target.value)}
      />
    );
  }

  return <div className="time-picker invalid-type">Invalid type</div>;
};

export default Timepicker;
