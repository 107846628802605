import React from "react";

import { useParams } from "react-router";

import MaterialTransferValidationWrapper from "../components/material-handling/material-transfer/validation/material-transfer-validation-wrapper";
import UrlParameters from "../domain/enums/url-parameters";

type MatchProps = {
  [UrlParameters.materialTransferId]?: string;
};

const MaterialTransferValidationRoute = (): React.ReactElement | null => {
  const { materialTransferId } = useParams<MatchProps>();

  return (
    (materialTransferId && (
      <MaterialTransferValidationWrapper
        materialTransferId={materialTransferId}
      />
    )) ||
    null
  );
};

export default MaterialTransferValidationRoute;
