import React, { useMemo } from "react";

import { useParams } from "react-router";

import ChecklistViewer from "../components/site-projects/checklist-viewer";
import UrlParameters from "../domain/enums/url-parameters";

type MatchProps = {
  [UrlParameters.siteProjectId]: string | undefined;
  [UrlParameters.activityId]: string | undefined;
  [UrlParameters.surveyId]: string | undefined;
};

const ChecklistViewerRoute = (): React.ReactElement | null => {
  const { siteProjectId, activityId, surveyId } = useParams<MatchProps>();
  const activityIdAsNumber = useMemo<number | undefined>(
    () => (activityId ? Number.parseInt(activityId) : undefined),
    [activityId]
  );

  return (
    (siteProjectId && activityIdAsNumber && surveyId && (
      <ChecklistViewer
        siteProjectId={siteProjectId}
        activityId={activityIdAsNumber}
        surveyId={surveyId}
      />
    )) ||
    null
  );
};

export default ChecklistViewerRoute;
