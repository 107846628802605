import React, { useContext } from "react";

import { useSettings } from "@react-gcc-eds/core";

import { AppSettingsContext } from "../../../../contexts/app-settings-context";
import MapTileView from "./map-tile-view";

type Props = {
  lat: number;
  lng: number;
  zoom: number;
  city: string;
};

const MapTileComponent = ({ lat, lng, zoom, city }: Props): JSX.Element => {
  const appSettings = useContext(AppSettingsContext);
  const { theme } = useSettings();

  return appSettings.googleMapsKey ? (
    <MapTileView
      theme={theme}
      lat={lat}
      lng={lng}
      city={city}
      zoom={zoom}
      mapsKey={appSettings.googleMapsKey}
    />
  ) : (
    <></>
  );
};

export default MapTileComponent;
