import React, { useContext } from "react";

import { AcceptedFileFormatContext } from "../../../../../contexts/accepted-file-formats-context";
import { ChecklistItemImageViewModel } from "../../../../../domain/client-obsolete";
import {
  ChecklistSpecificKeys,
  NamespaceKeys
} from "../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../translation/translation-utils";
import FileUploadButton from "../../../../common/file-upload-button";
import {
  ChecklistItemEditModel,
  ChecklistItemImageEditModel,
  ChecklistItemImageRotationEditModel
} from "../../checklist-models";
import ChecklistItemHeader from "../generic/checklist-item-header";
import UploadedImagesContainer from "./uploaded-images/uploaded-images-container";

type Props = {
  item: ChecklistItemEditModel;
  onNewImagesAdded: (files: File[]) => void;
  onSavedImageUpdated: (
    updatedImage: ChecklistItemImageRotationEditModel
  ) => void;
  onUnsavedImageUpdated: (updatedImage: ChecklistItemImageEditModel) => void;
  onDeleteUnsavedImage: (image: ChecklistItemImageEditModel) => void;
  onDeleteSavedImage: (imageToDelete: ChecklistItemImageViewModel) => void;
};

const ImageElementView = ({
  item,
  onUnsavedImageUpdated,
  onSavedImageUpdated,
  onNewImagesAdded,
  onDeleteUnsavedImage,
  onDeleteSavedImage
}: Props): React.ReactElement => {
  const { translate } = useTranslation();
  const { imageFormats } = useContext(AcceptedFileFormatContext);
  return (
    <>
      <ChecklistItemHeader item={item} />
      <UploadedImagesContainer
        item={item}
        onUnsavedImageUpdated={onUnsavedImageUpdated}
        onSavedImageUpdated={onSavedImageUpdated}
        onDeleteUnsavedImage={onDeleteUnsavedImage}
        onDeleteSavedImage={onDeleteSavedImage}
      />
      <div className="image-button">
        <FileUploadButton
          title={translate(
            NamespaceKeys.ChecklistSpecific,
            ChecklistSpecificKeys.UploadImages
          )}
          onChange={onNewImagesAdded}
          allowedFileFormats={imageFormats ?? []}
          multiple
        />
      </div>
    </>
  );
};

export default ImageElementView;
