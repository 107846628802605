import {
  DeliveryProductListItemModel,
  MaterialTransferItemStatus,
  MaterialTransferValidationSerializedItemsRequestModel,
  MaterialTransferValidationNonSerializedItemRequestModel,
  MaterialTransferDetailsNonSerializedItemViewModel,
  MaterialTransferDetailsSerializedItemViewModel,
  MaterialTransferItemStatusAndQuantityRequestModel
} from "../../../../domain/client-customer";
import {
  NonSerializedItemState,
  SerializedItemState
} from "../../receive-material-overview/shared/receive-material-models";
import { validationQuantitiesKeys } from "./material-transfer-validation-component";
import {
  QuantityValidationStateItem,
  ValidationQuantities
} from "./quantity-validator/quantity-validator-component";

export const changesOnNonSerializedItems = (
  originalNonSerialized: NonSerializedItemState[],
  outputNonSerialized: NonSerializedItemState[]
): boolean =>
  outputNonSerialized.some((currentItem: NonSerializedItemState) => {
    const matchingItem = originalNonSerialized.find(
      (i) =>
        i.billOfMaterialItemIdentity === currentItem.billOfMaterialItemIdentity
    );

    if (!matchingItem) {
      return true;
    }

    if (
      currentItem.quantityValidated !== matchingItem.quantityValidated ||
      validationQuantitiesKeys.some(
        (statusKey) =>
          currentItem.validationQuantities?.[statusKey] !==
          matchingItem.validationQuantities?.[statusKey]
      )
    ) {
      return true;
    }

    return false;
  });

export const changesOnSerializedItems = (
  originalSerialized: SerializedItemState[],
  outputSerialized: SerializedItemState[]
): boolean =>
  outputSerialized.some((currentItem: SerializedItemState) => {
    const matchingItem = originalSerialized.find(
      (i) => i.customerSerialNumber === currentItem.customerSerialNumber
    );

    if (!matchingItem) {
      return true;
    }

    if (
      currentItem.quantityValidated !== matchingItem.quantityValidated ||
      currentItem.status !== matchingItem.status ||
      currentItem.ericssonSerialNumber !== matchingItem.ericssonSerialNumber ||
      currentItem.customerSerialNumber !== matchingItem.customerSerialNumber ||
      currentItem.serialNumbersValidated !== matchingItem.serialNumbersValidated
    ) {
      return true;
    }

    return false;
  });

export const fromNonSerializedToGeneric = (
  nonSerializedItems: NonSerializedItemState[]
): QuantityValidationStateItem[] =>
  nonSerializedItems.map(
    (i: NonSerializedItemState): QuantityValidationStateItem => ({
      isSerialized: false,
      quantityValidated: i.quantityValidated,
      dplItem: i.dplItem,
      materialCategory: i.materialCategory,
      quantity: i.quantity,
      validationQuantities: {
        None: i.validationQuantities?.None ?? 0,
        Ok: i.validationQuantities?.Ok ?? 0,
        Damaged: i.validationQuantities?.Damaged ?? 0,
        Excess: i.validationQuantities?.Excess ?? 0,
        Incorrect: i.validationQuantities?.Incorrect ?? 0,
        Missing: i.validationQuantities?.Missing ?? 0
      },
      note: i.note
    })
  );

export const fromSerializedToGeneric = (
  serializedItems: SerializedItemState[]
): QuantityValidationStateItem[] =>
  serializedItems.reduce((items, currentItem) => {
    const matchingItem = items.find(
      (i) =>
        i.dplItem?.Id === currentItem.dplItem?.Id &&
        i.materialCategory === currentItem.materialCategory
    );
    const status = currentItem.status ?? MaterialTransferItemStatus.None;

    if (matchingItem) {
      matchingItem.quantity++;
      matchingItem.validationQuantities = {
        ...matchingItem.validationQuantities
      };
      matchingItem.validationQuantities[status] =
        (matchingItem.validationQuantities[status] ?? 0) + 1;
      return items;
    } else {
      return [
        ...items,
        {
          isSerialized: true,
          quantityValidated: currentItem.quantityValidated,
          dplItem: currentItem.dplItem,
          materialCategory: currentItem.materialCategory,
          quantity: 1,
          validationQuantities: {
            [status]: 1
          },
          note: currentItem.note
        }
      ];
    }
  }, [] as QuantityValidationStateItem[]);

export const findDplItemById = (
  dplItemId: string | undefined,
  dplItems: DeliveryProductListItemModel[] | undefined
): DeliveryProductListItemModel => {
  if (!dplItemId) throw new Error("Parameter dplItemId was not provided.");
  if (!dplItems) throw new Error("Parameter dplItems was not provided.");

  const dplItem = dplItems.find((dplItem) => dplItem.Id === dplItemId);
  if (!dplItem) throw new Error("No DPL item was matching");

  return dplItem;
};

export const toNonSerializedItemState = (
  nonSerializedViewModel: MaterialTransferDetailsNonSerializedItemViewModel,
  dplItems: DeliveryProductListItemModel[] | undefined
): NonSerializedItemState => {
  const dplItem = findDplItemById(nonSerializedViewModel.DplItemId, dplItems);
  if (
    !nonSerializedViewModel.MaterialCategory ||
    !nonSerializedViewModel.HandlingUnitQuantity ||
    !nonSerializedViewModel.BillOfMaterialItemIdentity
  ) {
    throw new Error("Item is missing values and could not be mapped.");
  }
  return {
    dplItem: dplItem,
    materialCategory: nonSerializedViewModel.MaterialCategory,
    billOfMaterialItemIdentity:
      nonSerializedViewModel.BillOfMaterialItemIdentity,
    quantity: nonSerializedViewModel.DispatchItemQuantity,
    quantityValidated: false,
    validationQuantities: {
      None: nonSerializedViewModel.HandlingUnitQuantity
    },
    note: nonSerializedViewModel.Note,
    handligUnitQuantity: nonSerializedViewModel.HandlingUnitQuantity
  };
};

export const toSerializedItemState = (
  serializedViewModel: MaterialTransferDetailsSerializedItemViewModel,
  dplItems: DeliveryProductListItemModel[] | undefined
): SerializedItemState => {
  const dplItem = findDplItemById(serializedViewModel.DplItemId, dplItems);
  if (
    !serializedViewModel.MaterialCategory ||
    !serializedViewModel.SerialNumbersNeeded ||
    !serializedViewModel.BillOfMaterialItemIdentity
  ) {
    throw new Error("Item is missing values and could not be mapped.");
  }
  return {
    dplItem: dplItem,
    quantity: serializedViewModel.DispatchItemQuantity,
    materialCategory: serializedViewModel.MaterialCategory,
    billOfMaterialItemIdentity: serializedViewModel.BillOfMaterialItemIdentity,
    quantityValidated: false,
    serialNumbersValidated: false,
    serialNumbersNeeded: serializedViewModel.SerialNumbersNeeded,
    isSerialNumbersLocked: false,
    status: MaterialTransferItemStatus.None,
    note: serializedViewModel.Note,
    handligUnitQuantity: serializedViewModel.HandlingUnitQuantity,
    serialNumberIndex: serializedViewModel.SerialNumberIndex,
    ericssonSerialNumber: serializedViewModel.EricssonSerialNumber
  };
};

export const toValidationQuantities = (
  itemValidationQuantities: ValidationQuantities | undefined
): MaterialTransferItemStatusAndQuantityRequestModel[] => {
  const validationQuantities: MaterialTransferItemStatusAndQuantityRequestModel[] =
    [];
  if (!itemValidationQuantities) return validationQuantities;
  for (const k in itemValidationQuantities) {
    try {
      const status: MaterialTransferItemStatus =
        MaterialTransferItemStatus[k as keyof ValidationQuantities];
      const quantity: number | undefined = itemValidationQuantities[status];
      if (quantity) {
        validationQuantities.push({
          Status: status,
          Quantity: quantity
        });
      }
    } catch (error) {
      throw Error(
        "Error occurred when mapping validation quantity statuses, validation data is not valid."
      );
    }
  }

  return validationQuantities;
};

export const toMaterialTransferValidationNonSerializedItemsRequestModel = (
  nonSerializedItem: NonSerializedItemState
): MaterialTransferValidationNonSerializedItemRequestModel => ({
  BillOfMaterialItemIdentity: nonSerializedItem.billOfMaterialItemIdentity,
  StatusAndQuantities: toValidationQuantities(
    nonSerializedItem.validationQuantities
  )
});

export const toMaterialTransferValidationSerializedItemsRequestModel = (
  serializedItem: SerializedItemState
): MaterialTransferValidationSerializedItemsRequestModel => {
  const requestModel: MaterialTransferValidationSerializedItemsRequestModel = {
    BillOfMaterialItemIdentity: serializedItem.billOfMaterialItemIdentity,
    Status: serializedItem.status,
    CustomerSerialNumber: serializedItem.customerSerialNumber,
    EricssonSerialNumber: serializedItem.ericssonSerialNumber
  };
  return requestModel;
};
