import React from "react";

import { mdiCameraRetakeOutline } from "@mdi/js";
import { Icon as MaterialDesignIcon } from "@mdi/react";
import {
  Icon,
  TextField,
  Pill,
  Row,
  Column,
  Button
} from "@react-gcc-eds/core";

import { MaterialTransferItemStatus } from "../../../domain/client-customer";
import useWindowDimensions from "../../../hooks/window-dimensions";
import {
  NamespaceKeys,
  ScannerSpecificKeys,
  DplSpecificKeys,
  GeneralKeys,
  BomSpecificKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";
import { FeedbackMessage } from "../barcode-scanning/barcode-scanning-models/feedback-message";
import FeedbackStatus from "../barcode-scanning/barcode-scanning-models/feedback-status";
import { SearchAreaType } from "../barcode-scanning/search-area-hook";
import Viewfinder from "../barcode-scanning/viewfinder";
import TitleValuePair from "../layout/title-value-pair";
import { ScannedItem, ValidTimer } from "./smart-scanner-component";

type Props = {
  searchArea: SearchAreaType;
  scanning: boolean;
  scannedItem: ScannedItem | undefined;
  title: string | undefined;
  subtitle: string | undefined;
  feedbackMessage: FeedbackMessage | undefined;
  progressData: ValidTimer | undefined;
  showChangeCameraButton: boolean;
  refFn: (instance: HTMLDivElement | null) => void;
  onClose: () => void;
  onScanStart: () => void;
  onScanEnd: () => void;
  onStatusOkToggle: (newValue: boolean) => void;
  onStatusDamagedToggle: (newValue: boolean) => void;
  onClickRestoreScannedItem: () => void;
  onClickRestoreEricssonSerialNumber: () => void;
  onClickRestoreCustomerSerialNumber: () => void;
  onClickChangeCamera: () => void;
};

const SmartScannerView = ({
  onClose,
  refFn,
  searchArea,
  scanning,
  onScanStart,
  onScanEnd,
  scannedItem,
  title,
  subtitle,
  feedbackMessage,
  onStatusOkToggle,
  onStatusDamagedToggle,
  onClickRestoreScannedItem,
  onClickRestoreEricssonSerialNumber,
  onClickRestoreCustomerSerialNumber,
  progressData,
  onClickChangeCamera,
  showChangeCameraButton
}: Props): React.ReactElement => {
  const { translate } = useTranslation();
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();
  return (
    <div
      className="scanner-container tile"
      style={{
        height: windowHeight,
        width: windowWidth
      }}
    >
      <div className="header">
        <div className="left">
          <Icon name="cross" onClick={onClose} />
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
        </div>
        <div className="right">
          {showChangeCameraButton && (
            <div
              className="action material-design-action-icon-fix"
              onClick={onClickChangeCamera}
            >
              <MaterialDesignIcon path={mdiCameraRetakeOutline} />
            </div>
          )}
        </div>
      </div>
      <div className="content no-margins" ref={refFn}>
        <Viewfinder searchArea={searchArea} scanning={scanning} />
        {(scannedItem || feedbackMessage) && (
          <div className="top-content">
            {scannedItem && (
              <div className="result">
                <TitleValuePair
                  titleSize={5}
                  valueSize={7}
                  title={translate(
                    NamespaceKeys.DplSpecific,
                    DplSpecificKeys.EricssonProductNumber
                  )}
                  value={
                    <div className="text-field-with-button-container">
                      <div className="text-field-container">
                        <TextField
                          disabled
                          fullWidth
                          value={
                            (scannedItem?.nonSerializedItem
                              ? scannedItem.nonSerializedItem.dplItem
                                  .EricssonProductNumber
                              : scannedItem?.serializedItem?.dplItem
                                  ?.EricssonProductNumber) ?? ""
                          }
                        />
                      </div>
                      {(scannedItem?.nonSerializedItem?.dplItem
                        .EricssonProductNumber ||
                        scannedItem?.serializedItem?.dplItem
                          ?.EricssonProductNumber) && (
                        <Button
                          iconName="restore"
                          onClick={onClickRestoreScannedItem}
                        />
                      )}
                    </div>
                  }
                />
                <TitleValuePair
                  titleSize={5}
                  valueSize={7}
                  title={translate(
                    NamespaceKeys.BomSpecific,
                    BomSpecificKeys.MaterialCategory
                  )}
                  value={
                    <div className="text-field-with-button-container">
                      <div className="text-field-container">
                        <TextField
                          disabled
                          fullWidth
                          value={
                            (scannedItem?.nonSerializedItem
                              ? scannedItem.nonSerializedItem.materialCategory
                              : scannedItem?.serializedItem
                                  ?.materialCategory) ?? ""
                          }
                        />
                      </div>
                    </div>
                  }
                />
                {scannedItem.serializedItem && (
                  <>
                    <TitleValuePair
                      titleSize={5}
                      valueSize={7}
                      title={translate(
                        NamespaceKeys.DplSpecific,
                        DplSpecificKeys.EricssonSerialNumber
                      )}
                      value={
                        <div className="text-field-with-button-container">
                          <div className="text-field-container">
                            <TextField
                              fullWidth
                              disabled
                              value={
                                scannedItem.serializedItem
                                  .ericssonSerialNumber ?? ""
                              }
                            />
                          </div>
                          {scannedItem.serializedItem.ericssonSerialNumber && (
                            <Button
                              onClick={onClickRestoreEricssonSerialNumber}
                              iconName="trashcan"
                            />
                          )}
                        </div>
                      }
                    />
                    <TitleValuePair
                      titleSize={5}
                      valueSize={7}
                      title={translate(
                        NamespaceKeys.DplSpecific,
                        DplSpecificKeys.CustomerSerialNumber
                      )}
                      value={
                        <div className="text-field-with-button-container">
                          <div className="text-field-container">
                            <TextField
                              fullWidth
                              disabled
                              value={
                                scannedItem.serializedItem
                                  .customerSerialNumber ?? ""
                              }
                            />
                          </div>
                          {scannedItem.serializedItem.customerSerialNumber && (
                            <Button
                              onClick={onClickRestoreCustomerSerialNumber}
                              iconName="trashcan"
                            />
                          )}
                        </div>
                      }
                    />
                    <TitleValuePair
                      titleSize={5}
                      valueSize={7}
                      title={translate(
                        NamespaceKeys.General,
                        GeneralKeys.Quantity
                      )}
                      value={
                        <>
                          <Pill
                            toggled={
                              scannedItem.serializedItem.status ===
                              MaterialTransferItemStatus.Ok
                            }
                            onToggle={onStatusOkToggle}
                          >
                            {translate(NamespaceKeys.General, GeneralKeys.Ok)}
                          </Pill>
                          <Pill
                            toggled={
                              scannedItem.serializedItem.status ===
                              MaterialTransferItemStatus.Damaged
                            }
                            onToggle={onStatusDamagedToggle}
                          >
                            {translate(
                              NamespaceKeys.General,
                              GeneralKeys.Damaged
                            )}
                          </Pill>
                        </>
                      }
                    />
                  </>
                )}
                {scannedItem.nonSerializedItem && (
                  <Row className="quantities">
                    <Column sm={5}>
                      <TextField
                        value={scannedItem.nonSerializedItem.quantity.toString()}
                        suffix={
                          scannedItem.nonSerializedItem.dplItem
                            .UnitOfMeasurement
                        }
                        disabled
                        className="text-field-with-suffix"
                      />
                    </Column>
                    <Column sm={7}>
                      <Pill
                        toggled={
                          scannedItem.nonSerializedItem.validationQuantities
                            ?.Ok === scannedItem.nonSerializedItem.quantity
                        }
                        onToggle={onStatusOkToggle}
                      >
                        {translate(NamespaceKeys.General, GeneralKeys.Ok)}
                      </Pill>
                      <Pill
                        toggled={
                          scannedItem.nonSerializedItem.validationQuantities
                            ?.Ok !== scannedItem.nonSerializedItem.quantity
                        }
                        onToggle={onStatusDamagedToggle}
                      >
                        <Icon name="flag" />
                        {translate(
                          NamespaceKeys.General,
                          GeneralKeys.Deviation
                        )}
                      </Pill>
                    </Column>
                  </Row>
                )}

                {scannedItem.valid && (
                  <div className="progress-bar">
                    <progress
                      value={progressData?.progress}
                      max="100"
                    ></progress>
                  </div>
                )}
              </div>
            )}
            {feedbackMessage && (
              <div
                className={`feedback ${
                  feedbackMessage.status === FeedbackStatus.Error
                    ? "error"
                    : feedbackMessage.status === FeedbackStatus.Warning
                    ? "warning"
                    : "information"
                }`}
              >
                <Icon
                  name={
                    feedbackMessage.status === FeedbackStatus.Error
                      ? "triangle-warning"
                      : feedbackMessage.status === FeedbackStatus.Warning
                      ? "triangle-warning"
                      : "info"
                  }
                />
                {feedbackMessage.text}
              </div>
            )}
          </div>
        )}
        <div className="scan-status">
          {!scanning
            ? translate(
                NamespaceKeys.ScannerSpecific,
                ScannerSpecificKeys.BottomHelperPressToScan
              )
            : translate(
                NamespaceKeys.ScannerSpecific,
                ScannerSpecificKeys.BottomHelperPointYourCameraToScan
              )}
        </div>
        <div
          onTouchStart={onScanStart}
          onTouchCancel={onScanEnd}
          onTouchEnd={onScanEnd}
          className={`tap-to-scan${scanning ? " active" : " awaiting"}`}
        ></div>
      </div>
    </div>
  );
};

export default SmartScannerView;
