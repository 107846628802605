import React, { ChangeEvent } from "react";

import cx from "classnames";
import ITextArea from "./ITextArea";

const TextArea = ({
  value,
  onChange,
  onBlur,
  onKeyDown,
  onKeyPress,
  onKeyUp,
  placeholder,
  disabled,
  readonly,
  className,
  style
}: ITextArea) => {
  const onChangeHandler = (e: ChangeEvent<HTMLTextAreaElement>) =>
    onChange && onChange(e.target.value);

  return (
    <textarea
      placeholder={placeholder !== undefined ? placeholder : ""}
      value={value}
      onChange={onChangeHandler}
      onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) => onBlur && onBlur(e)}
      onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => onKeyDown && onKeyDown(e)}
      onKeyUp={(e: React.KeyboardEvent<HTMLTextAreaElement>) => onKeyUp && onKeyUp(e)}
      onKeyPress={(e: React.KeyboardEvent<HTMLTextAreaElement>) => onKeyPress && onKeyPress(e)}
      disabled={disabled}
      readOnly={readonly}
      className={cx(className)}
      style={style}
    />
  );
};

export default TextArea;
