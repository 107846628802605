import React, { useState, useEffect, useContext, useCallback } from "react";

import { useNotifications } from "@react-gcc-eds/core";
import { useHistory } from "react-router";

import { AuthenticationContext } from "../../../../contexts/authentication-context";
import { CustomerContext } from "../../../../contexts/customer-context";
import { LayoutContext } from "../../../../contexts/layout-context";
import {
  MaterialTransferItemStatus,
  InstallMaterialAtSiteRequestModel,
  MaterialToInstallDetailsViewModel,
  MaterialTransfersClient,
  MobileViewAccessIdentifier
} from "../../../../domain/client-customer";
import {
  ComponentSpecificKeys,
  MaterialSpecificKeys,
  NamespaceKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import { viewAccessMenuDictionary } from "../../../../view-access/view-access";
import {
  NonSerializedItemState,
  SerializedItemState
} from "../../receive-material-overview/shared/receive-material-models";
import {
  toInstallMaterialItemAtSiteRequestModel,
  toNonInstallMaterialItemAtSiteRequestModel,
  toNonSerializedItemState,
  toSerializedItemState
} from "./material-installation-validation-utils";
import MaterialInstallationValidationView from "./material-installation-validation-view";
import { ValidationQuantities } from "./quantity-validator/quantity-validator-component";

import "./material-installation-validation-component.scss";

export type SmartScanItemUpdate = {
  constructionItemId: string;
  validationQuantities: ValidationQuantities;
  ericssonSerialNumber?: string;
  customerSerialNumber?: string;
};

export interface NonSerializedInstallationItemState
  extends NonSerializedItemState {
  quantityTotal?: number;
}
export const validationQuantitiesKeys = Object.values(
  MaterialTransferItemStatus
);
export interface SerializedInstallationItemState extends SerializedItemState {
  quantityTotal?: number;
  isSerialNumbersLocked: boolean;
}

export enum WizardPages {
  QuantityValidator,
  SerialNumberValidator,
  Review
}

export type WizardPagesCompletableStatus = {
  [WizardPages.QuantityValidator]: boolean;
  [WizardPages.SerialNumberValidator]: boolean;
  [WizardPages.Review]: boolean;
};

type Props = {
  siteProjectId: string;
};

const MaterialInstallationValidationComponent = ({
  siteProjectId
}: Props): React.ReactElement => {
  const { createNotification } = useNotifications();
  const { currentCustomer } = useContext(CustomerContext);
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const { translate } = useTranslation();
  const history = useHistory();

  const [nonSerializedStateItems, setNonSerializedStateItems] = useState<
    NonSerializedInstallationItemState[]
  >([]);
  const [serializedStateItems, setSerializedStateItems] = useState<
    SerializedInstallationItemState[]
  >([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { setPageTitle } = useContext(LayoutContext);
  const [errorState, setErrorState] = useState<string>();
  const [currentPage, setCurrentPage] = useState<WizardPages>(
    WizardPages.QuantityValidator
  );
  const [completedSteps, setCompletedSteps] = useState<WizardPages[]>([]);
  const [wizardPagesCompletableStatus, setWizardPagesCompletableStatus] =
    useState<WizardPagesCompletableStatus>({
      [WizardPages.QuantityValidator]: true,
      [WizardPages.SerialNumberValidator]: true,
      [WizardPages.Review]: false
    });
  const [posting, setPosting] = useState<boolean>(false);

  const [materialToInstallDetails, setMaterialToInstallDetails] =
    useState<MaterialToInstallDetailsViewModel>();
  const [totalQuantity, setTotalQuantity] = useState<number>();

  const onNavigateBack = useCallback(
    () =>
      history.push(
        viewAccessMenuDictionary[
          MobileViewAccessIdentifier.MaterialInstallationsOverview
        ].link
      ),
    [history]
  );

  const handleOnNextChange = useCallback(
    (nextPage: WizardPages, prevPage: WizardPages): void => {
      if (!wizardPagesCompletableStatus[currentPage]) {
        return;
      }

      setCurrentPage(nextPage);
      setCompletedSteps((prev) => [...prev, prevPage]);
    },
    [currentPage, wizardPagesCompletableStatus]
  );

  const handleOnPreviousPage = useCallback(
    (previousPage: WizardPages | undefined): void => {
      if (previousPage === undefined) {
        return;
      }
      setCompletedSteps((prev) => prev.filter((step) => step !== currentPage));
      setCurrentPage(previousPage);
    },
    [currentPage]
  );

  const createErrorWhileSavingNotification = useCallback(() => {
    createNotification(
      translate(
        NamespaceKeys.ComponentSpecific,
        ComponentSpecificKeys.MaterialInstallationPostValidationErrorTitle
      ),
      translate(
        NamespaceKeys.ComponentSpecific,
        ComponentSpecificKeys.MaterialInstallationPostValidationErrorDescription
      ),
      "warning",
      undefined,
      5000
    );
    setPosting(false);
  }, [createNotification, translate]);

  const saveInstallation = useCallback(async (): Promise<void> => {
    const items = nonSerializedStateItems
      .filter((itm) => itm.validationQuantities?.Ok ?? 0 > 0)
      .map((itm) => toNonInstallMaterialItemAtSiteRequestModel(itm));
    const allItems = items.concat(
      serializedStateItems
        .filter((itm) => itm.status === MaterialTransferItemStatus.Ok)
        .map((itm) => toInstallMaterialItemAtSiteRequestModel(itm))
    );
    const request: InstallMaterialAtSiteRequestModel = {
      Items: allItems
    };

    const customerIdentifier =
      currentCustomer && currentCustomer.HeaderIdentifier;

    if (!customerIdentifier || !siteProjectId) {
      createErrorWhileSavingNotification();
      return;
    }
    try {
      await new MaterialTransfersClient(
        await getCustomerConfigurationProvider()
      ).installAtSite(siteProjectId, customerIdentifier, request);
      onNavigateBack();
    } catch {
      createErrorWhileSavingNotification();
      return;
    }
  }, [
    createErrorWhileSavingNotification,
    currentCustomer,
    siteProjectId,
    getCustomerConfigurationProvider,
    nonSerializedStateItems,
    onNavigateBack,
    serializedStateItems
  ]);

  const asyncHandleOnFinish = useCallback(async (): Promise<void> => {
    setCompletedSteps((prev) => [...prev, currentPage]);
    setPosting(false);
    saveInstallation();
  }, [currentPage, saveInstallation]);

  const synchronousHandleOnFinish = useCallback(() => {
    asyncHandleOnFinish();
  }, [asyncHandleOnFinish]);

  const getMaterialInstallationDetails = useCallback(
    async (siteProjectId: string, customerIdentifier: string) => {
      try {
        setLoading(true);
        const materialToInstall = await new MaterialTransfersClient(
          await getCustomerConfigurationProvider()
        ).getMaterialToInstallDetails(siteProjectId, customerIdentifier);
        setTotalQuantity(materialToInstall.TotalQuantity);
        setMaterialToInstallDetails(materialToInstall);
        setNonSerializedStateItems(
          (materialToInstall.NonSerialNumberMaterialTransferItems || []).map(
            (i) =>
              toNonSerializedItemState(
                i,
                materialToInstall.DeliveryProductListItems
              )
          )
        );
        setSerializedStateItems(
          (materialToInstall.SerialNumberMaterialTransferItems || []).map((i) =>
            toSerializedItemState(i, materialToInstall.DeliveryProductListItems)
          )
        );
      } catch (e) {
        if (e instanceof Error) {
          setErrorState(e.message);
        }
      } finally {
        setLoading(false);
      }
    },
    [getCustomerConfigurationProvider, setTotalQuantity]
  );

  useEffect(() => {
    if (
      !materialToInstallDetails &&
      currentCustomer?.HeaderIdentifier &&
      siteProjectId
    ) {
      getMaterialInstallationDetails(
        siteProjectId,
        currentCustomer.HeaderIdentifier
      );
    }
  }, [
    currentCustomer,
    siteProjectId,
    getMaterialInstallationDetails,
    materialToInstallDetails
  ]);

  useEffect(() => {
    const translatedTitle = translate(
      NamespaceKeys.MaterialSpecific,
      MaterialSpecificKeys.ValidationTitle,
      { reason: "Installation" }
    );

    setPageTitle &&
      setPageTitle({
        title: translatedTitle,
        subtitle: materialToInstallDetails?.SiteName
      });
  }, [materialToInstallDetails, setPageTitle, translate]);

  const handleOnSerializedItemsUpdated = useCallback(
    (
      serializedItemsToUpdate: (
        | readonly [number, SerializedInstallationItemState]
        | SerializedInstallationItemState
      )[]
    ): void => {
      setSerializedStateItems((prev) =>
        prev.map((previousItem, i) => {
          if (serializedItemsToUpdate.length > 0) {
            if (serializedItemsToUpdate[0] instanceof Array) {
              const updatedItem = (
                serializedItemsToUpdate as (readonly [
                  number,
                  SerializedInstallationItemState
                ])[]
              ).find(([index]) => index === i)?.[1];

              return updatedItem ?? previousItem;
            } else {
              const updatedItem = (
                serializedItemsToUpdate as SerializedInstallationItemState[]
              ).find(
                (item) =>
                  item.billOfMaterialItemIdentity ===
                  previousItem.billOfMaterialItemIdentity
              );

              return updatedItem ?? previousItem;
            }
          }

          return previousItem;
        })
      );
    },
    []
  );

  const handleOnNonSerializedItemUpdated = useCallback(
    (nonSerializedItemToUpdate: NonSerializedInstallationItemState): void => {
      setNonSerializedStateItems((prev: NonSerializedInstallationItemState[]) =>
        prev.map((previousItem: NonSerializedInstallationItemState) => {
          if (
            previousItem.dplItem.Id === nonSerializedItemToUpdate.dplItem.Id &&
            previousItem.materialCategory ===
              nonSerializedItemToUpdate.materialCategory &&
            previousItem.billOfMaterialItemIdentity.Value ===
              nonSerializedItemToUpdate.billOfMaterialItemIdentity.Value
          ) {
            return nonSerializedItemToUpdate;
          }
          return previousItem;
        })
      );
    },
    []
  );

  const handleOnStepCompletionStatusChange = useCallback(
    (page: WizardPages, isCompletable: boolean) => {
      setWizardPagesCompletableStatus(
        (prev: WizardPagesCompletableStatus): WizardPagesCompletableStatus => {
          return {
            ...prev,
            [page]: isCompletable
          };
        }
      );
    },
    []
  );

  const handleRetry = useCallback(() => {
    setErrorState(undefined);

    currentCustomer?.HeaderIdentifier &&
      getMaterialInstallationDetails(
        siteProjectId,
        currentCustomer?.HeaderIdentifier
      );
  }, [currentCustomer, siteProjectId, getMaterialInstallationDetails]);

  return (
    <>
      <MaterialInstallationValidationView
        error={errorState}
        loading={loading}
        nonSerializedStateItems={nonSerializedStateItems}
        serializedStateItems={serializedStateItems}
        currentPage={currentPage}
        completedSteps={completedSteps}
        onNextPage={handleOnNextChange}
        onFinish={synchronousHandleOnFinish}
        posting={posting}
        onCancel={onNavigateBack}
        onPreviousPage={handleOnPreviousPage}
        onSerializedItemsUpdated={handleOnSerializedItemsUpdated}
        onNonSerializedItemUpdated={handleOnNonSerializedItemUpdated}
        onStepCompletionStatusChanged={handleOnStepCompletionStatusChange}
        onRetry={handleRetry}
        totalQuantity={totalQuantity ?? 0}
      />
    </>
  );
};

export default MaterialInstallationValidationComponent;
