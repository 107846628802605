import * as React from "react";
import { useState } from "react";

import { ReceiveMaterialContext } from "../contexts/receive-material-context";
import {
  ComponentViews,
  ReceiveMaterialTransferStateItem,
  SelectableMaterialTransferOverviewViewModel
} from "../shared/receive-material-models";

type ReceiveMaterialProviderProps = {
  children: React.ReactChild;
};

export const ReceiveMaterialProvider = ({
  children
}: ReceiveMaterialProviderProps): React.ReactElement => {
  const [transfersOverviewStateItems, setTransfersOverviewStateItems] =
    useState<SelectableMaterialTransferOverviewViewModel[]>([]);

  const [transferDetailsStateItems, setTransferDetailsStateItems] = useState<
    ReceiveMaterialTransferStateItem[]
  >([]);

  const [currentView, setCurrentView] = useState<ComponentViews>(
    ComponentViews.TransferList
  );

  return (
    <ReceiveMaterialContext.Provider
      value={{
        currentView,
        setCurrentView,
        transfersOverviewStateItems,
        setTransfersOverviewStateItems,
        transferDetailsStateItems,
        setTransferDetailsStateItems
      }}
    >
      {children}
    </ReceiveMaterialContext.Provider>
  );
};
