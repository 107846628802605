import React from "react";

import { useParams } from "react-router";

import MaterialInstallationValidationComponent from "../components/material-handling/material-installation/validation/material-installation-validation-component";
import UrlParameters from "../domain/enums/url-parameters";

type MatchProps = {
  [UrlParameters.siteProjectId]?: string;
};

const MaterialInstallationValidationRoute = (): React.ReactElement | null => {
  const { siteProjectId } = useParams<MatchProps>();

  return (
    (siteProjectId && (
      <MaterialInstallationValidationComponent siteProjectId={siteProjectId} />
    )) ||
    null
  );
};

export default MaterialInstallationValidationRoute;
