import React, { ChangeEvent, useRef, useEffect, useState } from "react";

import cx from "classnames";

import { uniqueId } from "../../utils/utils";
import ITextField from "./ITextField";

const TextField = ({
  label,
  leftLabel,
  value,
  onChange,
  placeholder,
  focus,
  disabled,
  readonly,
  fullWidth,
  prefix,
  suffix,
  suffixInside,
  validation,
  className,
  onBlur,
  onKeyDown,
  onKeyPress,
  onKeyUp
}: ITextField) => {
  // const { inputRef } = useFocus();
  const inputRef = useRef<HTMLInputElement>(null);

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) =>
    onChange && onChange(e.target.value);

  const [id] = useState(uniqueId());

  const prefixRef = useRef<HTMLDivElement>(null);
  const suffixRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  const [prefixWidth, setPrefixWidth] = useState(0);
  const [suffixWidth, setSuffixWidth] = useState(0);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setPrefixWidth(prefixRef.current ? prefixRef.current.clientWidth : 0);
    setSuffixWidth(suffixRef.current ? suffixRef.current.offsetWidth : 10);
    setLabelWidth(labelRef.current && leftLabel ? labelRef.current.offsetWidth: 0);
  });

  const fullwidthFix = { width: `calc(100% - ${prefixWidth + suffixWidth + labelWidth}px)` };
  
  const hasLabelSpace = () => {

    if (inputRef !== undefined && inputRef.current !== null && value !== undefined && value !== null) {
      const fontSize = parseInt(
        window
          .getComputedStyle(inputRef.current)
          .getPropertyValue("font-size")
          .replace("px", ""),
        0
      );

      const width = inputRef.current.clientWidth;
      return ((value.length - 1) * fontSize /  1.99) < width;
    }

    return true;
  };

  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      {label && (
        <label ref={labelRef} htmlFor={id} className={leftLabel ? "left" : ""}>
          {label}
        </label>
      )}
      {prefix && (
        <div ref={prefixRef} className="prefix">
          {prefix}
        </div>
      )}
      <input
        ref={inputRef}
        title={!hasLabelSpace() ? value : undefined}
        id={id}
        style={fullWidth && (prefix || suffix) ? fullwidthFix : undefined}
        type="text"
        className={cx(
          fullWidth ? "fullwidth" : "",
          { fullwidthWithPrefix: fullWidth && (prefix || suffix) },
          className
        )}
        placeholder={placeholder || ""}
        value={value}
        onChange={onChangeHandler}
        onBlur={(e: React.FocusEvent<HTMLInputElement>) => onBlur && onBlur(e)}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyDown && onKeyDown(e)}
        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyUp && onKeyUp(e)}
        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => onKeyPress && onKeyPress(e)}
        disabled={disabled}
        readOnly={readonly}
      />
      {validation !== undefined && value !== "" && (
        <span className="validation-error">{validation}</span>
      )}
      {suffix && (
        <div ref={suffixRef} className={cx("suffix", { "icon-inside": suffixInside })}>
          {suffix}
        </div>
      )}
    </>
  );
};

export default TextField;
