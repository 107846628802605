import React, { useMemo, useCallback, useState } from "react";

import { Tile, TileActionButton } from "@react-gcc-eds/core";

import {
  DeliveryProductListItemModel,
  MaterialTransferDetailsNonSerializedItemViewModel,
  MaterialTransferDetailsViewModel
} from "../../../../domain/client-customer";
import ProductDetailsDialog, {
  DeliveryProductListItemInformation
} from "../../../common/product-details-modal/product-details-dialog";
import { fromDplItemModelToInformationItem } from "../../../common/product-details-modal/product-details-utils";

export type MaterialTransferItem = Pick<
  MaterialTransferDetailsNonSerializedItemViewModel,
  | "HandlingUnitQuantity"
  | "DispatchItemQuantity"
  | "DplItemId"
  | "MaterialCategory"
>;

type Props = {
  materialTransferDetails: MaterialTransferDetailsViewModel;
  item: MaterialTransferItem;
};

const MaterialTransferDetailsItem = ({
  materialTransferDetails,
  item
}: Props): React.ReactElement => {
  const [showProductDetailsDialog, setShowProductDetailsDialog] =
    useState(false);
  const dplItem: DeliveryProductListItemModel | undefined = useMemo(
    () =>
      materialTransferDetails.DeliveryProductListItems?.find(
        (dplItem) => dplItem.Id === item.DplItemId
      ),
    [materialTransferDetails, item]
  );

  const onClickInfo = useCallback(() => {
    setShowProductDetailsDialog(true);
  }, [setShowProductDetailsDialog]);

  const onCloseProductDetailsDialog = useCallback(
    () => setShowProductDetailsDialog(false),
    [setShowProductDetailsDialog]
  );

  const dplInformationItem = useMemo<
    DeliveryProductListItemInformation | undefined
  >(() => fromDplItemModelToInformationItem(dplItem), [dplItem]);

  const quantity: React.ReactNode = useMemo(
    () =>
      item.DispatchItemQuantity &&
      `${item.DispatchItemQuantity} of ${item.DispatchItemQuantity}`,
    [item]
  );
  const actions: React.ReactElement = useMemo(
    () => <TileActionButton iconName="info" onClick={onClickInfo} />,
    [onClickInfo]
  );
  const title: React.ReactNode = useMemo(
    () => (
      <div className="material-transfer-details-item-title">
        <div>{dplItem?.EricssonProductNumber}</div>
      </div>
    ),
    [dplItem]
  );

  const subtitle: React.ReactNode = useMemo(
    () => item.MaterialCategory,
    [item]
  );

  return (
    <>
      <Tile
        className="material-transfer-details-item"
        title={title}
        subtitle={subtitle}
        actions={actions}
      >
        {dplItem?.EricssonProductDescription ?? ""}
        <div className="quantity">{quantity}</div>
      </Tile>
      {showProductDetailsDialog && dplInformationItem && (
        <ProductDetailsDialog
          showModal
          item={dplInformationItem}
          onClose={onCloseProductDetailsDialog}
        />
      )}
    </>
  );
};

export default MaterialTransferDetailsItem;
