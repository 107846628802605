import React, {
  useState,
  useCallback,
  useContext,
  useLayoutEffect
} from "react";

import { Loader, Row, Button, Icon, Tile } from "@react-gcc-eds/core";
import { FixedSizeList, ListChildComponentProps } from "react-window";

import { CustomerContext } from "../../../contexts/customer-context";
import { LayoutContext } from "../../../contexts/layout-context";
import { MaterialTransferOverviewViewModel } from "../../../domain/client-customer";
import {
  NamespaceKeys,
  MaterialSpecificKeys,
  GeneralKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";
import CenteredContainer from "../../common/layout/centered-container";
import MaterialTransferOverviewFilters from "./material-transfer-overview-filters";
import MaterialTransferOverviewListItem from "./material-transfer-overview-list-item";

import "./material-transfer-overview.scss";

type Props = {
  error?: string;
  loading: boolean;
  items: MaterialTransferOverviewViewModel[];
  onRetry: () => void;
};

const MaterialTransferOverviewView: (
  props: Props
) => React.ReactElement | null = ({
  error,
  loading,
  items,
  onRetry
}: Props) => {
  const { translate } = useTranslation();
  const { setPageTitle } = useContext(LayoutContext);
  const { currentCustomer, currentUser } = useContext(CustomerContext);
  const [hideFilters, setHideFilters] = useState<boolean>(true);
  const [listContainerDomObjectRef, setListContainerDomObjectRef] =
    useState<HTMLDivElement>();
  const [listContainerHeight, setListContainerHeight] = useState<number>(0);
  const [filteredMaterialTransferItems, setFilteredMaterialTransferItems] =
    useState<MaterialTransferOverviewViewModel[]>([]);

  const refFn: (instance: HTMLDivElement | null) => void = useCallback(
    (node) => {
      if (node) {
        setListContainerDomObjectRef(node);
        setListContainerHeight(node.getBoundingClientRect().height);
      }
    },
    []
  );

  useLayoutEffect(() => {
    listContainerDomObjectRef &&
      setListContainerHeight(
        listContainerDomObjectRef.getBoundingClientRect().height
      );
  }, [listContainerDomObjectRef, hideFilters]);

  const handleToggleFilterClick = useCallback(() => {
    setListContainerHeight(0);
    setHideFilters((prev) => !prev);
  }, []);

  const handleOnFiltersChanged = useCallback(
    (filteredList: MaterialTransferOverviewViewModel[]) => {
      setFilteredMaterialTransferItems(filteredList);
      const title = translate(
        NamespaceKeys.MaterialSpecific,
        MaterialSpecificKeys.MaterialTransfersTitle,
        { postfix: loading ? "" : `(${filteredList.length})` }
      );
      setPageTitle &&
        (loading
          ? setPageTitle({ title })
          : setPageTitle({
              title,
              actions: (
                <div className="action">
                  <div className="dropdown more">
                    <Button onClick={handleToggleFilterClick}>
                      <Icon name="filter" />
                    </Button>
                  </div>
                </div>
              )
            }));
    },
    [handleToggleFilterClick, loading, setPageTitle, translate]
  );

  return error ? (
    <CenteredContainer>
      <div className="material-transfer-api-error">
        <div className="material-transfer-retry-title">{error}</div>
        <Button className="material-transfer-retry-button" onClick={onRetry}>
          {translate(NamespaceKeys.General, GeneralKeys.Retry)}
        </Button>
      </div>
    </CenteredContainer>
  ) : loading ? (
    <CenteredContainer>
      <Loader size="large" />
    </CenteredContainer>
  ) : (
    <div className="overview-list">
      {currentUser && (
        <MaterialTransferOverviewFilters
          hidden={hideFilters}
          items={items}
          onFiltersChanged={handleOnFiltersChanged}
        />
      )}
      {!filteredMaterialTransferItems.length ? (
        items && items.length ? (
          <div>
            <Tile
              title={translate(
                NamespaceKeys.MaterialSpecific,
                MaterialSpecificKeys.NoMaterialTransfersWithFiltersTitle
              )}
            >
              <p>
                {translate(
                  NamespaceKeys.MaterialSpecific,
                  MaterialSpecificKeys.NoMaterialTransfersWithFiltersMessageOne
                )}
                <Icon name="filter" />
                {translate(
                  NamespaceKeys.MaterialSpecific,
                  MaterialSpecificKeys.NoMaterialTransfersWithFiltersMessageTwo
                )}
              </p>
            </Tile>
          </div>
        ) : (
          <div>
            <Tile
              title={translate(
                NamespaceKeys.MaterialSpecific,
                MaterialSpecificKeys.NoMaterialTransfersTitle
              )}
            >
              <p>
                {translate(
                  NamespaceKeys.MaterialSpecific,
                  MaterialSpecificKeys.NoMaterialTransfersMessage,
                  {
                    customerName: currentCustomer && currentCustomer.DisplayName
                  }
                )}
              </p>
            </Tile>
          </div>
        )
      ) : (
        <Row className="list-row">
          <div ref={refFn} className="list-container">
            <FixedSizeList
              height={listContainerHeight}
              width="100%"
              itemCount={filteredMaterialTransferItems.length}
              itemSize={270}
            >
              {({
                index,
                style
              }: ListChildComponentProps): React.ReactElement => (
                <div style={style} className="overview-item">
                  <MaterialTransferOverviewListItem
                    materialTransfer={filteredMaterialTransferItems[index]}
                  />
                </div>
              )}
            </FixedSizeList>
          </div>
        </Row>
      )}
    </div>
  );
};

export default MaterialTransferOverviewView;
