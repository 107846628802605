import React from "react";
import cx from "classnames";
import Tooltip from "../tooltip/Tooltip";
import Icon from "../icon/Icon";
import ITileActionButton from "./ITileActionButton";

const TileActionButton = ({
  iconName,
  tooltipText,
  disabled,
  active,
  onClick,
  className
}: ITileActionButton) => {
  return (
    <div className={cx("action", className)}>
      {tooltipText ? (
        <Tooltip position="left" text={tooltipText} type="pointer">
          <Icon name={iconName} disabled={disabled} active={active} onClick={onClick} />
        </Tooltip>
      ) : (
        <Icon name={iconName} disabled={disabled} active={active} onClick={onClick} />
      )}
    </div>
  );
};
export default TileActionButton;
