import React from "react";
import cx from "classnames";

import useAppContext from "./context/ContextHelpers";

import { Notification } from "./contracts";

import { uniqueId } from "../../utils/utils";

import Icon from "../icon/Icon";

const A_SECOND = 1000;
const A_MINUTE = A_SECOND * 60;
const AN_HOUR = A_MINUTE * 60;
const A_DAY = AN_HOUR * 24;

interface Props {
  notificationsVisible: boolean;
  hideNotifications(): void;
  clearNotification(id: string): void;
  deleteNotification(id: string): void;
}

const NotificationLog = ({
  notificationsVisible,
  hideNotifications,
  clearNotification,
  deleteNotification
}: Props) => {
  const { notifications } = useAppContext();

  const noNotifications = () => <div className="notification-log-empty">No new notifications.</div>;

  const notificationSpan = (notification: Notification) => {
    const span = Math.round(new Date().getTime() - new Date(notification.timestamp).getTime());

    if (span < A_MINUTE) {
      return `${Math.round(span / A_SECOND)} s`;
    }

    if (span < AN_HOUR) {
      return `${Math.round(span / A_MINUTE)} m`;
    }

    if (span < A_DAY) {
      return `${Math.round(span / AN_HOUR)} h`;
    }

    return `${Math.round(span / A_DAY)} d`;
  };

  const renderNotifications = () => {
    return notifications.items
      .filter((n: Notification) => !n.instant)
      .map((n: Notification) => {
        return (
          <div
            key={uniqueId()}
            role="button"
            tabIndex={0}
            className={cx("notification-log-item", { new: n.new })}
            data-timestamp={n.timestamp}
            onClick={() => deleteNotification(n.id)}
            onKeyDown={() => deleteNotification(n.id)}
          >
            <div className="title">{n.title}</div>
            <div className="description">{n.description}</div>
            <span className="notification-log-item-time">{notificationSpan(n)}</span>
          </div>
        );
      });
  };

  const activeNotification = () => {
    const activeNotifications = notifications.items.filter(n => n.active).reverse();
    if (activeNotifications.length === 0) {
      return null;
    }

    const notification = activeNotifications[0];

    let style = {};

    switch (notification.type) {
      case "warning":
        style = { borderColor: "rgb(194, 82, 88)" };
        break;
      case "primary":
        style = { borderColor: "rgb(0, 130, 240)" };
        break;
      default:
        break;
    }

    return (
      <div className="notification live" style={style}>
        <div className="top-row">
          <div className="title">
            {notification.icon && <Icon name={notification.icon} />}
            {notification.icon && " "}
            {notification.title}
          </div>
          <Icon onClick={() => clearNotification(notification.id)} className="close" name="cross" />
        </div>
        {notification.description !== "" && (
          <div className="description">{notification.description}</div>
        )}
      </div>
    );
  };

  return (
    <>
      {activeNotification()}
      <div className={cx("notification-log", { hidden: !notificationsVisible })}>
        <div className="syspanel-top">
          <span className="syspanel-title">Notifications</span>
          <Icon onClick={hideNotifications} className="syspanel-close" name="cross" />
        </div>

        <div className="notification-log-container">
          {notifications.items && notifications.items.length
            ? renderNotifications()
            : noNotifications()}
        </div>
      </div>
    </>
  );
};

export default NotificationLog;
