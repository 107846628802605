import React, { useContext, useEffect, useState, useCallback } from "react";

import { AuthenticationContext } from "../../../../contexts/authentication-context";
import { CustomerContext } from "../../../../contexts/customer-context";
import {
  MaterialTransferToValidateDetailsViewModel,
  MaterialTransfersClient
} from "../../../../domain/client-customer";
import MaterialTransferDetailsViewComponent from "./material-transfer-details-view-component";

type Props = {
  materialTransferId: string | undefined;
  externalHandlingUnit?: string | undefined;
};

const MaterialTransferDetailsComponent = ({
  materialTransferId,
  externalHandlingUnit
}: Props): React.ReactElement => {
  const [materialTransferDetails, setMaterialTransferDetails] =
    useState<MaterialTransferToValidateDetailsViewModel>();

  const { currentCustomer } = useContext(CustomerContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );

  const getMaterialTransferDetails = useCallback(
    async (materialTransferId: string, customerIdentifier: string) => {
      try {
        setLoading(true);
        const materialTransferDetails = await new MaterialTransfersClient(
          await getCustomerConfigurationProvider()
        ).getMaterialTransferValidationDetails(
          materialTransferId,
          externalHandlingUnit ?? "",
          customerIdentifier
        );

        setMaterialTransferDetails(materialTransferDetails);
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        } else {
          setError(JSON.stringify(e));
        }
      } finally {
        setLoading(false);
      }
    },
    [getCustomerConfigurationProvider, externalHandlingUnit]
  );

  const onRetry = useCallback((): void => {
    if (!loading && currentCustomer?.HeaderIdentifier && materialTransferId) {
      setError(undefined);
      getMaterialTransferDetails(
        materialTransferId,
        currentCustomer.HeaderIdentifier
      );
    }
  }, [
    loading,
    currentCustomer,
    materialTransferId,
    getMaterialTransferDetails
  ]);

  useEffect(() => {
    if (
      !materialTransferDetails &&
      currentCustomer?.HeaderIdentifier &&
      materialTransferId
    ) {
      getMaterialTransferDetails(
        materialTransferId,
        currentCustomer.HeaderIdentifier
      );
    }
  }, [
    currentCustomer,
    materialTransferId,
    materialTransferDetails,
    getMaterialTransferDetails
  ]);

  return (
    <MaterialTransferDetailsViewComponent
      loading={loading}
      error={error}
      onRetry={onRetry}
      materialTransferDetails={materialTransferDetails}
    />
  );
};

export default MaterialTransferDetailsComponent;
