import React, { ReactElement, useState, useCallback } from "react";

import {
  BillOfMaterialItemIdentity,
  DeliveryProductListItemModel,
  MaterialTransferItemStatus,
  SerialNumbersNeeded
} from "../../../../../domain/client-customer";
import { ComponentSpecificKeys } from "../../../../../translation/dictionary-keys";
import { SerializedItemState } from "../../shared/receive-material-models";
import SerialNumberValidatorView from "./serial-number-validator-view";

export type SerialNumberValidationItem = {
  serialNumbersValidated: boolean;
  dplItem: DeliveryProductListItemModel;
  materialCategory: string;
  ericssonSerialNumber: string | undefined;
  customerSerialNumber: string | undefined;
  materialTransferItemId: BillOfMaterialItemIdentity;
  serialNumbersNeeded: SerialNumbersNeeded | undefined;
  status: MaterialTransferItemStatus;
};

export enum SerialNumberType {
  None,
  EricssonSerialNumber,
  CustomerSerialNumber
}

type Props = {
  materialTransferId: string;
  serializedItems: SerializedItemState[];
  onSerializedItemsUpdated: (
    materialTransferId: string,
    serializedItemsToUpdate: SerializedItemState[]
  ) => void;
};
const SerialNumberValidatorComponent = ({
  materialTransferId,
  serializedItems,
  onSerializedItemsUpdated
}: Props): ReactElement => {
  const [errorMessage, setErrorMessage] = useState<ComponentSpecificKeys>();

  const handleOnItemChange = useCallback(
    (materialTransferId: string, item: SerialNumberValidationItem) => {
      const itemToUpdate = serializedItems.find(
        (si) => si.billOfMaterialItemIdentity === item.materialTransferItemId
      );
      if (!itemToUpdate) {
        setErrorMessage(
          ComponentSpecificKeys.AnErrorOccurredPleaseTryAgainLater
        );
        return;
      }
      const updatedItem: SerializedItemState = {
        ...itemToUpdate,
        customerSerialNumber: item.customerSerialNumber,
        ericssonSerialNumber: item.ericssonSerialNumber,
        serialNumbersValidated: item.serialNumbersValidated
      };

      onSerializedItemsUpdated(materialTransferId, [updatedItem]);
    },
    [onSerializedItemsUpdated, serializedItems]
  );

  return (
    <>
      <SerialNumberValidatorView
        materialTransferId={materialTransferId}
        serializedItems={serializedItems}
        errorMessage={errorMessage}
        onItemChange={handleOnItemChange}
      />
    </>
  );
};

export default SerialNumberValidatorComponent;
