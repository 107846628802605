import React from "react";
import cx from "classnames";
import IDialog from "./IDialog";

const Dialog = ({
  title,
  buttons,
  fullscreen,
  className,
  contentClassName,
  onClose,
  children,
  visible = true,
  style
}: IDialog) => {
  return (
    <div className={cx("dialog", { fullscreen }, { show: visible })}>
      <div className={cx("content", className)} style={style}>
        <div className="top">
          <div className="title">{title}</div>
          {fullscreen && (
            <div role="presentation" onClick={() => onClose && onClose()} className="right">
              {" "}
              <i className="icon icon-cross" data-close="true"></i>
            </div>
          )}
        </div>
        <div className={cx("body", contentClassName)}>{children}</div>
        <div className="bottom">{buttons}</div>
      </div>
    </div>
  );
};
export default Dialog;
