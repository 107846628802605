import React, { useState } from "react";
import cx from "classnames";
import Icon from "../icon/Icon";
import DateCalendar from "./DateCalendar";

import { useClickOutside } from "../../utils/utils";
import IDateField from "./IDateField";

const DateField = ({ value, onDateSelected, className, disabled, style }: IDateField) => {
  const [toggleCalendar, setToggleCalendar] = useState(false);
  const [visibleDate, setVisibleDate] = useState(new Date());
  const [inputField, setInputField] = useState("");

  const closeCalendar = () => setToggleCalendar(false);

  const containerRef = useClickOutside(closeCalendar);
  const validateAndSetDate = (date: Date | undefined) => {
    if (onDateSelected) {
      // if (!date) {
      //   onDateSelected(date);
      // }
      onDateSelected(date);
    }
  };
  return (
    <div ref={containerRef} className={cx("datepicker", { disabled }, className)}>
      <input
        type="text"
        name="datepicker"
        maxLength={10}
        className="with-icon"
        style={style}
        placeholder="YYYY-MM-DD"
        value={inputField}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.value.length <= 0) {
            validateAndSetDate(undefined);
          } else if (e.target.value.length === 10 && !isNaN(Date.parse(e.target.value))) {
            setVisibleDate(new Date(e.target.value));
          }
          setInputField(e.target.value);
          validateAndSetDate(new Date(e.target.value));
        }}
        onClick={() => setToggleCalendar(!toggleCalendar)}
      />
      <div className="suffix icon-inside clickable">
        <Icon name="calendar" onClick={() => setToggleCalendar(!toggleCalendar)} />
      </div>
      <DateCalendar
        visible={toggleCalendar}
        visibleDate={visibleDate}
        selectedDate={value}
        onVisibleDateChanged={(date: Date) => setVisibleDate(date)}
        onDateSelected={(date: Date) => {
          setInputField(date.toLocaleDateString("fr-CA"));
          validateAndSetDate(date);
          setToggleCalendar(false);
        }}
      />
    </div>
  );
};

export default DateField;
