import React from "react";

import { Button, Dialog } from "@react-gcc-eds/core";

import {
  GeneralKeys,
  NamespaceKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import { DeviationSummaryItem } from "../shared/receive-material-models";
import useDeviationSummaries from "../shared/use-deviation-summaries";
import ReceiveMaterialDeviationsSummaryItem from "./receive-material-deviations-summary-item";

import "./receive-material-deviations-summary.scss";

type Props = {
  materialTransferId: string;
  onCancel: () => void;
};

const ReceiveMaterialDeviationsSummaryDialog = ({
  materialTransferId,
  onCancel
}: Props): React.ReactElement => {
  const { translate } = useTranslation();

  const { deviationSummaryList } = useDeviationSummaries();

  const deviations = React.useMemo(() => {
    return deviationSummaryList
      .filter((x) => x.materialTransferId === materialTransferId)
      ?.reduce((deviations: DeviationSummaryItem[], currentItem) => {
        currentItem.deviationSummaryList.forEach((summaryItem) => {
          const exisitingItem = deviations.find(
            (f) =>
              f.ericssonProductNumber === summaryItem.ericssonProductNumber &&
              f.status === summaryItem.status
          );
          if (exisitingItem) {
            const exisitingItemIndex = deviations.findIndex(
              (f) =>
                f.ericssonProductNumber === summaryItem.ericssonProductNumber &&
                f.status === summaryItem.status
            );
            deviations[exisitingItemIndex].quantity =
              exisitingItem.quantity + summaryItem.quantity;
          } else deviations.push(summaryItem);
        });

        return deviations;
      }, []);
  }, [deviationSummaryList, materialTransferId]);

  const classList = React.useMemo(() => {
    let classes = " rm-deviation-summary-list-dialog ";
    if (deviations) {
      classes +=
        deviations.length < 15
          ? deviations.length < 9
            ? "rm-sm "
            : "rm-md "
          : "";
    }
    return classes;
  }, [deviations]);

  return (
    <div className={classList}>
      <Dialog
        title={translate(NamespaceKeys.General, GeneralKeys.Deviations)}
        buttons={[
          <Button key="Close" onClick={onCancel}>
            {translate(NamespaceKeys.General, GeneralKeys.Close)}
          </Button>
        ]}
      >
        <div className="rm-deviation-summary-list">
          {deviations?.map(
            (item, index): React.ReactElement => (
              <ReceiveMaterialDeviationsSummaryItem
                deviationSummaryItem={item}
                key={index}
              />
            )
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default ReceiveMaterialDeviationsSummaryDialog;
