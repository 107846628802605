import React, { useState, useEffect, ChangeEvent, useRef } from "react";
import cx from "classnames";
import { useClickOutside, uniqueId } from "../../utils/utils";
import Pill from "../pill/Pill";
import ISelectItem from "./ISelectItem";

interface Props {
  /** Type */
  type?: "single" | "multi" | "multi-pills" | "input-single" | "input-multi";
  /** List of available items to choose from */
  items: ISelectItem[];
  /** Disabled */
  disabled?: boolean;
  /** Placeholder */
  placeholder?: string;
  /** Current label */
  label?: string;
  /** Selected item */
  selectedItems?: ISelectItem[];
  /** onChange callback */
  onChange?: Function;
  /** Extra css class  */
  className?: string | string[];
}
/** @deprecated This component is deprecated, please use SingleSelect or MultiSelect instead. */
const Select = ({
  type,
  items,
  disabled,
  placeholder,
  label,
  selectedItems,
  onChange,
  className
}: Props) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState(label || "");
  const [filteredItems, setFilteredItems] = useState<ISelectItem[]>([]);

  const optionscontainerRef = useRef<HTMLDivElement>(null);

  const filterOptions = (typedValue: string) => {
    if (items !== undefined) {
      setFilteredItems(
        items.filter(
          val => val.title.toLocaleLowerCase().indexOf(typedValue.toLocaleLowerCase()) > -1
        )
      );
    }
  };

  useEffect(() => {
    if (type === "input-single" || type === "input-multi") {
      filterOptions(text);
    } else {
      setFilteredItems(items);
    }
  }, [items]);

  const validTypes = ["single", "multi", "multi-pills", "input-single", "input-multi"];
  const selectedDataType = type !== undefined ? type : "single";

  const closeSelect = () => setOpen(false);
  const containerRef = useClickOutside(closeSelect);

  const invalidType = (
    <div className="select closed" data-type="invalid-type">
      <div className="invalid_select">Invalid type</div>
    </div>
  );

  const renderItem = (item: ISelectItem) => (
    <div
      key={item.title}
      title={item.title}
      role="presentation"
      onClick={e => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        setText(item.title);
        if (onChange !== undefined) {
          onChange(item);
        }
        setOpen(false);
      }}
      className={cx("item", { active: item.title === label })}
    >
      {item.title}
    </div>
  );

  const renderMultiItem = (item: ISelectItem) => {
    const id = uniqueId();
    const selectedLabels = selectedItems !== undefined ? selectedItems.map(cur => cur.title) : [];
    const checked = selectedItems !== undefined && selectedLabels.includes(item.title);
    return (
      <div className={cx("item")} key={item.title} title={item.title}>
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
          onChange={() => {
            if (onChange !== undefined) {
              let returnValues;
              if (checked) {
                returnValues =
                  selectedItems !== undefined
                    ? selectedItems.filter((l: ISelectItem) => l.title !== item.title)
                    : [];
              } else {
                returnValues = selectedItems !== undefined ? [item, ...selectedItems] : [item];
              }
              onChange(returnValues);
            }
          }}
        />
        <label
          htmlFor={id}
          onClick={e => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          }}
        >
          {item.title}
        </label>
      </div>
    );
  };

  const pills = () => {
    const pillsToAdd = [];
    const selectedLabels = selectedItems !== undefined ? selectedItems.map(cur => cur.title) : [];
    if (
      (selectedDataType === "multi-pills" || selectedDataType === "input-multi") &&
      selectedItems !== undefined
    ) {
      for (let i = 0; i < selectedItems.length; i += 1) {
        pillsToAdd.push(
          <Pill
            key={i}
            onRemove={() =>
              onChange !== undefined
                ? onChange(selectedItems.filter((l: ISelectItem) => l.title !== selectedLabels[i]))
                : null
            }
          >
            {`${selectedItems[i].title}`}
          </Pill>
        );
      }
    }
    return (
      <div ref={optionscontainerRef} className="options_container">
        {pillsToAdd}
      </div>
    );
  };

  const buttonType =
    selectedDataType === "input-single" || selectedDataType === "input-multi" ? (
      <div
        role="presentation"
        className="clickable current-options"
        onClick={e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          setOpen(true);
        }}
      >
        <input
          value={text || ""}
          type="text"
          placeholder={placeholder}
          onFocus={() => {
            setOpen(true);
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === "") {
              setOpen(true);
            }
            setText(e.target.value);
            filterOptions(e.target.value);
          }}
        />
      </div>
    ) : (
      <button
        type="button"
        className="btn current-options"
        onClick={e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          setOpen(!open);
        }}
      >
        {label || placeholder || ""}
      </button>
    );

  const isOutOfViewport = (elem: HTMLDivElement) => {
    const itemHeightOffset = 32;
    const maxOffset = 130;
    const offsetHeight = items.length < 4 ? itemHeightOffset * items.length : maxOffset;
    const bounding = elem.getBoundingClientRect();

    return (
      bounding.bottom + offsetHeight > (window.innerHeight || document.documentElement.clientHeight)
    );
  };

  const outOfViewport = containerRef.current ? isOutOfViewport(containerRef.current) : false;
  const optionscontainerHeight = optionscontainerRef.current
    ? optionscontainerRef.current.offsetHeight
    : 0;

  if (validTypes.includes(selectedDataType)) {
    return (
      <div title={label}>
        <div
          ref={containerRef}
          role="presentation"
          className={cx("select", { disabled }, { closed: !open }, { open }, className)}
          data-type={selectedDataType}
        >
          {buttonType}

          <div
            className={cx("options-list overflow", { above: outOfViewport })}
            style={
              outOfViewport &&
              (selectedDataType === "multi-pills" || selectedDataType === "input-multi")
                ? { bottom: `${28 + optionscontainerHeight}px` }
                : undefined
            }
          >
            {(type === "input-single" || type === "input-multi") &&
            filteredItems.length === 0 &&
            text !== "" ? (
              <div className="no_results">No results found</div>
            ) : null}
            {filteredItems.map((item: ISelectItem) => {
              let render = renderItem;
              if (
                selectedDataType === "multi" ||
                selectedDataType === "multi-pills" ||
                selectedDataType === "input-multi"
              ) {
                render = renderMultiItem;
              }
              return render(item);
            })}
          </div>
          {pills()}
        </div>
      </div>
    );
  }
  return invalidType;
};

export default Select;
