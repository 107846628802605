import React, { useCallback, useMemo } from "react";

import { Switch } from "@react-gcc-eds/core";

import ChecklistStandardItem from "./generic/checklist-standard-item";
import { stringToBooleanOrUndefined } from "./string-to-type-deserializer";
import { SingleItemComponent, SingleItemProps } from "./types";

const BooleanElement: SingleItemComponent = ({
  item,
  onValueChanged
}: SingleItemProps) => {
  const valueAsBoolean: boolean = useMemo<boolean>(
    () =>
      !!item.Values?.length &&
      (stringToBooleanOrUndefined(item.Values[0]) ?? false),
    [item.Values]
  );

  const handleOnChange = useCallback(
    (value: boolean): void =>
      onValueChanged({
        ...item,
        Values: [value.toString()]
      }),
    [item, onValueChanged]
  );

  return (
    <div className="switch-container bool">
      <ChecklistStandardItem item={item} onValueChanged={onValueChanged}>
        <Switch
          value={valueAsBoolean}
          onChange={handleOnChange}
          onLabel=""
          offLabel=""
        />
      </ChecklistStandardItem>
    </div>
  );
};

export default BooleanElement;
