import { useEffect, useCallback, useState } from "react";

import { configure } from "scandit-sdk";

import { AppSettings } from "../../../../hooks/app-settings";

type ScanditProps = {
  licenseConfigured: boolean;
  scanditLicenseError: boolean;
};

const useScanditLicense = (appSettings: AppSettings): ScanditProps => {
  const [licenseConfigured, setLicenseConfigured] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const initializeScanditLicenseIfNeeded = useCallback(async () => {
    if (licenseConfigured || !appSettings.scanditLicense) return;
    try {
      await configure(appSettings.scanditLicense, {
        engineLocation: "/"
      });
      setLicenseConfigured(true);
    } catch (error) {
      setError(true);
    }
  }, [appSettings.scanditLicense, licenseConfigured]);

  useEffect(() => {
    initializeScanditLicenseIfNeeded();
  }, [initializeScanditLicenseIfNeeded]);

  return { licenseConfigured, scanditLicenseError: error };
};

export default useScanditLicense;
