import React, { ReactElement } from "react";
import cx from "classnames";

import Settings from "./Settings";
import NotificationLog from "./NotificationLog";

import { UserInfo } from "./contracts";
import useAppContext from "./context/ContextHelpers";
import SettingsNode from "./SettingsNode";
import SettingsItem from "./SettingsItem";
import { useClickOutside } from "../../utils/utils";

interface Props {
  title?: string;
  onLogout?: React.MouseEventHandler;
  settingsContent?: ReactElement<typeof SettingsNode>[] | ReactElement<typeof SettingsItem>[];
  user?: UserInfo;
  iconName?: string;
}

const SysPanel = ({ title, user, settingsContent, onLogout, iconName }: Props) => {
  const { settings, theme, notifications } = useAppContext();

  const hideNotifications = () => {
    notifications.toggle();
    notifications.clearNew();
  };

  const containerRef = useClickOutside((e: Event) => {
    const sysbar = document.querySelector(".sysbar") ? document.querySelector(".sysbar") : null;
    if (!(sysbar && sysbar.contains(e.target as Node))) {
      notifications.setVisible(false);
      settings.setVisible(false);
    }
  });

  return (
    <aside
      ref={containerRef}
      className={cx("syspanel", { hidden: !settings.visible && !notifications.visible })}
    >
      <Settings
        title={title}
        user={user}
        settingsContent={settingsContent}
        onLogout={onLogout}
        settingsVisible={settings.visible}
        toggleSettings={settings.toggle}
        theme={theme.current}
        toggleTheme={theme.forced ? undefined : theme.toggle}
        iconName={iconName}
      />
      <NotificationLog
        notificationsVisible={notifications.visible}
        hideNotifications={hideNotifications}
        clearNotification={notifications.clear}
        deleteNotification={notifications.delete}
      />
    </aside>
  );
};

export default SysPanel;
