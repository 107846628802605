import React from "react";

import { Icon } from "@react-gcc-eds/core";

import { FeedbackMessage } from "../barcode-scanning/barcode-scanning-models/feedback-message";
import FeedbackStatus from "../barcode-scanning/barcode-scanning-models/feedback-status";

type Props = {
  feedbackMessage: FeedbackMessage;
};

const ScannerFeedbackCurtainView = ({
  feedbackMessage
}: Props): JSX.Element => {
  return (
    <div className="top-content">
      <div
        className={`feedback ${
          feedbackMessage.status === FeedbackStatus.Error
            ? "error"
            : feedbackMessage.status === FeedbackStatus.Warning
            ? "warning"
            : "information"
        }`}
      >
        <Icon
          name={
            feedbackMessage.status === FeedbackStatus.Error
              ? "triangle-warning"
              : feedbackMessage.status === FeedbackStatus.Warning
              ? "triangle-warning"
              : "info"
          }
        />
        {feedbackMessage.text}
      </div>
    </div>
  );
};

export default ScannerFeedbackCurtainView;
