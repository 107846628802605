import React, { useCallback, useState, useMemo } from "react";

import {
  Icon,
  Tooltip,
  DropdownMenu,
  Dialog,
  Button,
  Loader
} from "@react-gcc-eds/core";
import moment from "moment";

import { FileResponse } from "../../../../../domain/client-customer";
import { DocumentType } from "../../../../../domain/constants";
import {
  NamespaceKeys,
  DocumentSpecificKeys,
  GeneralKeys
} from "../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../translation/translation-utils";
import FileDownloadButton from "../../../../common/file-download-button";
import { SaveAsData } from "../../../../common/file-download-button/file-download-button-component";
import TitleValuePair from "../../../../common/layout/title-value-pair";
import UserInformationButton from "../../../../common/user-information-button";
import { DocumentModelState } from "../documents-tile-component";

type Props = {
  siteProjectId: string;
  document: DocumentModelState;
  editable: boolean;
  onDelete: (documentId: string, index: number) => Promise<void>;
  index: number;
};

const SiteProjectDocumentsTileItemComponent = ({
  siteProjectId,
  document,
  editable,
  onDelete,
  index
}: Props): React.ReactElement => {
  const { translate } = useTranslation();
  const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);

  const uploadedAt = useMemo<string>(
    () => moment.utc(document.UploadedAt).local().format("LLL"),
    [document.UploadedAt]
  );

  const isLink = useMemo<boolean>(
    () =>
      document.DocumentType === DocumentType.ErisiteLink ||
      document.DocumentType === DocumentType.Link,
    [document.DocumentType]
  );

  const iconExtension = useMemo<string>(() => {
    switch (document.Extension) {
      case "url":
        return "link";
      default:
        return "file";
    }
  }, [document.Extension]);

  const saveAs: (response: FileResponse) => SaveAsData = useCallback(
    (response) => ({
      data: response.data,
      filename: document.FileName
    }),
    [document]
  );

  const handleCloseDialog = useCallback((): void => {
    setShowDetailsDialog(false);
  }, []);
  const handleShowDialog = useCallback((): void => {
    setShowDetailsDialog(true);
  }, []);

  const handleOnClickShowDeleteDialog = useCallback((): void => {
    setShowDeleteDialog(true);
  }, []);
  const handleOnClickCloseDeleteDialog = useCallback((): void => {
    setShowDeleteDialog(false);
  }, []);

  const handleOnClickDelete = useCallback((): void => {
    if (!document.Id) return;
    setShowDeleteDialog(false);
    onDelete(document.Id, index);
  }, [onDelete, document.Id, index]);

  return (
    <div className="document-row">
      <div className="icon-column">
        {document.isDeleting ? (
          <Loader size="small" />
        ) : (
          <Icon name={iconExtension} />
        )}
      </div>
      <div className="text-column">
        <div
          onClick={!document.isDeleting ? handleShowDialog : undefined}
          className="title"
        >
          {document.FileName}
        </div>
        <div className="meta-data">
          {document.isDeleting ? (
            translate(
              NamespaceKeys.DocumentSpecific,
              DocumentSpecificKeys.DeletingDocument
            )
          ) : (
            <>
              {document.DocumentType === DocumentType.RestrictedAccess && (
                <Tooltip
                  text={translate(
                    NamespaceKeys.DocumentSpecific,
                    DocumentSpecificKeys.RestrictedAccessTooltipMessage
                  )}
                  type="pointer"
                >
                  <Icon name="eye" />
                </Tooltip>
              )}
              {uploadedAt}
            </>
          )}
        </div>
      </div>
      {editable && document.IsDeletable && !document.isDeleting && (
        <div className="action-column">
          <DropdownMenu
            menuItems={[
              {
                item: (
                  <div onClick={handleShowDialog}>
                    {translate(NamespaceKeys.General, GeneralKeys.Details)}
                  </div>
                )
              },
              {
                item: (
                  <div onClick={handleOnClickShowDeleteDialog}>
                    {translate(NamespaceKeys.General, GeneralKeys.Delete)}
                  </div>
                )
              }
            ]}
          />
        </div>
      )}
      {showDetailsDialog && (
        <Dialog
          title={translate(
            NamespaceKeys.DocumentSpecific,
            DocumentSpecificKeys.DocumentDetails
          )}
          fullscreen
          className="document-details"
          buttons={[
            isLink ? (
              <a
                href={document.DocumentPath}
                rel="noopener noreferrer"
                target="_blank"
                key="link"
                className="btn primary"
              >
                <Icon name="launch" />
                {translate(NamespaceKeys.General, GeneralKeys.OpenInNewTab)}
              </a>
            ) : (
              <FileDownloadButton
                key="download"
                primary
                siteProjectId={siteProjectId}
                documentId={document.Id ?? ""}
                saveAsFn={saveAs}
              >
                <Icon name="download-save" />
                {translate(NamespaceKeys.General, GeneralKeys.Download)}
              </FileDownloadButton>
            ),
            <Button onClick={handleCloseDialog} key="close">
              {translate(NamespaceKeys.General, GeneralKeys.Close)}
            </Button>
          ]}
          onClose={handleCloseDialog}
        >
          <TitleValuePair
            title={translate(
              NamespaceKeys.DocumentSpecific,
              DocumentSpecificKeys.Name
            )}
            value={<div className="filename">{document.FileName}</div>}
            titleSize={4}
            valueSize={8}
          />
          {isLink && (
            <TitleValuePair
              title={translate(
                NamespaceKeys.DocumentSpecific,
                DocumentSpecificKeys.Url
              )}
              value={<pre>{document.DocumentPath}</pre>}
              titleSize={4}
              valueSize={8}
            />
          )}
          <TitleValuePair
            title={translate(
              NamespaceKeys.DocumentSpecific,
              DocumentSpecificKeys.UploadedBy
            )}
            value={
              <div>
                {document.UploadedBy ? (
                  <UserInformationButton user={document.UploadedBy} />
                ) : (
                  translate(NamespaceKeys.General, GeneralKeys.UnknownUser)
                )}
              </div>
            }
            titleSize={4}
            valueSize={8}
          />
          <TitleValuePair
            title={translate(
              NamespaceKeys.DocumentSpecific,
              DocumentSpecificKeys.UploadAt
            )}
            value={uploadedAt}
            titleSize={4}
            valueSize={8}
          />
          {document.DocumentType === DocumentType.RestrictedAccess && (
            <div className="restricted-note">
              <Icon name="eye" />
              {translate(
                NamespaceKeys.DocumentSpecific,
                DocumentSpecificKeys.RestrictedAccessDialogMessage
              )}
            </div>
          )}
        </Dialog>
      )}
      {showDeleteDialog && (
        <Dialog
          title={translate(
            NamespaceKeys.DocumentSpecific,
            DocumentSpecificKeys.ConfirmDocumentDeletionDialogTitle
          )}
          buttons={[
            <Button primary key="deleteBtn" onClick={handleOnClickDelete}>
              <Icon name="trashcan" />
              {translate(NamespaceKeys.General, GeneralKeys.Delete)}
            </Button>,
            <Button key="closeBtn" onClick={handleOnClickCloseDeleteDialog}>
              {translate(NamespaceKeys.General, GeneralKeys.Cancel)}
            </Button>
          ]}
        >
          {translate(
            NamespaceKeys.DocumentSpecific,
            DocumentSpecificKeys.ConfirmDocumentDeletionDialogMessage
          )}
        </Dialog>
      )}
    </div>
  );
};

export default SiteProjectDocumentsTileItemComponent;
