import React, { useContext, useCallback, useState, useEffect } from "react";

import { Loader, Tile } from "@react-gcc-eds/core";
import { useParams } from "react-router";

import { AuthenticationContext } from "../../contexts/authentication-context";
import { CustomerContext } from "../../contexts/customer-context";
import { LayoutContext } from "../../contexts/layout-context";
import {
  SiteProjectDetailsModel,
  SiteProjectsClient,
  ResourceAccessIdentifier
} from "../../domain/client-customer";
import UrlParameters from "../../domain/enums/url-parameters";
import {
  ComponentSpecificKeys,
  NamespaceKeys,
  MenuTitleKeys,
  GeneralKeys
} from "../../translation/dictionary-keys";
import { useTranslation } from "../../translation/translation-utils";
import { HasSiteProjectPermission } from "../../utils/utils";
import CenteredContainer from "../common/layout/centered-container";
import { SiteInformation } from "./site-information";
import BomTile from "./site-project-item/bom-tile";
import ContactsTile from "./site-project-item/contacts-tile";
import SiteProjectDocumentsTile from "./site-project-item/documents-tile";

type ParameterProps = {
  [UrlParameters.siteProjectId]: string | undefined;
};

const SiteProjectItem = (): JSX.Element => {
  const params = useParams<ParameterProps>();
  const siteProjectId = params[UrlParameters.siteProjectId];
  const { setPageTitle } = useContext(LayoutContext);
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const { currentCustomer, authenticatedCall } = useContext(CustomerContext);
  const { translate } = useTranslation();
  const [siteProject, setSiteProject] = useState<SiteProjectDetailsModel>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<ComponentSpecificKeys>();

  const getSiteProject = useCallback(async () => {
    const customerIdentifier =
      currentCustomer && currentCustomer.HeaderIdentifier;
    if (!customerIdentifier || !siteProjectId) {
      setErrorMessage(ComponentSpecificKeys.CustomersErrorMessageTitle);
      return;
    }
    setLoading(true);
    authenticatedCall(
      new SiteProjectsClient(
        await getCustomerConfigurationProvider()
      ).getSiteProject(siteProjectId, customerIdentifier)
    )
      .then((fetchedSiteProject) => {
        setSiteProject(fetchedSiteProject);
      })
      .catch(() => {
        setErrorMessage(ComponentSpecificKeys.SiteProjectsErrorMessageTitle);
      })
      .finally(() => setLoading(false));
  }, [
    currentCustomer,
    getCustomerConfigurationProvider,
    siteProjectId,
    authenticatedCall
  ]);

  useEffect(() => {
    if (siteProject) return;
    getSiteProject();
  }, [siteProject, getSiteProject]);

  useEffect(() => {
    const titleToSet =
      (siteProject && siteProject.DisplayName) ||
      translate(NamespaceKeys.MenuTitles, MenuTitleKeys.SiteProjectMenuTitle);
    setPageTitle && setPageTitle({ title: titleToSet });
  }, [translate, setPageTitle, siteProject]);

  return (
    <>
      {loading ? (
        <CenteredContainer>
          <Loader size="large" />
        </CenteredContainer>
      ) : errorMessage ? (
        <Tile title={translate(NamespaceKeys.ComponentSpecific, errorMessage)}>
          {translate(NamespaceKeys.General, GeneralKeys.PleaseTryAgainLater)}
        </Tile>
      ) : !siteProject || !siteProject.Id || !siteProject.SiteId ? (
        <Tile
          title={translate(
            NamespaceKeys.ComponentSpecific,
            ComponentSpecificKeys.NoSiteProjectsTitle
          )}
        >
          {translate(
            NamespaceKeys.ComponentSpecific,
            ComponentSpecificKeys.NoSiteProjectsMessage
          )}
        </Tile>
      ) : (
        <>
          <ContactsTile siteProjectId={siteProject.Id} />
          <BomTile siteProjectId={siteProject.Id} />
          <SiteProjectDocumentsTile
            siteProjectId={siteProject.Id}
            editable={HasSiteProjectPermission(
              siteProject,
              ResourceAccessIdentifier.CanEditSiteProjectDocuments
            )}
          />
          <SiteInformation siteId={siteProject.SiteId} />
        </>
      )}
    </>
  );
};

export default SiteProjectItem;
