import React from "react";
import cx from "classnames";
import { uniqueId } from "../../utils/utils";
import ICheckbox from "./ICheckbox";

const Checkbox = ({
  text,
  checked,
  onChange,
  disabled,
  indeterminate,
  className
}: ICheckbox) => {
  const id = uniqueId();

  const stopBubbling = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (indeterminate && onChange) {
      onChange(checked);
    }
  };

  return (
    <>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onClick={stopBubbling}
        ref={input => {
          const Input = input;
          if (Input) {
            Input.indeterminate = indeterminate as boolean;
          }
        }}
        onChange={() => {
          if (onChange) {
            onChange(!checked);
          }
        }}
        disabled={disabled}
        className={cx("checkbox", className)}
      />
      <label onClick={stopBubbling} className={cx("chklabel", className)} htmlFor={id}>
        {text}
      </label>
    </>
  );
};
export default Checkbox;
