import React, { useEffect, useState, useCallback } from "react";

import { Row, Tile, TextField, Switch } from "@react-gcc-eds/core";

import {
  CurrentUserModel,
  SiteProjectViewModel
} from "../../domain/client-customer";
import { ActivityTypes, UserRoles } from "../../domain/enums";
import TitleValuePair from "../common/layout/title-value-pair";

type Props = {
  hidden?: boolean;
  currentUser: CurrentUserModel;
  unfilteredList: SiteProjectViewModel[] | undefined;
  onFiltersChanged: (filteredList: SiteProjectViewModel[]) => void;
};

const userMaySeeAssignedFilter: (
  user: CurrentUserModel | undefined
) => boolean = (user) =>
  !!user &&
  (user.Role === UserRoles.ConstructionManager ||
    user.Role === UserRoles.SuperUser);

const userMaySeeCompanySiteProjectsFilter: (
  user: CurrentUserModel | undefined
) => boolean = (user) => !!user && user.Role === UserRoles.CrewLeader;

const SiteProjectsListFilters: (props: Props) => JSX.Element | null = ({
  hidden,
  currentUser,
  unfilteredList,
  onFiltersChanged
}) => {
  const [assignedOnly, setAssignedOnly] = useState<boolean>(true);
  const [hideCompanySiteProjects, setHideCompanySiteProjects] =
    useState<boolean>(true);
  const [hideCompleted, setHideCompleted] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");

  useEffect(() => {
    let filtered = unfilteredList || [];

    if (currentUser && userMaySeeAssignedFilter(currentUser) && assignedOnly) {
      filtered = filtered.filter((f) => f.IsAssignedToSiteProject);
    }
    if (
      currentUser &&
      userMaySeeCompanySiteProjectsFilter(currentUser) &&
      hideCompanySiteProjects
    ) {
      filtered = filtered.filter((f) => !f.IsCompanySiteProject);
    }
    if (hideCompleted) {
      filtered = filtered.filter(
        (f) => f.CurrentActivityType !== ActivityTypes.SiteProjectCompleted
      );
    }
    if (searchQuery && searchQuery.length) {
      const lowercaseSearchQuery = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (f) =>
          (f.SiteName &&
            f.SiteName.toLowerCase().includes(lowercaseSearchQuery)) ||
          (f.Name && f.Name.toLowerCase().includes(lowercaseSearchQuery))
      );
    }

    onFiltersChanged(filtered);
  }, [
    currentUser,
    onFiltersChanged,
    unfilteredList,
    assignedOnly,
    hideCompanySiteProjects,
    hideCompleted,
    searchQuery
  ]);

  const handleOnMySiteProjectsChange: (newValue: boolean) => void = useCallback(
    (newValue: boolean) => setAssignedOnly(newValue),
    []
  );
  const handleOnHideCompletedChange: (newValue: boolean) => void = useCallback(
    (newValue: boolean) => setHideCompleted(newValue),
    []
  );
  const handleOnCompanySiteProjectsChange: (newValue: boolean) => void =
    useCallback(
      (newValue: boolean) => setHideCompanySiteProjects(newValue),
      []
    );

  const handleOnSearchChange: (newValue: string) => void = useCallback(
    (newValue) => setSearchQuery(newValue),
    []
  );

  return (
    (!hidden && (
      <Row className="filters-row">
        <Tile sm={12} title="Filters">
          <TextField
            value={searchQuery}
            onChange={handleOnSearchChange}
            placeholder="Search site project / site name"
            fullWidth
            className="freetext-filter"
          />
          {userMaySeeCompanySiteProjectsFilter(currentUser) && (
            <TitleValuePair
              title="Hide company site projects"
              value={
                <Switch
                  value={hideCompanySiteProjects}
                  onChange={handleOnCompanySiteProjectsChange}
                />
              }
            />
          )}
          {userMaySeeAssignedFilter(currentUser) && (
            <TitleValuePair
              title="Only assigned site projects"
              value={
                <Switch
                  value={assignedOnly}
                  onChange={handleOnMySiteProjectsChange}
                />
              }
            />
          )}
          <TitleValuePair
            title="Hide completed site projects"
            value={
              <Switch
                value={hideCompleted}
                onChange={handleOnHideCompletedChange}
              />
            }
          />
        </Tile>
      </Row>
    )) ||
    null
  );
};

export default SiteProjectsListFilters;
