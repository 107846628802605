import React, { ReactElement, useMemo } from "react";

import { Button } from "@react-gcc-eds/core";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { MaterialTransferItemStatus } from "../../../../../domain/client-customer";
import {
  ComponentSpecificKeys,
  NamespaceKeys
} from "../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../translation/translation-utils";
import { SerializedItemState } from "../../../receive-material-overview/shared/receive-material-models";
import { SerialNumberValidationItem } from "./serial-number-validator-component";
import SerialNumberValidatorItem from "./serial-number-validator-item";

import "./serial-number-validator.scss";

const toSerialNumberValidationItem = (
  item: SerializedItemState
): SerialNumberValidationItem => {
  if (!item.dplItem) {
    throw new Error("Could not map to serial number validation item.");
  }
  return {
    serialNumbersValidated: item.serialNumbersValidated,
    dplItem: item.dplItem,
    serialNumbersNeeded: item.serialNumbersNeeded,
    materialCategory: item.materialCategory,
    materialTransferItemId: item.billOfMaterialItemIdentity.Value,
    customerSerialNumber: item.customerSerialNumber ?? undefined,
    ericssonSerialNumber: item.ericssonSerialNumber ?? undefined,
    status: item.status ?? MaterialTransferItemStatus.Ok,
    serialKey: item.serialKey
  };
};

type Props = {
  serializedItems: SerializedItemState[];
  errorMessage: ComponentSpecificKeys | undefined;
  onItemChange: (item: SerialNumberValidationItem) => void;
  onSmartScanClicked: () => void;
};

const SerialNumberValidatorView = ({
  serializedItems,
  errorMessage,
  onItemChange,
  onSmartScanClicked
}: Props): ReactElement => {
  const { translate } = useTranslation();
  const receivedSerializedItems = useMemo(
    () =>
      serializedItems
        .filter(
          (item) =>
            item.status !== MaterialTransferItemStatus.None &&
            item.status !== MaterialTransferItemStatus.Missing
        )
        .map((item, i) => [i, item] as const),
    [serializedItems]
  );
  const validatedItems = useMemo(
    () =>
      receivedSerializedItems
        .filter(([, item]) => item.serialNumbersValidated)
        .map(([i, item]) => [i, toSerialNumberValidationItem(item)] as const),
    [receivedSerializedItems]
  );
  const nonValidatedItems = useMemo(
    () =>
      receivedSerializedItems
        .filter(([, item]) => !item.serialNumbersValidated)
        .map(([i, item]) => [i, toSerialNumberValidationItem(item)] as const),
    [receivedSerializedItems]
  );

  return errorMessage ? (
    <div>{translate(NamespaceKeys.ComponentSpecific, errorMessage)}</div>
  ) : (
    <>
      {(nonValidatedItems.length && (
        <>
          <div className="item-list-header wizard-page-title">
            <span className="title-text">
              {translate(
                NamespaceKeys.ComponentSpecific,
                ComponentSpecificKeys.SerialNumbersToScan
              )}
            </span>
            <div className="actions">
              <Button onClick={onSmartScanClicked} iconName="camera-photo">
                {translate(
                  NamespaceKeys.ComponentSpecific,
                  ComponentSpecificKeys.MaterialTransferValidationSmartScannerButtonLabel
                )}
              </Button>
            </div>
          </div>
        </>
      )) ||
        null}
      <TransitionGroup>
        {nonValidatedItems.map(([i, item]) => (
          <CSSTransition key={i} timeout={300} classNames="item">
            <SerialNumberValidatorItem
              item={item}
              onItemChange={onItemChange}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
      {(validatedItems.length && (
        <>
          <div className="item-list-header">
            {translate(
              NamespaceKeys.ComponentSpecific,
              ComponentSpecificKeys.ScannedSerialNumbers
            )}
          </div>
        </>
      )) ||
        null}
      <TransitionGroup>
        {validatedItems.map(([i, item]) => (
          <CSSTransition key={i} timeout={300} classNames="item">
            <SerialNumberValidatorItem
              item={item}
              onItemChange={onItemChange}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
    </>
  );
};

export default SerialNumberValidatorView;
