import React, { useState, ChangeEvent } from "react";
import cx from "classnames";
import * as utils from "../../utils/utils";
import Pill from "../pill/Pill";
import ITagsInput from "./ITagsInput";

const TagsInput = ({
  placeholder = "",
  tags = [],
  onTagAdd,
  onTagRemove,
  onChange,
  className,
  disabled
}: ITagsInput) => {
  const [text, setText] = useState("");

  const pills = () => {
    return (
      <div className="options_container">
        {tags.map((tag: string, i: number) => (
          <Pill
            key={utils.uniqueId()}
            onRemove={() => {
              onTagRemove(i);
            }}
          >
            {tag}
          </Pill>
        ))}
      </div>
    );
  };

  const input = (
    <div role="presentation" className="tagsinput clickable current-options">
      <input
        value={text || ""}
        type="text"
        placeholder={placeholder}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setText(e.target.value);
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter" && text !== "") {
            setText("");
            onTagAdd(text);
          }
        }}
      />
    </div>
  );

  return (
    <div role="presentation" className={cx("select closed", { disabled }, className)}>
      {input}
      {pills()}
    </div>
  );
};

export default TagsInput;
