export enum ActivityStatus {
  Compelted = "Completed",
  Ok = "Ok",
  Warning = "Warning",
  Critical = "Critical",
  Overdue = "Overdue"
}

export enum ActivityTypes {
  DesignSurvey = "Design Survey",
  SiteWalkEngineering = "Site Walk Engineering",
  SiteProjectCompleted = "SiteProject Completed"
}
export enum UserRoles {
  SuperUser = "SuperUser",
  CrewLeader = "CrewLeader",
  ConstructionManager = "ConstructionManager"
}

export enum ProductFilters {
  Commodity = "Commodity",
  MaterialCategory = "MaterialCategory",
  SourcingProvider = "SourcingProvider",
  EricssonProductNumber = "EricssonProductNumber"
}
