import { RotationEnum } from "../../../../../domain/client-obsolete";

export const getStylingFromRotation = (
  rotation: RotationEnum | undefined
): React.CSSProperties | undefined =>
  rotation === RotationEnum.Ninety
    ? { transform: "rotate(90deg)" }
    : rotation === RotationEnum.OneHundredEighty
    ? { transform: "rotate(180deg)" }
    : rotation === RotationEnum.TwoHundredSeventy
    ? { transform: "rotate(270deg)" }
    : undefined;
