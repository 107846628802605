import React from "react";

import { Row, Column } from "@react-gcc-eds/core";

import "../../../styling/components/common/title-value-pair.scss";

type Props = {
  title: string;
  value: JSX.Element | string | number;
  titleSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  valueSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
};

const DEFAULT_SIZE = 6;

const TitleValuePair = (props: Props): JSX.Element => {
  const renderValue = (): JSX.Element => {
    if (props.value instanceof String || props.value instanceof Number) {
      return <span>{props.value}</span>;
    }
    return props.value as JSX.Element;
  };

  return (
    <Row className="title-value-pair-container">
      <Column
        sm={props.titleSize ?? DEFAULT_SIZE}
        md={props.titleSize ?? DEFAULT_SIZE}
        className="title-value-pair-title-column"
      >
        <span className="title-value-pair-title">{props.title}</span>
      </Column>
      <Column
        sm={props.valueSize ?? DEFAULT_SIZE}
        md={props.valueSize ?? DEFAULT_SIZE}
        className="title-value-pair-value-column"
      >
        <div>{renderValue()}</div>
      </Column>
    </Row>
  );
};

export default TitleValuePair;
