import React from "react";
import ProgressBar from "../progress-bar/ProgressBar";
import IKpiProgressBar from "./IKpiProgressBar";

const KpiProgressBar = ({value, red, className}:IKpiProgressBar) => {
  return (
    <ProgressBar className={className}  max={100} value={value}
        color={red ? "red" : ""}
        thin
  />
  );
};

export default KpiProgressBar;
