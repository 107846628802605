import React from "react";
import cx from "classnames";
import KpiWarning from "./KpiWarning";
import KpiProgressBar from "./KpiProgressBar";
import Icon from "../icon/Icon";

// @ts-ignore
const KpiItem = ({lg, xl, color, sm, inline, span,separator, className ,children}: {l?:boolean, xl?:boolean, color:string, sm?: boolean, inline: boolean, span: boolean , separator:boolean; className:string, children:ReactElement<typeof KpiItem> | ReactElement<typeof KpiProgressBar> | ReactElement<typeof KpiWarning> | ReactElement<typeof Icon> }) => {
  
  const customColor = color ? `color-${color}` : '';

  const classes = cx({
    'item': inline,
    'text-lg': lg, 
    'text-xl': xl, 
    'text-sm': sm}, className ,customColor
  );

  const style = separator ? {marginLeft: '5px', marginRight: '5px'} : {}

  return !span ? (
    <div className={classes} style={style}>
     {children} 
    </div>
  ) : (
    <span className={classes}>
     {children} 
    </span>
  );
}

export default KpiItem;