/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useRef, useState, useEffect } from "react";
import cx from "classnames";

import { getStringDomLength, useMoveOutside } from "../../utils/utils";
import IRangeSlider from "./IRangeSlider";

interface IPosition {
  value: number;
  position: number;
}

const RangeSlider = ({
  min = 0,
  max = 100,
  value = 0,
  label,
  tooltipLabels,
  step = 1,
  multiple,
  values = [0, 0],
  onChange,
  hideTooltip = false,
  disabled,
  leftLabel,
  rightLabel
}: IRangeSlider) => {
  const slider = useRef<HTMLDivElement>(null);
  const handle = useRef<HTMLDivElement>(null);
  const primaryRangerTooltip = useRef<HTMLDivElement>(null);
  const secondaryRangerTooltip = useRef<HTMLDivElement>(null);

  const [positions, setPositions] = useState<IPosition[]>([]);
  const [initialized, setInitialized] = useState(false);

  const [useCombinedTooltip, setUseCombinedTooltip] = useState(false);
  const [dragging, setDragging] = useState(false);

  const [currentHandle, setCurrentHandle] = useState(-1);
  const [focus, setFocus] = useState(false);

  const containerRef = useMoveOutside(() => {
    setDragging(false);
  });

  useEffect(() => {
    const newPositions = [];

    let n = Math.abs(max);
    if (min < 0) {
      n += Math.abs(min);
    } else {
      n -= min;
    }

    const percentStep = 100 / n;
    if (step > 0) {
      for (let i = 0; i <= n; i += step) {
        newPositions.push({
          value: min + i,
          position: i * percentStep
        });
      }
    }

    setPositions(newPositions);
    setInitialized(true);
  }, [min, max]);

  const currentPositions = (positions.filter((cur: IPosition) => {
    if (multiple) {
      return cur.value === values[0] || cur.value === values[1];
    }
    return cur.value === value;
  }) as unknown) as IPosition;

  const getHandlePositions = () => {
    const rangerStyle = !multiple ? { width: "0%" } : { left: "0%", width: "0%" };

    const multipleHandlerStyling =
      currentPositions[1] !== undefined
        ? {
            left: `${currentPositions[0].position}%`,
            width: `${currentPositions[1].position - currentPositions[0].position}%`
          }
        : {
            width: `0%`,
            left: `${currentPositions[0].position}%`
          };

    if (currentPositions !== undefined && currentPositions[0] !== undefined) {
      return !multiple ? { width: `${currentPositions[0].position}%` } : multipleHandlerStyling;
    }
    return rangerStyle;
  };

  const tipsIntersecting = () => {
    if (primaryRangerTooltip.current && secondaryRangerTooltip.current) {
      const a = primaryRangerTooltip.current.getBoundingClientRect();
      const b = secondaryRangerTooltip.current.getBoundingClientRect();
      return !(a.right < b.left || a.left > b.right || a.bottom < b.top || a.top > b.bottom);
    }
    return false;
  };

  const getValueFromPosition = (position: number) => {
    // @ts-ignore
    const pos = position - slider.current?.offsetLeft;
    // @ts-ignore
    const size = slider.current?.offsetWidth;

    // get the position of the cursor over the bar as a percentage
    const newPosition = (pos / (size || 1)) * 100;
    const closest = (positions.reduce((prev: IPosition, curr: IPosition) => {
      return Math.abs(curr.position - newPosition) < Math.abs(prev.position - newPosition)
        ? curr
        : prev;
    }) as unknown) as IPosition;

    const newValue = closest.value;

    if (multiple) {
      const newValues = [values[0], values[1]];
      if (currentHandle === -1) {
        if (newValue < values[0]) {
          setCurrentHandle(0);
        }
        if (newValue > values[1]) {
          setCurrentHandle(1);
        }

        if (newValue > values[0] && newValue < values[1]) {
          if (Math.abs(newValue - values[0]) < Math.abs(newValue - values[1])) {
            setCurrentHandle(0);
          } else {
            setCurrentHandle(1);
          }
        }
      }
      newValues[currentHandle] = newValue;
      setUseCombinedTooltip(tipsIntersecting());

      if (newValues[0] <= newValues[1]) {
        return newValues;
      }
      return values;
    }

    return closest.value;
  };

  const handleDrag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (dragging && onChange) {
      onChange(getValueFromPosition(e.clientX - 15) as number[]);
    }
  };

  const handleStart = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setDragging(true);
    if (onChange) {
      onChange(getValueFromPosition(e.clientX - 15) as number[]);
    }
    setFocus(true);
  };

  const handleEnd = () => {
    setDragging(false);
    setFocus(false);
    setCurrentHandle(-1);
  };

  if (!initialized) {
    return <div></div>;
  }

  const getLabels = () => {
    if (tooltipLabels) {
      const labelsDictionary = Object.keys(tooltipLabels);
      const foundLabels = ["", ""];

      labelsDictionary.forEach(key => {
        if (parseInt(key, 0) === values[0]) {
          foundLabels[0] = tooltipLabels[key];
        }

        if (parseInt(key, 0) === values[1]) {
          foundLabels[1] = tooltipLabels[key];
        }

        const nextIndex = labelsDictionary.indexOf(key) + 1;
        const nextKey =
          labelsDictionary[nextIndex] !== undefined
            ? labelsDictionary[nextIndex]
            : Number.MAX_SAFE_INTEGER.toString();

        if (values[0] > parseInt(key, 0) && values[0] < parseInt(nextKey, 0)) {
          foundLabels[0] = tooltipLabels[key];
        }

        if (values[1] > parseInt(key, 0) && values[1] < parseInt(nextKey, 0)) {
          foundLabels[1] = tooltipLabels[key];
        }
      });
      return foundLabels;
    }
    return values;
  };

  const getLabel = () => {
    if (tooltipLabels) {
      const labelsDictionary = Object.keys(tooltipLabels);
      let foundLabel = "";
      labelsDictionary.forEach(key => {
        if (parseInt(key, 0) === value) {
          foundLabel = tooltipLabels[key];
        }

        const nextIndex = labelsDictionary.indexOf(key) + 1;
        const nextKey =
          labelsDictionary[nextIndex] !== undefined
            ? labelsDictionary[nextIndex]
            : Number.MAX_SAFE_INTEGER.toString();

        if (value > parseInt(key, 0) && value < parseInt(nextKey, 0)) {
          foundLabel = tooltipLabels[key];
        }
      });
      return foundLabel;
    }
    return value.toString();
  };

  const tooltipLabel = !multiple ? getLabel() : getLabels();

  const getTooltipStyling = () => {
    const fontSize = 12;
    if (value === min) {
      return { paddingLeft: `${getStringDomLength(min.toString(), fontSize) / 2}px` };
    }
    if (value === max) {
      return { marginRight: `${getStringDomLength(max.toString(), fontSize) / 4}px` };
    }

    return { padding: 0 };
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (multiple) {
      return;
    }
    const { keyCode } = e;
    let sliderValue;

    switch (keyCode) {
      case 38:
      case 39:
        sliderValue = value + step > max ? max : value + step;
        if (onChange) {
          onChange(sliderValue);
        }
        break;
      case 37:
      case 40:
        sliderValue = value - step < min ? min : value - step;
        if (onChange) {
          onChange(sliderValue);
        }
        break;
      default:
        break;
    }
  };

  return (
    <div
      ref={containerRef}
      role="presentation"
      onMouseDown={handleStart}
      onMouseMove={handleDrag}
      onMouseUp={handleEnd}
      className={cx({ disabled })}
    >
      {label && <label htmlFor="range">{label}</label>}
      <div
        role="presentation"
        className={cx(
          "ranger-container",
          { disabled },
          { "ranger-multiple": multiple },
          { "combined-tooltip": useCombinedTooltip }
        )}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => handleKeyDown(e)}
        // @ts-ignore
        tabIndex={0}
        ref={slider}
      >
        <div className="ranger-track">
          <div className={cx("ranger-progress", { focus })} style={getHandlePositions()}>
            <div className={cx("ranger-handle", { focus })} ref={handle}>
              {!hideTooltip && (
                <div
                  className="ranger-tooltip unselectable"
                  style={getTooltipStyling()}
                  ref={primaryRangerTooltip}
                >
                  {!multiple ? tooltipLabel : tooltipLabel[0]}
                </div>
              )}
            </div>
            {multiple && (
              <>
                <div className={cx("ranger-handle", { focus })}>
                  {!hideTooltip && (
                    <div
                      className="ranger-tooltip unselectable"
                      style={{ marginRight: "0px" }}
                      ref={secondaryRangerTooltip}
                    >
                      {values[1]}
                    </div>
                  )}
                </div>
                {useCombinedTooltip && !hideTooltip && (
                  <div className="ranger-tooltip unselectable" style={{ marginRight: "0px;" }}>
                    {values[0] === values[1] ? values[0] : `${values[0]} - ${values[1]}`}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="range-limits">
            <div className="range-limit unselectable">{leftLabel || min}</div>
            <div className="range-limit unselectable">{rightLabel || max}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RangeSlider;
