import {
  NamespaceKeys,
  ApplicationKeys,
  Namespace,
  GeneralKeys,
  LoginSpecificKeys,
  MenuTitleKeys,
  BomSpecificKeys,
  ChecklistSpecificKeys,
  DplSpecificKeys,
  ActivitySpecificKeys,
  SiteSpecificKeys,
  DocumentSpecificKeys,
  ComponentSpecificKeys,
  ScannerSpecificKeys,
  MaterialSpecificKeys,
  InstallationSpecificKeys,
  ReceivingMaterialSpecificKeys
} from "../dictionary-keys";

const EN: Namespace = {
  [NamespaceKeys.Application]: {
    [ApplicationKeys.ApplicationName]: "Ericsson Site Rollout",
    [ApplicationKeys.ApplicationAcronym]: "ESR",
    [ApplicationKeys.SwitchCustomers]: "Customer",
    [ApplicationKeys.NewAppNotificationDoNotShow]:
      "Do not show this message again",
    [ApplicationKeys.NewAppNotificationDetail]:
      "As part of our ongoing improvements, we have migrated to a new application to better serve our needs. Current app will be decommissioned. Please access our new application here to continue your tasks seamlessly."
  },
  [NamespaceKeys.General]: {
    [GeneralKeys.Reload]: "Reload",
    [GeneralKeys.Ok]: "Ok",
    [GeneralKeys.Damaged]: "Damaged",
    [GeneralKeys.Damage]: "damage",
    [GeneralKeys.Missing]: "Missing",
    [GeneralKeys.Correct]: "Correct",
    [GeneralKeys.Valid]: "Valid",
    [GeneralKeys.Restore]: "Restore",
    [GeneralKeys.Cancel]: "Cancel",
    [GeneralKeys.Save]: "Save",
    [GeneralKeys.Next]: "Next",
    [GeneralKeys.Edit]: "Edit",
    [GeneralKeys.Deviation]: "Deviation",
    [GeneralKeys.Done]: "Done",
    [GeneralKeys.Yes]: "Yes",
    [GeneralKeys.No]: "No",
    [GeneralKeys.PleaseTryAgainLater]: "Please try again later.",
    [GeneralKeys.Quantity]: "Quantity",
    [GeneralKeys.NotAvailable]: "Not available",
    [GeneralKeys.ShowMore]: "Show more",
    [GeneralKeys.ShowLess]: "Show less",
    [GeneralKeys.Undo]: "Undo",
    [GeneralKeys.EnterAtLeastXCharacters]:
      "Enter at least {{numberOfCharacters}} characters",
    [GeneralKeys.Title]: "Title",
    [GeneralKeys.Retry]: "Retry",
    [GeneralKeys.Discard]: "Discard",
    [GeneralKeys.Details]: "Details",
    [GeneralKeys.Delete]: "Delete",
    [GeneralKeys.OpenInNewTab]: "Open in new tab",
    [GeneralKeys.Download]: "Download",
    [GeneralKeys.Close]: "Close",
    [GeneralKeys.Clear]: "Clear",
    [GeneralKeys.UnknownUser]: "Unknown user",
    [GeneralKeys.Upload]: "Upload",
    [GeneralKeys.Filters]: "Filters",
    [GeneralKeys.All]: "All",
    [GeneralKeys.Add]: "Add",
    [GeneralKeys.Search]: "Search {{name}}",
    [GeneralKeys.Open]: "Open",
    [GeneralKeys.CreatedAt]: "Created at",
    [GeneralKeys.NoFilter]: "No filter",
    [GeneralKeys.Type]: "Type",
    [GeneralKeys.ClearFilter]: "Clear filter",
    [GeneralKeys.Excess]: "Excess",
    [GeneralKeys.None]: "None",
    [GeneralKeys.Reason]: "Reason",
    [GeneralKeys.Notes]: "Notes",
    [GeneralKeys.Date]: "Date",
    [GeneralKeys.Report]: "Report",
    [GeneralKeys.Back]: "Back",
    [GeneralKeys.Complete]: "Complete",
    [GeneralKeys.Validate]: "Validate",
    [GeneralKeys.Deviations]: "Deviations",
    [GeneralKeys.Received]: "Received",
    [GeneralKeys.ScanBarcode]: "Scan barcode",
    [GeneralKeys.PC]: "PC"
  },
  [NamespaceKeys.MenuTitles]: {
    [MenuTitleKeys.HomeMenuTitle]: "Home",
    [MenuTitleKeys.ReleaseNotesMenuTitle]: "Release Notes",
    [MenuTitleKeys.HelpMenuTitle]: "Help",
    [MenuTitleKeys.SiteProjectsMenuTitle]: "Site Projects",
    [MenuTitleKeys.SiteProjectMenuTitle]: "Site Project",
    [MenuTitleKeys.MaterialTransferOverviewTitle]: "Material Transfers",
    [MenuTitleKeys.ReceiveMaterialOverviewTitle]: "Receive with reference",
    [MenuTitleKeys.ReceiveMaterialListOverviewTitle]: "Menu",
    [MenuTitleKeys.MaterialInstallationOverviewTitle]: "Install at site"
  },
  [NamespaceKeys.LoginSpecific]: {
    [LoginSpecificKeys.EricssonSingleSignOn]: "Ericsson Single Sign On",
    [LoginSpecificKeys.EricssonPrivacyPolicy]: "Ericsson Privacy Policy",
    [LoginSpecificKeys.AuthenticationDisabledLabel]: "Authentication Disabled",
    [LoginSpecificKeys.AuthenticationDisabledErrorMessage]:
      "Ericsson Site Rollout authentication is temporarily disabled, please try again later."
  },
  [NamespaceKeys.BomSpecific]: {
    [BomSpecificKeys.BomStatus]: "BOM Status",
    [BomSpecificKeys.BomIsEmpty]: "BOM is empty",
    [BomSpecificKeys.BomSaved]: "BOM saved",
    [BomSpecificKeys.TheBomWasSavedSuccessfully]:
      "The BOM was saved successfully",
    [BomSpecificKeys.CouldNotSaveBom]: "Could not save BOM",
    [BomSpecificKeys.Status]: "Status",
    [BomSpecificKeys.BillOfMaterial]: "Bill Of Material",
    [BomSpecificKeys.View]: "View",
    [BomSpecificKeys.Note]: "Note",
    [BomSpecificKeys.DrSupplementalReason]: "DR Supplemental reason {{count}}",
    [BomSpecificKeys.CrWillBeCreatedChangedFor]:
      "CR will be created/changed for {{commodityAndMaterialCategory}}",
    [BomSpecificKeys.PleaseStateTheReasonForChangingTheOriginalScope]:
      "Please state the reason for changing the original scope",
    [BomSpecificKeys.SelectAreasonCode]: "Select a reason code",
    [BomSpecificKeys.EnterReasonHere]: "Enter reason here",
    [BomSpecificKeys.ChangeReasonCode]: "Reason Code",
    [BomSpecificKeys.ChangeReasonMessage]: "Message",
    [BomSpecificKeys.MaterialCategory]: "Material Category"
  },
  [NamespaceKeys.ChecklistSpecific]: {
    [ChecklistSpecificKeys.Checklist]: "Checklist",
    [ChecklistSpecificKeys.NoChecklistFound]:
      "This site project has no checklists.",
    [ChecklistSpecificKeys.ChecklistSaved]: "Checklist saved.",
    [ChecklistSpecificKeys.TheChecklistWasSavedSuccessfully]:
      "The checklist was saved successfully",
    [ChecklistSpecificKeys.TheChecklistCouldNotBeSaved]:
      "The checklist could not be saved. Please try pressing save again, or undo changes.",
    [ChecklistSpecificKeys.PressTheButtonBelowToUploadImages]:
      "Press the button below to upload images.",
    [ChecklistSpecificKeys.UploadImages]: "Upload Images",
    [ChecklistSpecificKeys.Rotate]: "Rotate",
    [ChecklistSpecificKeys.UploadFailed]: "Upload failed",
    [ChecklistSpecificKeys.Uploading]: "Uploading...",
    [ChecklistSpecificKeys.ReadingFile]: "Reading file...",
    [ChecklistSpecificKeys.ChecklistCouldNotBeRetrieved]:
      "We could not retrieve the checklist. Try refreshing the page"
  },
  [NamespaceKeys.DplSpecific]: {
    [DplSpecificKeys.DplItemId]: "DPL Item Id",
    [DplSpecificKeys.EricssonProductNumber]: "Ericsson Product Number",
    [DplSpecificKeys.EricssonProductDescription]:
      "Ericsson Product Description",
    [DplSpecificKeys.CustomerProductNumber]: "Customer Product Number",
    [DplSpecificKeys.CustomerProductDescription]:
      "Customer Product Description",
    [DplSpecificKeys.FecProduct]: "FEC Product",
    [DplSpecificKeys.Commodity]: "Commodity",
    [DplSpecificKeys.Category]: "Category",
    [DplSpecificKeys.Subcategory]: "Subcategory",
    [DplSpecificKeys.ProductPackageType]: "Product Package Type",
    [DplSpecificKeys.UnitOfMeasurement]: "Unit Of Measurment",
    [DplSpecificKeys.CriticalSpare]: "Critical Spare",
    [DplSpecificKeys.LeadTimeInWorkingDays]: "Lead Time (working days)",
    [DplSpecificKeys.WarrantyPeriod]: "Warranty Period",
    [DplSpecificKeys.MaterialCategories]: "Material Categories",
    [DplSpecificKeys.MaterialCategory]: "Material Category",
    [DplSpecificKeys.SourcingProvider]: "Sourcing Provider",
    [DplSpecificKeys.Comment]: "Comment",
    [DplSpecificKeys.EricssonSerialNumber]: "Ericsson Serial Number",
    [DplSpecificKeys.CustomerSerialNumber]: "Customer Serial Number"
  },
  [NamespaceKeys.ActivitySpecific]: {
    [ActivitySpecificKeys.CurrentActivity]: "Current Activity",
    [ActivitySpecificKeys.SitewalkPlanned]: "{{activityLabel}} planned",
    [ActivitySpecificKeys.LeadConstructionManager]: "Lead Construction Manager",
    [ActivitySpecificKeys.FieldConstructionManager]:
      "Field Construction Manager",
    [ActivitySpecificKeys.SendForApproval]: "Send for approval",
    [ActivitySpecificKeys.SentForApproval]: "Sent for approval",
    [ActivitySpecificKeys.SentForApprovalErrorMessageTitle]:
      "An error occurred while sending activity for approval,",
    [ActivitySpecificKeys.UnknownActivity]: "Unknown activity",
    [ActivitySpecificKeys.CouldNotSendForApproval]:
      "Could not send for approval",
    [ActivitySpecificKeys.ScheduledUser]: "Scheduled User",
    [ActivitySpecificKeys.ScheduledDate]: "Scheduled Date",
    [ActivitySpecificKeys.CompletedUser]: "Completed User",
    [ActivitySpecificKeys.ActivityIsNotScheduled]: "Activity is not scheduled",
    [ActivitySpecificKeys.NotAssigned]: "Not assigned",
    [ActivitySpecificKeys.SentForApprovalDate]: "Sent for Approval Date",
    [ActivitySpecificKeys.PlannedFor]: "Planned for",
    [ActivitySpecificKeys.ScheduledFor]: "Scheduled for",
    [ActivitySpecificKeys.CompletedBy]: "Completed by",
    [ActivitySpecificKeys.SentForApprovalAt]: "Sent for approval at"
  },
  [NamespaceKeys.SiteSpecific]: {
    [SiteSpecificKeys.SiteIdByCustomer]: "Site Id By Customer",
    [SiteSpecificKeys.CustomerRegion]: "Customer Region",
    [SiteSpecificKeys.Region]: "Region",
    [SiteSpecificKeys.Subregion]: "Subregion",
    [SiteSpecificKeys.SiteInformation]: "Site Information",
    [SiteSpecificKeys.Location]: "Location",
    [SiteSpecificKeys.Weather]: "Weather",
    [SiteSpecificKeys.Name]: "Site Name",
    [SiteSpecificKeys.Type]: "Site Type",
    [SiteSpecificKeys.Address]: "Address",
    [SiteSpecificKeys.ZipCode]: "Zip Code",
    [SiteSpecificKeys.City]: "City",
    [SiteSpecificKeys.Latitude]: "Latitude",
    [SiteSpecificKeys.Longitude]: "Longitude",
    [SiteSpecificKeys.GoToMap]: "Go to map",
    [SiteSpecificKeys.Site]: "Site"
  },
  [NamespaceKeys.DocumentSpecific]: {
    [DocumentSpecificKeys.Documents]: "Documents",
    [DocumentSpecificKeys.UploadDocument]: "Upload document",
    [DocumentSpecificKeys.NoDocumentsUploaded]: "No documents uploaded",
    [DocumentSpecificKeys.DocumentDeleted]: "Document deleted",
    [DocumentSpecificKeys.DocumentWasSuccessfullyDeleted]:
      "Document was successfully deleted",
    [DocumentSpecificKeys.DocumentLinkWithSpecifiedUrlAlreadyExists]:
      "Document link with specified URL already exists",
    [DocumentSpecificKeys.AddNewSiteDocumentLink]: "Add new site document link",
    [DocumentSpecificKeys.Name]: "Name",
    [DocumentSpecificKeys.LinkName]: "Link name",
    [DocumentSpecificKeys.Url]: "URL",
    [DocumentSpecificKeys.LinkUrl]: "Link URL",
    [DocumentSpecificKeys.LinkAdded]: "Link added",
    [DocumentSpecificKeys.DocumentLinkWasSuccessfullyAdded]:
      "Document link was successfully added",
    [DocumentSpecificKeys.UploadingDocument]: "Uploading document...",
    [DocumentSpecificKeys.UploadFailed]: "Upload failed",
    [DocumentSpecificKeys.ReadingDocument]: "Reading document...",
    [DocumentSpecificKeys.DeletingDocument]: "Deleting document...",
    [DocumentSpecificKeys.RestrictedAccessTooltipMessage]:
      "Restricted access. Unavailable for SP companies.",
    [DocumentSpecificKeys.RestrictedAccessDialogMessage]:
      "Document has restricted access. It is unavailable for SP companies.",
    [DocumentSpecificKeys.UploadedBy]: "Uploaded by",
    [DocumentSpecificKeys.UploadAt]: "Uploaded at",
    [DocumentSpecificKeys.ConfirmDocumentDeletionDialogTitle]:
      "Confirm document deletion",
    [DocumentSpecificKeys.ConfirmDocumentDeletionDialogMessage]:
      "Are you sure you want to delete the document?",
    [DocumentSpecificKeys.DocumentDetails]: "Document details",
    [DocumentSpecificKeys.ErrorWhileLoading]:
      "An error occured while loading documents, please try again.",
    [DocumentSpecificKeys.ErrorWhileDeletingNotificationTitle]:
      "Document could not be deleted",
    [DocumentSpecificKeys.ErrorWhileDeletingNotificationMessage]:
      "An error occurred while deleting document. Please try again.",
    [DocumentSpecificKeys.ErrorWhileAddingLink]:
      "An error occurred while adding document link. Please try again.",
    [DocumentSpecificKeys.AddLink]: "Add link",
    [DocumentSpecificKeys.PendingUploadsDialogTitle]:
      "Choose visibility options",
    [DocumentSpecificKeys.PendingUploadsDialogMessageRow1]:
      "Please select if new document(s) will have restricted access.",
    [DocumentSpecificKeys.PendingUploadsDialogMessageRow2]:
      "Restricted documents cannot be seen by Crew Leaders."
  },

  [NamespaceKeys.ComponentSpecific]: {
    // TODO: Name these according to the component file name or similar (e.g. site-project-contacts-{thenameofthekey})
    [ComponentSpecificKeys.ActivitiesErrorMessageTitle]:
      "We could not retrieve any activities",
    [ComponentSpecificKeys.Contacts]: "Contacts",
    [ComponentSpecificKeys.SiteProjectContactErrorMessageTitle]:
      "We could not retrieve any contacts",
    [ComponentSpecificKeys.Activity]: "Activity",
    [ComponentSpecificKeys.AnErrorOccurredPleaseTryAgainLater]:
      "An error occurred, please try again later.",
    [ComponentSpecificKeys.AnErrorOccurredPleaseTryAgain]:
      "An error occurred, please try again.",
    [ComponentSpecificKeys.ProductTitle]: "Product",
    [ComponentSpecificKeys.NoSiteProjectsTitle]: "No site projects",
    [ComponentSpecificKeys.NoSiteProjectsWithFiltersTitle]:
      "No site projects found with current filters",
    [ComponentSpecificKeys.NoSiteProjectsMessage]:
      "You have no site projects for {{customerName}} ",
    [ComponentSpecificKeys.NoSiteProjectsWithFiltersMessageOne]:
      "There are site projects hidden by an active filter. View and set filters by pressing the ",
    [ComponentSpecificKeys.NoSiteProjectsWithFiltersMessageTwo]:
      " button in the top right corner.",
    [ComponentSpecificKeys.SiteProjectsErrorMessageTitle]:
      "We could not retrieve any site projects",
    [ComponentSpecificKeys.BomErrorMessageTitle]:
      "We could not retrieve any BOM",
    [ComponentSpecificKeys.ReleaseNotesErrorMessageTitle]:
      "We could not retrieve any release notes",
    [ComponentSpecificKeys.CustomersErrorMessageTitle]:
      "We could not retrieve any customers",
    [ComponentSpecificKeys.SitesCoordinatesErrorMessageTitle]:
      "We could not retrieve the site information",
    [ComponentSpecificKeys.SitesLocationErrorMessageTitle]:
      "We could not retrieve the site coordinates",
    [ComponentSpecificKeys.SitesWeatherErrorMessageTitle]:
      "We could not retrieve the site coordinates",
    [ComponentSpecificKeys.ThisItemHasDeviations]: "This item has deviations.",
    [ComponentSpecificKeys.MaterialToValidate]: "Material to validate",
    [ComponentSpecificKeys.MaterialToInstall]: "Material to install",
    [ComponentSpecificKeys.ValidatedMaterial]: "Validated material",
    [ComponentSpecificKeys.InstallAction]: "Install",
    [ComponentSpecificKeys.MaterialTransferValidationReviewResultParagraph]:
      "These are the resulting item statuses.",
    [ComponentSpecificKeys.MaterialTransferValidationReviewSerialNumbersParagraph]:
      "The provided serial numbers will be attached to their corresponding item and can not be changed later.",
    [ComponentSpecificKeys.MaterialTransferValidationReviewResposabilityParagraph]:
      "By finishing the validation you will obtain responsibility for the validated items.",
    [ComponentSpecificKeys.SerialNumber]: "Serial Number",
    [ComponentSpecificKeys.SerialNumbersToScan]: "Serial numbers to scan",
    [ComponentSpecificKeys.ScannedSerialNumbers]: "Scanned serial numbers",
    [ComponentSpecificKeys.ValidateMaterial]: "Validate Material",
    [ComponentSpecificKeys.ValidateInstallation]: "Validate Installation",
    [ComponentSpecificKeys.AddSerialNumbers]: "Add Serial Numbers",
    [ComponentSpecificKeys.ReviewChanges]: "Review Changes",
    [ComponentSpecificKeys.MaterialTransferPostValidationErrorTitle]:
      "Could not validate the material transfer",
    [ComponentSpecificKeys.MaterialTransferPostValidationErrorDescription]:
      "An unexpected error occurred while validating the material transfer. Please try again.",
    [ComponentSpecificKeys.MaterialInstallationPostValidationErrorTitle]:
      "Could not validate the material installation",
    [ComponentSpecificKeys.MaterialInstallationPostValidationErrorDescription]:
      "An unexpected error occurred while validating the material installation. Please try again.",
    [ComponentSpecificKeys.MaterialTransferValidationValidateAllQuantitiesApproveButtonLabel]:
      "Validate all items",
    [ComponentSpecificKeys.MaterialTransferValidationValidateAllQuantitiesDialogTitle]:
      "Validate all items?",
    [ComponentSpecificKeys.MaterialTransferValidationValidateAllQuantitiesDialogMessage]:
      "Do you want to validate all non-validated items as OK and continue?",
    [ComponentSpecificKeys.MaterialTransferValidationSmartScannerButtonLabel]:
      "Smart Scan",
    [ComponentSpecificKeys.BomSavingErrorDescription]:
      "An unexpected error occurred while saving the BOM. Please try again.",
    [ComponentSpecificKeys.BomChangeValidationErrorDescription]:
      "An unexpected error occurred while validating BOM changes. Please try again.",
    [ComponentSpecificKeys.ProductDetailsDialogTitle]:
      "Detailed Item Information",
    [ComponentSpecificKeys.Validate]: "Validate {{activity}}",
    [ComponentSpecificKeys.ValidateAll]: "Validate All",
    [ComponentSpecificKeys.AddAll]: "Add All",
    [ComponentSpecificKeys.TransferActivityBaseFailedToGetInformationTitle]:
      "Could not get {{activityLabel}} information",
    [ComponentSpecificKeys.TransferActivityBaseFailedToGetInformationMessage]:
      "System could not get information about {{activityLabel}} and might not function as expected. Please refresh to try again.",
    [ComponentSpecificKeys.FileTypeTitle]:
      "Could not get supported file extensions.",
    [ComponentSpecificKeys.FileTypeMessage]:
      "Could not get supported file extensions, the application might not work as expected. Please refresh to try again."
  },
  [NamespaceKeys.ScannerSpecific]: {
    [ScannerSpecificKeys.BottomHelperPressToScan]: "Press and hold to scan",
    [ScannerSpecificKeys.BottomHelperPointYourCameraToScan]:
      "Point your camera at a barcode",
    [ScannerSpecificKeys.FeedbackItemAlreadyValidated]:
      "Item has already been validated",
    [ScannerSpecificKeys.FeedbackNoItemFoundForScannedProductNumber]:
      "No item found with scanned product number: {{productNumber}}",
    [ScannerSpecificKeys.FeedbackItemWasJustScanned]: "Item was just scanned",
    [ScannerSpecificKeys.FeedbackNoProductNumberFound]:
      "No product number could be found: {{productNumber}}",
    [ScannerSpecificKeys.TitleScanProductNumber]: "Scan a product number",
    [ScannerSpecificKeys.TitleProductValidated]: "Product validated",
    [ScannerSpecificKeys.TitleScanSerialNumbers]: "Scan serial numbers",
    [ScannerSpecificKeys.TitleScanCustomerSerialNumber]:
      "Scan customer serial number",
    [ScannerSpecificKeys.TitleScanEricssonSerialNumber]:
      "Scan Ericsson serial number",
    [ScannerSpecificKeys.SubtitleValidationText]:
      "Validated {{validItems}} / {{totalItems}}",
    [ScannerSpecificKeys.ItemDidNotMeetValidationRequirements]:
      "Item did not meet validation requirements.",
    [ScannerSpecificKeys.AnErrorOccurredWhileScanningTryAgain]:
      "An error occurred while scanning. Please try again.",
    [ScannerSpecificKeys.CouldNotChangeCameraErrorMessage]:
      "Could not change camera. Please try again.",
    [ScannerSpecificKeys.CouldNotStartOrStopScannerErrorMessage]:
      "An error occurred. Please try again. ",
    [ScannerSpecificKeys.ScanShipmentfromPackingSlip]:
      "Scan Shipment number from the packing slip",
    [ScannerSpecificKeys.ClickHereToSeeExampleOf]:
      "Click here to see example of",
    [ScannerSpecificKeys.ShipmentReference]: "Shipment reference",
    [ScannerSpecificKeys.OrEnterShipmentReferenceNumber]:
      "Or Enter Shipment / Reference Number",
    [ScannerSpecificKeys.ShipmentReferenceNumber]: "Shipment/ReferenceNumber"
  },
  [NamespaceKeys.MaterialSpecific]: {
    [MaterialSpecificKeys.Receiving]: "Receiving",
    [MaterialSpecificKeys.HandingOver]: "Handing over",
    [MaterialSpecificKeys.SearchSiteProjectOrSiteName]:
      "Search site project / site name",
    [MaterialSpecificKeys.Reason]: "Reason",
    [MaterialSpecificKeys.MaterialTransfersTitle]:
      "Material Transfers {{postfix}}",
    [MaterialSpecificKeys.NoMaterialTransfersWithFiltersTitle]:
      "No material transfers found with current filters",
    [MaterialSpecificKeys.NoMaterialTransfersWithFiltersMessageOne]:
      "There are material transfers hidden by an active filter. View and set filters by pressing the ",
    [MaterialSpecificKeys.NoMaterialTransfersWithFiltersMessageTwo]:
      " button in the top right corner.",
    [MaterialSpecificKeys.NoMaterialTransfersTitle]: "No material transfers",
    [MaterialSpecificKeys.NoMaterialTransfersMessage]:
      "You have no material transfers for {{customerName}} ",
    [MaterialSpecificKeys.FromCompany]: "From company",
    [MaterialSpecificKeys.ToCompany]: "To company",
    [MaterialSpecificKeys.DispatchCreated]: "Delivery",
    [MaterialSpecificKeys.MaterialRemovedFromSiteProject]: "Return",
    [MaterialSpecificKeys.MaterialReplacementNeeded]: "Replacement",
    [MaterialSpecificKeys.ValidationTitle]: "Validate {{reason}}",
    [MaterialSpecificKeys.ValidationDetailsTitle]: "Details for {{reason}}",
    [MaterialSpecificKeys.DeliveryTitle]: "Delivery",
    [MaterialSpecificKeys.ReturnTitle]: "Return"
  },
  [NamespaceKeys.InstallationSpecific]: {
    [InstallationSpecificKeys.QuantitySummaryReview]:
      "{{quantity}} of {{availableQuantity}} {{unitOfMeasurement}}",
    [InstallationSpecificKeys.QuantitySummary]:
      "of {{quantity}} {{unitOfMeasurement}} (Total {{quantityTotal}})",
    [InstallationSpecificKeys.StartDate]: "Start date",
    [InstallationSpecificKeys.PageTitle]: "Install at site ({{amount}})",
    [InstallationSpecificKeys.DetailsTitle]: "Install at site",
    [InstallationSpecificKeys.MaterialInstallationsTitle]:
      "Material installations {{postfix}}",
    [InstallationSpecificKeys.NoMaterialInstallationsWithFiltersTitle]:
      "No material installations found with current filters",
    [InstallationSpecificKeys.NoMaterialInstallationsWithFiltersMessageOne]:
      "There are material installations hidden by an active filter. View and set filters by pressing the ",
    [InstallationSpecificKeys.NoMaterialInstallationsWithFiltersMessageTwo]:
      " button in the top right corner.",
    [InstallationSpecificKeys.NoMaterialInstallationsTitle]:
      "No material installations",
    [InstallationSpecificKeys.NoMaterialInstallationsMessage]:
      "You have no material installations for {{customerName}} ",
    [InstallationSpecificKeys.InstallMaterial]: "Install items",
    [InstallationSpecificKeys.InstalledMaterial]: "Installed items",
    [InstallationSpecificKeys.NonInstalledMaterial]: "Not installed"
  },

  [NamespaceKeys.ReceivingMaterialSpecific]: {
    [ReceivingMaterialSpecificKeys.ReportedAsDamaged]: "Reported as damage",
    [ReceivingMaterialSpecificKeys.ReportedAsMissing]: "Reported as missing",
    [ReceivingMaterialSpecificKeys.ValidationMessageWithHandlingUnit]:
      "{{totalSiteProjects}} site projects with total of {{totalHandlingUnits}} handling units containing {{totalItems}} items with the total qty of {{totalQty}} will be validated as received.",
    [ReceivingMaterialSpecificKeys.ValidationMessageWithoutHandlingUnit]:
      "{{totalSiteProjects}} site projects containing {{totalItems}} items with the total qty of {{totalQty}} will be validated as received.",
    [ReceivingMaterialSpecificKeys.And]: "and",
    [ReceivingMaterialSpecificKeys.HandlingUnitReceived]:
      "Handling units received",
    [ReceivingMaterialSpecificKeys.Summary]: "Summary",
    [ReceivingMaterialSpecificKeys.NumberOfSiteProjects]: "Site projects",
    [ReceivingMaterialSpecificKeys.ItemsReceived]: "Items received",
    [ReceivingMaterialSpecificKeys.TotalQtyReceived]: "Total quantity received",
    [ReceivingMaterialSpecificKeys.Damage]: "Damage",
    [ReceivingMaterialSpecificKeys.ShipmentNumberReferenceNotFound]:
      "Shipment number / Reference not found",
    [ReceivingMaterialSpecificKeys.AddSerialNumber]: "Add Serial number",
    [ReceivingMaterialSpecificKeys.FailedToLoadData]: "Failed to load data.",
    [ReceivingMaterialSpecificKeys.Received]: "Received",
    [ReceivingMaterialSpecificKeys.HU]: "HU: ",
    [ReceivingMaterialSpecificKeys.Items]: "Items: ",
    [ReceivingMaterialSpecificKeys.Qty]: "Qty: ",
    [ReceivingMaterialSpecificKeys.Deviations]: "Deviations: ",
    [ReceivingMaterialSpecificKeys.Asterisk]: "*"
  }
};

export default EN;
