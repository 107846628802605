import { createContext } from "react";

import {
  ComponentViews,
  ReceiveMaterialTransferStateItem,
  SelectableMaterialTransferOverviewViewModel
} from "../shared/receive-material-models";

export type ReceiveMaterialContextProps = {
  currentView: ComponentViews;
  setCurrentView?: React.Dispatch<React.SetStateAction<ComponentViews>>;
  transfersOverviewStateItems: SelectableMaterialTransferOverviewViewModel[];
  setTransfersOverviewStateItems?: React.Dispatch<
    React.SetStateAction<SelectableMaterialTransferOverviewViewModel[]>
  >;
  transferDetailsStateItems: ReceiveMaterialTransferStateItem[];

  setTransferDetailsStateItems?: React.Dispatch<
    React.SetStateAction<ReceiveMaterialTransferStateItem[]>
  >;
};

export const ReceiveMaterialContext =
  createContext<ReceiveMaterialContextProps>({
    currentView: ComponentViews.TransferList,
    transfersOverviewStateItems: [],
    transferDetailsStateItems: []
  });
