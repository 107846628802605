import React, { useState, useCallback } from "react";

import {
  AppView,
  App as EDSApp,
  SingleSelect,
  Loader,
  ISelectItem,
  Icon
} from "@react-gcc-eds/core";
import AzureAD from "react-aad-msal";
import { QueryClient, QueryClientProvider } from "react-query";
import { Switch, Redirect, Route } from "react-router-dom";

import { authenticationProvider } from "./authentication/authentication-provider";
import { ScannerContext } from "./components/common/barcode-scanning/scanner-context";
import useScanner from "./components/common/barcode-scanning/scanner-hook";
import CenteredContainer from "./components/common/layout/centered-container";
import NotificationModal from "./components/common/notification-modal/modal";
import BundleInformation from "./components/developer-information/bundle-information";
import NotFound from "./components/error-pages/not-found";
import SignIn from "./components/sign-in/sign-in";
import { AcceptedFileFormatContext } from "./contexts/accepted-file-formats-context";
import { AppSettingsContext } from "./contexts/app-settings-context";
import { AuthenticationContext } from "./contexts/authentication-context";
import { CustomerContext } from "./contexts/customer-context";
import { LayoutContext, PageTitle } from "./contexts/layout-context";
import {
  CustomerViewModel,
  SwaggerException,
  MobileViewAccessIdentifier
} from "./domain/client-customer";
import { useAppSettings } from "./hooks/app-settings";
import useCustomers from "./hooks/customers-hook";
import useFileType from "./hooks/file-format-hook";
import useAuthentication from "./hooks/useAuthentication";
import { NamespaceKeys, ApplicationKeys } from "./translation/dictionary-keys";
import { useTranslation } from "./translation/translation-utils";
import useViewAccess from "./view-access/hooks/useViewAccess";
import { viewAccessMenuDictionary } from "./view-access/view-access";

import "./app.scss";

const queryClient = new QueryClient();

const renderCustomerSelection = (
  customers: CustomerViewModel[] | undefined
): ISelectItem[] =>
  (customers || []).map((c) => ({
    title: c.DisplayName ?? "",
    value: c
  }));

const App: React.FC = () => {
  const [appSettings] = useAppSettings();
  const authentication = useAuthentication(appSettings);
  const {
    customers,
    currentCustomer,
    changeCustomer,
    currentUser,
    userError,
    loading,
    resetUserData
  } = useCustomers(authentication, appSettings);
  const { translate } = useTranslation();
  const scannerState = useScanner(appSettings);
  const [pageTitle, setPageTitle] = useState<PageTitle>();
  const fileFormats = useFileType(currentCustomer, authentication);
  const { routes, menu } = useViewAccess(currentUser?.MobileViewAccess);
  const [showModal, setShowModal] = useState(true);
  const [isChecked, setIsChecked] = useState(false);

  const authenticatedCall: <T>(promise: Promise<T>) => Promise<T> = useCallback(
    (promise) => {
      promise.catch((error) => {
        console.log("generic", error);
        if (
          error === "TypeError: Failed to fetch" || // workaround for testing with chrome request blocking
          (SwaggerException.isSwaggerException(error) && error.status === 500) // if we fix swagger responses we can also check error message for HTTP status message
        ) {
          resetUserData();
        }
      });
      return promise;
    },
    [resetUserData]
  );
  const handleNotificationModal = useCallback((res) => {
    setShowModal(res);
  }, []);
  const handleChangeCustomer = useCallback(
    (dropdownItem: ISelectItem) => {
      const customer: CustomerViewModel | undefined =
        dropdownItem.value as CustomerViewModel;
      if (!customer) return;
      changeCustomer(customer);
    },
    [changeCustomer]
  );
  const handleOnchecked = (e: boolean) => {
    setIsChecked(e);
  };
  return (
    <QueryClientProvider client={queryClient}>
      <AppSettingsContext.Provider value={appSettings}>
        <AuthenticationContext.Provider value={authentication}>
          {(!currentUser && <SignIn errorMessage={userError} />) || (
            <AzureAD provider={authenticationProvider}>
              <LayoutContext.Provider
                value={{
                  pageTitle: pageTitle,
                  setPageTitle: setPageTitle
                }}
              >
                <CustomerContext.Provider
                  value={{
                    currentUser,
                    onLogout: authentication.logout,
                    customers,
                    currentCustomer,
                    onChangeCustomer: changeCustomer,
                    authenticatedCall
                  }}
                >
                  <ScannerContext.Provider value={scannerState}>
                    <AcceptedFileFormatContext.Provider value={fileFormats}>
                      <EDSApp
                        theme="light"
                        applicationName={translate(
                          NamespaceKeys.Application,
                          ApplicationKeys.ApplicationName
                        )}
                        acronym={translate(
                          NamespaceKeys.Application,
                          ApplicationKeys.ApplicationAcronym
                        )}
                        user={
                          (currentUser &&
                            !!currentUser.FirstName &&
                            !!currentUser.LastName && {
                              firstName: currentUser.FirstName,
                              lastName: currentUser.LastName
                            }) ||
                          undefined
                        }
                        settingsContent={[
                          <div
                            className="custom-setting-container item"
                            key="customer-dropdown"
                          >
                            <div className="left">
                              {translate(
                                NamespaceKeys.Application,
                                ApplicationKeys.SwitchCustomers
                              )}
                            </div>
                            <div className="right">
                              <SingleSelect
                                items={renderCustomerSelection(customers)}
                                label={currentCustomer?.DisplayName}
                                onChange={handleChangeCustomer}
                              />
                            </div>
                          </div>,
                          <BundleInformation key="bundle-information" />
                        ]}
                        onLogout={authentication.logout}
                        appBarItems={[
                          <div className="app-bar-item" key="container">
                            {currentCustomer && currentCustomer.DisplayName}
                          </div>,
                          <div className="app-bar-app-icon" key="container">
                            <Icon
                              name="app-launcher"
                              onClick={() => {
                                setShowModal(true);
                              }}
                            />
                          </div>
                        ]}
                      >
                        <AppView
                          title={pageTitle?.title ?? ""}
                          subtitle={pageTitle?.subtitle}
                          actions={pageTitle?.actions}
                          menu={menu}
                        >
                          {(loading && (
                            <CenteredContainer>
                              <Loader size="large" />
                            </CenteredContainer>
                          )) || (
                            <>
                              {showModal && (
                                <NotificationModal
                                  isAuthenticatedPage={false}
                                  isCheckbox={isChecked}
                                  setModalOpen={handleNotificationModal}
                                  setIsChecked={handleOnchecked}
                                ></NotificationModal>
                              )}
                              <Switch>
                                <Redirect
                                  exact
                                  from="/"
                                  to={
                                    viewAccessMenuDictionary[
                                      MobileViewAccessIdentifier
                                        .SiteProjectsOverview
                                    ].link
                                  }
                                />
                                {routes}
                                <Route key="not-found" component={NotFound} />
                              </Switch>
                            </>
                          )}
                        </AppView>
                      </EDSApp>
                    </AcceptedFileFormatContext.Provider>
                  </ScannerContext.Provider>
                </CustomerContext.Provider>
              </LayoutContext.Provider>
            </AzureAD>
          )}
        </AuthenticationContext.Provider>
      </AppSettingsContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
