import React, { MouseEventHandler } from "react";

import { Button, Dialog } from "@react-gcc-eds/core";

import { DeliveryProductListSourcingDto } from "../../../domain/client-customer";
import {
  NamespaceKeys,
  DplSpecificKeys,
  GeneralKeys,
  ComponentSpecificKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";

import "./product-details-dialog.scss";

type Props = {
  item: DeliveryProductListItemInformation;
  onClose: MouseEventHandler;
  showModal: boolean;
};

export type DeliveryProductListItemInformation = {
  id?: string | undefined;
  dplItemId?: string | undefined;
  customerProductNumber?: string | undefined;
  customerProductDescription?: string | undefined;
  ericssonProductNumber?: string | undefined;
  ericssonProductDescription?: string | undefined;
  fecProduct?: string | undefined;
  phaseOutDate?: Date | undefined;
  unitOfMeasurement?: string | undefined;
  commodity?: string | undefined;
  productPackageType?: string | undefined;
  category?: string | undefined;
  subcategory?: string | undefined;
  leadTimeInWorkingDays?: number | undefined;
  warrantyPeriod?: string | undefined;
  criticalSpare?: boolean | undefined;
  generalConstructionKit?: boolean | undefined;
  showNote?: boolean | undefined;
  comment?: string | undefined;
  sIPPStatus?: string | undefined;
  alternativePart?: string | undefined;
  sourcings?: DeliveryProductListSourcingDto[] | undefined;
  validQuantities?: number[] | undefined;
  materialCategories?: string[] | undefined;
};

const ProductDetailsDialog = ({
  item,
  onClose,
  showModal
}: Props): JSX.Element => {
  const { translate } = useTranslation();

  const renderRow = (title?: string, value?: string | number): JSX.Element => {
    return (
      <div className="product-details-modal-row">
        <div className="product-details-modal-title">{title}</div>
        <div className="product-details-modal-value">{value ?? "-"}</div>
      </div>
    );
  };

  const materialCategoriesList = (materialCategories?: string[]): string => {
    return (materialCategories || [])
      .map((materialCategory) => (materialCategory ? materialCategory : ""))
      .join(", ");
  };

  if (showModal) {
    return (
      <Dialog
        title={translate(
          NamespaceKeys.ComponentSpecific,
          ComponentSpecificKeys.ProductDetailsDialogTitle
        )}
        buttons={<Button onClick={onClose}>Close</Button>}
        className={"product-details-dialog"}
      >
        <>
          {renderRow(
            translate(NamespaceKeys.DplSpecific, DplSpecificKeys.DplItemId),
            item.dplItemId
          )}
          {renderRow(
            translate(
              NamespaceKeys.DplSpecific,
              DplSpecificKeys.EricssonProductNumber
            ),
            item.ericssonProductNumber
          )}
          {renderRow(
            translate(
              NamespaceKeys.DplSpecific,
              DplSpecificKeys.EricssonProductDescription
            ),
            item.ericssonProductDescription
          )}
          {renderRow(
            translate(
              NamespaceKeys.DplSpecific,
              DplSpecificKeys.CustomerProductNumber
            ),
            item.customerProductNumber
          )}
          {renderRow(
            translate(
              NamespaceKeys.DplSpecific,
              DplSpecificKeys.CustomerProductDescription
            ),
            item.customerProductDescription
          )}
          {renderRow(
            translate(NamespaceKeys.DplSpecific, DplSpecificKeys.FecProduct),
            item.fecProduct
          )}
          {renderRow(
            translate(NamespaceKeys.DplSpecific, DplSpecificKeys.Commodity),
            item.commodity
          )}
          {renderRow(
            translate(NamespaceKeys.DplSpecific, DplSpecificKeys.Category),
            item.category
          )}
          {renderRow(
            translate(NamespaceKeys.DplSpecific, DplSpecificKeys.Subcategory),
            item.subcategory
          )}
          {renderRow(
            translate(
              NamespaceKeys.DplSpecific,
              DplSpecificKeys.ProductPackageType
            ),
            item.productPackageType
          )}
          {renderRow(
            translate(
              NamespaceKeys.DplSpecific,
              DplSpecificKeys.UnitOfMeasurement
            ),
            item.unitOfMeasurement
          )}
          {renderRow(
            translate(NamespaceKeys.DplSpecific, DplSpecificKeys.CriticalSpare),
            item.criticalSpare
              ? translate(NamespaceKeys.General, GeneralKeys.Yes)
              : translate(NamespaceKeys.General, GeneralKeys.No)
          )}
          {renderRow(
            translate(
              NamespaceKeys.DplSpecific,
              DplSpecificKeys.LeadTimeInWorkingDays
            ),
            item.leadTimeInWorkingDays
          )}
          {renderRow(
            translate(
              NamespaceKeys.DplSpecific,
              DplSpecificKeys.WarrantyPeriod
            ),
            item.warrantyPeriod
          )}
          {renderRow(
            translate(
              NamespaceKeys.DplSpecific,
              DplSpecificKeys.MaterialCategories
            ),
            materialCategoriesList(item.materialCategories)
          )}
          {renderRow(
            translate(NamespaceKeys.DplSpecific, DplSpecificKeys.Comment),
            item.comment
          )}
        </>
      </Dialog>
    );
  }
  return <div></div>;
};

export default ProductDetailsDialog;
