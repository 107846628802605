import React, { useState, useEffect, ChangeEvent } from "react";
import cx from "classnames";
import { useClickOutside } from "../../utils/utils";
import ISelectItem from "./ISelectItem";
import ISingleSelect from "./ISingleSelect";

const SingleSelect = ({
  type,
  items,
  disabled,
  placeholder,
  label,
  onChange,
  className
}: ISingleSelect) => {
  
  const [open, setOpen] = useState(false);
  const [text, setText] = useState(label || "");
  const [filteredItems, setFilteredItems] = useState<ISelectItem[]>([]);

  const filterOptions = (typedValue: string) => {
    if (items !== undefined) {
      setFilteredItems(
        items.filter(
          val => val.title.toLocaleLowerCase().indexOf(typedValue.toLocaleLowerCase()) > -1
        )
      );
    }
  };

  useEffect(() => {
    if (type === "input-single") {
      filterOptions(text);
    } else {
      setFilteredItems(items);
    }
  }, [items]);

  const validTypes = ["single", "input-single"];
  const selectedDataType = type !== undefined ? type : "single";

  const closeSelect = () => setOpen(false);
  const containerRef = useClickOutside(closeSelect);

  const invalidType = (
    <div className="select closed" data-type="invalid-type">
      <div className="invalid_select">Invalid type</div>
    </div>
  );

  const renderItem = (item: ISelectItem) => (
    <div
      key={item.title}
      title={item.title}
      role="presentation"
      onClick={e => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        if(!item.disabled) {
          setText(item.title);
          if (onChange !== undefined) {
            onChange(item);
          }
          setOpen(false);
        }
      }}
      className={cx("item", { active: item.title === label }, { disabled: item.disabled })}
    >
      {item.title}
    </div>
  );

  const buttonType =
    selectedDataType === "input-single" ? (
      <div
        role="presentation"
        className="clickable current-options"
        onClick={e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          setOpen(true);
        }}
      >
        <input
          value={text || ""}
          type="text"
          placeholder={placeholder}
          onFocus={() => {
            setOpen(true);
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.value === "") {
              setOpen(true);
            }
            setText(e.target.value);
            filterOptions(e.target.value);
          }}
        />
      </div>
    ) : (
      <button
        type="button"
        className="btn current-options"
        onClick={e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          setOpen(!open);
        }}
      >
        {label || placeholder || ""}
      </button>
    );

  const isOutOfViewport = (elem: HTMLDivElement) => {
    const itemHeightOffset = 32;
    const maxOffset = 130;
    const offsetHeight = items.length < 4 ? itemHeightOffset * items.length : maxOffset;
    const bounding = elem.getBoundingClientRect();

    return (
      bounding.bottom + offsetHeight > (window.innerHeight || document.documentElement.clientHeight)
    );
  };

  const outOfViewport = containerRef.current ? isOutOfViewport(containerRef.current) : false;

  if (validTypes.includes(selectedDataType)) {
    return (
      <div title={label}>
        <div
          ref={containerRef}
          role="presentation"
          className={cx("select", { disabled }, { closed: !open }, { open }, className)}
          data-type={selectedDataType}
        >
          {buttonType}

          <div className={cx("options-list overflow", { above: outOfViewport })}>
            {type === "input-single" && filteredItems.length === 0 && text !== "" ? (
              <div className="no_results">No results found</div>
            ) : null}
            {filteredItems.map((item: ISelectItem) => {
              return renderItem(item);
            })}
          </div>
        </div>
      </div>
    );
  }
  return invalidType;
};

export default SingleSelect;
