import React, { useState, useEffect } from "react";

import { uniqueId, isMobileDevice } from "../../../utils/utils";

import { UserInfo, Notification, Theme } from "../contracts";

const LOCAL_STORAGE_NOTIFICATIONS_KEY = "notifications";
const LOCAL_STORAGE_THEME_KEY = "theme";

const localStorageKey = (key: string, id?: string, user?: UserInfo) =>
  `${id || "gcc-eds"}-${user ? `${user.firstName}.${user.lastName}` : "."}-${key}`;

export interface IAppContext {
  user: UserInfo | undefined;
  appId?: string;
  theme: { current: string; toggle(): void; forced: boolean };
  menu: { visible: boolean; toggle(): void };
  settings: { visible: boolean; toggle(): void; setVisible(visible: boolean): void };
  notifications: {
    visible: boolean;
    setVisible(visible: boolean): void;
    items: Notification[];
    new: number;
    toggle(): void;
    clearNew(): void;
    clearActive(): void;
    create(
      title: string,
      description: string,
      type?: "default" | "warning" | "primary",
      icon?: string,
      timeout?: number | null,
      instant?: boolean
    ): string;
    createInstant(
      title: string,
      description: string,
      type?: "default" | "warning" | "primary",
      icon?: string,
      timeout?: number | null
    ): string;
    clear(id: string): void;
    delete(id: string): void;
  };
}

const ApplicationContext = React.createContext<IAppContext | undefined>(undefined);

export const ApplicationContextProvider = ({
  user,
  appId,
  theme,
  forcedTheme,
  children
}: {
  user?: UserInfo;
  appId?: string;
  theme?: Theme;
  forcedTheme?: boolean;
  children: React.ReactNode;
}) => {
  // Theme
  const getPersistedTheme = () =>
    window.localStorage.getItem(localStorageKey(LOCAL_STORAGE_THEME_KEY, appId, user));

  const getTheme = () => (theme && forcedTheme ? theme : getPersistedTheme() || theme || "dark");

  const persistTheme = (t: string) => {
    window.localStorage.setItem(localStorageKey(LOCAL_STORAGE_THEME_KEY, appId, user), t);
  };

  const [menuVisible, setMenuVisible] = useState(!isMobileDevice());

  const [settingsVisible, setSettingsVisible] = useState(false);
  const [currentTheme, setTheme] = useState(getTheme);

  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [newNotifications, setNewNotifications] = useState(0);

  useEffect(() => {
    setTheme(getTheme());
  }, [user]);

  useEffect(() => {
    if (!document.body.classList.contains(currentTheme)) {
      // document.body.classList.remove("light", "dark"); // unsupported in ie
      document.body.classList.remove("light");
      document.body.classList.remove("dark");
      document.body.classList.add(currentTheme);
    }
    persistTheme(currentTheme);
  }, [currentTheme]);

  // Theme
  const toggleTheme = () => {
    setTheme(currentTheme === "light" ? "dark" : "light");
  };

  // Settings
  const toggleSettings = () => {
    setSettingsVisible(!settingsVisible);
  };

  // Menu
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  // Notifications

  const getPersistedNotifications = () => {
    const items = window.localStorage.getItem(
      localStorageKey(LOCAL_STORAGE_NOTIFICATIONS_KEY, appId, user)
    );

    if (items) {
      return JSON.parse(items);
    }

    return [];
  };

  const persistNotifications = (items: Notification[]) => {
    window.localStorage.setItem(
      localStorageKey(LOCAL_STORAGE_NOTIFICATIONS_KEY, appId, user),
      JSON.stringify(items.filter((n: Notification) => !n.instant))
    );
  };

  const nofNewNotifications = (items: Notification[]) =>
    items.filter((i: Notification) => i.new && !i.instant).length;

  useEffect(() => {
    const items = getPersistedNotifications();

    setNotifications(items);
    setNewNotifications(nofNewNotifications(items));
  }, [user]);

  const toggleNotifications = () => {
    setNotificationsVisible(!notificationsVisible);
  };

  const clearNewNotifications = async () => {
    const clearedNotifications = notifications.map((n: Notification) => ({ ...n, new: false }));

    persistNotifications(clearedNotifications);

    setNotifications(clearedNotifications);
    setNewNotifications(0);
  };

  const clearActiveNotifications = async () => {
    const clearedNotifications = notifications.map((n: Notification) => ({ ...n, active: false }));

    persistNotifications(clearedNotifications);

    setNotifications(clearedNotifications);
  };

  const clearNotification = (id: string) => {
    const clearedNotifications = notifications.map((n: Notification) => {
      if (n.id !== id) {
        return n;
      }

      if (n.timerId) {
        window.clearTimeout(n.timerId);
      }

      return { ...n, new: false, active: false, timerId: undefined };
    });

    persistNotifications(clearedNotifications);

    setNotifications(clearedNotifications);
    setNewNotifications(nofNewNotifications(clearedNotifications));
  };

  const deleteNotification = (id: string) => {
    const updatedNotifications = notifications.filter((n: Notification) => n.id !== id);

    persistNotifications(updatedNotifications);

    setNotifications(updatedNotifications);
    setNewNotifications(nofNewNotifications(updatedNotifications));
  };

  const autoHideNotification = (items: Notification[], id: string) => {
    const clearedNotifications = items.map((n: Notification) =>
      n.id !== id ? n : { ...n, active: false, timerId: undefined }
    );

    persistNotifications(clearedNotifications);

    setNotifications(clearedNotifications);
    setNewNotifications(nofNewNotifications(clearedNotifications));
  };

  const createNotification = (
    title: string,
    description: string,
    type?: string,
    icon?: string,
    timeout?: number | null,
    instant?: boolean
  ) => {
    const notificationId = uniqueId("ntf");

    const newNotification: Notification = {
      id: notificationId,
      type: type || "default",
      icon: icon !== undefined ? icon : "",
      title,
      description,
      timestamp: new Date(),
      new: true,
      active: true,
      instant: instant === true
    };

    const updatedNotifications = [
      newNotification,
      ...notifications.map((n: Notification) => {
        if (n.timerId) {
          window.clearTimeout(n.timerId);
        }

        return { ...n, active: false, timerId: undefined };
      })
    ];

    if (timeout !== null && timeout !== 0) {
      const timeoutId = window.setTimeout(
        () => autoHideNotification(updatedNotifications, notificationId),
        timeout || 10000
      );
      newNotification.timerId = timeoutId;
    }

    persistNotifications(updatedNotifications);

    setNotifications(updatedNotifications);
    setNewNotifications(nofNewNotifications(updatedNotifications));

    if (notificationsVisible) {
      setNotificationsVisible(false);
    }

    if (settingsVisible) {
      setSettingsVisible(false);
    }

    return notificationId;
  };

  const createInstantNotification = (
    title: string,
    description: string,
    type?: string,
    icon?: string,
    timeout?: number | null
  ) => createNotification(title, description, type, icon, timeout, true);

  return (
    <ApplicationContext.Provider
      value={{
        user,
        theme: {
          current: currentTheme,
          toggle: toggleTheme,
          forced: forcedTheme !== undefined ? forcedTheme : false
        },
        menu: { visible: menuVisible, toggle: toggleMenu },
        settings: {
          visible: settingsVisible,
          toggle: toggleSettings,
          setVisible: setSettingsVisible
        },
        notifications: {
          visible: notificationsVisible,
          setVisible: setNotificationsVisible,
          toggle: toggleNotifications,
          items: notifications,
          new: newNotifications,
          clearNew: clearNewNotifications,
          clearActive: clearActiveNotifications,
          create: createNotification,
          createInstant: createInstantNotification,
          clear: clearNotification,
          delete: deleteNotification
        }
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationContext;
