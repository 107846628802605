import React, { ReactElement, useMemo } from "react";

import { Tile } from "@react-gcc-eds/core";
import moment from "moment";
import { Link } from "react-router-dom";

import { MaterialTransferOverviewViewModel } from "../../../domain/client-customer";
import {
  NamespaceKeys,
  GeneralKeys,
  ComponentSpecificKeys,
  MaterialSpecificKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";
import TitleValuePair from "../../common/layout/title-value-pair";

type Props = {
  materialTransfer: MaterialTransferOverviewViewModel;
};
const MaterialTransferOverviewListItem = ({
  materialTransfer
}: Props): JSX.Element => {
  const { translate } = useTranslation();

  const detailsAndValidateActions: ReactElement[] = useMemo<
    ReactElement[]
  >(() => {
    const actionsToAlwaysShow: JSX.Element[] = [
      <Link
        key="details-action"
        to={`/materialtransfer/${materialTransfer.Id}`}
        className="btn"
      >
        {translate(NamespaceKeys.General, GeneralKeys.Details)}
      </Link>
    ];
    const validateAction: JSX.Element | undefined =
      materialTransfer.CurrentUserCanExecuteTransfer ? (
        <Link
          key="validate-action"
          to={`/materialtransfer/${materialTransfer.Id}/validate`}
          className="btn primary"
        >
          {translate(
            NamespaceKeys.ComponentSpecific,
            ComponentSpecificKeys.Validate
          )}
        </Link>
      ) : undefined;
    if (!validateAction) return actionsToAlwaysShow;
    return [...actionsToAlwaysShow, validateAction];
  }, [materialTransfer, translate]);

  const fromCompany: JSX.Element | null = useMemo<JSX.Element | null>(
    () =>
      materialTransfer.FromCompanyName ? (
        <TitleValuePair
          title={translate(
            NamespaceKeys.MaterialSpecific,
            MaterialSpecificKeys.FromCompany
          )}
          value={
            <div className="to-and-from-company-name">
              {materialTransfer.FromCompanyName}
            </div>
          }
        />
      ) : null,
    [materialTransfer.FromCompanyName, translate]
  );

  const toCompany: JSX.Element = useMemo<JSX.Element>(
    () => (
      <TitleValuePair
        title={translate(
          NamespaceKeys.MaterialSpecific,
          MaterialSpecificKeys.ToCompany
        )}
        value={
          <div className="to-and-from-company-name">
            {materialTransfer.ToCompanyName}
          </div>
        }
      />
    ),
    [materialTransfer.ToCompanyName, translate]
  );

  const transferType: JSX.Element = useMemo<JSX.Element>(() => {
    const transferTypeText = translate(
      NamespaceKeys.MaterialSpecific,
      MaterialSpecificKeys[
        materialTransfer.Reason as keyof typeof MaterialSpecificKeys
      ]
    );
    return (
      <TitleValuePair
        title={translate(NamespaceKeys.General, GeneralKeys.Type)}
        value={<>{transferTypeText}</>}
      />
    );
  }, [materialTransfer.Reason, translate]);

  const createdAt: JSX.Element = useMemo<JSX.Element>(() => {
    return (
      <TitleValuePair
        title={translate(NamespaceKeys.General, GeneralKeys.CreatedAt)}
        value={<>{moment(materialTransfer.CreatedAt).format("LL")}</>}
      />
    );
  }, [materialTransfer, translate]);

  return (
    <Tile
      title={materialTransfer.SiteProjectName}
      subtitle={materialTransfer.SiteName}
      className="overview-list-item"
    >
      <div className="overview-content">
        {fromCompany}
        {toCompany}
        {transferType}
        {createdAt}
      </div>
      <div className="actions">{detailsAndValidateActions}</div>
    </Tile>
  );
};

export default MaterialTransferOverviewListItem;
