import React from "react";
import cx from "classnames";
import IAction from "./IKpiAction";

const KpiAction = ({label, onClick, className=""}:IAction) => {
  
  return (
    <div role="presentation" className={cx("color-gray action", className)} onClick={() => onClick && onClick()}>
        <span className="item">{label}</span>
        <span className="item color-blue">
            <i className="icon icon-arrow-right"></i>
        </span>
    </div>
  );
};

export default KpiAction;
