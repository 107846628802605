import React from "react";
import cx from "classnames";
import ITreeItem from "./ITreeItem";

const TreeItem = ({ tag, children, className, style,disabled, ...rest }: ITreeItem) => {
  const Tag = tag;

  return (
    <li>
      <Tag className={cx("item",{disabled}, className)} style={style} {...rest}>
        {children}
      </Tag>
    </li>
  );
};

export default TreeItem;
