import React, { ReactNode, useCallback, useMemo } from "react";

import { Route } from "react-router";

import {
  MobileViewAccess,
  MobileViewAccessIdentifier
} from "../../domain/client-customer";
import { MenuItemProps } from "../types";
import { viewAccessMenuDictionary } from "../view-access";

const useRoutes = (viewAccess: MobileViewAccess[] | undefined): ReactNode => {
  const getRoutesFromViewAccess = useCallback(
    (
      viewAccessIdentifier: MobileViewAccessIdentifier,
      children: MobileViewAccess[]
    ): JSX.Element[] => {
      const item: MenuItemProps =
        viewAccessMenuDictionary[viewAccessIdentifier];
      const subRoutes =
        item.routes?.map((r) => (
          <Route
            key={r.link}
            exact={r.exact}
            path={r.link}
            component={r.component}
          />
        )) ?? [];
      const childRoutes = children.flatMap((currentChild) =>
        getRoutesFromViewAccess(currentChild.Identifier, currentChild.Children)
      );

      return [
        ...childRoutes,
        <Route
          key={item.link}
          exact={item.exact}
          path={item.link}
          component={item.component}
        />,
        ...subRoutes
      ];
    },
    []
  );

  const routes = useMemo<React.ReactNode>(
    () =>
      viewAccess?.map((va) =>
        getRoutesFromViewAccess(va.Identifier, va.Children)
      ) ?? [],
    [viewAccess, getRoutesFromViewAccess]
  );

  return routes;
};

export default useRoutes;
