import React, { useCallback, memo, useMemo } from "react";

import {
  NumberField,
  Icon,
  TextField,
  Dropdown,
  IDropdownItem
} from "@react-gcc-eds/core";
import { ListChildComponentProps, areEqual } from "react-window";

import { DeliveryProductListSourcingDto } from "../../../../domain/client-customer";
import {
  NamespaceKeys,
  DplSpecificKeys,
  BomSpecificKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import TitleValuePair from "../../../common/layout/title-value-pair";
import { BillOfMaterialItemDtoEditState } from "../bom-viewer-component";
import { DropdownItem } from "../bom-viewer-utils";

export interface BomItemComponentProps {
  items: BillOfMaterialItemDtoEditState[];
  editable?: boolean;
  onNoteChanged: (item: BillOfMaterialItemDtoEditState, value: string) => void;
  onQuantityChanged: (
    item: BillOfMaterialItemDtoEditState,
    value: number
  ) => void;
  onItemRemoved: (item: BillOfMaterialItemDtoEditState) => void;
  onOpenProductDetails: (item: BillOfMaterialItemDtoEditState) => void;
  onSourcingProviderChanged: (
    item: BillOfMaterialItemDtoEditState,
    sourcingProvider: string
  ) => void;
  onMaterialCategoryChanged: (
    item: BillOfMaterialItemDtoEditState,
    materialCategory: string
  ) => void;
  onItemCollapseToggle: (item: BillOfMaterialItemDtoEditState) => void;
}
const mapMaterialCategorySelection = (
  materialCategories: string[] | undefined
): DropdownItem[] =>
  (materialCategories || []).map((mc) => ({
    title: mc || "",
    value: mc || ""
  }));

const mapValidQuantities = (
  validQuantities: string[] | undefined
): DropdownItem[] =>
  (validQuantities || []).map((vq) => ({
    title: vq || "",
    value: vq || ""
  }));

const BomItem = ({
  data,
  index,
  style
}: ListChildComponentProps): React.ReactElement => {
  const {
    editable,
    items,
    onQuantityChanged,
    onItemRemoved,
    onNoteChanged,
    onOpenProductDetails,
    onSourcingProviderChanged,
    onMaterialCategoryChanged,
    onItemCollapseToggle
  } = data as BomItemComponentProps;
  const item: BillOfMaterialItemDtoEditState | undefined = useMemo<
    BillOfMaterialItemDtoEditState | undefined
  >(() => items[index], [index, items]);
  const { translate } = useTranslation();

  const handleOnChangeValidQuantity = useCallback(
    (dropdownItem: IDropdownItem) => {
      const value: number = dropdownItem.value;
      if (!editable || !item) return;
      const checkedValue = value > 0 ? value : 1;
      onQuantityChanged(item, checkedValue);
    },
    [editable, item, onQuantityChanged]
  );

  const handleOnChangeQuantity = useCallback(
    (value: number) => {
      if (!editable || !item) return;
      const checkedValue = value > 0 ? value : 1;
      onQuantityChanged(item, checkedValue);
    },
    [editable, item, onQuantityChanged]
  );

  const handleOnRemove = useCallback(() => {
    if (!item) return;
    onItemRemoved(item);
  }, [item, onItemRemoved]);

  const handleOnClick = useCallback(() => {
    if (!item) return;
    onOpenProductDetails(item);
  }, [item, onOpenProductDetails]);

  const handleOnChangeNote = useCallback(
    (note: string) => {
      if (!item) return;
      onNoteChanged(item, note);
    },
    [item, onNoteChanged]
  );

  const renderSourcingProviderSelection = (
    sourcingProviders: DeliveryProductListSourcingDto[] | undefined
  ): DropdownItem[] =>
    (sourcingProviders || []).map((c) => ({
      title: c.SourcingProvider || "",
      value: c.SourcingProvider || ""
    }));

  const handleOnChangeSourcingProvider = useCallback(
    (dropdownItem: IDropdownItem) => {
      const sourcingProvider: string = dropdownItem.value;
      if (!item) return;
      onSourcingProviderChanged(item, sourcingProvider);
    },
    [item, onSourcingProviderChanged]
  );

  const handleOnChangeMaterialCategory = useCallback(
    (dropdownItem: IDropdownItem) => {
      const materialCategory: string = dropdownItem.value;
      if (!item) return;
      onMaterialCategoryChanged(item, materialCategory);
    },
    [item, onMaterialCategoryChanged]
  );

  const handleOnClickProductTitle = useCallback(() => {
    if (!item) return;
    onItemCollapseToggle(item);
  }, [item, onItemCollapseToggle]);

  return item ? (
    <div style={style} className="bom-list-item">
      <div
        className="first-row"
        key={
          item.bomItem.DeliveryProductListItem &&
          `${item.bomItem.DeliveryProductListItem.Id}${item.bomItem.MaterialCategory}`
        }
      >
        <div className="bom-product-title" onClick={handleOnClickProductTitle}>
          {item.bomItem.DeliveryProductListItem?.EricssonProductNumber}
          <div
            className={`bom-product-subtitle${
              item.doesItemAppearMoreThanOnceOnBom
                ? " multiple-material-categories"
                : ""
            }`}
          >
            {item.doesItemAppearMoreThanOnceOnBom && <Icon name="info" />}
            {item.bomItem.MaterialCategory}
          </div>
        </div>
        <div className="bom-product-details">
          <Icon name="info" onClick={handleOnClick} />
        </div>
        <div className="bom-number-field">
          {item.bomItem.Quantity &&
          item.bomItem.DeliveryProductListItem?.ValidQuantities?.length ? (
            <Dropdown
              items={mapValidQuantities(
                item.bomItem.DeliveryProductListItem?.ValidQuantities.map(
                  String
                )
              )}
              label={item.bomItem?.Quantity?.toString()}
              onItemClick={handleOnChangeValidQuantity}
              disabled={!editable}
            />
          ) : (
            <NumberField
              type="spinner"
              value={item.bomItem.Quantity ?? 0}
              onChange={handleOnChangeQuantity}
              disabled={!editable}
            />
          )}
        </div>
        {editable && (
          <Icon
            name="trashcan"
            className="remove-button"
            onClick={handleOnRemove}
          />
        )}
      </div>
      {!item.collapsed && (
        <div
          className="second-row"
          key={item.bomItem.DeliveryProductListItem?.Id}
        >
          <TitleValuePair
            title={translate(
              NamespaceKeys.DplSpecific,
              DplSpecificKeys.SourcingProvider
            )}
            value={
              <Dropdown
                items={renderSourcingProviderSelection(
                  item.bomItem.DeliveryProductListItem?.Sourcings
                )}
                label={item.bomItem.SourcingProvider}
                disabled={
                  !editable ||
                  item.bomItem.DeliveryProductListItem?.Sourcings?.length === 1
                }
                onItemClick={handleOnChangeSourcingProvider}
              />
            }
          />
          <TitleValuePair
            title={translate(
              NamespaceKeys.DplSpecific,
              DplSpecificKeys.MaterialCategory
            )}
            value={
              <Dropdown
                items={mapMaterialCategorySelection(
                  item.bomItem.DeliveryProductListItem?.MaterialCategories
                )}
                label={item.bomItem.MaterialCategory}
                disabled={
                  !editable ||
                  item.bomItem.DeliveryProductListItem?.MaterialCategories
                    ?.length === 1 ||
                  item.doesItemAppearMoreThanOnceOnBom
                }
                onItemClick={handleOnChangeMaterialCategory}
              />
            }
          />

          <TitleValuePair
            title={translate(NamespaceKeys.BomSpecific, BomSpecificKeys.Note)}
            value={
              <TextField
                value={item.bomItem.Note ?? ""}
                disabled={!editable}
                onChange={handleOnChangeNote}
              />
            }
          />
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default memo(BomItem, areEqual);
