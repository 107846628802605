import { BrowserCompatibility } from "./browserCompatibility";
import { CustomError } from "./customError";

export class UnsupportedBrowserError extends CustomError {
  public readonly data?: BrowserCompatibility;

  // istanbul ignore next
  constructor(browserCompatibility: BrowserCompatibility) {
    super({
      name: "UnsupportedBrowserError",
      message: `This OS / browser has one or more missing features preventing it from working correctly (${browserCompatibility.missingFeatures.join(
        ", "
      )})`,
    });
    this.data = browserCompatibility;
  }
}
