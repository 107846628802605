import { Barcode, Camera, ScanSettings, SearchArea } from "scandit-sdk";

const PDF417_CONTENT_CONTAINER = "\u001E";
const PDF417_CONTENT_SEPARATOR = "\u001D";

const EricssonProductNumberIdentifier = "1P";
const EricssonSerialNumberIdentifier = "S";

export type ScanResultItem = {
  ericssonProductNumber?: string;
  serialNumber?: string;
};

export const findFromPdf417 = (
  barcode: string,
  identifier: string,
  separator: string
): string | undefined =>
  barcode
    .split(PDF417_CONTENT_CONTAINER)[1]
    ?.split(separator)
    .find((part) => part.startsWith(identifier))
    ?.slice(identifier.length);

export const toScanResultItem = (barcode: Barcode): ScanResultItem => {
  if (barcode.symbology === Barcode.Symbology.PDF417) {
    return {
      ericssonProductNumber: findFromPdf417(
        barcode.data,
        EricssonProductNumberIdentifier,
        PDF417_CONTENT_SEPARATOR
      ),
      serialNumber: findFromPdf417(
        barcode.data,
        EricssonSerialNumberIdentifier,
        PDF417_CONTENT_SEPARATOR
      )
    };
  }
  if (barcode.data.startsWith(EricssonProductNumberIdentifier)) {
    return {
      ericssonProductNumber: barcode.data.slice(
        EricssonProductNumberIdentifier.length
      )
    };
  }
  return { serialNumber: barcode.data };
};

export const getDevelopmentCameraIfAvailable = (
  cameras: Camera[]
): Camera | undefined =>
  cameras?.find((c) => c.label.indexOf("HP HD Camera") > -1);

export const getBackFacingCameraOrDefault = (
  cameras: Camera[]
): Camera | undefined =>
  cameras?.find((c) => c.cameraType.includes("back")) ??
  cameras?.find((c) => c.label.includes("back")) ??
  ((cameras?.length && cameras[0]) || undefined);

export const removeIncompatibleCamerasFilter = (c: Camera): boolean =>
  !c.label.includes("HP IR Camera");

export const getScanSettings = (searchArea?: SearchArea): ScanSettings =>
  new ScanSettings({
    enabledSymbologies: [
      Barcode.Symbology.PDF417,
      Barcode.Symbology.CODE128,
      Barcode.Symbology.CODE39
    ],
    codeDuplicateFilter: 3000,
    searchArea: searchArea
  });
