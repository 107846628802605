import React, { useState, ChangeEvent, useEffect } from "react";
import cx from "classnames";
import {ISignInButton} from "./contracts";
import ISignIn from "./contracts/ISignIn";
import printCopyrightText from "../../copyright";

const SignIn = (props: ISignIn) => {
  const {
    anonymousSignIn,
    signInButtons,
    productName,
    onSignIn,
    signedInUser,
    invalidCredentials,
    support
  } = {
    ...props
  };
  const [showPassword, setShowPassword] = useState();
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  useEffect(() => {
    if (!document.body.classList.contains("dark")) {
      document.body.classList.remove("light", "dark");
      document.body.classList.add("dark");
    }
  }, []);

  const buttons: ISignInButton[] =
    signInButtons && signInButtons.length
      ? signInButtons
      : [{ text: "Sign in", onClick: onSignIn }];

  return (
    <main>
      <div className="signin">
        <div className="watermark">
          <i className="icon icon-econ" />
          <div className="product">{productName}</div>
        </div>

        {signedInUser && (
          <div className="welcome">
            <div className="message">Welcome</div>
            <div className="username">{`${signedInUser.firstName} ${signedInUser.lastName}`}</div>
            <div className="loading" />
          </div>
        )}

        <div className={cx("error", { hidden: !invalidCredentials })}>
          Invalid username and/or password.
        </div>

        {!signedInUser && (
          <form action="/templates/signin" method="get" className="">
            <h1>Welcome</h1>
            <p>Please sign in to continue</p>
            {!anonymousSignIn && (
              <>
                <div className="field validate">
                  <input
                    type="email"
                    name="username"
                    id="username"
                    placeholder="Email"
                    tabIndex={0}
                    value={userName}
                    required
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setUserName(e.target.value)}
                  />
                  <label htmlFor="username">Username</label>
                  <div className="hint" />
                </div>
                <div className="field validate">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    placeholder=" "
                    tabIndex={0}
                    required
                    value={password}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                  />
                  <label htmlFor="password">Password</label>
                  <span
                    role="presentation"
                    className="reveal tooltip"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <i className="icon icon-eye" />
                    <span className="message bottom-end">Show password</span>
                  </span>
                  <div className="hint" />
                </div>
              </>
            )}
            <div className="signin-buttons">
              {buttons.map(b => (
                <button
                  key={b.text}
                  type="button"
                  className="btn big primary"
                  onClick={() => b.onClick && b.onClick(userName, password)}
                >
                  {b.text}
                </button>
              ))}
            </div>
            <div className="help">{support}</div>
          </form>
        )}
        <div className="footer">
          {printCopyrightText()}
        </div>
      </div>
    </main>
  );
};
export default SignIn;
