import React, { ReactElement, useMemo } from "react";

import { Column, Row } from "@react-gcc-eds/core";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import {
  InstallationSpecificKeys,
  NamespaceKeys
} from "../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../translation/translation-utils";
import {
  NonSerializedInstallationItemState,
  SerializedInstallationItemState
} from "../material-installation-validation-component";
import {
  mapItemsFromPropsToNonValidatedItems,
  mapItemsFromPropsToValidatedItems
} from "../quantity-validator/quantity-validator-component";

type Props = {
  serializedItems: SerializedInstallationItemState[];
  nonSerializedItems: NonSerializedInstallationItemState[];
};

const ReviewViewItem = ({
  serializedItems,
  nonSerializedItems
}: Props): ReactElement => {
  const { translate } = useTranslation();
  const validatedItems = useMemo(
    () =>
      mapItemsFromPropsToValidatedItems(serializedItems, nonSerializedItems),
    [nonSerializedItems, serializedItems]
  );
  const nonValidatedItems = useMemo(
    () =>
      mapItemsFromPropsToNonValidatedItems(serializedItems, nonSerializedItems),
    [nonSerializedItems, serializedItems]
  );

  return (
    <>
      <TransitionGroup>
        {validatedItems.map((item) => (
          <CSSTransition
            key={`${item.dplItem?.Id ?? 0}-${
              item.billOfMaterialItemIdentity?.Value
            }-${item.materialCategory}`}
            timeout={300}
            classNames="item"
          >
            <div className="quantity-tile">
              <Row>
                <Column sm={9} className="quality-validator-item-header">
                  <div className="quality-validator-item-title">
                    {item.dplItem?.EricssonProductNumber}
                  </div>
                  <div className="quality-validator-item-subtitle">
                    {item.materialCategory}
                  </div>
                  <div className="quality-validator-item-description">
                    {item.dplItem?.EricssonProductDescription ?? ""}
                  </div>
                </Column>
              </Row>
              <Row>
                <Column sm={6} className="quantity-container">
                  {translate(
                    NamespaceKeys.InstallationSpecific,
                    InstallationSpecificKeys.QuantitySummaryReview,
                    {
                      quantity: item?.validationQuantities?.Ok ?? 0,
                      availableQuantity: item.quantity.toString(),
                      unitOfMeasurement: item.dplItem?.UnitOfMeasurement
                    }
                  )}
                </Column>
              </Row>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
      <TransitionGroup>
        {nonValidatedItems.map((item) => (
          <CSSTransition
            key={`${item.dplItem?.Id ?? 0}-${
              item.billOfMaterialItemIdentity?.Value
            }-${item.materialCategory}`}
            timeout={300}
            classNames="item"
          >
            <div className="quantity-tile">
              <Row>
                <Column sm={9} className="quality-validator-item-header">
                  <div className="quality-validator-item-title">
                    {item.dplItem?.EricssonProductNumber}
                  </div>
                  <div className="quality-validator-item-subtitle">
                    {item.materialCategory}
                  </div>
                  <div className="quality-validator-item-description">
                    {item.dplItem?.EricssonProductDescription ?? ""}
                  </div>
                </Column>
              </Row>
              <Row>
                <Column sm={6} className="quantity-container">
                  {translate(
                    NamespaceKeys.InstallationSpecific,
                    InstallationSpecificKeys.QuantitySummaryReview,
                    {
                      quantity: item?.validationQuantities?.Ok ?? 0,
                      availableQuantity: item.quantity.toString(),
                      unitOfMeasurement: item.dplItem?.UnitOfMeasurement
                    }
                  )}
                </Column>
              </Row>
            </div>
          </CSSTransition>
        ))}
      </TransitionGroup>
    </>
  );
};

export default ReviewViewItem;
