import { BrowserHelper } from "../browserHelper";
import { Camera } from "../camera";
import { CameraAccess } from "../cameraAccess";
import { CameraSettings } from "../cameraSettings";
import { CustomError } from "../customError";
import { Logger } from "../logger";
import { Scanner } from "../scanner";
import { GUI } from "./gui";

export enum MeteringMode {
  CONTINUOUS = "continuous",
  MANUAL = "manual",
  NONE = "none",
  SINGLE_SHOT = "single-shot",
}

export enum CameraResolutionConstraint {
  ULTRA_HD = 0,
  FULL_HD = 1,
  HD = 2,
  SD = 3,
  NONE = 4,
}

export interface ExtendedMediaTrackCapabilities extends MediaTrackCapabilities {
  focusMode?: MeteringMode[];
  torch?: boolean;
  zoom?: {
    max: number;
    min: number;
    step: number;
  };
}

export interface ExtendedMediaTrackConstraintSet extends MediaTrackConstraintSet {
  focusMode?: MeteringMode;
  torch?: boolean;
  zoom?: number;
}

/**
 * A barcode picker utility class used to handle camera interaction.
 */
export class CameraManager {
  private static readonly cameraAccessTimeoutMs: number = 4000;
  private static readonly videoMetadataCheckTimeoutMs: number = 4000;
  private static readonly videoMetadataCheckIntervalMs: number = 50;
  private static readonly getCapabilitiesTimeoutMs: number = 500;
  private static readonly autofocusIntervalMs: number = 1500;
  private static readonly manualToAutofocusResumeTimeoutMs: number = 5000;
  private static readonly manualFocusWaitTimeoutMs: number = 400;
  private static readonly noCameraErrorParameters: { name: string; message: string } = {
    name: "NoCameraAvailableError",
    message: "No camera available",
  };
  private static readonly notReadableErrorParameters: { name: string; message: string } = {
    name: "NotReadableError",
    message: "Could not initialize camera correctly",
  };

  public selectedCamera?: Camera;
  public activeCamera?: Camera;
  public activeCameraSettings?: CameraSettings;

  private readonly scanner: Scanner;
  private readonly triggerCameraAccessError: (error: Error) => void;
  private readonly gui: GUI;
  private readonly postStreamInitializationListener: () => void = this.postStreamInitialization.bind(this);
  private readonly videoResizeListener: () => void = this.videoResizeHandle.bind(this);
  private readonly videoTrackEndedListener: () => void = this.videoTrackEndedRecovery.bind(this);
  private readonly videoTrackMuteListener: (event: Event) => void = this.videoTrackMuteRecovery.bind(this);
  private readonly triggerManualFocusListener: () => void = this.triggerManualFocus.bind(this);
  private readonly triggerZoomStartListener: () => void = this.triggerZoomStart.bind(this);
  private readonly triggerZoomMoveListener: () => void = this.triggerZoomMove.bind(this);
  private readonly checkCameraVideoStreamAccessIfVisibleListener: () => void =
    this.checkCameraVideoStreamAccessIfVisible.bind(this);

  private cameraType: Camera.Type;
  private selectedCameraSettings?: CameraSettings;
  private mediaStream?: MediaStream;
  private mediaTrackCapabilities?: ExtendedMediaTrackCapabilities;
  private cameraAccessTimeout: number;
  private cameraAccessRejectCallback?: (reason?: Error) => void;
  private videoMetadataCheckInterval: number;
  private getCapabilitiesTimeout: number;
  private autofocusInterval: number;
  private manualToAutofocusResumeTimeout: number;
  private manualFocusWaitTimeout: number;
  private cameraSwitcherEnabled: boolean;
  private cameraFOVSwitcherEnabled: boolean;
  private torchToggleEnabled: boolean;
  private tapToFocusEnabled: boolean;
  private pinchToZoomEnabled: boolean;
  private pinchToZoomDistance?: number;
  private pinchToZoomInitialZoom: number;
  private torchEnabled: boolean;
  private cameraInitializationPromise?: Promise<void>;
  private abortedCameraInitializationResolveCallback?: (value: void | PromiseLike<void>) => void;
  private cameraSetupPromise?: Promise<void>;

  constructor(scanner: Scanner, triggerCameraAccessError: (error: Error) => void, gui: GUI) {
    this.scanner = scanner;
    this.triggerCameraAccessError = triggerCameraAccessError;
    this.gui = gui;
    this.cameraType = Camera.Type.BACK;
  }

  public setInteractionOptions(
    cameraSwitcherEnabled: boolean,
    cameraFOVSwitcherEnabled: boolean,
    torchToggleEnabled: boolean,
    tapToFocusEnabled: boolean,
    pinchToZoomEnabled: boolean
  ): void {
    this.cameraSwitcherEnabled = cameraSwitcherEnabled;
    this.cameraFOVSwitcherEnabled = cameraFOVSwitcherEnabled;
    this.torchToggleEnabled = torchToggleEnabled;
    this.tapToFocusEnabled = tapToFocusEnabled;
    this.pinchToZoomEnabled = pinchToZoomEnabled;
  }

  public isCameraSwitcherEnabled(): boolean {
    return this.cameraSwitcherEnabled;
  }

  public async setCameraSwitcherEnabled(enabled: boolean): Promise<void> {
    this.cameraSwitcherEnabled = enabled;

    if (this.cameraSwitcherEnabled) {
      const cameras: Camera[] = await CameraAccess.getCameras();
      if (cameras.length > 1) {
        this.gui.setCameraSwitcherVisible(true);
      }
    } else {
      this.gui.setCameraSwitcherVisible(false);
    }
  }

  public isCameraFOVSwitcherEnabled(): boolean {
    return this.cameraFOVSwitcherEnabled;
  }

  public setCameraFOVSwitcherEnabled(enabled: boolean): void {
    this.cameraFOVSwitcherEnabled = enabled;

    if (this.isCameraFOVSwitcherAllowed() && this.activeCamera != null) {
      this.gui.setCameraFOVSwitcherState(CameraAccess.isIOSUltraWideBackCameraLabel(this.activeCamera.label));
      this.gui.setCameraFOVSwitcherVisible(true);
    } else {
      this.gui.setCameraFOVSwitcherVisible(false);
    }
  }

  public isTorchToggleEnabled(): boolean {
    return this.torchToggleEnabled;
  }

  public setTorchToggleEnabled(enabled: boolean): void {
    this.torchToggleEnabled = enabled;

    if (this.torchToggleEnabled) {
      if (this.mediaStream != null && this.mediaTrackCapabilities?.torch === true) {
        this.gui.setTorchTogglerVisible(true);
      }
    } else {
      this.gui.setTorchTogglerVisible(false);
    }
  }

  public isTapToFocusEnabled(): boolean {
    return this.tapToFocusEnabled;
  }

  public setTapToFocusEnabled(enabled: boolean): void {
    this.tapToFocusEnabled = enabled;

    if (this.mediaStream != null) {
      if (this.tapToFocusEnabled) {
        this.enableTapToFocusListeners();
      } else {
        this.disableTapToFocusListeners();
      }
    }
  }

  public isPinchToZoomEnabled(): boolean {
    return this.pinchToZoomEnabled;
  }

  public setPinchToZoomEnabled(enabled: boolean): void {
    this.pinchToZoomEnabled = enabled;

    if (this.mediaStream != null) {
      if (this.pinchToZoomEnabled) {
        this.enablePinchToZoomListeners();
      } else {
        this.disablePinchToZoomListeners();
      }
    }
  }

  public setInitialCameraType(cameraType: Camera.Type): void {
    this.cameraType = cameraType;
  }

  public async setCameraType(cameraType: Camera.Type): Promise<void> {
    this.setInitialCameraType(cameraType);
    const mainCameraForType: Camera | undefined = CameraAccess.getMainCameraForType(
      await CameraAccess.getCameras(),
      cameraType
    );
    if (mainCameraForType != null && mainCameraForType !== this.selectedCamera) {
      return this.initializeCameraWithSettings(mainCameraForType, this.selectedCameraSettings);
    }
  }

  public setSelectedCamera(camera?: Camera): void {
    this.selectedCamera = camera;
  }

  public setSelectedCameraSettings(cameraSettings?: CameraSettings): void {
    this.selectedCameraSettings = cameraSettings;
  }

  public async setupCameras(): Promise<void> {
    if (this.cameraSetupPromise != null) {
      return this.cameraSetupPromise;
    }
    this.cameraSetupPromise = this.setupCamerasAndStream();

    return this.cameraSetupPromise;
  }

  public async stopStream(cameraInitializationFailure: boolean = false): Promise<void> {
    if (this.activeCamera != null) {
      this.activeCamera.currentResolution = undefined;
    }

    this.activeCamera = undefined;
    this.torchEnabled = false;
    this.gui.setTorchTogglerVisible(false);
    this.gui.setCameraFOVSwitcherVisible(false);

    if (this.mediaStream != null) {
      Logger.log(
        Logger.Level.DEBUG,
        `Stop camera video stream access${cameraInitializationFailure ? " (abort access detection)" : ""}:`,
        this.mediaStream
      );
      document.removeEventListener("visibilitychange", this.checkCameraVideoStreamAccessIfVisibleListener);

      window.clearTimeout(this.cameraAccessTimeout);
      window.clearInterval(this.videoMetadataCheckInterval);
      window.clearTimeout(this.getCapabilitiesTimeout);
      window.clearTimeout(this.manualFocusWaitTimeout);
      window.clearTimeout(this.manualToAutofocusResumeTimeout);
      window.clearInterval(this.autofocusInterval);

      // Pause video and asynchronously stop tracks to prevent bug on some Android devices freezing the camera on Chrome
      this.gui.videoElement.pause();

      return new Promise((resolve) => {
        setTimeout(() => {
          this.mediaStream?.getVideoTracks().forEach((track) => {
            track.removeEventListener("ended", this.videoTrackEndedListener);
            track.stop();
          });
          this.gui.videoElement.srcObject = null;
          this.mediaStream = undefined;
          this.mediaTrackCapabilities = undefined;
          if (!cameraInitializationFailure) {
            this.abortedCameraInitializationResolveCallback?.();
          }
          resolve();
        }, 0);
      });
    }
  }

  public async applyCameraSettings(cameraSettings?: CameraSettings): Promise<void> {
    this.selectedCameraSettings = cameraSettings;

    if (this.activeCamera == null) {
      throw new CustomError(CameraManager.noCameraErrorParameters);
    }

    return this.initializeCameraWithSettings(this.activeCamera, cameraSettings);
  }

  public async reinitializeCamera(): Promise<void> {
    if (this.activeCamera == null) {
      // If the initial camera isn't active yet, do nothing: if and when the camera is later confirmed to be the correct
      // (main with wanted type or only) one this method will be called again after the camera is set to be active
      Logger.log(Logger.Level.DEBUG, "Camera reinitialization delayed");
    } else {
      Logger.log(Logger.Level.DEBUG, "Reinitialize camera:", this.activeCamera);
      try {
        const camera: Camera = this.activeCamera;
        await this.stopStream();
        await this.initializeCameraWithSettings(camera, this.activeCameraSettings);
      } catch (error) {
        Logger.log(Logger.Level.WARN, "Couldn't access camera:", this.activeCamera, error);
        this.triggerCameraAccessError(error);
        throw error;
      }
    }
  }

  public async initializeCameraWithSettings(camera?: Camera, cameraSettings?: CameraSettings): Promise<void> {
    if (this.cameraInitializationPromise != null) {
      await this.cameraInitializationPromise;
    }
    if (camera == null) {
      throw new CustomError(CameraManager.noCameraErrorParameters);
    }
    this.setSelectedCamera(camera);
    this.selectedCameraSettings = this.activeCameraSettings = cameraSettings;
    this.cameraInitializationPromise = this.initializeCameraAndCheckUpdatedSettings(camera);

    return this.cameraInitializationPromise;
  }

  public async setTorchEnabled(enabled: boolean): Promise<void> {
    if (this.mediaStream != null && this.mediaTrackCapabilities?.torch === true) {
      this.torchEnabled = enabled;
      const videoTracks: MediaStreamTrack[] = this.mediaStream.getVideoTracks();
      // istanbul ignore else
      if (videoTracks.length !== 0 && typeof videoTracks[0].applyConstraints === "function") {
        await videoTracks[0].applyConstraints({ advanced: <ExtendedMediaTrackConstraintSet[]>[{ torch: enabled }] });
      }
    }
  }

  public async toggleTorch(): Promise<void> {
    this.torchEnabled = !this.torchEnabled;
    await this.setTorchEnabled(this.torchEnabled);
  }

  public async setZoom(zoomPercentage: number, currentZoom?: number): Promise<void> {
    if (this.mediaStream != null && this.mediaTrackCapabilities?.zoom != null) {
      const videoTracks: MediaStreamTrack[] = this.mediaStream.getVideoTracks();
      // istanbul ignore else
      if (videoTracks.length !== 0 && typeof videoTracks[0].applyConstraints === "function") {
        const zoomRange: number = this.mediaTrackCapabilities.zoom.max - this.mediaTrackCapabilities.zoom.min;
        const targetZoom: number = Math.max(
          this.mediaTrackCapabilities.zoom.min,
          Math.min(
            (currentZoom ?? this.mediaTrackCapabilities.zoom.min) + zoomRange * zoomPercentage,
            this.mediaTrackCapabilities.zoom.max
          )
        );
        await videoTracks[0].applyConstraints({
          advanced: <ExtendedMediaTrackConstraintSet[]>[{ zoom: targetZoom }],
        });
      }
    }
  }

  private isCameraFOVSwitcherAllowed(): boolean {
    return (
      this.cameraFOVSwitcherEnabled &&
      BrowserHelper.isBrokenIPhoneCameraDevice() &&
      BrowserHelper.isIOSDeviceWithExtendedCameraAccess() &&
      this.activeCamera != null &&
      (CameraAccess.isIOSWideBackCameraLabel(this.activeCamera.label) ||
        CameraAccess.isIOSUltraWideBackCameraLabel(this.activeCamera.label))
    );
  }

  private async recoverStreamIfNeeded(): Promise<void> {
    // Due to non-standard behaviour, it could happen that the stream got interrupted while getting the list of
    // cameras, this isn't handled by the existing "ended" event listener as the active camera wasn't set until
    // before this, so manually reinitialize camera if needed
    const videoTracks: MediaStreamTrack[] | undefined = this.mediaStream?.getVideoTracks();
    if (videoTracks?.[0]?.readyState === "ended") {
      await this.reinitializeCamera();
    }
  }

  private async setupCamerasAndStream(): Promise<void> {
    try {
      let initialCamera: Camera | undefined;
      if (this.selectedCamera == null) {
        this.gui.setVideoVisible(false);
        initialCamera = await this.accessInitialCamera();
      }
      const cameras: Camera[] = await CameraAccess.getCameras(false, true);

      if (this.cameraSwitcherEnabled && cameras.length > 1) {
        this.gui.setCameraSwitcherVisible(true);
      }

      // Get but don't save deviceId in initialCamera to differentiate it from final cameras
      const initialCameraDeviceId: string | undefined = this.mediaStream?.getVideoTracks()[0]?.getSettings?.().deviceId;
      if (this.mediaStream != null && initialCamera != null) {
        // We successfully accessed the initial camera
        const activeCamera: Camera | undefined =
          cameras.length === 1
            ? cameras[0]
            : cameras.find((camera) => {
                return (
                  camera.deviceId === initialCameraDeviceId ||
                  (camera.label !== "" && camera.label === initialCamera?.label)
                );
              });
        if (activeCamera != null) {
          CameraAccess.adjustCameraFromMediaStream(this.mediaStream, activeCamera);
          if (BrowserHelper.isDesktopDevice()) {
            // When the device is a desktop/laptop, we store the initial camera as it should be considered the main one
            // for its camera type and the currently set camera type (which might be different)
            CameraAccess.mainCameraForTypeOverridesOnDesktop.set(this.cameraType, activeCamera);
            CameraAccess.mainCameraForTypeOverridesOnDesktop.set(activeCamera.cameraType, activeCamera);
          }
          if (cameras.length === 1 || CameraAccess.getMainCameraForType(cameras, this.cameraType) === activeCamera) {
            Logger.log(
              Logger.Level.DEBUG,
              "Initial camera access was correct (main camera), keep camera:",
              activeCamera
            );
            this.setSelectedCamera(activeCamera);
            this.updateActiveCameraCurrentResolution(activeCamera);
            await this.recoverStreamIfNeeded();

            return;
          } else {
            Logger.log(Logger.Level.DEBUG, "Initial camera access was incorrect (not main camera), change camera", {
              ...initialCamera,
              deviceId: initialCameraDeviceId,
            });
          }
        } else {
          Logger.log(Logger.Level.DEBUG, "Initial camera access was incorrect (unknown camera), change camera", {
            ...initialCamera,
            deviceId: initialCameraDeviceId,
          });
        }
      }

      if (this.selectedCamera == null) {
        return await this.accessAutoselectedCamera(cameras);
      } else {
        return await this.initializeCameraWithSettings(this.selectedCamera, this.selectedCameraSettings);
      }
    } finally {
      this.gui.setVideoVisible(true);
      this.cameraSetupPromise = undefined;
    }
  }

  private getInitialCameraResolutionConstraint(): CameraResolutionConstraint {
    let cameraResolutionConstraint: CameraResolutionConstraint;
    switch (this.activeCameraSettings?.resolutionPreference) {
      case CameraSettings.ResolutionPreference.ULTRA_HD:
        cameraResolutionConstraint = CameraResolutionConstraint.ULTRA_HD;
        break;
      case CameraSettings.ResolutionPreference.FULL_HD:
        cameraResolutionConstraint = CameraResolutionConstraint.FULL_HD;
        break;
      case CameraSettings.ResolutionPreference.HD:
      default:
        cameraResolutionConstraint = CameraResolutionConstraint.HD;
        break;
    }

    return cameraResolutionConstraint;
  }

  private async accessAutoselectedCamera(cameras: Camera[]): Promise<void> {
    cameras = CameraAccess.sortCamerasForCameraType(cameras, this.cameraType);

    let autoselectedCamera: Camera | undefined = cameras.shift();
    while (autoselectedCamera != null) {
      try {
        return await this.initializeCameraWithSettings(autoselectedCamera, this.selectedCameraSettings);
      } catch (error) {
        this.setSelectedCamera();
        if (cameras.length === 1) {
          this.gui.setCameraSwitcherVisible(false);
        }
        if (cameras.length >= 1) {
          Logger.log(Logger.Level.WARN, "Couldn't access camera:", autoselectedCamera, error);
          autoselectedCamera = cameras.shift();
          continue;
        }
        throw error;
      }
    }
    throw new CustomError(CameraManager.noCameraErrorParameters);
  }

  private async accessInitialCamera(): Promise<Camera> {
    // Note that the initial camera's information (except deviceId) will be updated after a successful access
    const initialCamera: Camera = {
      deviceId: "",
      label: "",
      cameraType: this.cameraType,
    };

    try {
      await this.initializeCameraWithSettings(initialCamera, this.selectedCameraSettings);
    } catch {
      // Ignored
    } finally {
      this.setSelectedCamera();
    }

    return initialCamera;
  }

  private updateActiveCameraCurrentResolution(camera: Camera): void {
    if (this.gui.videoElement.videoWidth > 2 && this.gui.videoElement.videoHeight > 2) {
      camera.currentResolution = {
        width: this.gui.videoElement.videoWidth,
        height: this.gui.videoElement.videoHeight,
      };
    }
    // If it's the initial camera, do nothing: if and when the camera is later confirmed to be the
    // correct (main with wanted type or only) one this method will be called again with the right camera object
    if (camera.deviceId !== "") {
      this.activeCamera = camera;
      this.gui.setMirrorImageEnabled(this.gui.isMirrorImageEnabled(), false);
      if (this.isCameraFOVSwitcherAllowed()) {
        this.gui.setCameraFOVSwitcherState(CameraAccess.isIOSUltraWideBackCameraLabel(this.activeCamera.label));
        this.gui.setCameraFOVSwitcherVisible(true);
      }
    }
  }

  private postStreamInitialization(): void {
    window.clearTimeout(this.getCapabilitiesTimeout);
    this.getCapabilitiesTimeout = window.setTimeout(() => {
      this.storeStreamCapabilities();
      this.setupAutofocus();
      if (this.torchToggleEnabled && this.mediaStream != null && this.mediaTrackCapabilities?.torch === true) {
        this.gui.setTorchTogglerVisible(true);
      }
    }, CameraManager.getCapabilitiesTimeoutMs);
  }

  private videoResizeHandle(): void {
    if (this.activeCamera != null) {
      this.updateActiveCameraCurrentResolution(this.activeCamera);
    }
  }

  private checkCameraVideoStreamAccessIfVisible(): void {
    if (document.visibilityState === "visible") {
      Logger.log(Logger.Level.DEBUG, "Page is visible again, waiting for camera video stream start...");
      document.removeEventListener("visibilitychange", this.checkCameraVideoStreamAccessIfVisibleListener);
      this.setCameraAccessTimeout();
    }
  }

  private async videoTrackEndedRecovery(): Promise<void> {
    Logger.log(Logger.Level.DEBUG, 'Detected video track "ended" event, try to reinitialize camera');
    if (document.visibilityState !== "visible") {
      Logger.log(Logger.Level.DEBUG, "Page is currently not visible, delay camera reinitialization until visible");
      document.addEventListener("visibilitychange", this.checkCameraVideoStreamAccessIfVisibleListener);
    } else {
      try {
        await this.reinitializeCamera();
      } catch {
        // Ignored
      }
    }
  }

  private async videoTrackMuteRecovery(event: Event): Promise<void> {
    if (this.gui.videoElement.onloadeddata != null) {
      Logger.log(
        Logger.Level.DEBUG,
        `Detected video track "${event.type}" event, delay camera video stream access detection`
      );
      this.setCameraAccessTimeout();

      return;
    }

    const isMuteEvent: boolean = event.type === "mute";
    if (isMuteEvent !== this.gui.isCameraRecoveryVisible()) {
      Logger.log(
        Logger.Level.DEBUG,
        `Detected video track "${event.type}" event, ${isMuteEvent ? "enable" : "disable"} camera recovery`
      );
      this.gui.setCameraRecoveryVisible(isMuteEvent);
    }
  }

  private async triggerManualFocusForContinuous(): Promise<void> {
    if (this.mediaStream == null) {
      return;
    }

    this.manualToAutofocusResumeTimeout = window.setTimeout(async () => {
      await this.triggerFocusMode(MeteringMode.CONTINUOUS);
    }, CameraManager.manualToAutofocusResumeTimeoutMs);

    let manualFocusResetNeeded: boolean = true;
    const videoTracks: MediaStreamTrack[] = this.mediaStream.getVideoTracks();
    // istanbul ignore else
    if (videoTracks.length !== 0 && typeof videoTracks[0].getConstraints === "function") {
      manualFocusResetNeeded =
        videoTracks[0].getConstraints().advanced?.some((constraint: ExtendedMediaTrackConstraintSet) => {
          return constraint.focusMode === MeteringMode.MANUAL;
        }) === true;
    }
    if (manualFocusResetNeeded) {
      await this.triggerFocusMode(MeteringMode.CONTINUOUS);
      this.manualFocusWaitTimeout = window.setTimeout(async () => {
        await this.triggerFocusMode(MeteringMode.MANUAL);
      }, CameraManager.manualFocusWaitTimeoutMs);
    } else {
      await this.triggerFocusMode(MeteringMode.MANUAL);
    }
  }

  private async triggerManualFocusForSingleShot(): Promise<void> {
    window.clearInterval(this.autofocusInterval);

    this.manualToAutofocusResumeTimeout = window.setTimeout(() => {
      this.autofocusInterval = window.setInterval(this.triggerAutoFocus.bind(this), CameraManager.autofocusIntervalMs);
    }, CameraManager.manualToAutofocusResumeTimeoutMs);

    try {
      await this.triggerFocusMode(MeteringMode.SINGLE_SHOT);
    } catch {
      // istanbul ignore next
    }
  }

  private async triggerManualFocus(event?: MouseEvent | TouchEvent): Promise<void> {
    if (event != null) {
      event.preventDefault();
      if (event.type === "touchend" && (<TouchEvent>event).touches.length !== 0) {
        return;
      }
      // Check if we were using pinch-to-zoom
      if (this.pinchToZoomDistance != null) {
        this.pinchToZoomDistance = undefined;

        return;
      }
    }
    window.clearTimeout(this.manualFocusWaitTimeout);
    window.clearTimeout(this.manualToAutofocusResumeTimeout);
    if (this.mediaStream != null && this.mediaTrackCapabilities != null) {
      const focusModeCapability: MeteringMode[] | undefined = this.mediaTrackCapabilities.focusMode;
      if (focusModeCapability instanceof Array) {
        if (
          focusModeCapability.includes(MeteringMode.CONTINUOUS) &&
          focusModeCapability.includes(MeteringMode.MANUAL)
        ) {
          await this.triggerManualFocusForContinuous();
        } else if (focusModeCapability.includes(MeteringMode.SINGLE_SHOT)) {
          await this.triggerManualFocusForSingleShot();
        }
      }
    }
  }

  private triggerZoomStart(event?: TouchEvent): void {
    if (event?.touches.length !== 2) {
      return;
    }
    event.preventDefault();
    this.pinchToZoomDistance = Math.hypot(
      (event.touches[1].screenX - event.touches[0].screenX) / screen.width,
      (event.touches[1].screenY - event.touches[0].screenY) / screen.height
    );
    if (this.mediaStream != null && this.mediaTrackCapabilities?.zoom != null) {
      const videoTracks: MediaStreamTrack[] = this.mediaStream.getVideoTracks();
      // istanbul ignore else
      if (videoTracks.length !== 0 && typeof videoTracks[0].getConstraints === "function") {
        this.pinchToZoomInitialZoom = this.mediaTrackCapabilities.zoom.min;
        const currentConstraints: MediaTrackConstraints = videoTracks[0].getConstraints();
        if (currentConstraints.advanced != null) {
          const currentZoomConstraint: ExtendedMediaTrackConstraintSet | undefined = currentConstraints.advanced.find(
            (constraint) => {
              return "zoom" in constraint;
            }
          );
          if (currentZoomConstraint?.zoom != null) {
            this.pinchToZoomInitialZoom = currentZoomConstraint.zoom;
          }
        }
      }
    }
  }

  private async triggerZoomMove(event?: TouchEvent): Promise<void> {
    if (this.pinchToZoomDistance == null || event?.touches.length !== 2) {
      return;
    }
    event.preventDefault();
    await this.setZoom(
      (Math.hypot(
        (event.touches[1].screenX - event.touches[0].screenX) / screen.width,
        (event.touches[1].screenY - event.touches[0].screenY) / screen.height
      ) -
        this.pinchToZoomDistance) *
        2,
      this.pinchToZoomInitialZoom
    );
  }

  private storeStreamCapabilities(): void {
    // istanbul ignore else
    if (this.mediaStream != null) {
      const videoTracks: MediaStreamTrack[] = this.mediaStream.getVideoTracks();
      // istanbul ignore else
      if (videoTracks.length !== 0 && typeof videoTracks[0].getCapabilities === "function") {
        this.mediaTrackCapabilities = videoTracks[0].getCapabilities();
      }
    }
    if (this.activeCamera != null) {
      this.scanner.reportCameraProperties(
        this.activeCamera.cameraType,
        this.mediaTrackCapabilities?.focusMode == null || // assume the camera supports autofocus by default
          this.mediaTrackCapabilities.focusMode.includes(MeteringMode.CONTINUOUS)
      );
    }
  }

  private setupAutofocus(): void {
    window.clearTimeout(this.manualFocusWaitTimeout);
    window.clearTimeout(this.manualToAutofocusResumeTimeout);
    // istanbul ignore else
    if (this.mediaStream != null && this.mediaTrackCapabilities != null) {
      const focusModeCapability: MeteringMode[] | undefined = this.mediaTrackCapabilities.focusMode;
      if (
        focusModeCapability instanceof Array &&
        !focusModeCapability.includes(MeteringMode.CONTINUOUS) &&
        focusModeCapability.includes(MeteringMode.SINGLE_SHOT)
      ) {
        window.clearInterval(this.autofocusInterval);
        this.autofocusInterval = window.setInterval(
          this.triggerAutoFocus.bind(this),
          CameraManager.autofocusIntervalMs
        );
      }
    }
  }

  private async triggerAutoFocus(): Promise<void> {
    await this.triggerFocusMode(MeteringMode.SINGLE_SHOT);
  }

  private async triggerFocusMode(focusMode: MeteringMode): Promise<void> {
    // istanbul ignore else
    if (this.mediaStream != null) {
      const videoTracks: MediaStreamTrack[] = this.mediaStream.getVideoTracks();
      if (videoTracks.length !== 0 && typeof videoTracks[0].applyConstraints === "function") {
        try {
          await videoTracks[0].applyConstraints({ advanced: <MediaTrackConstraintSet[]>(<unknown>[{ focusMode }]) });
        } catch {
          // Ignored
        }
      }
    }
  }

  private enableTapToFocusListeners(): void {
    ["touchend", "mousedown"].forEach((eventName) => {
      this.gui.videoElement.addEventListener(eventName, this.triggerManualFocusListener);
    });
  }

  private enablePinchToZoomListeners(): void {
    this.gui.videoElement.addEventListener("touchstart", this.triggerZoomStartListener);
    this.gui.videoElement.addEventListener("touchmove", this.triggerZoomMoveListener);
  }

  private disableTapToFocusListeners(): void {
    ["touchend", "mousedown"].forEach((eventName) => {
      this.gui.videoElement.removeEventListener(eventName, this.triggerManualFocusListener);
    });
  }

  private disablePinchToZoomListeners(): void {
    this.gui.videoElement.removeEventListener("touchstart", this.triggerZoomStartListener);
    this.gui.videoElement.removeEventListener("touchmove", this.triggerZoomMoveListener);
  }

  private async initializeCameraAndCheckUpdatedSettings(camera: Camera): Promise<void> {
    try {
      await this.initializeCameraForResolution(camera);
      // Check if due to asynchronous behaviour camera settings were changed while camera was initialized
      if (
        this.selectedCameraSettings !== this.activeCameraSettings &&
        (this.selectedCameraSettings == null ||
          this.activeCameraSettings == null ||
          (<(keyof CameraSettings)[]>Object.keys(this.selectedCameraSettings)).some((cameraSettingsProperty) => {
            return (
              (<CameraSettings>this.selectedCameraSettings)[cameraSettingsProperty] !==
              (<CameraSettings>this.activeCameraSettings)[cameraSettingsProperty]
            );
          }))
      ) {
        this.activeCameraSettings = this.selectedCameraSettings;

        return await this.initializeCameraAndCheckUpdatedSettings(camera);
      }
    } finally {
      this.cameraInitializationPromise = undefined;
    }
  }

  private async handleCameraInitializationError(
    camera: Camera,
    cameraResolutionConstraint: CameraResolutionConstraint,
    error: Error
  ): Promise<void> {
    if (
      !["OverconstrainedError", "NotReadableError"].includes(error.name) ||
      (error.name === "NotReadableError" && cameraResolutionConstraint === CameraResolutionConstraint.NONE)
    ) {
      // Camera is not accessible at all
      Logger.log(Logger.Level.DEBUG, "Camera video stream access failure (unrecoverable error)", camera, error);
      if (error.name !== "NotAllowedError") {
        CameraAccess.markCameraAsInaccessible(camera);
      }
      throw error;
    }
    if (error.name === "OverconstrainedError" && cameraResolutionConstraint === CameraResolutionConstraint.NONE) {
      // Camera device has changed deviceId
      // We can't rely on checking whether the constraint error property in the browsers reporting it is equal to
      // "deviceId" as it is used even when the error is due to a too high resolution being requested.
      // Whenever we get an OverconstrainedError or NotReadableError we keep trying until we are using no constraints
      // except for deviceId (cameraResolutionConstraint is NONE), if an error still happens we know said device doesn't
      // exist anymore (the device has changed deviceId).

      // If it's the initial camera, do nothing
      if (camera.deviceId === "") {
        Logger.log(
          Logger.Level.DEBUG,
          "Camera video stream access failure (no camera with such type error)",
          camera,
          error
        );
        throw error;
      }
      Logger.log(
        Logger.Level.DEBUG,
        "Detected non-existent deviceId error, attempt to find and reaccess updated camera",
        camera,
        error
      );
      const currentCameraDeviceId: string = camera.deviceId;
      // Refresh camera deviceId information
      await CameraAccess.getCameras(true);
      if (currentCameraDeviceId === camera.deviceId) {
        Logger.log(
          Logger.Level.DEBUG,
          "Camera video stream access failure (updated camera not found after non-existent deviceId error)",
          camera,
          error
        );
        CameraAccess.markCameraAsInaccessible(camera);

        throw error;
      } else {
        Logger.log(
          Logger.Level.DEBUG,
          "Updated camera found (recovered from non-existent deviceId error), attempt to access it",
          camera
        );

        return this.initializeCameraForResolution(camera);
      }
    }

    return this.initializeCameraForResolution(camera, cameraResolutionConstraint + 1);
  }

  private async updateStreamForResolution(
    camera: Camera,
    cameraResolutionConstraint: CameraResolutionConstraint
  ): Promise<void> {
    if (this.mediaStream == null) {
      await this.initializeCameraForResolution(camera, cameraResolutionConstraint);
    } else {
      try {
        Logger.log(Logger.Level.DEBUG, "Setting new resolution for active camera video stream...");
        await this.mediaStream
          .getVideoTracks()[0]
          .applyConstraints(CameraAccess.getUserMediaVideoParams(cameraResolutionConstraint));
        await new Promise<void>((resolve) => {
          this.gui.videoElement.addEventListener(
            "timeupdate",
            () => {
              resolve();
            },
            { once: true }
          );
        });
        Logger.log(Logger.Level.DEBUG, "New active camera video stream resolution set");
      } catch (error) {
        if (cameraResolutionConstraint === CameraResolutionConstraint.NONE) {
          throw error;
        } else {
          await this.updateStreamForResolution(camera, cameraResolutionConstraint + 1);
        }
      }
    }
  }

  private async initializeStreamForResolution(
    camera: Camera,
    cameraResolutionConstraint: CameraResolutionConstraint
  ): Promise<void> {
    await this.stopStream();

    try {
      const stream: MediaStream = await CameraAccess.accessCameraStream(cameraResolutionConstraint, camera);
      Logger.log(Logger.Level.DEBUG, "Camera accessed, waiting for camera video stream start...");
      // Detect weird browser behaviour that on unsupported resolution returns a 2x2 video instead
      if (typeof stream.getVideoTracks()[0].getSettings === "function") {
        const mediaTrackSettings: MediaTrackSettings = stream.getVideoTracks()[0].getSettings();
        if (
          mediaTrackSettings.width != null &&
          mediaTrackSettings.height != null &&
          (mediaTrackSettings.width === 2 || mediaTrackSettings.height === 2)
        ) {
          Logger.log(Logger.Level.DEBUG, "Camera video stream access failure (invalid video metadata):", camera);
          if (cameraResolutionConstraint === CameraResolutionConstraint.NONE) {
            throw new CustomError(CameraManager.notReadableErrorParameters);
          } else {
            return this.initializeStreamForResolution(camera, cameraResolutionConstraint + 1);
          }
        }
      }
      this.mediaStream = stream;
      this.mediaStream.getVideoTracks().forEach((track) => {
        // Handle unexpected stream end events
        track.addEventListener("ended", this.videoTrackEndedListener);
        // If the track gets muted we need to give the chance to manually reinitialize the camera to access it again
        // (this is also tried automatically if and when the page is detected to be visible again and the track in a
        // muted state).
        // This will add the listeners only once in case of multiple calls: identical listeners are ignored.
        track.addEventListener("mute", this.videoTrackMuteListener);
        track.addEventListener("unmute", this.videoTrackMuteListener);
      });

      try {
        await this.setupCameraStreamVideo(camera, stream);
      } catch (error) {
        if (cameraResolutionConstraint === CameraResolutionConstraint.NONE) {
          throw error;
        } else {
          return this.initializeStreamForResolution(camera, cameraResolutionConstraint + 1);
        }
      }
    } catch (error) {
      return this.handleCameraInitializationError(camera, cameraResolutionConstraint, error);
    }
  }

  private async initializeCameraForResolution(
    camera: Camera,
    cameraResolutionConstraint?: CameraResolutionConstraint
  ): Promise<void> {
    this.gui.setCameraRecoveryVisible(false);

    const constraint: CameraResolutionConstraint =
      cameraResolutionConstraint ?? this.getInitialCameraResolutionConstraint();

    if (this.mediaStream != null && camera.deviceId !== "" && this.activeCamera?.deviceId === camera.deviceId) {
      // Always reset the stream on iPhone to avoid invalid stream format on resolution change.
      await (BrowserHelper.isIPhone()
        ? this.initializeStreamForResolution(camera, constraint)
        : this.updateStreamForResolution(camera, constraint));
    } else {
      await this.initializeStreamForResolution(camera, constraint);
    }
  }

  private setCameraAccessTimeout(): void {
    window.clearTimeout(this.cameraAccessTimeout);
    this.cameraAccessTimeout = window.setTimeout(async () => {
      if (document.visibilityState !== "visible") {
        Logger.log(Logger.Level.DEBUG, "Page is currently not visible, delay camera video stream access detection");
        document.addEventListener("visibilitychange", this.checkCameraVideoStreamAccessIfVisibleListener);
      } else {
        await this.stopStream(true);
        this.cameraAccessRejectCallback?.(new CustomError(CameraManager.notReadableErrorParameters));
      }
    }, CameraManager.cameraAccessTimeoutMs);
  }

  private checkCameraAccess(camera: Camera): Promise<void> {
    return new Promise((_, reject) => {
      this.cameraAccessRejectCallback = (reason?: Error) => {
        Logger.log(Logger.Level.DEBUG, "Camera video stream access failure (video data load timeout):", camera);
        this.gui.setCameraRecoveryVisible(true);
        reject(reason);
      };
      this.setCameraAccessTimeout();
    });
  }

  private async checkVideoMetadata(camera: Camera): Promise<void> {
    return new Promise((resolve, reject) => {
      this.gui.videoElement.onloadeddata = () => {
        this.gui.videoElement.onloadeddata = null;
        window.clearTimeout(this.cameraAccessTimeout);

        // Detect weird browser behaviour that on unsupported resolution returns a 2x2 video instead
        // Also detect failed camera access with no error but also no video stream provided
        if (
          this.gui.videoElement.videoWidth > 2 &&
          this.gui.videoElement.videoHeight > 2 &&
          this.gui.videoElement.currentTime > 0
        ) {
          this.updateActiveCameraCurrentResolution(camera);
          Logger.log(Logger.Level.DEBUG, "Camera video stream access success:", camera);

          return resolve();
        }

        const videoMetadataCheckStartTime: number = performance.now();

        window.clearInterval(this.videoMetadataCheckInterval);
        this.videoMetadataCheckInterval = window.setInterval(async () => {
          // Detect weird browser behaviour that on unsupported resolution returns a 2x2 video instead
          // Also detect failed camera access with no error but also no video stream provided
          if (
            this.gui.videoElement.videoWidth <= 2 ||
            this.gui.videoElement.videoHeight <= 2 ||
            this.gui.videoElement.currentTime === 0
          ) {
            if (performance.now() - videoMetadataCheckStartTime > CameraManager.videoMetadataCheckTimeoutMs) {
              Logger.log(
                Logger.Level.DEBUG,
                "Camera video stream access failure (valid video metadata timeout):",
                camera
              );
              window.clearInterval(this.videoMetadataCheckInterval);
              await this.stopStream(true);

              return reject(new CustomError(CameraManager.notReadableErrorParameters));
            }

            return;
          }

          window.clearInterval(this.videoMetadataCheckInterval);
          this.updateActiveCameraCurrentResolution(camera);
          Logger.log(Logger.Level.DEBUG, "Camera video stream access success:", camera);

          resolve();
        }, CameraManager.videoMetadataCheckIntervalMs);
      };
    });
  }

  private setupCameraStreamVideo(camera: Camera, stream: MediaStream): Promise<void> {
    // These will add the listeners only once in the case of multiple calls, identical listeners are ignored
    this.gui.videoElement.addEventListener("loadedmetadata", this.postStreamInitializationListener);
    this.gui.videoElement.addEventListener("resize", this.videoResizeListener);

    if (this.tapToFocusEnabled) {
      this.enableTapToFocusListeners();
    }
    if (this.pinchToZoomEnabled) {
      this.enablePinchToZoomListeners();
    }

    const cameraStreamVideoCheck: Promise<void> = Promise.race([
      this.checkCameraAccess(camera),
      this.checkVideoMetadata(camera),
      // tslint:disable-next-line: promise-must-complete
      new Promise<void>((resolve) => {
        this.abortedCameraInitializationResolveCallback = resolve;
      }),
    ]);

    this.gui.videoElement.srcObject = stream;
    this.gui.videoElement.load();
    this.gui.playVideo();

    // Report camera properties already now in order to have type information before autofocus information is available.
    // Even if later the initialization could fail nothing bad results from this.
    this.scanner.reportCameraProperties(camera.cameraType);

    return cameraStreamVideoCheck;
  }
}
