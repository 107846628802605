import * as React from "react";

import { Button, Tile } from "@react-gcc-eds/core";

import SampleBarcode from "../../../assets/sample-barcode.png";
import {
  GeneralKeys,
  NamespaceKeys,
  ScannerSpecificKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";

type ReceiveMaterialSampleBarcodeProps = {
  onClose?: () => void;
};

const ReceiveMaterialSampleBarcode: React.MemoExoticComponent<
  ({ onClose }: ReceiveMaterialSampleBarcodeProps) => React.ReactElement
> = React.memo(
  ({ onClose }: ReceiveMaterialSampleBarcodeProps): React.ReactElement => {
    const { translate } = useTranslation();
    return (
      <div className="sample-barcode-container">
        <Tile className="sample-barcode-container__tile">
          <div className="sample-barcode-container__tile-flex">
            <img
              className="sample-barcode-container__tile-img"
              src={SampleBarcode}
            />
            <h3 className="scanner-box--text">
              {translate(
                NamespaceKeys.ScannerSpecific,
                ScannerSpecificKeys.ShipmentReference
              )}
            </h3>
            <Button
              className="sample-barcode-container__tile-img"
              primary
              onClick={() => onClose?.()}
            >
              {translate(NamespaceKeys.General, GeneralKeys.Close)}
            </Button>
          </div>
        </Tile>
      </div>
    );
  }
);

ReceiveMaterialSampleBarcode.displayName = "ReceiveMaterialSampleBarcode";

export default ReceiveMaterialSampleBarcode;
