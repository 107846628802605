import React, { useContext, useEffect, useState, useMemo } from "react";

import {
  Button,
  Checkbox,
  Column,
  DropdownMenu,
  IDropdownMenuItem,
  Loader,
  Row
} from "@react-gcc-eds/core";
import { Link } from "react-router-dom";

import { LayoutContext } from "../../../../contexts/layout-context";
import {
  GeneralKeys,
  MenuTitleKeys,
  NamespaceKeys,
  ReceivingMaterialSpecificKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import { SortByAscending } from "../../../../utils/array-sort-extensions";
import CenteredContainer from "../../../common/layout/centered-container";
import { ReceiveMaterialContext } from "../contexts/receive-material-context";
import {
  ComponentViews,
  SelectableMaterialTransferOverviewViewModel
} from "../shared/receive-material-models";
import useValidateReceivedMaterial from "../shared/use-validate-received-material";
import ReceiveMaterialValidationSummary from "../transfer-summary/receive-material-validation-summary";
import ReceiveMaterialConfirmValidationDialog from "./receive-material-confirm-validation-dialog";
import ReceiveMaterialDeviationsSummaryDialog from "./receive-material-deviations-summary-dialog";
import ReceiveMaterialTransferDetailsView from "./receive-material-transfer-details-view";
import ReceiveMaterialTransferListItem from "./receive-material-transfer-list-item";

import "./receive-material-transfer-list.scss";

const renderLoading = () => {
  return (
    <CenteredContainer>
      <Loader size="large" />
    </CenteredContainer>
  );
};

type Props = {
  error?: string;
  loading: boolean;
  items: SelectableMaterialTransferOverviewViewModel[];
  selectionMode: boolean;
  onChangeItem: (
    checked: boolean,
    materialTransferId: string,
    externalHandlingUnit: string
  ) => void;
  onChangeSelectAllItems: (newValue: boolean) => void;
  onChangeSelectionMode: (newValue: boolean) => void;
};

const ReceiveMaterialTransferList: React.MemoExoticComponent<
  ({
    error,
    loading,
    items,
    selectionMode,
    onChangeItem,
    onChangeSelectAllItems,
    onChangeSelectionMode
  }: Props) => React.ReactElement | null
> = React.memo(
  ({
    error,
    loading,
    items,
    selectionMode: selectionMode,
    onChangeItem,
    onChangeSelectAllItems,
    onChangeSelectionMode: onChangeSelectionMode
  }: Props) => {
    const { translate } = useTranslation();
    const { setCurrentView } = useContext(ReceiveMaterialContext);
    const [showValidationConfirmDialog, setShowValidationConfirmDialog] =
      React.useState<boolean>(false);

    const { setPageTitle } = useContext(LayoutContext);

    const noItemsSelected = React.useMemo((): boolean => {
      return items?.filter((q) => q.Selected).length > 0 ? false : true;
    }, [items]);

    const [currentMaterialTransferId, setCurrentMaterialTransferId] =
      React.useState<string>("");

    const noTransfersFounded = React.useMemo((): boolean => {
      return items?.length <= 0;
    }, [items]);

    const DoesNotContainsSerializedItems = React.useMemo((): boolean => {
      return (
        items
          ?.filter((q) => q.Selected)
          .find((x) => x.ContainsSerializedItems) === undefined
      );
    }, [items]);

    const allItemsAreSelected = React.useMemo((): boolean => {
      return items?.filter((q) => !q.Selected).length <= 0;
    }, [items]);

    const currentItems =
      React.useMemo((): SelectableMaterialTransferOverviewViewModel[] => {
        if (!selectionMode) return items?.filter((q) => q.Selected);
        else return items;
      }, [items, selectionMode]);

    const [showDeviationSummaryDialog, setShowDeviationSummaryDialog] =
      React.useState<boolean>(false);

    const handleOnClickDeviations = React.useCallback((): void => {
      setCurrentView?.(ComponentViews.ReportDeviation);
    }, [setCurrentView]);

    const handleOnClickAddSerialNumbers = React.useCallback((): void => {
      setCurrentView?.(ComponentViews.ScanSerialNumbers);
    }, [setCurrentView]);

    const handleOnShowDeviationSummary = React.useCallback(
      (materialTransferId: string): void => {
        setShowDeviationSummaryDialog(true);
        setCurrentMaterialTransferId(materialTransferId);
      },
      []
    );

    const addSerialNumberText = React.useMemo((): string => {
      return translate(
        NamespaceKeys.ReceivingMaterialSpecific,
        ReceivingMaterialSpecificKeys.AddSerialNumber
      );
    }, [translate]);

    const deviationsText = React.useMemo((): string => {
      return translate(NamespaceKeys.General, GeneralKeys.Deviations);
    }, [translate]);

    const handleOnClickDropdown = React.useCallback(
      (item: IDropdownMenuItem): void => {
        if (addSerialNumberText === item.item) {
          handleOnClickAddSerialNumbers();
        }

        if (deviationsText === item.item) {
          handleOnClickDeviations();
        }
      },
      [
        handleOnClickAddSerialNumbers,
        handleOnClickDeviations,
        deviationsText,
        addSerialNumberText
      ]
    );

    const {
      success: validationSuccess,
      error: validationError,
      loading: validationLoading,
      getData
    } = useValidateReceivedMaterial();

    const handleOnClickValidate = React.useCallback((): void => {
      setShowValidationConfirmDialog(true);
    }, []);

    const handleOnClickBackToSelection = React.useCallback((): void => {
      onChangeSelectionMode(true);
    }, [onChangeSelectionMode]);

    const handleOnChangeSelectionMode = React.useCallback((): void => {
      onChangeSelectionMode(false);
    }, [onChangeSelectionMode]);

    const renderConfirmValidationDialog = (): React.ReactElement => {
      return (
        <ReceiveMaterialConfirmValidationDialog
          onValidate={(data) => {
            setShowValidationConfirmDialog(false);
            getData(data);
          }}
          onCancel={() => {
            setShowValidationConfirmDialog(false);
          }}
        />
      );
    };

    const renderDeviationSummaryDialog = (): React.ReactElement => {
      return (
        <ReceiveMaterialDeviationsSummaryDialog
          materialTransferId={currentMaterialTransferId}
          onCancel={() => {
            setShowDeviationSummaryDialog(false);
          }}
        />
      );
    };

    const renderError = (): React.ReactElement => {
      return (
        <CenteredContainer>
          <div className="empty-state">
            <div className="message">
              <p>
                {translate(
                  NamespaceKeys.ReceivingMaterialSpecific,
                  ReceivingMaterialSpecificKeys.ShipmentNumberReferenceNotFound
                )}
              </p>
              <Link to={`/receivematerial/`} className="btn">
                {translate(NamespaceKeys.General, GeneralKeys.Retry)}
              </Link>
            </div>
          </div>
        </CenteredContainer>
      );
    };

    const [currentItem, setCurrentItem] =
      useState<SelectableMaterialTransferOverviewViewModel>();

    const handleOnClickDetails = React.useCallback((item) => {
      setCurrentItem(item);
    }, []);

    const handleOnClickBackToList = React.useCallback(() => {
      setCurrentItem(undefined);
    }, []);

    const dropdownMenuItems = [
      {
        disabled: DoesNotContainsSerializedItems,
        item: addSerialNumberText
      },
      {
        separator: true
      },
      {
        item: deviationsText
      }
    ];

    const sortedCurrentItems:
      | SelectableMaterialTransferOverviewViewModel[]
      | undefined = useMemo(
      () =>
        currentItems.sort(
          SortByAscending(
            (
              materialTransferViewModel: SelectableMaterialTransferOverviewViewModel
            ) => materialTransferViewModel.ExternalHandlingUnit
          )
        ),
      [currentItems]
    );

    const renderTransfersList = () => (
      <div className="rm-container list-transfers">
        <div className="rm-header">
          {selectionMode ? (
            <Checkbox
              text={translate(
                NamespaceKeys.ReceivingMaterialSpecific,
                ReceivingMaterialSpecificKeys.Received
              )}
              checked={allItemsAreSelected}
              onChange={onChangeSelectAllItems}
            />
          ) : (
            <span>
              {translate(
                NamespaceKeys.ReceivingMaterialSpecific,
                ReceivingMaterialSpecificKeys.Received
              )}
            </span>
          )}
        </div>
        <div className="rm-body">
          <div className="list-transfers">
            {sortedCurrentItems?.map((item, index) => {
              return (
                <div key={item.MaterialTransferId + item.ExternalHandlingUnit}>
                  <ReceiveMaterialTransferListItem
                    item={item}
                    index={index} // Directly use the index provided by map
                    selectionMode={selectionMode}
                    onClickDetails={handleOnClickDetails}
                    onShowDeviationSummary={handleOnShowDeviationSummary}
                    onChangeItem={onChangeItem}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="rm-footer">
          <div className="rm-row">
            {selectionMode ? (
              <Row className="">
                <Column sm={6}>
                  <Link to={`/receivematerial/`} className="btn">
                    {translate(NamespaceKeys.General, GeneralKeys.Back)}
                  </Link>
                </Column>

                <Column sm={6} className="justify-content-right">
                  <Button
                    disabled={noItemsSelected}
                    className="primary"
                    onClick={handleOnChangeSelectionMode}
                  >
                    {translate(NamespaceKeys.General, GeneralKeys.Next)}
                  </Button>
                </Column>
              </Row>
            ) : (
              <Row className="rm-transfer-list-row">
                <Column className="rm-back-btn-column">
                  <Button onClick={handleOnClickBackToSelection}>
                    {translate(NamespaceKeys.General, GeneralKeys.Back)}
                  </Button>
                </Column>

                <Column className="rm-validate-btn-column">
                  <Button
                    disabled={noItemsSelected}
                    className="primary"
                    onClick={handleOnClickValidate}
                  >
                    {translate(NamespaceKeys.General, GeneralKeys.Validate)}
                  </Button>
                </Column>
                <Column className="rm-menu-column">
                  <DropdownMenu
                    className=""
                    menuItems={dropdownMenuItems}
                    onChange={handleOnClickDropdown}
                  />
                </Column>
              </Row>
            )}
          </div>
        </div>
      </div>
    );

    const renderDetailsView = () => {
      return (
        <ReceiveMaterialTransferDetailsView
          item={currentItem}
          onClickBackToList={handleOnClickBackToList}
        />
      );
    };

    useEffect(() => {
      setPageTitle &&
        setPageTitle({
          title: translate(
            NamespaceKeys.MenuTitles,
            MenuTitleKeys.ReceiveMaterialListOverviewTitle
          )
        });
    }, [translate, setPageTitle, currentItem]);

    return error || validationError
      ? renderError()
      : loading || noTransfersFounded || validationLoading
      ? renderLoading()
      : !noTransfersFounded && (
          <>
            {validationSuccess ? (
              <ReceiveMaterialValidationSummary />
            ) : (
              <>
                {currentItem && renderDetailsView()}
                {!currentItem && renderTransfersList()}
                {(showValidationConfirmDialog &&
                  renderConfirmValidationDialog()) ||
                  null}
                {(showDeviationSummaryDialog &&
                  renderDeviationSummaryDialog()) ||
                  null}
              </>
            )}
          </>
        );
  }
);

ReceiveMaterialTransferList.displayName = "ReceiveMaterialTransferList";

export default ReceiveMaterialTransferList;
