import React, { useCallback, ReactNode, useMemo } from "react";

import { Switch } from "@react-gcc-eds/core";

import { ChecklistItemOptionViewModel } from "../../../../domain/client-obsolete";
import ChecklistStandardItem from "./generic/checklist-standard-item";
import { SingleItemProps, SingleItemComponent } from "./types";

const MultiSelectElement: SingleItemComponent = ({
  item,
  onValueChanged
}: SingleItemProps) => {
  const isChecked = useCallback(
    (optionModel: ChecklistItemOptionViewModel): boolean =>
      item.Values?.includes(optionModel.Option ?? "") ?? false,
    [item.Values]
  );

  const handleOnChange = useCallback(
    (optionModel: ChecklistItemOptionViewModel): void => {
      if (!optionModel.Option) return;
      const current = item.Values?.slice(0) ?? [];

      if (isChecked(optionModel)) {
        current.splice(current.indexOf(optionModel.Option), 1);
      } else {
        current.push(optionModel.Option);
      }

      onValueChanged({
        ...item,
        Values: current
      });
    },
    [isChecked, item, onValueChanged]
  );
  const children = useMemo<ReactNode>(
    () =>
      item.Options?.map((o: ChecklistItemOptionViewModel) => {
        const callback = (): void => handleOnChange(o);

        return (
          <div onClick={callback} className="switch-container" key={o.Id}>
            <div className="title">{o.Option}</div>
            <Switch
              key={o.Option}
              value={isChecked(o)}
              onChange={callback}
              onLabel=""
              offLabel=""
            />
          </div>
        );
      }),
    [item.Options, isChecked, handleOnChange]
  );

  return (
    <ChecklistStandardItem item={item} onValueChanged={onValueChanged}>
      {children}
    </ChecklistStandardItem>
  );
};

export default MultiSelectElement;
