import React from "react";

import { Tile, Row, Icon, Loader, Column } from "@react-gcc-eds/core";
import { Link } from "react-router-dom";

import {
  BillOfMaterialItemDto,
  BillOfMaterialDetailedModel,
  BomStatus
} from "../../../../domain/client-customer";
import {
  ComponentSpecificKeys,
  NamespaceKeys,
  BomSpecificKeys,
  GeneralKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import CenteredContainer from "../../../common/layout/centered-container";
import TitleValuePair from "../../../common/layout/title-value-pair";

type Props = {
  siteProjectId: string | undefined;
  bom: BillOfMaterialDetailedModel | undefined;
  errorMessage: ComponentSpecificKeys | undefined;
  loading: boolean;
};

const BomTileView = ({
  siteProjectId,
  bom,
  errorMessage,
  loading
}: Props): React.ReactElement => {
  const { translate } = useTranslation();

  const getBomStatus = (): string => {
    if (bom?.StatusOfBoM) {
      return bom.StatusOfBoM === BomStatus.Empty ? "red" : "green";
    }
    return "";
  };

  const renderBomItemSummary = (
    items: BillOfMaterialItemDto[]
  ): JSX.Element => {
    const itemCountByCommodity = items.reduce((previous, currentValue) => {
      if (previous[currentValue.DeliveryProductListItem?.Commodity ?? "none"]) {
        previous[currentValue.DeliveryProductListItem?.Commodity ?? "none"]++;
      } else {
        previous[currentValue.DeliveryProductListItem?.Commodity ?? "none"] = 1;
      }
      return previous;
    }, {} as { [key: string]: number });
    return (
      <div>
        {Object.keys(itemCountByCommodity).map(
          (commodity: string): React.ReactElement => (
            <div className="kpi" key={commodity}>
              <div className="item text-x">
                {itemCountByCommodity[commodity]}
              </div>
              <div className="item">
                <div className="kpi-name">{commodity}</div>
              </div>
            </div>
          )
        )}
      </div>
    );
  };

  return (
    <Row>
      {loading ? (
        <Tile
          title={translate(
            NamespaceKeys.BomSpecific,
            BomSpecificKeys.BillOfMaterial
          )}
        >
          <CenteredContainer>
            <Loader size="medium" />
          </CenteredContainer>
        </Tile>
      ) : !bom ? (
        <Tile
          title={translate(
            NamespaceKeys.BomSpecific,
            BomSpecificKeys.BillOfMaterial
          )}
          subtitle={
            errorMessage &&
            translate(NamespaceKeys.ComponentSpecific, errorMessage)
          }
        >
          {translate(NamespaceKeys.General, GeneralKeys.PleaseTryAgainLater)}
        </Tile>
      ) : (
        <Tile
          title={translate(
            NamespaceKeys.BomSpecific,
            BomSpecificKeys.BillOfMaterial
          )}
        >
          <TitleValuePair
            title={translate(NamespaceKeys.BomSpecific, BomSpecificKeys.Status)}
            value={
              <>
                <Icon name="view-list" className={getBomStatus()} />{" "}
                {bom.StatusOfBoM}
              </>
            }
          />
          <Row>
            <Column sm={6} md={6}>
              <div className="bom-tile">
                <div className="bom-summary">
                  {renderBomItemSummary(bom.BillOfMaterialItems || [])}
                </div>
              </div>
            </Column>
            <Column sm={6} md={6}>
              <div className="bom-view-btn">
                <Link
                  to={`/siteProjects/billofmaterial/${siteProjectId}`}
                  className="btn primary"
                >
                  {translate(NamespaceKeys.BomSpecific, BomSpecificKeys.View)}
                </Link>
                <div />
              </div>
            </Column>
          </Row>
        </Tile>
      )}
    </Row>
  );
};

export default BomTileView;
