import React, { useCallback, useRef } from "react";

import { Icon } from "@react-gcc-eds/core";

import "./file-upload-button-component.scss";

type Props = {
  allowedFileFormats: string[];
  disabled?: boolean;
  multiple?: boolean;
  title: string;
  onChange: (fileList: File[]) => void;
};
const extractFilesFromFileList = (fileList: FileList | null): File[] => {
  const result: File[] = [];
  if (!fileList) return result;
  for (let i = 0; i < fileList.length; i++) {
    const item = fileList.item(i);
    if (item) {
      result.push(item);
    }
  }
  return result;
};
const FileUploadButtonComponent = ({
  title,
  onChange,
  multiple,
  disabled,
  allowedFileFormats
}: Props): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      onChange(extractFilesFromFileList(event.target.files));
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    },
    [onChange]
  );
  return (
    <label className="file-upload-btn-container">
      <input
        type="file"
        onChange={handleOnChange}
        accept={allowedFileFormats.join(", ")}
        multiple={multiple}
        disabled={disabled}
        ref={inputRef}
      />
      <div className={`btn ${disabled ? "file-upload-btn-disabled" : ""}`}>
        <Icon name="upload" />
        {title}
      </div>
    </label>
  );
};

export default FileUploadButtonComponent;
