import React, { ReactElement, useCallback, useState, useEffect } from "react";

import { MaterialTransferItemStatus } from "../../../../../domain/client-customer";
import {
  NonSerializedItemState,
  SerializedItemState
} from "../../../receive-material-overview/shared/receive-material-models";
import { ValidationQuantities } from "../quantity-validator/quantity-validator-component";
import ReviewView from "./review-view";

type Props = {
  posting: boolean;
  serializedItems: SerializedItemState[];
  nonSerializedItems: NonSerializedItemState[];
};

const ReviewComponent = ({
  posting,
  serializedItems,
  nonSerializedItems
}: Props): ReactElement => {
  const getStatusAggregate = useCallback(
    (
      serializedItems: SerializedItemState[],
      nonSerializedItems: NonSerializedItemState[]
    ) => {
      const statusAggregate: ValidationQuantities = {
        Ok: 0,
        Damaged: 0,
        Missing: 0,
        Excess: 0,
        Incorrect: 0
      };

      Object.values(MaterialTransferItemStatus).forEach((status) => {
        const numberOfSerializedItems = serializedItems.filter(
          (item) => item.status === status
        ).length;
        const numberOfNonSerializedItems = nonSerializedItems
          .map((item) => item.validationQuantities?.[status] ?? 0)
          .reduce((sum, quantity) => sum + quantity, 0);

        statusAggregate[status] =
          numberOfSerializedItems + numberOfNonSerializedItems;
      });

      return statusAggregate;
    },
    []
  );

  const [statusAggregate, setStatusAggregate] = useState<ValidationQuantities>(
    getStatusAggregate(serializedItems, nonSerializedItems)
  );

  useEffect(() => {
    setStatusAggregate(getStatusAggregate(serializedItems, nonSerializedItems));
  }, [getStatusAggregate, serializedItems, nonSerializedItems]);

  return <ReviewView statusAggregate={statusAggregate} posting={posting} />;
};

export default ReviewComponent;
