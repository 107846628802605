import React, { ReactElement } from "react";

import { Button, Dialog } from "@react-gcc-eds/core";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import {
  ComponentSpecificKeys,
  NamespaceKeys,
  GeneralKeys
} from "../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../translation/translation-utils";
import { QuantityValidationStateItem } from "./quantity-validator-component";
import QuantityValidatorItem from "./quantity-validator-item";

import "./quantity-validator.scss";

type Props = {
  nonValidatedItems: QuantityValidationStateItem[];
  validatedItems: QuantityValidationStateItem[];
  errorMessage: ComponentSpecificKeys | undefined;
  showApproveAllItemsDialog: boolean;
  onItemChange: (item: QuantityValidationStateItem) => void;
  onSmartScanClicked: () => void;
  onApproveAllItemsDialogCancel: () => void;
  onApproveAllItemsClicked: () => void;
};
const QuantityValidator = ({
  nonValidatedItems,
  validatedItems,
  errorMessage,
  showApproveAllItemsDialog,
  onSmartScanClicked,
  onItemChange,
  onApproveAllItemsDialogCancel,
  onApproveAllItemsClicked
}: Props): ReactElement => {
  const { translate } = useTranslation();

  return errorMessage ? (
    <p>{translate(NamespaceKeys.ComponentSpecific, errorMessage)}</p>
  ) : (
    <>
      {(nonValidatedItems.length && (
        <>
          <div className="quantity-validator-title wizard-page-title">
            <span className="title-text">
              {translate(
                NamespaceKeys.ComponentSpecific,
                ComponentSpecificKeys.MaterialToValidate
              )}
            </span>
            <div className="actions">
              <Button onClick={onSmartScanClicked} iconName="camera-photo">
                {translate(
                  NamespaceKeys.ComponentSpecific,
                  ComponentSpecificKeys.MaterialTransferValidationSmartScannerButtonLabel
                )}
              </Button>
            </div>
          </div>
        </>
      )) ||
        null}
      <TransitionGroup>
        {nonValidatedItems.map((item) => (
          <CSSTransition
            key={`${item.dplItem?.Id ?? 0}-${item.materialCategory}`}
            timeout={300}
            classNames="item"
          >
            <QuantityValidatorItem
              item={item}
              isValidated={false}
              onItemChange={onItemChange}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
      {(validatedItems.length && (
        <>
          <div className="quantity-validator-title validated-items-title">
            {translate(
              NamespaceKeys.ComponentSpecific,
              ComponentSpecificKeys.ValidatedMaterial
            )}
          </div>
        </>
      )) ||
        null}
      <TransitionGroup>
        {validatedItems.map((item) => (
          <CSSTransition
            key={`${item.dplItem?.Id ?? 0}-${item.materialCategory}`}
            timeout={300}
            classNames="item"
          >
            <QuantityValidatorItem
              item={item}
              isValidated={true}
              onItemChange={onItemChange}
            />
          </CSSTransition>
        ))}
      </TransitionGroup>
      {(showApproveAllItemsDialog && (
        <Dialog
          title={translate(
            NamespaceKeys.ComponentSpecific,
            ComponentSpecificKeys.MaterialTransferValidationValidateAllQuantitiesDialogTitle
          )}
          buttons={[
            <Button
              key="ValidateAllItems"
              onClick={onApproveAllItemsClicked}
              primary
            >
              {translate(
                NamespaceKeys.ComponentSpecific,
                ComponentSpecificKeys.MaterialTransferValidationValidateAllQuantitiesApproveButtonLabel
              )}
            </Button>,
            <Button key="Cancel" onClick={onApproveAllItemsDialogCancel}>
              {translate(NamespaceKeys.General, GeneralKeys.Cancel)}
            </Button>
          ]}
        >
          <p>
            {translate(
              NamespaceKeys.ComponentSpecific,
              ComponentSpecificKeys.MaterialTransferValidationValidateAllQuantitiesDialogMessage
            )}
          </p>
        </Dialog>
      )) ||
        null}
    </>
  );
};
export default QuantityValidator;
