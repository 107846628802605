import React, { useState } from "react";
import cx from "classnames";
import IMenuNode from "./IMenyNode";

const MenuNode = ({ title, children, className }: IMenuNode) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <li>
      <span
        role="presentation"
        className={cx("item title", { opened: expanded }, className)}
        onKeyPress={() => setExpanded(!expanded)}
        onClick={() => setExpanded(!expanded)}
      >
        {title}
      </span>
      <ul>{children}</ul>
    </li>
  );
};

export default MenuNode;
