import React from "react";

interface Props {
  children: JSX.Element[] | JSX.Element;
}

const CenteredContainer = (props: Props): JSX.Element => {
  return (
    <div className="centered-container">
      <div className="centered-content">{props.children}</div>
    </div>
  );
};

export default CenteredContainer;
