import React, { useCallback, useMemo } from "react";

import { Tile } from "@react-gcc-eds/core";
import GoogleMapReact, { Maps, MapOptions, Coords } from "google-map-react";

import {
  NamespaceKeys,
  SiteSpecificKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import { lightStyle, darkStyle } from "./map-style";
import { DarkMarker, LightMarker } from "./markers";

import "./map-tile.scss";

type Props = {
  lat: number;
  lng: number;
  zoom: number;
  city: string;
  theme: string;
  mapsKey: string;
};

/* eslint-disable*/
// Correct type for map and maps have not been created in the maps types package yet.
// Remove this when package has been updated.
type MapProps = {
  map: any;
  maps: any;
};
/* eslint-enable */

// Suppressing this since maps component will not render it without specifying coords props even though we do not use it
// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const Marker = (_: Coords): JSX.Element => <div className="pulse" />;

const MapTileView = ({
  lat,
  lng,
  zoom,
  city,
  theme,
  mapsKey
}: Props): JSX.Element => {
  const { translate } = useTranslation();
  const title = useMemo<string>(
    () => translate(NamespaceKeys.SiteSpecific, SiteSpecificKeys.GoToMap),
    [translate]
  );

  const createMapOptions = useCallback(
    (maps: Maps): MapOptions => {
      return {
        styles: theme === "dark" ? darkStyle : lightStyle,
        zoomControlOptions: {
          position: maps?.ControlPosition.RIGHT_CENTER
        },
        mapTypeControlOptions: {
          position: maps.ControlPosition.TOP_LEFT
        },
        mapTypeControl: true,
        streetViewControl: true,
        controlSize: 20
      };
    },
    [theme]
  );

  const renderMarkers = useCallback(
    ({ map, maps }: MapProps): void => {
      const image = new maps.MarkerImage(
        theme === "dark" ? DarkMarker : LightMarker,
        null,
        null,
        null,
        new maps.Size(50, 50)
      );

      const marker = new maps.Marker({
        position: { lat: lat, lng: lng },
        map,
        title: title,
        icon: image,
        optimized: false,
        url: `https://maps.google.com/maps?z=12&t=m&q=loc:${lat}+${lng}`
      });

      marker.addListener("click", () => {
        window.open(marker.url, "_blank");
      });
    },
    [lat, lng, theme, title]
  );

  return (
    <Tile title="Location" subtitle={`${city}`}>
      <div className="map-wrapper">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: mapsKey,
            language: "en"
          }}
          defaultCenter={{ lat: lat, lng: lng }}
          defaultZoom={zoom}
          options={createMapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={renderMarkers}
        >
          <Marker lat={lat} lng={lng} />
        </GoogleMapReact>
      </div>
    </Tile>
  );
};

export default MapTileView;
