import React from "react";

import { useParams } from "react-router";

import BomViewer from "../components/site-projects/bom-viewer";
import UrlParameters from "../domain/enums/url-parameters";

type MatchProps = {
  [UrlParameters.siteProjectId]?: string;
};

const BomViewerRoute = (): React.ReactElement | null => {
  const params = useParams<MatchProps>();
  const siteProjectId = params.siteProjectId;

  return (siteProjectId && <BomViewer siteProjectId={siteProjectId} />) || null;
};

export default BomViewerRoute;
