import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  ReactNode,
  useContext
} from "react";

import {
  Row,
  Tile,
  TextField,
  Dropdown,
  IDropdownItem,
  Pill
} from "@react-gcc-eds/core";

import { CustomerContext } from "../../../contexts/customer-context";
import { MaterialTransferOverviewViewModel } from "../../../domain/client-customer";
import {
  NamespaceKeys,
  MaterialSpecificKeys,
  GeneralKeys,
  SiteSpecificKeys
} from "../../../translation/dictionary-keys";
import { useTranslation } from "../../../translation/translation-utils";
import TitleValuePair from "../../common/layout/title-value-pair";

enum MaterialTransferDirection {
  Receiving = "Receiving",
  HandingOver = "HandingOver"
}

type Props = {
  hidden?: boolean;
  items: MaterialTransferOverviewViewModel[] | undefined;
  onFiltersChanged: (
    filteredItems: MaterialTransferOverviewViewModel[]
  ) => void;
};

const MaterialTransferOverviewFilters: (props: Props) => JSX.Element | null = ({
  hidden,
  items,
  onFiltersChanged
}: Props) => {
  const { translate } = useTranslation();
  const { currentUser } = useContext(CustomerContext);
  const userCompanyName: string | undefined = useMemo(
    () => currentUser?.CompanyName,
    [currentUser]
  );
  const [siteProjectAndSiteNameQuery, setSiteProjectAndSiteNameQuery] =
    useState<string>("");
  const [directionSelection, setDirectionSelection] =
    useState<MaterialTransferDirection>();
  const [subregionSelection, setSubregionSelection] = useState<string>();
  const [reasonSelection, setReasonSelection] = useState<string>();

  const handleOnSearchChange: (newValue: string) => void = useCallback(
    (newValue) => setSiteProjectAndSiteNameQuery(newValue),
    []
  );

  const noFilterTranslation = useMemo<string>(
    () => translate(NamespaceKeys.General, GeneralKeys.NoFilter),
    [translate]
  );

  const reasonSelectionTranslation = useMemo<string>(
    () =>
      reasonSelection
        ? translate(
            NamespaceKeys.MaterialSpecific,
            MaterialSpecificKeys[
              reasonSelection as keyof typeof MaterialSpecificKeys
            ]
          )
        : noFilterTranslation,
    [translate, reasonSelection, noFilterTranslation]
  );

  const clearFilterTranslation = useMemo<string>(
    () => translate(NamespaceKeys.General, GeneralKeys.ClearFilter),
    [translate]
  );

  useEffect(() => {
    let filteredItems = items ?? [];
    if (siteProjectAndSiteNameQuery && siteProjectAndSiteNameQuery.length) {
      const lowercaseSearchQuery = siteProjectAndSiteNameQuery.toLowerCase();
      filteredItems = filteredItems.filter(
        (f) =>
          (f.SiteName &&
            f.SiteName.toLowerCase().includes(lowercaseSearchQuery)) ||
          (f.SiteProjectName &&
            f.SiteProjectName.toLowerCase().includes(lowercaseSearchQuery))
      );
    }
    if (directionSelection) {
      filteredItems = filteredItems.filter(
        (item) =>
          (directionSelection === MaterialTransferDirection.Receiving &&
            item.ToCompanyName === userCompanyName) ||
          (directionSelection === MaterialTransferDirection.HandingOver &&
            item.FromCompanyName === userCompanyName)
      );
    }
    if (subregionSelection) {
      filteredItems = filteredItems.filter(
        (item) => item.Subregion === subregionSelection
      );
    }
    if (reasonSelection) {
      filteredItems = filteredItems.filter(
        (item) => item.Reason === reasonSelection
      );
    }
    onFiltersChanged(filteredItems);
  }, [
    items,
    onFiltersChanged,
    siteProjectAndSiteNameQuery,
    directionSelection,
    subregionSelection,
    reasonSelection,
    userCompanyName
  ]);

  const siteProjectAndSiteNameField = useMemo<JSX.Element>(
    () => (
      <TextField
        key="name-filter"
        value={siteProjectAndSiteNameQuery}
        onChange={handleOnSearchChange}
        placeholder={translate(
          NamespaceKeys.MaterialSpecific,
          MaterialSpecificKeys.SearchSiteProjectOrSiteName
        )}
        fullWidth
        className="freetext-filter"
      />
    ),
    [siteProjectAndSiteNameQuery, handleOnSearchChange, translate]
  );

  const onDirectionFieldChange = useCallback(
    (toggle: boolean, direction: MaterialTransferDirection): void =>
      setDirectionSelection(toggle ? direction : undefined),
    []
  );

  const onSubregionFieldChange = useCallback(
    (item: IDropdownItem): void => setSubregionSelection(item.value),
    []
  );

  const directionField = useMemo<ReactNode>(() => {
    const uniqueDirectionItems: MaterialTransferDirection[] = Object.values(
      MaterialTransferDirection
    );

    const pills: ReactNode = uniqueDirectionItems.map((direction) => {
      const translationKey: MaterialSpecificKeys | undefined =
        (direction &&
          MaterialSpecificKeys[
            direction as keyof typeof MaterialSpecificKeys
          ]) ||
        undefined;

      if (!translationKey) return undefined;

      const toggled = directionSelection === direction;

      return (
        <Pill
          key={direction}
          toggled={toggled}
          onToggle={(toggle: boolean): void =>
            onDirectionFieldChange(toggle, direction)
          }
        >
          {translate(NamespaceKeys.MaterialSpecific, translationKey)}
        </Pill>
      );
    });

    return <div className="material-transfers-pill-filter">{pills}</div>;
  }, [onDirectionFieldChange, directionSelection, translate]);

  const subregionField = useMemo<JSX.Element>(() => {
    const uniqueSubregionItems: MaterialTransferOverviewViewModel[] = (
      items ?? []
    ).reduce(
      (
        aggregate: MaterialTransferOverviewViewModel[],
        currentItem: MaterialTransferOverviewViewModel
      ) => {
        const itemIsInAggregate = !!aggregate.find(
          (item) => item.Subregion === currentItem.Subregion
        );
        if (itemIsInAggregate) return aggregate;
        return [...aggregate, currentItem];
      },
      []
    );

    const options: IDropdownItem[] = uniqueSubregionItems
      .map((item) => {
        return {
          title: item.Subregion ?? clearFilterTranslation,
          value: item.Subregion ?? clearFilterTranslation
        };
      })
      .reduce(
        (
          aggregate: IDropdownItem[],
          currentItem: IDropdownItem | undefined
        ) => {
          if (!currentItem) return aggregate;
          return [...aggregate, currentItem];
        },
        []
      );
    const optionsWithDefault: IDropdownItem[] = [
      { title: clearFilterTranslation, value: undefined },
      ...options
    ];
    const value = (
      <Dropdown
        label={subregionSelection ?? noFilterTranslation}
        key="subregion-filter"
        items={subregionSelection ? optionsWithDefault : options}
        onItemClick={onSubregionFieldChange}
      />
    );
    return (
      <TitleValuePair
        title={translate(
          NamespaceKeys.SiteSpecific,
          SiteSpecificKeys.Subregion
        )}
        value={value}
      />
    );
  }, [
    items,
    onSubregionFieldChange,
    subregionSelection,
    clearFilterTranslation,
    noFilterTranslation,
    translate
  ]);

  const onTransferTypeSelectionChanged = useCallback(
    (selection: IDropdownItem): void => {
      setReasonSelection(selection.value as string);
    },
    []
  );

  const transferTypeField: JSX.Element = useMemo<JSX.Element>(() => {
    const uniqueTransferTypes: string[] = (items ?? []).reduce(
      (
        aggregate: string[],
        currentItem: MaterialTransferOverviewViewModel
      ): string[] => {
        const valueExistsInArray = aggregate.some(
          (reason) => reason === currentItem.Reason
        );
        if (!currentItem.Reason || valueExistsInArray) return aggregate;
        return [...aggregate, currentItem.Reason];
      },
      []
    );
    const options: IDropdownItem[] = uniqueTransferTypes.map(
      (transferType: string): IDropdownItem => ({
        value: transferType,
        title: translate(
          NamespaceKeys.MaterialSpecific,
          MaterialSpecificKeys[
            transferType as keyof typeof MaterialSpecificKeys
          ]
        )
      })
    );
    const optionsWithDefault: IDropdownItem[] = [
      { title: clearFilterTranslation, value: undefined }
    ].concat(options);
    const dropdownElement = (
      <Dropdown
        label={reasonSelectionTranslation}
        key="subregion-filter"
        items={reasonSelection ? optionsWithDefault : options}
        onItemClick={onTransferTypeSelectionChanged}
      />
    );
    return (
      <TitleValuePair
        title={translate(
          NamespaceKeys.MaterialSpecific,
          MaterialSpecificKeys.Reason
        )}
        value={dropdownElement}
      />
    );
  }, [
    onTransferTypeSelectionChanged,
    reasonSelection,
    reasonSelectionTranslation,
    items,
    translate,
    clearFilterTranslation
  ]);

  return hidden ? null : (
    <Row className="filters-row">
      <Tile
        sm={12}
        title={translate(NamespaceKeys.General, GeneralKeys.Filters)}
      >
        {siteProjectAndSiteNameField}
        {subregionField}
        {transferTypeField}
        {directionField}
      </Tile>
    </Row>
  );
};

export default MaterialTransferOverviewFilters;
