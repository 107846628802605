import React from "react";
import cx from "classnames";
import * as utils from "../../utils/utils";

interface Props {
  titles: string[];
  currentStep: number;
  completedPages: number[];
  onStepClick: (e: number) => void;
  onLeftClick: () => void;
  onRightClick: () => void;
  visibleSteps: string[];
  stepLeftDisabled: boolean;
  stepRightDisabled: boolean;
  stepsDisabled: boolean;

  hideStepLeft: boolean;
  hideStepRight:boolean;
  hideSteps: boolean;

}

const WizardSteps = ({
  titles,
  currentStep,
  completedPages,
  onStepClick,
  visibleSteps,
  onLeftClick,
  onRightClick,
  stepLeftDisabled = false,
  stepRightDisabled = false,
  stepsDisabled = false,
  hideStepLeft = false,
  hideStepRight = false,
  // @ts-ignore
  hideSteps = false,
}: Props) => {
  
  const arrowLeft = (
    <div
      role="presentation"
      style={{ visibility: !visibleSteps.includes(titles[0]) ? "visible" : "hidden" }}
      className={cx("arrowLeft arrow left visible", {noHover: stepLeftDisabled})}
      onClick={() => !stepLeftDisabled && onLeftClick()}
    >
      <i className="icon icon-arrow-left" />
    </div>
  );

  const arrowRight = (
    <div
      role="presentation"
      style={{
        visibility: !visibleSteps.includes(titles[titles.length - 1]) ? "visible" : "hidden"
      }}
      className={cx("arrowRight arrow right visible", {noHover: stepRightDisabled})}
      onClick={() => !stepRightDisabled && onRightClick()}
    >
      <i className="icon icon-arrow-right" />
    </div>
  );

  const steps = titles.map((title: string, i: number) => {
    const isCompleted =  completedPages.includes(i);
    const isCurrentPage = i === currentStep;
    const isVisible = visibleSteps.includes(titles[i]);

    return (
      <div
        key={utils.uniqueId()}
        role="presentation"
        className={cx(
          "step",
          { current: isCurrentPage },
          { completed: isCompleted },
          { visible: isVisible },
          { hidden: !isVisible },
          { noHover: stepsDisabled}
        )}
        onClick={() => (isCompleted && !stepsDisabled ? onStepClick(i) : undefined)}
      >
        <div className="prevbar" />
        <div className="nextbar" />
        <div className="sphere" />
        <div className="description">
          {i + 1}. {title}
        </div>
      </div>
    );
  });

  return !hideSteps ? (
    <div className="wizard-steps">
      {!hideStepLeft && arrowLeft}
      <div className="steps">{steps}</div>
      {!hideStepRight && arrowRight}
    </div>
  ) : <span></span> ;
  
};

export default WizardSteps;
