export const toggleNavigation = () => {
   // The timeout is a workaround for the charts.
   setTimeout(() => {
    // eslint-disable-next-line no-undef
    const event = new CustomEvent("toggleNavigation");
    window.dispatchEvent(event);
  }, 320);
};

export const toggleTiles = () => {
  // The timeout is a workaround for the charts.
  setTimeout(() => {
   // eslint-disable-next-line no-undef
   const event = new CustomEvent("toggleNavigation");
   window.dispatchEvent(event);
 }, 320);
};

export const toggleSettings = () => {
  // eslint-disable-next-line no-undef
  const event = new CustomEvent("toggleSettings");
  window.dispatchEvent(event);
};

export const toggleNotifications = () => {
   // eslint-disable-next-line no-undef
   const event = new CustomEvent("toggleNotifications");
   window.dispatchEvent(event);   
}