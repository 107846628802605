import {
  BillOfMaterialItemIdentity,
  DeliveryProductListItemModel,
  MaterialTransferByShipmentNumberViewModel,
  MaterialTransferItemStatus,
  MaterialTransferReason,
  SerialNumbersNeeded,
  ValidateMaterialTransferRequestModel
} from "../../../../domain/client-customer";

export interface NonSerializedItemState {
  billOfMaterialItemIdentity: BillOfMaterialItemIdentity;
  quantity: number;
  materialCategory: string;
  validationQuantities?: ValidationQuantities;
  dplItem: DeliveryProductListItemModel;
  quantityValidated: boolean;
  note?: string;
  handlingUnit?: string;
  handligUnitQuantity?: number;
}

export interface SerializedItemState {
  materialCategory: string;
  quantity: number;
  billOfMaterialItemIdentity: BillOfMaterialItemIdentity;
  status?: MaterialTransferItemStatus;
  ericssonSerialNumber?: string;
  customerSerialNumber?: string;
  dplItem: DeliveryProductListItemModel | undefined;
  quantityValidated: boolean;
  serialNumbersValidated: boolean;
  serialNumbersNeeded?: SerialNumbersNeeded | undefined;
  isSerialNumbersLocked: boolean;
  note?: string;
  handlingUnit?: string;
  handligUnitQuantity?: number;
  serialNumberIndex?: number;
  serialKey?: number;
  validationQuantities?: {
    [key: string]: number;
  };
}

export enum ComponentViews {
  Details,
  TransferList,
  ValidationSummery,
  ReportDeviation,
  ScanSerialNumbers
}

export type SelectableMaterialTransferOverviewViewModel =
  MaterialTransferByShipmentNumberViewModel & {
    Selected: boolean;
  };

export type ReceiveMaterialTransferStateItem = {
  HandlingUnits: MaterialTransferHandlingUnitState[];
  MaterialTransferId: string;
  Reason: MaterialTransferReason;
  SiteProjectId: string;
  SiteProjectName: string;
};

export type MaterialTransferHandlingUnitState = {
  ExternalHandlingUnit: string;
  NonSerializedItems: NonSerializedItemState[];
  SerializedItems: SerializedItemState[];
  DeliveryProductListItems: DeliveryProductListItemModel[];
  Order: number;
};

export type ValidateReceiveMaterialRequestModel =
  ValidateMaterialTransferRequestModel & {
    MaterialTransferId: string;
    SiteProjectId: string;
  };

export type ValidationQuantities = Partial<
  Record<MaterialTransferItemStatus, number>
>;

export type SmartScanItemUpdate = {
  constructionItemId: string;
  validationQuantities: ValidationQuantities;
  ericssonSerialNumber?: string;
  customerSerialNumber?: string;
};

export const validationQuantitiesKeys = Object.values(
  MaterialTransferItemStatus
);

export interface ReceiveMaterialTransferItemViewModel {
  ericssonProductNumber: string;
  quantity: number;
  status: MaterialTransferItemStatus;
}
export interface DeviationSummaryItem {
  ericssonProductNumber?: string;
  quantity: number;
  status: string;
}
