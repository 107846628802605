import ReleaseNotes from "../components/release-notes/release-notes";
import SiteProjectItem from "../components/site-projects/site-project-item";
import SiteProjectList from "../components/site-projects/site-project-list";
import UrlParameters from "../domain/enums/url-parameters";
import BomViewerRoute from "../routes/bom-viewer";
import ChecklistViewerRoute from "../routes/checklist-viewer";
import MaterialInstallationOverviewRoute from "../routes/material-installation-overview";
import MaterialInstallationValidationRoute from "../routes/material-installation-validation";
import MaterialTransferDetailsRoute from "../routes/material-transfer-details";
import MaterialTransferOverviewRoute from "../routes/material-transfer-overview";
import MaterialTransferValidationRoute from "../routes/material-transfer-validation-route";
import ReceiveMaterialOverviewRoute from "../routes/receive-material-overview";
import ReceiveMaterialValidationRoute from "../routes/receive-material-validation-route";
import { MenuTitleKeys } from "../translation/dictionary-keys";
import { InjectUrlParameter } from "../utils/utils";
import { MenuDictionary } from "./types";

export const viewAccessMenuDictionary: MenuDictionary = {
  SiteProjectsOverview: {
    link: "/siteprojects",
    menuTitleKey: MenuTitleKeys.SiteProjectsMenuTitle,
    component: SiteProjectList,
    exact: true,
    routes: [
      {
        link: `/siteprojects/${InjectUrlParameter(
          UrlParameters.siteProjectId
        )}`,
        component: SiteProjectItem,
        exact: true
      },
      {
        link: `/siteprojects/billofmaterial/${InjectUrlParameter(
          UrlParameters.siteProjectId
        )}`,
        component: BomViewerRoute,
        exact: true
      },
      {
        link: `/siteprojects/${InjectUrlParameter(
          UrlParameters.siteProjectId
        )}/activities/${InjectUrlParameter(
          UrlParameters.activityId
        )}/survey/${InjectUrlParameter(UrlParameters.surveyId)}`,
        component: ChecklistViewerRoute,
        exact: true
      }
    ]
  },
  MaterialTransfersOverview: {
    menuTitleKey: MenuTitleKeys.MaterialTransferOverviewTitle,
    link: "/materialtransferoverview",
    component: MaterialTransferOverviewRoute,
    exact: true,
    routes: [
      {
        link: `/materialtransfer/${InjectUrlParameter(
          UrlParameters.materialTransferId
        )}`,
        component: MaterialTransferDetailsRoute,
        exact: true
      },
      {
        link: `/materialtransfer/${InjectUrlParameter(
          UrlParameters.materialTransferId
        )}/validate`,
        component: MaterialTransferValidationRoute,
        exact: true
      }
    ]
  },
  ReceiveMaterialOverview: {
    menuTitleKey: MenuTitleKeys.ReceiveMaterialOverviewTitle,
    link: "/receivematerial",
    component: ReceiveMaterialOverviewRoute,
    exact: true,
    routes: [
      {
        link: `/receivematerial/${InjectUrlParameter(
          UrlParameters.shipmentReferenceNumber
        )}`,
        component: ReceiveMaterialValidationRoute,
        exact: true
      }
    ]
  },
  MaterialInstallationsOverview: {
    menuTitleKey: MenuTitleKeys.MaterialInstallationOverviewTitle,
    link: "/materialinstallationoverview",
    component: MaterialInstallationOverviewRoute,
    exact: true,
    routes: [
      {
        link: `/materialinstallation/${InjectUrlParameter(
          UrlParameters.siteProjectId
        )}`,
        component: MaterialInstallationValidationRoute,
        exact: true
      }
    ]
  },
  ReleaseNotes: {
    menuTitleKey: MenuTitleKeys.ReleaseNotesMenuTitle,
    link: "/release-notes",
    component: ReleaseNotes,
    exact: true
  }
};
