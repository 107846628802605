import { useState, useEffect, useCallback } from "react";

export type AppSettings = {
  applicationLinkBaseUri?: string;
  scanditLicense?: string;
  googleMapsKey?: string;
  ecoAndroidUrl?: string;
  ecoIosUrl?: string;
};

type RawAppSettings = {
  ["ApplicationLinkBaseUri"]?: string;
  ["scanditLicense"]?: string;
  ["googleMapsKey"]?: string;
  ["ecoAndroidUrl"]?: string;
  ["ecoIosUrl"]?: string;
};

const mapRawAppSetting = (rawAppSettings: RawAppSettings): AppSettings => ({
  applicationLinkBaseUri: rawAppSettings["ApplicationLinkBaseUri"],
  scanditLicense: rawAppSettings["scanditLicense"],
  googleMapsKey: rawAppSettings["googleMapsKey"],
  ecoAndroidUrl: rawAppSettings["ecoAndroidUrl"],
  ecoIosUrl: rawAppSettings["ecoIosUrl"]
});

export const useAppSettings = (): [AppSettings] => {
  const [appSettings, setAppSettings] = useState<AppSettings>({});

  const getAppSettings = useCallback(async (): Promise<void> => {
    try {
      const appsettingsResponse = await fetch("/appSettings.json");
      if (!appsettingsResponse.ok)
        throw new Error("Non-ok response code returned");
      const appSettingsJson = await appsettingsResponse.json();
      const parsedAppSettings = mapRawAppSetting(
        appSettingsJson as RawAppSettings
      );
      setAppSettings(parsedAppSettings);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect((): void => {
    getAppSettings();
  }, [getAppSettings]);

  return [appSettings];
};
