import React from "react";

import cx from "classnames";
import ILoader from "./ILoader";

const Loader = ({ size, className, style }: ILoader) => (
  <div className={cx("loading", size || "small", className)} style={style} />
);

export default Loader;
