import React, { ReactElement } from "react";
import cx from "classnames";

import { UserInfo } from "./contracts";

import { Row, Column } from "../layout";
import Icon from "../icon/Icon";
import Switch from "../switch/Switch";
import Button from "../button/Button";
import SettingsItem from "./SettingsItem";
import { uniqueId } from "../../utils/utils";
import SettingsNode from "./SettingsNode";

interface Props {
  title?: string;
  user?: UserInfo;
  settingsContent?: ReactElement<typeof SettingsNode>[] | ReactElement<typeof SettingsItem>[];
  onLogout?: React.MouseEventHandler;
  settingsVisible: boolean;
  toggleSettings(): void;
  theme: string;
  toggleTheme?(): void;
  iconName?: string;
}

const Settings = ({
  user,
  title,
  settingsContent,
  onLogout,
  settingsVisible,
  toggleSettings,
  theme,
  toggleTheme,
  iconName
}: Props) => {
  return (
    <div className={cx("settings", { hidden: !settingsVisible })}>
      <div className="syspanel-top">
        <span className="syspanel-title">{title}</span>
        <Icon onClick={toggleSettings} className="syspanel-close" name="cross" />
      </div>
      <Row>
        <Column sm={12} asContainer>
          {user && (
            <div className="profile">
              <Icon name={iconName || "profile"} />
              <div className="username">{`${user.firstName} ${user.lastName}`}</div>

              {user.header && <div className="header syspanel-header">{user.header}</div>}
              {user.subHeader && <div className="syspanel-subheader">{user.subHeader}</div>}
              {((user.header !== undefined && user.header !== "") ||
                (user.subHeader !== undefined && user.subHeader !== "")) && (
                <div className="dashedline"></div>
              )}
            </div>
          )}

          <div className="content">
            <div className="title">My settings</div>
            {toggleTheme && (
              <SettingsItem text="Switch theme" key={uniqueId()}>
                <Switch
                  onLabel="Light"
                  offLabel="Dark"
                  value={theme === "light"}
                  onChange={() => {
                    toggleTheme();
                  }}
                />
              </SettingsItem>
            )}
            {settingsContent}
          </div>

          {onLogout && (
            <div className="bottom">
              <Button big onClick={onLogout}>
                <Icon name="logout" />
                Sign out
              </Button>
            </div>
          )}
        </Column>
      </Row>
    </div>
  );
};

export default Settings;
