import React, { useMemo, useContext, useEffect, useCallback } from "react";

import { Loader, Button, Accordion, AccordionItem } from "@react-gcc-eds/core";

import { LayoutContext } from "../../../../contexts/layout-context";
import { MaterialTransferToValidateDetailsViewModel } from "../../../../domain/client-customer";
import {
  NamespaceKeys,
  MaterialSpecificKeys,
  GeneralKeys,
  ReceivingMaterialSpecificKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import CenteredContainer from "../../../common/layout/centered-container";
import MaterialTransferDetailsView from "./material-transfer-details-view";

import "./material-transfer-details.scss";

type Props = {
  loading: boolean;
  error: string | undefined;
  onRetry: () => void;
  materialTransferDetails:
    | MaterialTransferToValidateDetailsViewModel
    | undefined;
};

const MaterialTransferDetailsViewComponent = ({
  loading,
  error,
  onRetry,
  materialTransferDetails
}: Props): React.ReactElement => {
  const { translate } = useTranslation();
  const { pageTitle, setPageTitle } = useContext(LayoutContext);

  const title = useMemo<string>(
    () => materialTransferDetails?.SiteProjectName ?? "",
    [materialTransferDetails]
  );

  const subtitle = useMemo<string>(
    () =>
      (materialTransferDetails?.Reason &&
        translate(
          NamespaceKeys.MaterialSpecific,
          MaterialSpecificKeys[
            materialTransferDetails.Reason as keyof typeof MaterialSpecificKeys
          ]
        )) ||
      "",
    [materialTransferDetails, translate]
  );
  const setTitle = useCallback(() => {
    if (
      !setPageTitle ||
      (pageTitle?.title === title && pageTitle.subtitle === subtitle)
    )
      return;
    setPageTitle({ title, subtitle });
  }, [title, subtitle, setPageTitle, pageTitle]);

  useEffect(() => setTitle(), [setTitle]);

  return error ? (
    <CenteredContainer>
      <div className="material-transfer-api-error">
        <div className="material-transfer-retry-title">{error}</div>
        <Button className="material-transfer-retry-button" onClick={onRetry}>
          {translate(NamespaceKeys.General, GeneralKeys.Retry)}
        </Button>
      </div>
    </CenteredContainer>
  ) : loading ? (
    <CenteredContainer>
      <Loader size="large" />
    </CenteredContainer>
  ) : (
    <div className="rm-container">
      <div className="rm-body">
        <Accordion lines>
          {materialTransferDetails?.HandlingUnits?.map((item, i) => (
            <AccordionItem
              className="material-transfer-details-component-item"
              defaultOpen={i === 0}
              key={item?.ExternalHandlingUnit}
              title={
                translate(
                  NamespaceKeys.ReceivingMaterialSpecific,
                  ReceivingMaterialSpecificKeys.HU
                ) + item.ExternalHandlingUnit
              }
            >
              <MaterialTransferDetailsView
                loading={loading}
                error={error}
                onRetry={onRetry}
                materialTransferDetails={item}
              />
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default MaterialTransferDetailsViewComponent;
