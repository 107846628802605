import React from "react";
import cx from "classnames";
import ITooltip from "./ITooltip";

const Tooltip = ({
  type = "dotted",
  position = "top",
  primary,
  text,
  alwaysShown,
  children,
  className,
  style
}: ITooltip) => {
  return (
    <span className={cx("tooltip", { [type]: type }, className)} style={style}>
      {children}
      <span
        className={cx(
          "message",
          { [position]: position },
          { blue: primary },
          { visible: alwaysShown }
        )}
      >
        {text.split("\n").map(l => (
          <div key={l}>
            {l}
            <br />
          </div>
        ))}
      </span>
    </span>
  );
};
export default Tooltip;
