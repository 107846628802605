import * as React from "react";

import {
  Accordion,
  AccordionItem,
  Button,
  Column,
  Row
} from "@react-gcc-eds/core";
import { useParams } from "react-router";

import UrlParameters from "../../../../domain/enums/url-parameters";
import {
  GeneralKeys,
  NamespaceKeys
} from "../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../translation/translation-utils";
import { ReceiveMaterialContext } from "../contexts/receive-material-context";
import { ComponentViews } from "../shared/receive-material-models";
import ReceiveMaterialReportDeviationView from "./receive-material-report-deviation-view";

import "./receive-material-report-deviation.scss";

type MatchProps = {
  [UrlParameters.shipmentReferenceNumber]?: string;
};

const ReceiveMaterialReportDeviationViewComponent: () => React.ReactElement =
  (): React.ReactElement => {
    const { translate } = useTranslation();
    const { shipmentReferenceNumber } = useParams<MatchProps>();
    const { setCurrentView } = React.useContext(ReceiveMaterialContext);
    const { transferDetailsStateItems, setTransferDetailsStateItems } =
      React.useContext(ReceiveMaterialContext);

    const handleOnClickBack = (): void => {
      setCurrentView?.(ComponentViews.TransferList);
    };

    const handleOnClickReport = (): void => {
      setTransferDetailsStateItems?.(transferDetailsStateItemsClone);
      setCurrentView?.(ComponentViews.TransferList);
    };
    const transferDetailsStateItemsClone = [...transferDetailsStateItems];

    return (
      <div className="rm-container">
        <div className="rm-header">{shipmentReferenceNumber}</div>
        <div className="rm-body">
          <div className="rm-report-deviations">
            <Accordion lines className="report-deviation-accordian">
              {transferDetailsStateItemsClone.map((item, i) => (
                <AccordionItem
                  defaultOpen={i === 0}
                  className="deviation-accordian-item"
                  key={item.MaterialTransferId}
                  title={item.SiteProjectName ?? ""}
                >
                  <ReceiveMaterialReportDeviationView
                    key={item.MaterialTransferId + "view"}
                    handlingUnits={item.HandlingUnits}
                  />
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
        <div className="rm-footer">
          <Row className="rm-row">
            <Column sm={6}>
              <Button onClick={handleOnClickBack}>
                {translate(NamespaceKeys.General, GeneralKeys.Back)}
              </Button>
            </Column>

            <Column sm={6} className="justify-content-right">
              <Button className="primary" onClick={handleOnClickReport}>
                {translate(NamespaceKeys.General, GeneralKeys.Report)}
              </Button>
            </Column>
          </Row>
        </div>
      </div>
    );
  };

export default ReceiveMaterialReportDeviationViewComponent;
