import React, { ReactElement, useCallback } from "react";

import { Column, Row, TextField, Button, Icon } from "@react-gcc-eds/core";

import {
  MaterialTransferItemStatus,
  DplItemCustomerSerialNumberEnum
} from "../../../../../../domain/client-customer";
import {
  GeneralKeys,
  NamespaceKeys,
  ComponentSpecificKeys,
  DplSpecificKeys
} from "../../../../../../translation/dictionary-keys";
import { useTranslation } from "../../../../../../translation/translation-utils";
import ProductDetailsDialog, {
  DeliveryProductListItemInformation
} from "../../../../../common/product-details-modal/product-details-dialog";
import SerialNumberScanner from "../../../../../common/serial-number-scanner";
import {
  SerialNumberValidationItem,
  SerialNumberType
} from "../serial-number-validator-component";

import "./serial-number-validator-item.scss";

type Props = {
  item: SerialNumberValidationItem;
  isValidated: boolean;
  validButtonDisabled: boolean;
  onEricssonSerialNumberChange: (serialNumber: string) => void;
  onCustomerSerialNumberChange: (serialNumber: string) => void;
  onItemValidate: () => void;
  onItemUndo: () => void;
  onToggleScanner: (serialNumberType?: SerialNumberType) => void;
  showScanner: boolean;
  onScan: (serial: string) => void;
  onValidateScanResult: (scannedData: string) => boolean;
  showProductDetailsDialog: boolean;
  onClickProductDetailsButton: () => void;
  onCloseProductDetailsDialog: () => void;
  dplInformationItem: DeliveryProductListItemInformation | undefined;
};

const SerialNumberValidatorItemView = ({
  item,
  isValidated,
  validButtonDisabled,
  onCustomerSerialNumberChange,
  onEricssonSerialNumberChange,
  onItemUndo,
  onItemValidate,
  onToggleScanner,
  showScanner,
  onScan,
  onValidateScanResult,
  showProductDetailsDialog,
  onClickProductDetailsButton,
  onCloseProductDetailsDialog,
  dplInformationItem
}: Props): ReactElement => {
  const { translate } = useTranslation();
  const handleOnEricssonScanButton = useCallback(() => {
    onToggleScanner(SerialNumberType.EricssonSerialNumber);
  }, [onToggleScanner]);

  const handleOnCustomerScanButton = useCallback(() => {
    onToggleScanner(SerialNumberType.CustomerSerialNumber);
  }, [onToggleScanner]);

  return showScanner ? (
    <SerialNumberScanner
      onClose={onToggleScanner}
      title={item.dplItem.EricssonProductNumber ?? ""}
      onScan={onScan}
      defaultSmallSearchArea={false}
      onValidateScanResult={onValidateScanResult}
    />
  ) : (
    <div className="serial-number-item-container">
      <Row>
        <Column sm={9}>
          <div className="serial-number-title">
            {item.dplItem.EricssonProductNumber}
          </div>
          <div className="serial-number-subtitle">{item.materialCategory}</div>
        </Column>
        <Column sm={3} className="justify-content-right">
          <div className="action-icons">
            {item.status === MaterialTransferItemStatus.Damaged && (
              <span className="validation-status">
                {translate(NamespaceKeys.General, GeneralKeys.Damaged)}
              </span>
            )}
            {!!dplInformationItem && (
              <Icon
                name="info"
                onClick={onClickProductDetailsButton}
                className={"actions-icon"}
              />
            )}
          </div>
        </Column>
      </Row>
      {item.serialNumbersNeeded?.IsEricssonSerialNumberNeeded && (
        <Row className="serial-number-row">
          <div className="serial-number-field">
            <TextField
              placeholder={translate(
                NamespaceKeys.ComponentSpecific,
                ComponentSpecificKeys.SerialNumber
              )}
              value={item.ericssonSerialNumber ?? ""}
              fullWidth
              disabled={isValidated}
              onChange={onEricssonSerialNumberChange}
              label={translate(
                NamespaceKeys.DplSpecific,
                DplSpecificKeys.EricssonSerialNumber
              )}
            />
          </div>
          {(!isValidated && (
            <div className="scan-button">
              <Button
                iconName="camera-photo"
                onClick={handleOnEricssonScanButton}
              />
            </div>
          )) || <></>}
        </Row>
      )}
      {item.serialNumbersNeeded?.IsCustomerSerialNumberNeeded &&
        item.serialNumbersNeeded.TypeOfCustomerSerialNumberNeeded ===
          DplItemCustomerSerialNumberEnum.CustomerSerialNumber && (
          <Row className="serial-number-row">
            <div className="serial-number-field">
              <TextField
                placeholder={translate(
                  NamespaceKeys.ComponentSpecific,
                  ComponentSpecificKeys.SerialNumber
                )}
                value={item.customerSerialNumber ?? ""}
                disabled={isValidated}
                onChange={onCustomerSerialNumberChange}
                fullWidth
                label={translate(
                  NamespaceKeys.DplSpecific,
                  DplSpecificKeys.CustomerSerialNumber
                )}
              />
            </div>
            {(!isValidated && (
              <div className="scan-button">
                <Button
                  iconName="camera-photo"
                  onClick={handleOnCustomerScanButton}
                />
              </div>
            )) || <></>}
          </Row>
        )}
      <Row className="validation-button-row">
        <Column sm={12} className="justify-content-right">
          {isValidated ? (
            <Button iconName="restore" onClick={onItemUndo}>
              {translate(NamespaceKeys.General, GeneralKeys.Restore)}
            </Button>
          ) : (
            <Button
              primary
              disabled={validButtonDisabled}
              onClick={onItemValidate}
              iconName="check"
            >
              {translate(NamespaceKeys.General, GeneralKeys.Done)}
            </Button>
          )}
        </Column>
      </Row>
      {showProductDetailsDialog && dplInformationItem && (
        <ProductDetailsDialog
          showModal
          item={dplInformationItem}
          onClose={onCloseProductDetailsDialog}
        />
      )}
    </div>
  );
};

export default SerialNumberValidatorItemView;
