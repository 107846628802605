import { useState, useCallback } from "react";

import { SearchArea } from "scandit-sdk";

export interface SearchAreaType extends SearchArea {
  toggleIcon: string;
  isLarge: boolean;
}

const largeSearchArea: SearchAreaType = {
  height: 0.2,
  width: 0.95,
  x: 0.025,
  y: 0.4,
  toggleIcon: "resize-down",
  isLarge: true
};

const smallSearchArea: SearchAreaType = {
  height: 0.04,
  width: 0.95,
  x: 0.025,
  y: 0.48,
  toggleIcon: "resize-up",
  isLarge: false
};

type ReturnType = [SearchAreaType, () => void];

const useSearchArea = (defaultSmallSize?: boolean): ReturnType => {
  const [searchArea, setSearchArea] = useState<SearchAreaType>(
    defaultSmallSize ? smallSearchArea : largeSearchArea
  );

  const toggleSize = useCallback(() => {
    setSearchArea((prev) =>
      prev === smallSearchArea ? largeSearchArea : smallSearchArea
    );
  }, []);

  return [searchArea, toggleSize];
};

export default useSearchArea;
