import React, { useCallback, useContext, useState, useEffect } from "react";

import { Button, Icon, Tile, Row, Loader } from "@react-gcc-eds/core";

import { AuthenticationContext } from "../../contexts/authentication-context";
import { CustomerContext } from "../../contexts/customer-context";
import { SitesClient, SiteDto } from "../../domain/client-customer";
import {
  ComponentSpecificKeys,
  NamespaceKeys,
  GeneralKeys,
  SiteSpecificKeys
} from "../../translation/dictionary-keys";
import { useTranslation } from "../../translation/translation-utils";
import CenteredContainer from "../common/layout/centered-container";
import TitleValuePair from "../common/layout/title-value-pair";
import MapTile from "./site-project-item/map-tile/index";
import WeatherTile from "./site-project-item/weather-tile";

import "./site-information.scss";

type Props = {
  siteId: string;
};

export const SiteInformation = ({ siteId }: Props): JSX.Element => {
  const { currentCustomer } = useContext(CustomerContext);
  const [site, setSite] = useState<SiteDto>();
  const [loading, setLoading] = useState<boolean>(true);
  const [onError, setOnError] = useState<boolean>(false);
  const [collapsed, setCollapsed] = useState<boolean>(true);
  const { getCustomerConfigurationProvider } = useContext(
    AuthenticationContext
  );
  const { translate } = useTranslation();

  const getSite = useCallback(async () => {
    const customerIdentifier =
      currentCustomer && currentCustomer.HeaderIdentifier;
    if (!customerIdentifier) {
      return;
    }
    setLoading(true);
    new SitesClient(await getCustomerConfigurationProvider())
      .getSite(siteId, customerIdentifier)
      .then((fetchedSite) => {
        setSite(fetchedSite);
      })
      .catch(() => {
        setOnError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [currentCustomer, getCustomerConfigurationProvider, siteId]);

  useEffect(() => {
    getSite();
  }, [getSite]);

  const handleRetry = (): void => {
    setOnError(false);
    getSite();
  };

  const handleOnToggle = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  return (
    <>
      {onError ? (
        <Tile
          title={translate(
            NamespaceKeys.ComponentSpecific,
            ComponentSpecificKeys.SitesCoordinatesErrorMessageTitle
          )}
        >
          <p>
            {translate(NamespaceKeys.General, GeneralKeys.PleaseTryAgainLater)}
          </p>
          <Button className="btn-icon" onClick={handleRetry}>
            <Icon name="reload" className="btn-icon" />
            {translate(NamespaceKeys.General, GeneralKeys.Reload)}
          </Button>
        </Tile>
      ) : site?.Latitude?.Value == 0 && site.Longitude?.Value == 0 ? (
        <Row>
          <Tile
            title={translate(
              NamespaceKeys.SiteSpecific,
              SiteSpecificKeys.SiteInformation
            )}
            className="site-information"
          >
            <TitleValuePair
              title={translate(
                NamespaceKeys.SiteSpecific,
                SiteSpecificKeys.SiteIdByCustomer
              )}
              value={
                site?.SiteIdByCustomer?.Value ??
                translate(NamespaceKeys.General, GeneralKeys.NotAvailable)
              }
            />
            <TitleValuePair
              title={translate(
                NamespaceKeys.SiteSpecific,
                SiteSpecificKeys.Name
              )}
              value={
                site?.Name?.Value ??
                translate(NamespaceKeys.General, GeneralKeys.NotAvailable)
              }
            />
            <TitleValuePair
              title={translate(
                NamespaceKeys.SiteSpecific,
                SiteSpecificKeys.Type
              )}
              value={
                site?.Type?.Value ??
                translate(NamespaceKeys.General, GeneralKeys.NotAvailable)
              }
            />
          </Tile>
        </Row>
      ) : (
        <>
          <Row>
            <Tile
              title={translate(
                NamespaceKeys.SiteSpecific,
                SiteSpecificKeys.SiteInformation
              )}
              className="site-information"
            >
              {loading ? (
                <CenteredContainer>
                  <Loader size="medium" />
                </CenteredContainer>
              ) : (
                <>
                  <TitleValuePair
                    title={translate(
                      NamespaceKeys.SiteSpecific,
                      SiteSpecificKeys.SiteIdByCustomer
                    )}
                    value={
                      site?.SiteIdByCustomer?.Value ??
                      translate(NamespaceKeys.General, GeneralKeys.NotAvailable)
                    }
                  />
                  <TitleValuePair
                    title={translate(
                      NamespaceKeys.SiteSpecific,
                      SiteSpecificKeys.CustomerRegion
                    )}
                    value={
                      site?.CustomerRegion?.Value ??
                      translate(NamespaceKeys.General, GeneralKeys.NotAvailable)
                    }
                  />
                  <TitleValuePair
                    title={translate(
                      NamespaceKeys.SiteSpecific,
                      SiteSpecificKeys.Region
                    )}
                    value={
                      site?.Region?.Value ??
                      translate(NamespaceKeys.General, GeneralKeys.NotAvailable)
                    }
                  />
                  <TitleValuePair
                    title={translate(
                      NamespaceKeys.SiteSpecific,
                      SiteSpecificKeys.Subregion
                    )}
                    value={
                      site?.Subregion?.Value ??
                      translate(NamespaceKeys.General, GeneralKeys.NotAvailable)
                    }
                  />
                  <TitleValuePair
                    title={translate(
                      NamespaceKeys.SiteSpecific,
                      SiteSpecificKeys.Name
                    )}
                    value={
                      site?.Name?.Value ??
                      translate(NamespaceKeys.General, GeneralKeys.NotAvailable)
                    }
                  />
                  {!collapsed && (
                    <>
                      <TitleValuePair
                        title={translate(
                          NamespaceKeys.SiteSpecific,
                          SiteSpecificKeys.Type
                        )}
                        value={
                          site?.Type?.Value ??
                          translate(
                            NamespaceKeys.General,
                            GeneralKeys.NotAvailable
                          )
                        }
                      />
                      <TitleValuePair
                        title={translate(
                          NamespaceKeys.SiteSpecific,
                          SiteSpecificKeys.Address
                        )}
                        value={
                          site?.Address?.Value ??
                          translate(
                            NamespaceKeys.General,
                            GeneralKeys.NotAvailable
                          )
                        }
                      />
                      <TitleValuePair
                        title={translate(
                          NamespaceKeys.SiteSpecific,
                          SiteSpecificKeys.ZipCode
                        )}
                        value={
                          site?.ZipCode?.Value ??
                          translate(
                            NamespaceKeys.General,
                            GeneralKeys.NotAvailable
                          )
                        }
                      />
                      <TitleValuePair
                        title={translate(
                          NamespaceKeys.SiteSpecific,
                          SiteSpecificKeys.City
                        )}
                        value={
                          site?.City?.Value ??
                          translate(
                            NamespaceKeys.General,
                            GeneralKeys.NotAvailable
                          )
                        }
                      />
                      <TitleValuePair
                        title={translate(
                          NamespaceKeys.SiteSpecific,
                          SiteSpecificKeys.Latitude
                        )}
                        value={
                          site?.Latitude?.Value ??
                          translate(
                            NamespaceKeys.General,
                            GeneralKeys.NotAvailable
                          )
                        }
                      />
                      <TitleValuePair
                        title={translate(
                          NamespaceKeys.SiteSpecific,
                          SiteSpecificKeys.Longitude
                        )}
                        value={
                          site?.Longitude?.Value ??
                          translate(
                            NamespaceKeys.General,
                            GeneralKeys.NotAvailable
                          )
                        }
                      />
                    </>
                  )}
                  {/*
                    Disabling rule because we are not controlling the jsx-a11y plugin.
                    Rule is regarding accessibility only
                  */}
                  {/* eslint-disable-next-line */}
                  <a className="show-more subtle-link" onClick={handleOnToggle}>
                    {collapsed
                      ? translate(NamespaceKeys.General, GeneralKeys.ShowMore)
                      : translate(NamespaceKeys.General, GeneralKeys.ShowLess)}
                  </a>
                </>
              )}
            </Tile>
          </Row>
          {!loading && (
            <Row>
              {site?.Longitude?.Value &&
              site?.Latitude?.Value &&
              site.City?.Value ? (
                <MapTile
                  lng={site.Longitude.Value}
                  lat={site.Latitude.Value}
                  zoom={10}
                  city={site.City.Value}
                />
              ) : (
                <Tile
                  title={translate(
                    NamespaceKeys.SiteSpecific,
                    SiteSpecificKeys.Location
                  )}
                >
                  {translate(
                    NamespaceKeys.ComponentSpecific,
                    ComponentSpecificKeys.SitesLocationErrorMessageTitle
                  )}
                </Tile>
              )}
            </Row>
          )}
          {!loading && (
            <Row>
              {site?.Id ? (
                <WeatherTile siteId={site.Id} collapsedDays={3} />
              ) : (
                <Tile
                  title={translate(
                    NamespaceKeys.SiteSpecific,
                    SiteSpecificKeys.Weather
                  )}
                >
                  {translate(
                    NamespaceKeys.ComponentSpecific,
                    ComponentSpecificKeys.SitesWeatherErrorMessageTitle
                  )}
                </Tile>
              )}
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default SiteInformation;
