import React, { useCallback } from "react";

import { ReceiveMaterialContext } from "../contexts/receive-material-context";
import {
  MaterialTransferHandlingUnitState,
  ReceiveMaterialTransferStateItem,
  SerializedItemState
} from "../shared/receive-material-models";
import SerialNumberValidator from "./serial-number-validator";

import "./scan-serial-numbers.scss";

type ReceiveMaterialScanSerialNumbersTransferItemProps = {
  materialTransferId: string;
  handlingUnit: MaterialTransferHandlingUnitState;
};

const ReceiveMaterialScanSerialNumbersTransferItem = ({
  materialTransferId,
  handlingUnit
}: ReceiveMaterialScanSerialNumbersTransferItemProps): React.ReactElement => {
  const { transferDetailsStateItems, setTransferDetailsStateItems } =
    React.useContext(ReceiveMaterialContext);

  const handleOnSerializedItemsUpdated = useCallback(
    (
      materialTransferId: string,
      serializedItemsToUpdate: SerializedItemState[]
    ): void => {
      const transToUpdate = transferDetailsStateItems.find(
        (x) => x.MaterialTransferId === materialTransferId
      );

      if (transToUpdate) {
        transToUpdate?.HandlingUnits.map((hu) => {
          hu.SerializedItems.map((previousItem: SerializedItemState) => {
            const matchingItem = serializedItemsToUpdate.find(
              (itemToUpdate) =>
                itemToUpdate.billOfMaterialItemIdentity ===
                previousItem.billOfMaterialItemIdentity
            );

            if (matchingItem) {
              previousItem.customerSerialNumber =
                matchingItem.customerSerialNumber;
              previousItem.ericssonSerialNumber =
                matchingItem.ericssonSerialNumber;
              previousItem.serialNumbersValidated =
                matchingItem.serialNumbersValidated;
            }

            return previousItem;
          });
        });

        if (typeof setTransferDetailsStateItems === "function") {
          setTransferDetailsStateItems(
            (prevTransferStateItem: ReceiveMaterialTransferStateItem[]) => {
              return prevTransferStateItem.map(
                (prevTransferItem: ReceiveMaterialTransferStateItem) => {
                  return transToUpdate.MaterialTransferId ===
                    prevTransferItem.MaterialTransferId
                    ? transToUpdate
                    : prevTransferItem;
                }
              );
            }
          );
        }
      }
    },
    [transferDetailsStateItems, setTransferDetailsStateItems]
  );
  return (
    <>
      <SerialNumberValidator
        materialTransferId={materialTransferId}
        serializedItems={handlingUnit.SerializedItems}
        onSerializedItemsUpdated={handleOnSerializedItemsUpdated}
      />
    </>
  );
};

export default ReceiveMaterialScanSerialNumbersTransferItem;
