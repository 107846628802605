import React from "react";
import cx from "classnames";
import IWizardPage from "./IWizardPage";

const WizardPage = ({title, children, page, totalPages, className }: IWizardPage) => {
  
  const subtitle = page !== undefined && totalPages !== undefined ?(
    <div className="subtitle">
        Step 
        <span style={{marginLeft: '2px', marginRight: '3px'}} className="current">{page}</span>
        of 
        <span style={{marginLeft: '3px', marginRight: '3px'}} className="total">{totalPages}</span>
      </div>
  ):null;

  return (
    <div className={cx("content", className)}>
      <div className="title">{title}</div>
      {subtitle}
      {children}
    </div>
  )
};

export default WizardPage;
